import { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/AppContext";
import AssignVendorDrawer from "../../Admin/Projects/AssignVendorDrawer";
import { ReactComponent as DownloadIcon } from "../../../assets/svg/download.svg";
import { ReactComponent as Plus } from "../../../assets/svg/plus.svg";
import { ReactComponent as Dot } from "../../../assets/svg/3-dot.svg";
import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../../constants/Constants";
import { FaStar } from "react-icons/fa";
import Loader from "../../../Loader";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { files } from "jszip";
const colors = {
  white: "#D3D3D3",
  blue: "#03298c",
};
const DetailedProjectTab = (props) => {
  const { type, setLoadTeamMember } = useContext(AppContext);
  const { projectData } = props;
  const [show, setShow] = useState(false);
  const [activeTag, setActiveTag] = useState("");

  useEffect(() => {
    if (projectData?.tags && projectData?.tags?.length > 0) {
      setActiveTag(projectData?.tags?.[0]?.name);
    }
  }, [projectData]);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [selectedScopeId, setSelectedScopeId] = useState(null);
  const [selectedRating, setSelectedRating] = useState([[]]);
  const stars = Array(5).fill(0);
  const [isMounted, setIsMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [assignVendor, setAssignVendor] = useState([]);
  const [assignId, setAssignId] = useState(null);
  const [tagData, setTagData] = useState(null);
  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const handleDownload = (file) => {
    const params = {
      filename: file,
    };
    axios
      .get(`${BASE_URL}/api/attachment-download`, { params, headers })
      .then((res) => {
        window.open(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRating = async (selectedRating, vendorIndex, scopeIndex) => {
    if (isMounted && selectedRating.length > 0) {
      const vendorRating = selectedRating[scopeIndex]?.[vendorIndex];

      if (!vendorRating) return;

      const [assignId] = Object.keys(vendorRating);
      const vendorIds = Object.keys(vendorRating[assignId]);

      if (!vendorIds.length) return;

      const [vendorId] = vendorIds;
      const rating = vendorRating[assignId][vendorId];
      const payload = { assign_id: assignId, vendor_id: vendorId, rating };

      try {
        await axios.put(`${BASE_URL}/api/vendor/rating`, payload, {
          headers,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleClick = async (
    value,
    vendorId,
    assignId,
    vendorIndex,
    scopeIndex
  ) => {
    const existingRating = [...selectedRating];
    existingRating[scopeIndex] = existingRating[scopeIndex] || [];
    existingRating[scopeIndex][vendorIndex] = {
      [assignId]: { [vendorId]: value },
    };
    setSelectedRating(existingRating);

    handleRating(existingRating, vendorIndex, scopeIndex);
  };

  const handleDotClick = (vendorId, tagId, scopeId) => {
    if (
      vendorId === selectedVendorId &&
      tagId === selectedTagId &&
      scopeId === selectedScopeId
    ) {
      setSelectedVendorId(null);
      setSelectedTagId(null);
      setSelectedScopeId(null);
    } else {
      setSelectedVendorId(vendorId);
      setSelectedTagId(tagId);
      setSelectedScopeId(scopeId);
    }
  };

  function handleDeleteClick(vendorId, assignId) {
    const params = {
      vendor_id: vendorId,
      assign_id: assignId,
    };
    axios
      .delete(`${BASE_URL}/api/vendor/delete`, { params, headers })
      .then((res) => {
        setLoadTeamMember(true);
        toast.success("Deleted Successfully");
      })
      .catch((err) => {
        toast.error("Please Try Again");
      });
  }

  const handleStatus = async (tagId, scopeId, assign_id, status) => {
    setLoading(true);
    const payload = {
      assign_id: assign_id,
      tag_id: tagId,
      scope_id: scopeId,
      project_id: projectData._id,
      status: status,
      vendor_id: JSON.parse(localStorage.getItem("User")).id,
    };

    await axios
      .put(`${BASE_URL}/api/vendor/status/update`, payload, { headers })
      .then((res) => {
        setLoadTeamMember(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleEdit = async (assign_id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/api/admin/getVendor/${assign_id}`,
        { headers }
      );
      setAssignVendor(res.data.getAssignVendor);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {show && (
        <AssignVendorDrawer
          show={show}
          isEdit={assignId ? true : false}
          setShow={setShow}
          tagDetail={assignVendor}
          tagData={tagData}
          scopeId={selectedScopeId}
          assignId={assignId}
        />
      )}
      {loading ? (
        <Loader className="mx-auto" />
      ) : (
        <div className="w-full flex my-10">
          <div
            id="projectDetails"
            className=" border border-[#C8C8C8]"
            style={{ background: "rgba(236, 244, 255, 0.4)" }}
          >
            <div className="text-[#5F5F5F] font-bold text-base w-full">
              <div className="pb-0.5 flex flex-col res125:text-xs res150:text-xs">
                {projectData?.tags?.map((tag, index) => (
                  <span
                    key={index}
                    className={`inline-block py-3 w-64 res125:w-52 res150:w-44 pl-5 mb-1 cursor-pointer ${activeTag === tag.name ? "bg-[#03298C] text-white" : ""
                      }`}
                    onClick={() => setActiveTag(tag.name)}
                  >
                    {tag.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className=" h-auto  ml-10 overflow-hidden w-full">
            <div className="flex flex-row w-full overflow-x-auto  pb-5">
              <ul className="flex gap-8 res125:gap-6 res150:gap-5 flex-wrap w-full">
                {(type === "admin" ||
                  type === "customer" ||
                  type === "service-provider") &&
                  projectData?.tags?.map((value, index) => {
                    return value?.serviceRequired?.map((techScope, i) => {
                      if (value?.name === activeTag) {
                        return (
                          <>
                            <div
                              className="w-80 res125:w-72 res150:w-72 flex flex-col mr-2  border border-[#C8C8C8] pt-3"
                              key={index}
                            >
                              <div className="font-bold text-base tracking-wide pb-2 text-[#000000]  px-6 res125:text-sm res150:text-xs">
                                {techScope?.microservice}
                              </div>
                              <div className="text-[#5F5F5F] font-normal text-sm pb-4 px-6 res150:text-xs">
                                <p className="pb-2 break-words">
                                  {value?.description}
                                </p>
                                <p className="pb-0.5 break-words">
                                  {value?.sub_vertical}
                                </p>
                                <p className="pb-0.5 break-words">
                                  {value?.sub_catagory}
                                </p>
                                <p className="pb-0.5 break-words">
                                  {value?.team_type}
                                </p>
                              </div>
                              <hr className="mx-6" />

                              {type === "admin" && (!["Completed"].includes(projectData.status)) &&
                                (techScope?.vendors.length > 0 ? (
                                  <div className="bg-[#03298C] text-white text-center py-1 mt-3 mx-6 rounded-full">
                                    <button
                                      className="text-base font-bold mx-auto res125:text-sm res150:text-xs w-full"
                                      onClick={() => {
                                        techScope?.vendors.forEach((vendor) => {
                                          handleEdit(vendor.assign_id);
                                          setTagData(value);
                                          setAssignId(vendor.assign_id);
                                          setShow(true);
                                        });
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                ) : null)}

                              <div className="py-3 px-6">
                                {type !== "service-provider" && JSON.parse(value?.attachments)?.map(
                                  (attachment, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="underline text-[#078CCE] font-normal text-sm break-words pb-1 flex cursor-pointer res150:text-xs"
                                        onClick={() =>
                                          handleDownload(attachment)
                                        }
                                      >
                                        <DownloadIcon className="pr-1.5" />
                                        {attachment}
                                      </div>
                                    );
                                  }
                                )}

                                {type === "service-provider" && JSON.parse(techScope?.files).map(
                                  (attachment, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="underline text-[#078CCE] font-normal text-sm break-words pb-1 flex cursor-pointer res150:text-xs"
                                        onClick={() =>
                                          handleDownload(attachment)
                                        }
                                      >
                                        <DownloadIcon className="pr-1.5" />
                                        {attachment}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              <hr />
                              {type === "service-provider" &&
                                techScope.vendorStatus === "Pending" && (
                                  <div className="flex flex-row mx-auto my-2">
                                    <div>
                                      <button
                                        className="p-2 mr-2 rounded border border-gray-300 w-24 res150:w-[4.6rem] res150:text-xs res150:p-1 flex flex-row bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900 group-hover:bg-green-200 dark:group-hover:bg-green-300 hover:scale-105 transition-transform items-center"
                                        onClick={() =>
                                          handleStatus(
                                            value?._id,
                                            techScope._id,
                                            techScope.assign_id,
                                            "Accepted"
                                          )
                                        }
                                      >
                                        <div>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            className="w-5 h-5 mr-0.5"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M4.5 12.75l6 6 9-13.5"
                                            />
                                          </svg>
                                        </div>
                                        Accept
                                      </button>
                                    </div>
                                    <div>
                                      <button
                                        className="p-2 rounded border border-gray-300  w-24 res150:w-[4.6rem] res150:p-1 flex res150:text-xs flex-row bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900 group-hover:bg-red-200 dark:group-hover:bg-red-300 hover:scale-105 transition-transform items-center"
                                        onClick={() =>
                                          handleStatus(
                                            value?._id,
                                            techScope._id,
                                            techScope.assign_id,
                                            "Rejected"
                                          )
                                        }
                                      >
                                        <div>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            className="w-5 h-5  mr-1"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M6 18L18 6M6 6l12 12"
                                            />
                                          </svg>
                                        </div>
                                        Reject
                                      </button>
                                    </div>
                                  </div>
                                )}
                              {type === "admin" &&
                                (techScope?.vendors.length > 0 ? (
                                  <div>
                                    {techScope?.vendors.map(
                                      (vendor, vendorIndex) => (
                                        <>
                                          <div className="flex flex-col py-4 bg-[#f7fbff]">
                                            <div className="flex px-6">
                                              <div className="flex justify-between w-full">
                                                <p
                                                  className="text-base font-bold mr-4 res150:text-sm"
                                                  title={vendor.name}
                                                >
                                                  {vendor.name?.length > 10
                                                    ? vendor.name.slice(0, 10) +
                                                    "..."
                                                    : vendor.name}
                                                </p>
                                                <div className="relative">
                                                  <div
                                                    className="py-2.5 cursor-pointer"
                                                    onClick={() => {
                                                      handleDotClick(
                                                        vendor.id,
                                                        value._id,
                                                        techScope.scopeId
                                                      );
                                                    }}
                                                  >
                                                    <Dot />
                                                  </div>
                                                  {selectedTagId ===
                                                    value._id &&
                                                    selectedScopeId ===
                                                    techScope.scopeId &&
                                                    selectedVendorId ===
                                                    vendor.id && (
                                                      <div className="bg-white w-28 shadow-md rounded-md absolute top-full -ml-16">
                                                        <ul>
                                                          <li
                                                            className="p-2 cursor-pointer rounded hover:bg-gray-200 text-sm text-center"
                                                            onClick={() =>
                                                              handleDeleteClick(
                                                                vendor.id,
                                                                vendor.assign_id
                                                              )
                                                            }
                                                          >
                                                            Delete
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="flex px-6 my-2">
                                              <div className="flex justify-between w-full text-sm font-normal text-[#5f5f5f] res125:text-xs res150:text-xs">
                                                <p
                                                  className="mr-4 whitespace-nowrap overflow-hidden text-ellipsis "
                                                  title={vendor?.email}
                                                >
                                                  {vendor.email}
                                                </p>
                                                <span>{vendor.contact_no}</span>
                                              </div>
                                            </div>
                                            <div className="flex px-6 mb-2">
                                              <div className="flex justify-between w-full text-sm font-normal text-[#5f5f5f] res125:text-xs res150:text-xs">
                                                <p className="mr-4 ">
                                                  {value.sub_vertical}
                                                </p>
                                                <span>
                                                  {techScope.microservice}
                                                </span>
                                              </div>
                                            </div>
                                            <hr className="mx-6 my-3" />
                                            <div className="flex px-6 my-2 w-full">
                                              <div className="flex-col w-1/2 text-sm font-medium res150:text-xs res125:text-xs">
                                                <p className="mr-4">
                                                  Overall Rating
                                                </p>
                                                <div className="mt-2 flex">
                                                  {stars.map((_, index) => {
                                                    return (
                                                      <FaStar
                                                        className="res125:w-3.5 res150:w-3.5"
                                                        key={index}
                                                        size={16}
                                                        color={
                                                          Math.round(
                                                            vendor.averageRating
                                                          ) > index
                                                            ? colors.blue
                                                            : colors.white
                                                        }
                                                        style={{
                                                          marginRight: 1,
                                                        }}
                                                      />
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                              <div className="flex-col w-1/2 justify-center text-sm font-medium pl-12 border-l border-[#c8c8c8]">
                                                <p className="mr-2 res125:text-xs res150:text-xs">
                                                  Add Rating
                                                </p>
                                                <div className="mt-2 flex">
                                                  {stars.map((_, index) => {
                                                    let isSelected;
                                                    if (
                                                      selectedRating[i]?.[
                                                      vendorIndex
                                                      ]?.[vendor.assign_id]?.[
                                                      vendor.id
                                                      ]
                                                    ) {
                                                      isSelected =
                                                        selectedRating[i]?.[
                                                        vendorIndex
                                                        ]?.[vendor.assign_id]?.[
                                                        vendor.id
                                                        ] > index;
                                                    } else {
                                                      isSelected =
                                                        vendor.rating > index;
                                                    }
                                                    const starColor = isSelected
                                                      ? colors.blue
                                                      : colors.white;

                                                    return (
                                                      <FaStar
                                                        className="res125:w-3.5 res150:w-3.5"
                                                        key={index}
                                                        size={16}
                                                        onClick={() =>
                                                          handleClick(
                                                            index + 1,
                                                            vendor.id,
                                                            vendor.assign_id,
                                                            vendorIndex,
                                                            i
                                                          )
                                                        }
                                                        color={starColor}
                                                        style={{
                                                          marginRight: 1,
                                                          cursor: "pointer",
                                                        }}
                                                      />
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <hr />
                                        </>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    <div class="flex flex-col py-4 res125:py-2 res150:py-2 cursor-pointer bg-[#f7fbff]">
                                      <div
                                        className="flex items-center mx-auto"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "SELECTED_TAG",
                                            JSON.stringify(
                                              value.serviceRequired[i]
                                            )
                                          );
                                          setTagData(value);
                                          setAssignVendor(null);
                                          setAssignId(null);
                                          setShow(true);
                                        }}
                                      >
                                        <Plus className="mr-4" />
                                        <span className="text-base font-bold res125:text-sm res150:text-xs">
                                          Assign Vendor
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </>
                        );
                      }
                    });
                  })}

                {type === "service-provider" && projectData?.tag && (
                  <>
                    <div className="w-[140px] flex flex-col mr-2">
                      <div className="font-bold text-base tracking-wide pb-2 text-[#000000]">
                        {projectData.tag.name} Detail
                      </div>
                      <div className="text-[#5F5F5F] font-normal text-sm pb-4">
                        <p className="pb-0.5 break-words">
                          {projectData.tag?.name}
                        </p>
                        <p className="pb-0.5 break-words">
                          {projectData.tag?.description}
                        </p>
                        <p className="pb-0.5 break-words">
                          {projectData.tag?.primary_scope}
                        </p>
                        <p className="pb-0.5 break-words">
                          {projectData.tag?.sub_category}
                        </p>
                        <p className="pb-0.5 break-words">
                          {projectData.tag?.team_type}
                        </p>
                        <p className="pb-0.5 break-words">
                          {projectData.scope?.name}
                        </p>
                      </div>
                      <div className="pb-4">
                        {JSON.parse(projectData.tag?.attachments)?.map(
                          (attachment, index) => {
                            return (
                              <p
                                key={index}
                                className="underline text-[#078CCE] font-normal text-sm break-words pb-1"
                              >
                                {attachment}
                              </p>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailedProjectTab;
