import EmailDetails from "../../Mails/EmailDetails";
import AppContext from "../../../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import ModifyProjectForm3 from "./ModifyProjectForm3";
import ModifyProject from "./ModifyProject";
import { useHistory } from "react-router-dom";
import { PROJECT_INFO_STEPPER } from "../../../../constants/Constants";

const ModifyTagForm = () => {
  const navigate = useHistory();
  const { selectedMail, projectInfo, tagInfo, setProjectInfo } =
    useContext(AppContext);
  const [fileNames, setFileNames] = useState([]);
  const [tagNo, setTagNo] = useState(-1);
  const [error, setError] = useState(false);
  const [errorMicro, setErrorMicro] = useState([]);

  useEffect(() => {
    if (navigate?.location?.state?.data) {
      setTagNo(navigate?.location?.state?.data);
    } else if (localStorage.getItem(PROJECT_INFO_STEPPER)) {
      setProjectInfo(JSON.parse(localStorage.getItem(PROJECT_INFO_STEPPER)));
      setTagNo(0);
    } else {
      setTagNo(-1);
    }
    let selectedMailFiles = JSON.parse(
      localStorage.getItem("SELECTED_MAIL")
    ).files;
    JSON.parse(selectedMailFiles).map((value, index) => {
      setFileNames((prevFileNames) => [
        ...prevFileNames,
        { id: index + 1, name: value.filename },
      ]);
    });
  }, []);

  const validateFields = () => {
    let error = "";
    for (let i = 0; i < projectInfo.tags; i++) {
      if (
        // !tagInfo[i].name ||
        // !tagInfo[i].description ||
        // !tagInfo[i].sub_category ||
        // // !tagInfo[i].team_type ||
        // !tagInfo[i].primary_scope ||
        // !tagInfo[i].technical_scope.length ||
        // !tagInfo[i].attachments
        !tagInfo[i].name ||
        !tagInfo[i].description ||
        !tagInfo[i].sub_category ||
        !tagInfo[i].verticals ||
        !tagInfo[i].sub_vertical ||
        !tagInfo[i].serviceRequired ||
        !tagInfo[i].attachments

      ) {
        error = "Please fill all the fields";
        setError(true);
      }
    }
    return error;
  };

  return (
    <div className="flex ml-auto w-4/5 overflow-hidden h-screen">
      <div className="h-screen bg-indigo-200 w-5"></div>
      <EmailDetails showMailList={false} selectedMail={selectedMail} />
      <ModifyProject validateFields={validateFields} setErrorMicro={setErrorMicro} errorMicro={errorMicro} >
        <ModifyProjectForm3 fileNames={fileNames} tagNo={tagNo} error={error} setErrorMicro={setErrorMicro} errorMicro={errorMicro} />
      </ModifyProject>
    </div>
  );
};

export default ModifyTagForm;
