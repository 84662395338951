import React from "react";
import { useHistory } from "react-router-dom";

export default function Login({
  handleSubmit,
  error,
  forget,
  handleChange,
  handleTogglePassword,
  isDisabled,
  loading,
  showPassword,
  forgetClickhandle,
}) {
  const navigate = useHistory();

  return (
    <>
      <form action="" className="px-[65px] " onSubmit={handleSubmit}>
        {/* error message */}
        {error && (
          <div
            class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 w-full text-center rounded relative mb-2 res125:py-2 res125:text-sm res150:py-2 res150:text-xs res125:h-9 res150:h-8"
            role="alert"
          >
            <span class="block sm:inline">{error}</span>
            {/* {expire && <p>Your account has expired</p>} */}
          </div>
        )}
        {/* email */}
        <label htmlFor="Email" className="res150:text-xs res125:text-xs">
          {forget ? "password" : "Email"}
        </label>
        <input
          className="w-full border-[#C8C8C8] rounded   placeholder-gray-400  res150:text-xs res125:text-sm mt-[15px]"
          placeholder="Enter your email"
          type="email"
          name="email"
          onChange={handleChange}
        />
        {/* password */}
        <div className="flex flex-col mb-[20px] mt-[15px]">
          <label htmlFor="password" className=" res125:text-xs res150:text-xs">
            {forget ? "confirm password" : "Password"}
          </label>
          <div className=" mt-3  res150:mb-0">
            <input
              type={showPassword ? "text" : "password"}
              className="w-full border-[#C8C8C8] rounded   placeholder-gray-400  res150:text-xs res125:text-sm"
              placeholder="Enter your password"
              name="password"
              onChange={handleChange}
            />
            <span
              type="button"
              className="w-1/12 ml-auto flex transform -translate-y-8 res125:mt-1 text-gray-500 focus:outline-none res150:mt-1.5 res150:mr-1"
              onClick={handleTogglePassword}
            >
              {showPassword ? (
                <i className="fa fa-eye cursor-pointer " aria-hidden="true"></i>
              ) : (
                <i
                  className="fa fa-eye-slash cursor-pointer"
                  aria-hidden="true"
                ></i>
              )}
            </span>
          </div>
          <p
            class=" text-blue-500 cursor-pointer underline text-end"
            onClick={forgetClickhandle}
          >
            Forgot Password ?
          </p>
        </div>

        {/* btn */}
        <button
          type="submit"
          disabled={isDisabled}
          className={`bg-[#03298C] w-full h-[3rem] hover:bg-[#03298C] text-white font-bold  rounded res150:text-xs res150:h-[40px] res125:h-[40px] mb-[30px] ${isDisabled ? "opacity-50 cursor-not-allowed" : ""
            }`}
        >
          {loading ? (
            <div className="flex justify-center ">
              <img
                src="https://media1.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif"
                className="w-[3rem] mx-auto res125:w-10 res150:w-9 "
                alt=""
              />
            </div>
          ) : (
            "SIGN IN"
          )}
        </button>
        <p className=" mb-3 res125:text-sm res150:mb-1 res150:text-xs text-center">
          Don't you have an account ? Sign Up
        </p>
        <div className="flex justify-between ">
          <span
            className="mb-0 text-blue-500 underline cursor-pointer res125:text-sm res150:text-xs"
            onClick={() => navigate.push("/auth/customer/signup")}
          >
            Customer
          </span>
          <span
            className="mb-10 text-blue-500 underline cursor-pointer res125:text-sm res150:text-xs"
            onClick={() => navigate.push("/auth/service-provider/signup")}
          >
            Service-provider
          </span>
        </div>
      </form>
    </>
  );
}
