import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import AllRoutes from "./routes/AllRoutes";
import AppContext from "../src/context/AppContext";
import JoyrideTour from "./components/Shared/takeATour";
import {
  DEFAULT_CUSTOMER_INFO_STATE,
  PROJECT_INFO_STATE,
  TAG_INFO_STATE,
} from "./constants/Constants";

function App() {
  const [selectedMail, setSelectedMail] = useState({});
  const [customerInfo, setCustomerInfo] = useState(DEFAULT_CUSTOMER_INFO_STATE);
  const [projectInfo, setProjectInfo] = useState(PROJECT_INFO_STATE);
  const [tagInfo, setTagInfo] = useState(TAG_INFO_STATE);
  const [dropDownChanged, isDropDownChanged] = useState(false);
  const [type, setType] = useState("");
  const [loadTeamMember, setLoadTeamMember] = useState(false);
  const [loadProject, setLoadProject] = useState(false);
  const [comments, setComments] = useState([]);
  const [isTextChanged, setTextChanged] = useState(false);
  const [pageno, setPageno] = useState(1);
  const [tagFiles, setTagFiles] = useState([]);
  const [imgURL, setImgURL] = useState("");
  const [step, setStep] = useState(0);
  const [user, setUser] = useState({});
  const [token, setToken] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialSet, setInitialState] = useState(false);
  const [isCommentsUpdated, setCommentsUpdated] = useState(false);
  const [xaxis, setXaxis] = useState(0);
  const [yaxis, setYaxis] = useState(0);
  const [CurrentStepIndex, setCurrentStepIndex] = useState(0);
  const [CurrentStepIndex1, setCurrentStepIndex1] = useState(0);
  const [rect, setRect] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("Access");
    const user = localStorage.getItem("User");
    setIsAuthenticated(!!token && !!user);
    setToken(!!token ? JSON.parse(token) : undefined);
    setUser(!!user ? JSON.parse(user) : undefined);
    setInitialState(true);
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("User"));
    if (user) {
      if (user.is_admin) {
        setType("admin");
      } else if (user.is_freelancer || user.is_company) {
        setType("service-provider");
      } else if (user.is_customer) {
        setType("customer");
      }
    }
  }, []);

  // const [runTour, setRunTour] = useState(false);

  // const startTour = () => {
  //   setRunTour(true);
  // };

  return (
    <AppContext.Provider
      value={{
        selectedMail: selectedMail,
        setSelectedMail: setSelectedMail,
        customerInfo: customerInfo,
        setCustomerInfo: setCustomerInfo,
        projectInfo: projectInfo,
        setProjectInfo: setProjectInfo,
        tagInfo: tagInfo,
        setTagInfo: setTagInfo,
        dropDownChanged: dropDownChanged,
        isDropDownChanged: isDropDownChanged,
        type: type,
        setType: setType,
        loadTeamMember: loadTeamMember,
        setLoadTeamMember: setLoadTeamMember,
        comments: comments,
        setComments: setComments,
        isTextChanged: isTextChanged,
        setTextChanged: setTextChanged,
        pageno: pageno,
        setPageno: setPageno,
        tagFiles: tagFiles,
        setTagFiles: setTagFiles,
        imgURL: imgURL,
        setImgURL: setImgURL,
        user: user,
        setUser: setUser,
        token: token,
        setToken: setToken,
        isAuthenticated: isAuthenticated,
        setIsAuthenticated: setIsAuthenticated,
        isInitialSet: isInitialSet,
        setInitialState: setInitialState,
        step: step,
        setStep: setStep,
        loadProject: loadProject,
        setLoadProject: setLoadProject,
        isCommentsUpdated: isCommentsUpdated,
        setCommentsUpdated: setCommentsUpdated,
        xaxis: xaxis,
        yaxis: yaxis,
        setXaxis: setXaxis,
        setYaxis: setYaxis,
        rect: rect,
        setRect: setRect,
        setCurrentStepIndex: setCurrentStepIndex,
        CurrentStepIndex: CurrentStepIndex,
      }}
    >
      
      {/* <button
        onClick={startTour}
        className="fixed top-14  text-sm right-4 bg-white text-blue-900 border border-blue-900 hover:bg-blue-900 hover:border-white hover:text-white  font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
      >
        Take a Tour
      </button> */}

      <Router>
        {/* <AllRoutes startTour={startTour}  /> */}
        <AllRoutes  />
      </Router>
      
      {/* <JoyrideTour runTour={runTour} setRunTour={setRunTour} /> */}
    </AppContext.Provider>
  );
}
export default App;
