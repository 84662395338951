
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../constants/Constants";
import moment from 'moment';
import Loader from "../../../Loader";
import AppContext from "../../../context/AppContext";
import { Badge } from "flowbite-react";


export default function Pricing() {
    const navigate = useHistory();
    const [loading, setLoading] = useState(true);
    const [pricingList, setpricingList] = useState([]);
    const headers = {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
    };
    const { type } = useContext(AppContext);

    const [scopeFilter, setScopeFilter] = useState("");
    const [userTypeFilter, setUserTypeFilter] = useState('');
    const [dateFilter, setDateFilter] = useState("");
    let [serialNo, setSerialNo] = useState(0);
    // const [filteredLists, setFilteredLists] = useState([]);
    const getPricing = async () => {
        const pathname = navigate.location.pathname.split("/");
        const project_id = pathname[pathname.length - 1];

        const getUserTypes = (user) => {
            if (user.is_admin) {
                return "Admin";
            } else if (user.is_company) {
                return "service_provider";
            } else if (user.is_customer) {
                return "customer";
            } else if (user.is_freelancer) {
                return "service_provider";
            }
        };
        const user = JSON.parse(localStorage.getItem("User"));
        const userTypes = getUserTypes(user)
        await axios
            .get(`${BASE_URL}/api/pricing/getPricingList/${project_id}/${userTypes}`, { headers })
            .then((res) => {
                const datas = res.data;

                let array = [];
                datas.getAllPricing.map(item => {
                    JSON.parse(item.tags).map(tag => { array.push({ ...tag, date: item.createdAt, userType: item.userType }) })
                });
                setpricingList(array)

                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        getPricing();
    }, []);

    const filterList = () => {
        let filteredData = [...pricingList];
        let sampFilter = []
        let result = [];
        if (scopeFilter) {
            filteredData.map((item) => {
                sampFilter = item?.scopes?.filter((list) =>
                    list.microservice.toLowerCase().includes(scopeFilter.toLowerCase())
                )
                if (sampFilter?.length > 0) {
                    result.push({ ...item, scopes: sampFilter })
                }
            })
            filteredData = result;
        }

        if (userTypeFilter) {
            filteredData = filteredData.filter((list) =>
                list.userType.toLowerCase().includes(userTypeFilter.toLowerCase())
            );
        }

        if (dateFilter) {
            filteredData = filteredData.filter((list) =>
                list.date.toLowerCase().includes(dateFilter.toLowerCase())
            );
        }

        return filteredData;
    };

    const handleUserTypeChange = (e) => {
        const selectedValue = e.target.value;
        setUserTypeFilter(selectedValue);
    };

    const filteredLists = filterList();

    const Theaders = ["S.No", "Tag Name", "Microservice", "Price", type === "admin" ? "User Type" : null, "Date", "Time"].filter(Boolean);

    return (
      <div>
        <div className="mt-4">
          {loading ? (
            <Loader className="mx-auto" />
          ) : (
            <>
              {type === "admin" ? (
                <div className="flex items-center mt-3">
                  <div className="block mr-4 ml-auto"></div>
                  <input
                    type="text"
                    placeholder="Search Microservice"
                    className={`me-2 mb-5 res150:mb-3 block appearance-none w-[16rem] h-12 res125:w-48 res125:h-10 res125:text-sm res150:w-40 res150:text-xs res150:h-8 bg-white border border-[#C8C8C8] px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline`}
                    onChange={(e) => setScopeFilter(e.target.value)}
                  />
                  <select
                    className={`me-2 mb-5 res150:mb-3 block appearance-none w-[16rem] h-12 res125:w-48 res125:h-10 res125:text-sm res150:w-40 res150:text-xs res150:h-8 bg-white border border-[#C8C8C8] px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline`}
                    id="User type"
                    name="User type"
                    required
                    onChange={handleUserTypeChange}
                    value={userTypeFilter}
                  >
                    <option value="">All</option>
                    <option value="admin">admin</option>
                    <option value="service-provider">service-provider</option>
                  </select>
                  <input
                    type="date"
                    className={`me-2 mb-5 res150:mb-3 block appearance-none w-[16rem] h-12 res125:w-48 res125:h-10 res125:text-sm res150:w-40 res150:text-xs res150:h-8 bg-white border border-[#C8C8C8] px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline`}
                    onChange={(e) => setDateFilter(e.target.value)}
                  />
                </div>
              ) : null}
              <table className="shadow-lg mb-20 ">
                <thead>
                  <tr className="">
                    {Theaders.map((header, index) => (
                      <th
                        className="bg-[#ECFFFF] text-[#03298C] w-96 h-16 res125:h-14 res150:h-9 capitalize text-center res125:text-sm res150:text-xs rounded"
                        key={index}
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {pricingList.length === 0 ? (
                    <tr>
                      <td
                        colSpan={7}
                        className="text-center pt-3 text-blue-900 res125:text-sm res150:text-xs"
                      >
                        No datas found
                      </td>
                    </tr>
                  ) : (
                    <>
                      {filteredLists?.map(
                        (item, index) => (
                          item?.scopes?.map((scope, scopeIndex) => (
                            <tr
                              key={index}
                              className="border-b-[0.5px] border-[#C8C8C8] res125:text-sm res150:text-xs text-center"
                            >
                              <td className="px-4 py-2 border-b border-indigo-200">
                                {++serialNo}
                              </td>
                              <td className="px-4 py-2 border-b border-indigo-200">
                                {item.name}
                              </td>
                              <td className="px-4 py-2 border-b border-indigo-200">
                                {scope.microservice}
                              </td>

                              <td className="px-4 py-2 border-b border-indigo-200">
                                {scope.price}
                              </td>

                              {type === "admin" ? (
                                <td className="px-4 py-2 border-b border-indigo-200">
                                  {(() => {
                                    const status = item.userType;

                                    let badgeComponent = null;

                                    if (status === "service-provider") {
                                      badgeComponent = (
                                        <Badge color="warning">
                                          Service Provider
                                        </Badge>
                                      );
                                    } else if (status === "Admin") {
                                      badgeComponent = (
                                        <Badge color="dark">Admin</Badge>
                                      );
                                    }

                                    return (
                                      <div className="flex justify-center items-center res150:text-xs">
                                        {badgeComponent}
                                      </div>
                                    );
                                  })()}
                                </td>
                              ) : null}

                              <td className="px-4 py-2 border-b border-indigo-200">
                                {moment(item.date).format(
                                  "DD MMM YYYY"
                                )}
                              </td>

                              <td className="px-4 py-2 border-b border-indigo-200">
                                {moment(item.date).format("hh:mm a")}
                              </td>
                            </tr>
                          ))
                        )
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    );
};