import { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useLocation, Link } from "react-router-dom";
import AppContext from "../../../context/AppContext";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import profile from "../../../assets/images/profile.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate, faBell } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as NotificationIcon } from "../../../assets/svg/notification_icon.svg";
import JoyrideTour from "../takeATour";

const menuOptions = [
  {
    role: "admin",
    options: [
      { to: "/admin/settings", label: "Settings" },
      // { to: "/admin/help", label: "Help ?" },
    ],
  },
  {
    role: "service",
    options: [
      { to: "/service-provider/settings", label: "Settings" },
      { to: "/service-provider/help", label: "Help ?" },

    ],
  },
  {
    role: "customer",
    options: [
      { to: "/customer/settings", label: "Settings" },
      // { to: "/customer/help", label: "Help ?" },
    ],
  },
];

// const NavBar = () => {
const NavBar = ({ startTour }) => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [name, setName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { type } = useContext(AppContext);
  const navigate = useHistory();
  const menuRef = useRef();

  const [seenValue, setSeenValue] = useState(false);

  // const [runTour, setRunTour] = useState(false);

  // const startTour = () => {
  //   setRunTour(true);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (type === "admin") {
          const response = await axios.get(
            `${BASE_URL}/api/notification/admin/list`,
            { headers }
          );
          let responseData = response.data;

          if (Array.isArray(responseData.message)) {
            setSeenValue(
              !responseData.message.some(
                (notification) => !notification.is_seen
              )
            );
          } else if (Array.isArray(responseData)) {
            setSeenValue(
              !responseData.some((notification) => !notification.is_seen)
            );
          } else {
            console.error("Unexpected response structure:", responseData);
          }
        } else if (type === "service-provider" || type === "customer") {
          const params = {
            vendor_id: JSON.parse(localStorage.getItem("User"))?.id,
          };

          const response = await axios.get(
            `${BASE_URL}/api/notification/list`,
            { params, headers }
          );
          let responseData = response.data;

          if (Array.isArray(responseData.message)) {
            setSeenValue(
              !responseData.message.some(
                (notification) => !notification.is_seen
              )
            );
          } else if (Array.isArray(responseData)) {
            setSeenValue(
              !responseData.some((notification) => !notification.is_seen)
            );
          } else {
            console.error("Unexpected response structure:", responseData);
          }
        }
      } catch (error) {
        console.error("Error fetching notification data:", error);
      }
    };

    fetchData();
  });

  useEffect(() => {
    if (showMenu) {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setShowMenu(false);
        }
      };
      window.addEventListener("mousedown", handleClickOutside);
      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showMenu]);

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    if (location.pathname.includes("admin")) {
      setUserRole("admin");
    } else if (location.pathname.includes("service-provider")) {
      setUserRole("service");
    } else if (location.pathname.includes("customer")) {
      setUserRole("customer");
    }
  }, [location.pathname]);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const handleOptionClick = () => {
    setShowMenu(false);
  };

  const options = menuOptions.find(
    (option) => option.role === userRole
  )?.options;

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("User"));
    if (userData && Object.keys(userData).length > 0) {
      setName(userData?.name);
      getFileUrl(userData?.profile_picture);
    }
  }, [localStorage.getItem("User")]);

  const getFileUrl = async (value) => {
    const params = {
      filter: "ProfilePicture",
      name: value,
    };
    await axios
      .get(`${BASE_URL}/api/file-url`, { params, headers })
      .then((res) => {
        setImageURL(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClick = () => {
    if (type === "admin") {
      navigate.push("/admin/notifications");
    } else if (type === "service-provider") {
      navigate.push("/service-provider/notifications");
    } else if (type === "customer") {
      navigate.push("/customer/notifications");
    }
  };

  const handleMailSync = async () => {
    setLoading(true);
    await axios
      .get(`${BASE_URL}/api/mail/messages`, { headers })
      .then((res) => {
        // console.log(res);
        setLoading(false);
        alert("Success! All mails have synced.");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <nav className="bg-[#011A59] border-gray-200 px-2 sm:px-4 py-3 res150:py-2 flex fixed w-4/5 ml-auto  z-50 right-0 top-0">
      <div id="Navebar" className="container flex flex-wrap items-center justify-between ml-auto">
        <div className="flex items-center md:order-2 ml-auto">
          {type != "admin" && (
            <button title="Take A Tour"
              onClick={startTour}
              className=" border border-blue-900 hover:bg-blue-900 border-white text-white font-bold p-1 mr-4 rounded-full focus:outline-none focus:shadow-outline"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                <line x1="12" y1="8" x2="12" y2="16"></line>
                <line x1="14" y1="8" x2="10" y2="8"></line>
              </svg>

            </button>
          )}

          {/* {type === "admin" && (
            <div
              className="relative mr-4 w-fit cursor-pointer"
              onClick={() => handleMailSync()}
            >
              <div className="flex items-center justify-center rounded-lg bg-indigo-400 px-3 py-1.5 res150:px-2 res150:text-xs res150:py-1 res150:rounded res125:text-sm res125:rounded text-center shadow-lg text-white text-base font-bold">
                <FontAwesomeIcon icon={faRotate} />
                <span className="pl-2">
                  {loading ? "Syncing Mails" : "Sync Mail"}
                </span>
              </div>
            </div>
          )} */}
          {/* {(type === "admin" || type === "service-provider") && ( */}
          <div
            className="relative mr-4 w-fit cursor-pointer"
            onClick={() => handleClick()}
          >
            <div id="notifications" className="flex items-center relative justify-center rounded-lg bg-indigo-400 px-1.5 py-1.5 text-center shadow-lg res150:px-[0.1rem] res150:py-[0.1rem] res150:rounded res150:bg-indigo-400">
              <FontAwesomeIcon className="text-xl text-white" icon={faBell} />
              {seenValue ? (
                ""
              ) : (
                <span className="text-3xl text-red-700 absolute -top-2 right-1">
                  &bull;
                </span>
              )}
            </div>
          </div>
          {/* )} */}

          <button
            type="button"
            className="flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0 border-2 focus:ring-4 focus:ring-gray-300 "
            id="user-menu-button"
            aria-expanded={showMenu}
            onClick={handleMenuClick}
          >
            <span className="sr-only">Open user menu</span>
            {JSON.parse(localStorage.getItem("User"))?.profile_picture ? (
              <img
                className="w-8 h-8 rounded-full object-cover"
                src={imageURL}
              />
            ) : (
              <img
                className="w-8 h-8 rounded-full object-cover res125:w-7 res125:h-7 res150:w-6 res150:h-6"
                src={profile}
              />
            )}
          </button>

          <div
            className={`${showMenu ? "block" : "hidden"
              } absolute top-full right-1 z-0 w-48 res150:w-40 py-1 mt-1 bg-white rounded-lg shadow-md`}
            id="user-dropdown"
            ref={menuRef}
          >
            <div className="px-4 py-3">
              <span className="block text-sm text-gray-900 res125:text-xs res150:text-xs">
                {name}
              </span>
              <span className="block text-sm font-medium text-gray-500 truncate res150:text-xs res125:text-xs">
                {userRole}
              </span>
            </div>
            <hr className="mx-3" />
            {options && (
              <div
                className={`${showMenu ? "block" : "hidden"
                  } px-2 pt-2 pb-3 space-y-1`}
              >
                {options.map((option, index) => (
                  <Link
                    key={index}
                    to={option.to}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-white hover:bg-[#03298C] res125:text-xs res150:text-xs"
                    onClick={handleOptionClick}
                  >
                    {option.label}
                  </Link>
                ))}
              </div>
            )}
          </div>

        </div>
      </div>

    </nav>
  );
};

export default NavBar;
