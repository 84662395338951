import React from "react";

export default function sendOtp({
  handleOTPSubmit,
  handleOTPChange,
  isDisabled,
  loading,
  error,
  handleResendOtp,
}) {
  return (
    <>
      <form
        action=""
        className="flex flex-col gap-3 px-[65px] "
        onSubmit={handleOTPSubmit}
      >
        {error && (
          <div
            class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 w-full text-center rounded relative mb-2 res125:py-2 res125:text-sm res150:py-2 res150:text-xs res125:h-9 res150:h-8"
            role="alert"
          >
            <span class="block sm:inline">{error}</span>
            {/* {expire && <p>Your account has expired</p>} */}
          </div>
        )}
        <label htmlFor="Email" className="res150:text-xs res125:text-xs">
          OTP
        </label>
        <input
          className="h-12 rounded border-[#C8C8C8]  mb-4  res150:h-8  res150:mb-1 res150:text-xs  res125:h-9  res125:mb-1 res125:text-xs w-full"
          placeholder="Enter OTP"
          type="text"
          name="otp"
          onChange={handleOTPChange}
        />
        <p
          className="mt-1 mb-1 res125:text-sm res150:mb-1 res150:text-xs underline text-blue-300 text-end cursor-pointer"
          onClick={handleResendOtp}
        >
          Resend otp
        </p>
        <button
          type="submit"
          disabled={isDisabled}
          className={`bg-[#03298C] w-full h-[4rem] hover:bg-[#03298C] text-white font-bold  rounded res150:text-xs res150:h-11 res125:h-12 mb-[120px]  ${
            isDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {loading ? (
            <div className="flex justify-center ">
              <img
                src="https://media1.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif"
                className="w-[3rem] mx-auto res125:w-10 res150:w-9 "
                alt=""
              />
            </div>
          ) : (
            "Verify OTP"
          )}
        </button>
      </form>
    </>
  );
}
