

import React, { useState, useEffect, useContext } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import axios from "axios";

// import { useMount } from 'react-use';
// import a11yChecker from 'a11y-checker';
import ProjectsTabSwicth from "../Shared/Projects/ProjectsTabSwitch"
import { useHistory } from "react-router";
import AppContext from '../../context/AppContext';
import {
    BASE_URL,
} from "../../../src/constants/Constants";

const JoyrideTour = ({ runTour, setRunTour }) => {

    const { type, CurrentStepIndex, setCurrentStepIndex, setCurrentStepIndex1 } = useContext(AppContext)
    const [steps, setSteps] = useState([])
    const [user, setUser] = useState(null)
    const navigate = useHistory();
    const headers = {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
    };

    let LocalUser = (JSON.parse(localStorage.getItem("User")));
    // setIUserId(Users.id)
    // if (user) {
    // }


    const startTour = () => {
        setRunTour(true);
    };

    useEffect(() => {

        if (LocalUser) {
            if (LocalUser.tour_guide === false) {
                startTour()
            }
        }



    }, [])

    useEffect(() => {
        if (type === 'customer') {
            setSteps([
                {
                    target: '#customerSearch',
                    title: 'Search box ',
                    content: "This search box is designed to help you find the project you're looking for",

                },
                {

                    content: (
                        <div>
                            You can click on this option to navigate inside and view your project data.
                            <br />
                            Click the Project Menu above!
                        </div>
                    ),
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    hideFooter: true,
                    placement: 'bottom',
                    spotlightClicks: true,
                    styles: {
                        options: {
                            zIndex: 10000,
                        },
                    },
                    target: '#cusprojects',
                    title: 'Project',
                },

                {
                    target: '#createProjectButton',
                    content: 'To create a project, please click on the "Create Project" option and fill in the required data',

                },
                {
                    target: '#createTicketButton',
                    content: 'If you are unsure about creating a project, you can instead create a ticket. Click here to fill in the necessary data',

                },
                {

                    content: (
                        <div>
                            You can view all the data related to this project here
                            <br />
                            Click the Project above!
                        </div>
                    ),
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    hideFooter: true,
                    placement: 'bottom',
                    spotlightClicks: true,
                    styles: {
                        options: {
                            zIndex: 10000,
                        },
                    },
                    target: '#projectcoulms',
                    title: 'Project',
                },
                {
                    target: '#notifications',
                    content: 'If you wish to view notifications, click here.',

                },
                {
                    target: '#projectsDetailsTab',
                    content: 'All the tag details of the project will be there',
                },
                {

                    content: (
                        <div>
                            Quotation details are here
                            <br />
                            Click the Quotation above!
                        </div>
                    ),
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    hideFooter: true,
                    placement: 'bottom',
                    spotlightClicks: true,
                    styles: {
                        options: {
                            zIndex: 10000,
                        },
                    },
                    target: '#projectsYourQuotation',
                    title: 'Quotation',
                },
                {

                    content: (
                        <div>
                            You can view all collaboration data for this project here
                            <br />
                            Click the  Project collaboration above!
                        </div>
                    ),
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    hideFooter: true,
                    placement: 'bottom',
                    spotlightClicks: true,
                    styles: {
                        options: {
                            zIndex: 10000,
                        },
                    },
                    target: '#ProjectCollabrations',
                    title: 'Project Collabrations',
                },
                {

                    content: (
                        <div>
                            Order details will be shown here.
                            <br />
                            Click the Your Orders above!
                        </div>
                    ),
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    hideFooter: true,
                    placement: 'bottom',
                    spotlightClicks: true,
                    styles: {
                        options: {
                            zIndex: 10000,
                        },
                    },
                    target: '#cusYourOrder',
                    title: 'Your Orders',
                },
                {
                    target: '#menubarID',
                    content: 'Your email details are here. You can add your email ID',
                    title: 'Mail Ids',

                },
            ])



        } else if (type === "service-provider") {
            setSteps([

                {
                    target: '#projectDashboardsearchbox',
                    content: "This search box is designed to help you find the project you're looking for",

                },
                {
                    target: '#statusfilter',
                    content: 'Status filter: Use this filter to refine all project data based on the selected status',

                },
                {
                    target: '#categroyfilter',
                    content: 'Project category filter: Use this option to filter and organize projects based on their respective categories',

                },
                {

                    content: (
                        <div>
                            You can view all the data related to this project here
                            <br />
                            Click the project above!
                        </div>
                    ),
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    hideFooter: true,
                    placement: 'bottom',
                    spotlightClicks: true,
                    styles: {
                        options: {
                            zIndex: 10000,
                        },
                    },
                    target: '#projectcoulms',
                    title: 'Project',
                },
                {
                    target: '#notifications',
                    content: 'If you wish to view notifications, click here.',
                },
                {
                    target: '#user-menu-button',
                    content: 'User menu button: Click here to access the user menu.',
                },
                {
                    target: '#projectsDetailsTab',
                    content: 'Project details are here',
                },
                {

                    content: (
                        <div>
                            You can view all collaboration data for this project here
                            <br />
                            Click the  Project collaboration above!
                        </div>
                    ),
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    hideFooter: true,
                    placement: 'bottom',
                    spotlightClicks: true,
                    styles: {
                        options: {
                            zIndex: 10000,
                        },
                    },
                    target: '#ProjectCollabrations',
                    title: 'Project Collabrations',
                },
                {
                    target: '#UploadFileButton',
                    content: 'If you want to upload a file for project collaboration, click here to upload.',
                },
                {

                    content: (
                        <div>
                            Quotation details are available here. You can create a new quotation, and options for editing and deleting are provided.
                            <br />
                            Click the Your Quotations above!
                        </div>
                    ),
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    hideFooter: true,
                    placement: 'bottom',
                    spotlightClicks: true,
                    styles: {
                        options: {
                            zIndex: 10000,
                        },
                    },
                    target: '#serviceProviderQuotations',
                    title: 'Your Quotations',
                },
                {

                    content: (
                        <div>
                            Order details are here.  can access them here
                            <br />
                            Click the Your Order above!
                        </div>
                    ),
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    hideFooter: true,
                    placement: 'bottom',
                    spotlightClicks: true,
                    styles: {
                        options: {
                            zIndex: 10000,
                        },
                    },
                    target: '#serviceProviderYourORder',
                    title: ' Your Order',
                },
                {

                    content: (
                        <div>
                            Pricing details will be displayed here.
                            <br />
                            Click the Pricing above!
                        </div>
                    ),
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    hideFooter: true,
                    placement: 'bottom',
                    spotlightClicks: true,
                    styles: {
                        options: {
                            zIndex: 10000,
                        },
                    },
                    target: '#pringingforseriverprovider',
                    title: 'Pricing',
                },



            ]);
        }

        setUser(type)

    }, [type])

    // useEffect(() => {
    //     const handlePopstate = () => {
    //       // When the user clicks the browser's back button, go back to the previous step
    //       setCurrentStepIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    //     };

    //     // Attach event listener when the component mounts
    //     window.addEventListener('popstate', handlePopstate);

    //     // Detach event listener when the component unmounts
    //     return () => {
    //       window.removeEventListener('popstate', handlePopstate);
    //     };
    //   }, [setCurrentStepIndex]);

    const tourguide = () => {
        const payload = {
            tour_guide: true,
            id: LocalUser.id
        }

        axios
            .put(`${BASE_URL}/api/customerTourGuide`, payload, { headers })
            .then((response) => {
                const updatedLocalUser = { ...LocalUser, tour_guide: true };
                localStorage.setItem('User', JSON.stringify(updatedLocalUser));
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleJoyrideCallback = data => {
        const { action, index, status, type } = data;


        if (action === ACTIONS.CLOSE) {
            setRunTour(false);
        } else if (action === ACTIONS.PREV) {
            setCurrentStepIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {


            if (user === "customer") {
                if (!(index === 1 || index === 4 || index === 7 || index === 8 || index === 9)) {
                    setCurrentStepIndex((prevIndex) => prevIndex + 1);
                }
            }
            if (user === "service-provider") {
                if (!(index === 3 || index === 7 || index === 9 || index === 10)) {
                    setCurrentStepIndex((prevIndex) => prevIndex + 1);
                }
            }
            if (type === EVENTS.TARGET_NOT_FOUND) {

                tourguide();
            }


        }
        else if ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.ERROR].includes(status)) {
            setRunTour(true);
            tourguide()

        }
    };





    return (
        <>
            <Joyride
                steps={steps}
                run={runTour}
                continuous={true}
                showProgress={true}
                callback={handleJoyrideCallback}
                stepIndex={CurrentStepIndex}
                styles={{
                    options: {
                        arrowColor: '#e3ffeb',
                        backgroundColor: '#fff',
                        //   overlayColor: 'rgba(79, 26, 0, 0.4)',
                        primaryColor: '#0d2970',
                        textColor: 'black',
                        width: 900,
                        zIndex: 1000,
                    }
                }}

            />

        </>
    );
};

export default JoyrideTour;

