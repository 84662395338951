import React, { useState, useEffect, useContext } from "react";
import { PROJECT_INFO_STEPPER } from "../../../../constants/Constants";
import AppContext from "../../../../context/AppContext";

const CreateProjectForms1 = ({ error, show }) => {
  const { projectInfo, setProjectInfo } = useContext(AppContext);

  const [selectedCategoryValue, setSelectedCategoryValue] =
    useState("Process Plants");

  useEffect(() => {
    const existingProjectInfo = localStorage.getItem(PROJECT_INFO_STEPPER);
    if (existingProjectInfo !== null && existingProjectInfo !== "undefined") {
      setProjectInfo({
        ...JSON.parse(existingProjectInfo),
        category: selectedCategoryValue,
      });
      setSelectedCategoryValue(JSON.parse(existingProjectInfo).category);
    } else {
      setProjectInfo({ ...projectInfo, category: selectedCategoryValue });
    }
  }, []);

  const handleProjectInfoForm = (event) => {
    const existingProjectInfo = { ...projectInfo };
    const { name, value } = event.target;
    existingProjectInfo[name] = value;
    setProjectInfo(existingProjectInfo);
    if (
      !existingProjectInfo.name &&
      !existingProjectInfo.projectUniqueId &&
      !existingProjectInfo.description &&
      !existingProjectInfo.category &&
      !existingProjectInfo.tags
    ) {
      localStorage.removeItem(PROJECT_INFO_STEPPER);
    }
  };

  return (
    <div>
      {/* Overlay */}
      {/* {show && (
        <div className="bg-black/50 fixed w-full h-full z-10 top-0 right-0"></div>
      )} */}

      {/* Side drawer*/}
      <div
        className={
          show
            ? " top-0 right-0 h-full z-10 duration-300  "
            : "fixed top-0 right-[-100%] w-[420px] h-full z-10 duration-300"
        }
        style={{
          background: "linear-gradient(90deg, #F8F8F8 0%, #FFFFFF 74.69%)",
        }}
      >
        <div className=" flex-col z-auto flex ml-auto overflow-x-hidden mt-12 res150:mt-8">
          <div className="flex flex-wrap max-w-md mx-12 -m-2 mb-5">
            {error && (
              <div
                className="bg-red-100 border text-center mt-4 border-red-400 text-red-700 px-2 py-2 w-full rounded mb-2 mx-2 res125:py-1.5 res125:text-sm"
                role="alert"
              >
                <span className="block sm:inline">
                  * Please fill all the fields.
                </span>
              </div>
            )}
            <div className="w-full p-2">
              <input
                className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res125:px-3 res150:px-2 res150:py-1 res150:text-xs res125:py-1.5 "
                type="text"
                placeholder="Project Name"
                name={"name"}
                value={projectInfo.name}
                onChange={handleProjectInfoForm}
              />
            </div>
            <div className="w-full p-2">
              <input
                className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res125:px-3 res150:px-2 res150:py-1 res150:text-xs res125:py-1.5 "
                type="text"
                placeholder="Project Unique Id"
                name={"projectUniqueId"}
                value={projectInfo.projectUniqueId}
                onChange={handleProjectInfoForm}
              />
            </div>
            <div className="w-full px-2 pt-2">
              <textarea
                className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res125:py-1 res150:py-1 res150:text-xs res125:px-3 res150:px-2"
                style={{ height: "165px", resize: "none" }}
                placeholder="Project Description"
                name={"description"}
                value={projectInfo.description}
                onChange={handleProjectInfoForm}
              />
              <select
                value={projectInfo.category}
                id="category"
                name="category"
                placeholder="Project Category"
                onChange={handleProjectInfoForm}
                className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] mt-2 mb-2 res150:py-1 res150:text-xs res125:py-1.5 res125:px-3 res150:px-2"
              >
                <option
                  value=""
                  className="text-gray-500 cursor-not-allowed"
                  selected
                  disabled
                >
                  Project Category
                </option>
                <option value="Process Plants">Process Plants</option>
              </select>
            </div>

            <div className="w-full p-2">
              <input
                className="numberbox w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res150:py-1 res150:text-xs res125:py-1.5 res125:px-3 res150:px-2"
                type="number"
                placeholder="# of Tags"
                name={"tags"}
                value={projectInfo.tags}
                onChange={handleProjectInfoForm}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProjectForms1;
