import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DetailsCard from "./DetailsCard";
import ProjectDetailsNavCard from ".//ProjectDetailsNavCard";
import TabSwitch from "./ProjectsTabSwitch";
import { BASE_URL, Logo } from "../../../constants/Constants";
import axios from "axios";
import AppContext from "../../../context/AppContext";
// import FactreehubLogo from "../../../assets/images/Factree Hub Logo.png";

export default function ProjectDetails() {
  const navigate = useHistory();
  const [projectData, setProjectData] = useState();
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const { type, loadTeamMember, setLoadTeamMember, setCurrentStepIndex } = useContext(AppContext);

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    const path = navigate.location.pathname;
    const pathArray = path.split("/");
    const id = pathArray[pathArray.length - 1];
    const user = JSON.parse(localStorage.getItem("User"));
    if (type === "admin") {
      setLoading(true);
      axios
        .get(`${BASE_URL}/api/project/${id}`, { headers })
        .then((res) => {
          setProjectData(res.data.message);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if (type === "service-provider") {
      setLoading(true);
      axios
        .get(`${BASE_URL}/api/serviceproviders/project/${id}/${user.id}`, {
          headers,
        })
        .then((res) => {
          setProjectData(res.data.message);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (type === "customer") {
      setLoading(true);
      axios
        .get(`${BASE_URL}/api/customer/project/${id}`, { headers })
        .then((res) => {
          setProjectData(res.data.message);
          setLoading(false);
        })

        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
    setLoadTeamMember(false);
  }, [type, loadTeamMember]);

  const handleBack = () => {
    if (type === "admin") {
      navigate.push("/admin/projects");
    } else if (type === "customer") {
      navigate.push("/customer/projects");
    } else if (type === "service-provider") {
      navigate.push("/service-provider/projects");
    }
  };
  const handleNextStep = () => {
    setCurrentStepIndex((prevIndex) => prevIndex + 1);

  };

  return (
    <div>
      {(type === "customer" || type === "service-provider") && (
        <div className="mt-20 res125:mt-[4.5rem] res150:mt-14">
          <img
            className="ml-auto mr-16 mt-4 w-36  res150:w-28"
            src={Logo}
            alt="FactreeHub"
          />
        </div>
      )}

      <div className=" w-4/5 ml-auto">
        <ProjectDetailsNavCard
          handleBack={handleBack}
          details={projectData}
          loading={loading}
          detailType={type === "admin" ? "admin" : ""}
        />
        {type !== "customer" && <DetailsCard projectData={projectData} />}
        <TabSwitch projectData={projectData} loading={loading} onNextStep={handleNextStep} />
      </div>

    </div>
  );
}
