import { useState, useEffect, useContext } from "react";
import React from "react";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import { useHistory, Link } from "react-router-dom";
import NewCredentialsDrawer from "../../Shared/PopupDrawer/NewCredentialsDrawer";
import AppContext from "../../../context/AppContext";
import { AiOutlineWarning } from "react-icons/ai";
import { toast } from "react-hot-toast";
import moment from "moment/moment";
import swal from "sweetalert";
import Loader from "../../../Loader";

const Teams = () => {
  const navigate = useHistory();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [type, setType] = useState(false);
  const { loadTeamMember, setLoadTeamMember } = useContext(AppContext);
  const [teamList, setTeamList] = useState([]);
  const [modal, showModal] = useState(false);
  const path = navigate.location.pathname;
  const pathArray = path.split("/");
  const UserType = pathArray[pathArray.length - 2];
  const isCompany = JSON.parse(localStorage.getItem("User"))?.is_company;
  const isAdmin = JSON.parse(localStorage.getItem("User"))?.is_admin;

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    setLoading(true);
    if (UserType === "service-provider") {
      const params = {
        vendor_id: JSON.parse(localStorage.getItem("User")).id,
      };
      setLoading(true);
      axios
        .get(`${BASE_URL}/api/teams/vendors/list`, { params, headers })
        .then((res) => {
          setLoading(false);
          setTeamList(res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else if (UserType === "admin") {
      const params = {
        admin_id: JSON.parse(localStorage.getItem("User")).id,
      };
      setLoading(true);
      axios
        .get(`${BASE_URL}/api/teams/list`, { params, headers })
        .then((res) => {
          setLoading(false);
          setTeamList(res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
    setLoadTeamMember(false);
  }, [loadTeamMember]);

  useEffect(() => {
    if (isAdmin) {
      return;
    } else if (
      !(
        isCompany &&
        (JSON.parse(localStorage.getItem("User"))?.team === null ||
          JSON.parse(localStorage.getItem("User"))?.team === undefined ||
          JSON.parse(localStorage.getItem("User"))?.team?.access_type ===
          "Admin")
      )
    ) {
      navigate.push("/404");
    }
  }, []);

  const handleDelete = (value) => {
    localStorage.setItem("Delete_account", JSON.stringify(value));
    swal({
      title: "Are you sure want to delete this Team member ?",
      text: "Once deleted, you will not be able to recover this member!",
      icon: "warning",
      buttons: {
        cancel: "No",
        confirm: "Yes",
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleConfirmDelete();
        swal(
          "Deleted Success!",
          "Team member has been deleted successfully!",
          "success"
        );
      } else {
        swal("Your team member data is safe!");
      }
    });
  };

  const handleConfirmDelete = () => {
    var account = JSON.parse(localStorage.getItem("Delete_account"));
    const params = {
      user_id: account.userId,
    };
    setLoading(true);
    setIsDisabled(true);
    axios
      .delete(`${BASE_URL}/api/teams/delete`, { params, headers })
      .then((res) => {
        localStorage.removeItem("Delete_account");
        setLoadTeamMember(true);
        setLoading(false);
        setIsDisabled(false);
        toast.success("Deleted Successfully");
        showModal(false);
      })
      .catch((err) => {
        toast.error("Please Try Again");
        setLoading(false);
        setIsDisabled(false);
      });
  };

  return (
    <>
      <div className="w-8/12 h-auto mr-28 ml-auto mt-24 res125:mt-20 res150:mt-16 res150:mr-20 res125:mr-24">
        <div className="w-full h-11 flex mt-1">
          <div className="text-base font-bold py-2.5 res125:text-sm res150:text-xs ">
            Team Members
          </div>

          <div className="ml-auto">
            <button
              className="bg-[#03298C] w-40 h-10 text-white  rounded res125:w-36 res125:h-9 res125:text-sm res150:w-28 res150:text-xs res150:h-8"
              onClick={() => {
                setShow(true);
                setType("Add New Team Member");
              }}
            >
              Add Member
            </button>
          </div>
        </div>
        <hr className="w-full border border-[#C8C8C8] my-3 res150:mt-0 res125:mt-0" />
        {loading ? (
          <Loader className="mx-auto" />
        ) : (
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-6 re res150:mt-4">
            <table className="w-full text-sm text-center text-black">
              <thead className="text-base text-[#03298C] bg-[#ECFFFF] res125:text-sm res150:text-xs">
                <tr>
                  <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                    Email ID
                  </th>
                  <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                    Password
                  </th>
                  <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                    Access Type
                  </th>
                  {isCompany ? (<th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                    Role
                  </th>) : null}
                  <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                    Created Date
                  </th>
                  <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {teamList.length === 0 ? (
                  <tr>
                    <td
                      colSpan="6"
                      className="px-6 py-4 text-center text-base res125:text-sm res150:text-xs"
                    >
                      No member added yet !
                    </td>
                  </tr>
                ) : (
                  teamList.map((value, index) => (
                    <tr
                      className="bg-white border-b text-center res150:text-xs"
                      key={index}
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 whitespace-nowrap font-bold res150:px-4 res150:py-3"
                      >
                        <div className="flex flex-col">
                          <p>{value.teamName}</p>
                        </div>
                      </th>

                      <td className="px-6 py-4 res150:px-4 res150:py-3">{value.email}</td>
                      <td className="px-6 py-4 res150:px-4 res150:py-3">{value.password}</td>
                      <td className="px-6 py-4 res150:px-4 res150:py-3">{value.accessType}</td>
                      {isCompany ? (<td className="px-6 py-4 res150:px-4 res150:py-3">{value.role ? (value.role) : (<span>-</span>)}</td>) : null}
                      <td className="px-6 py-4 res150:px-4 res150:py-3">
                        {moment(value.created_at).format("DD-MM-YYYY hh:mm a")}
                      </td>
                      <td className="py-4 flex res150:px-4 res150:py-3">
                        <div
                          onClick={() => {
                            setShow(true);
                            setType("Edit Team Member");
                            localStorage.setItem(
                              "Team_Credential",
                              JSON.stringify(value)
                            );
                          }}
                        >
                          <i
                            className="fa fa-2x fa-pencil-square-o cursor-pointer text-[#4277FF] hover:bg-[#4277FF] hover:text-white border border-[#4277FF] p-1 rounded mr-3"
                            aria-hidden="true"
                          ></i>{" "}
                        </div>
                        <div
                          onClick={() => {
                            handleDelete(value);
                          }}
                        >
                          <i
                            className="fa fa-2x fa-trash cursor-pointer text-[#E96F6F] hover:text-white hover:bg-red-500 border border-[#E96F6F] rounded p-1"
                            aria-hidden="true"
                          ></i>{" "}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {show && (
        <NewCredentialsDrawer
          show={show}
          setShow={setShow}
          type={type}
          cred_type="Teams"
        />
      )}
      {modal && (
        <main className="antialiased bg-gray-200 text-gray-900 font-sans overflow-x-hidden">
          <div className="relative px-4 min-h-screen md:flex md:items-center md:justify-center">
            <div className="bg-black opacity-25 w-full h-full absolute z-10 inset-0"></div>
            <div className="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative">
              <div className="md:flex items-center">
                <div className="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                  <AiOutlineWarning
                    size={35}
                    className="mb-2"
                  ></AiOutlineWarning>
                </div>
                <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                  <p className="font-bold">Delete your account</p>
                  <p className="text-sm text-gray-700 mt-1">
                    You will lose all of your data by deleting this account.
                    This action cannot be undone.
                  </p>
                </div>
              </div>
              <div className="text-center md:text-right mt-4 md:flex md:justify-end gap-3">
                <button
                  onClick={() => handleConfirmDelete()}
                  className="w-[10rem] bg-red-200 text-red-700 font-semibold rounded"
                  disabled={isDisabled}
                >
                  {loading ? (
                    <div className="flex justify-center ">
                      <img
                        src="https://media1.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif"
                        className="w-[2rem] mx-auto"
                        alt=""
                      />
                    </div>
                  ) : (
                    "Delete Account"
                  )}
                </button>
                <button
                  className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4
                md:mt-0 md:order-1"
                  onClick={() => {
                    localStorage.removeItem("Delete_account");
                    showModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Teams;
