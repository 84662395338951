import { ReactComponent as Mail } from "../assets/svg/mail.svg";
import { ReactComponent as Tickets } from "../assets/svg/ticket.svg";
import { ReactComponent as Project } from "../assets/svg/project.svg";
import { ReactComponent as Organization } from "../assets/svg/organization.svg";
import { ReactComponent as ServiceProvider } from "../assets/svg/service_provider.svg";
import { ReactComponent as Team } from "../assets/svg/team.svg";
import { ReactComponent as AddMicro } from "../assets/svg/addmicro.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
const isCompany = JSON.parse(localStorage.getItem("User"))?.is_company;
export const BASE_URL = process.env.REACT_APP_BASE_URL || "https://backend.factreehub.com"
console.log("BASE_URL",BASE_URL);

export const Logo =
  "https://d1v5edamowtwc9.cloudfront.net/static/media/FactreeHub%20Logo%20with%20Tagline.e81102e9c12e3687c3cd0505af25008e.svg";

export const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL || "https://app.factreehub.com/auth/signin"
// STEPPER INFOS
export const CUSTOMER_INFO_STEPPER = "customer_info";
export const PROJECT_INFO_STEPPER = "project_info";
export const TAG_INFO_STEPPER = "tag_info";

export const DEFAULT_CUSTOMER_INFO_STATE = {
  name: "",
  email: "",
  contact_no: "",
  organizations: {
    name: "",
    size: "",
  },
};

export const PROJECT_INFO_STATE = {
  name: "",
  projectUniqueId: "",
  description: "",
  category: "",
  tags: "",
};

export const TAG_INFO_STATE = {
  name: "",
  description: "",
  sub_category: "",
  // team_type: "",
  primary_scope: "",
  technical_scope: [],
  attachments: [],
};

export const ADMIN_SIDEBAR_MENU = [
  {
    route: "/admin/mails",
    name: "Mails",
    icon: <Mail />,
  },
  {
    route: "/admin/projects",
    name: "Projects",
    icon: <Project />,
  },
  {
    route: "/admin/organizations",
    name: "Organizations",
    icon: <Organization />,
  },
  {
    route: "/admin/service-providers",
    name: "Service Providers",
    icon: <ServiceProvider />,
  },
  {
    route: "/admin/teams",
    name: "Teams",
    icon: <Team />,
  },
  {
    route: "/admin/add",
    name: "Add Micro service",
    icon: <FontAwesomeIcon className="text-xl" icon={faPuzzlePiece} />,
  },
];

export const CUSTOMER_SIDEBAR_MENU = [
  {
    route: "/customer/projects",
    name: "Projects",
    icon: <Project />,
  },
  {
    route: "/customer/mails",
    name: "Mail ids",
    icon: <Mail />,
  },
  // {
  //   route: "/customer/ticket",
  //   name: "Tickets",
  //   icon: <Tickets />,
  // },
];
export const CUSTOMER_SIDEBAR_MENU_TEAMS = [
  {
    route: "/customer/projects",
    name: "Projects",
    icon: <Project />,
  },
];

export const subCategoryOptions = [
  { value: "static-equipment", label: "Static Equipment" },
  { value: "piping-structural", label: "Piping & Structural" },
];

export const primaryScopeOptions = {
  "static-equipment": [
    { value: "Design", label: "Design" },
    { value: "Drafting & Detailing", label: "Drafting & Detailing" },
    { value: "Document & Review", label: "Document & Review" },
    { value: "Analysis & Simulation", label: "Analysis & Simulation" },
  ],
  "piping-structural": [
    { value: "Design", label: "Design" },
    { value: "Drafting & Detailing", label: "Drafting & Detailing" },
    { value: "Analysis & Simulation", label: "Analysis & Simulation" },
  ],
};

export const technicalScopeOptions = {
  "static-equipment": {
    Design: [
      { value: "Mechanical Design", label: "Mechanical Design" },
      { value: "Thermal Design", label: "Thermal Design" },
    ],
    "Drafting & Detailing": [
      { value: "GA Drawing", label: "GA Drawing" },
      { value: "Fabrication Drawing", label: "Fabrication Drawing" },
      { value: "3-D Modeling", label: "3-D Modeling" },
    ],
    "Document & Review": [
      { value: "Vendor Document Review", label: "Vendor Document Review" },
      {
        value: "Mechanical Document Review",
        label: "Mechanical Document Review",
      },
      {
        value: "Detailed Document Review",
        label: "Detailed Document Review",
      },
    ],
    "Analysis & Simulation": [
      { value: "Finite Element Analysis", label: "Finite Element Analysis" },
      {
        value: "Computational Fluid Dynamics",
        label: "Computational Fluid Dynamics",
      },
    ],
  },
  "piping-structural": {
    Design: [{ value: "Structural Design", label: "Structural Design" }],
    "Drafting & Detailing": [
      {
        value: "Piping Schematic Drawing",
        label: "Piping Schematic Drawing",
      },
      { value: "Isometric Drawing", label: "Isometric Drawing" },
    ],
    "Analysis & Simulation": [
      { value: "Piping Stress Analysis", label: "Piping Stress Analysis" },
    ],
  },
};

///customer sign up
export const industrySegmentOptions = [
  { value: "Oil & Gas " },
  { value: "Petrochemical" },
  { value: "Chemical" },
  { value: "Paints" },
  { value: "Sugar" },
  { value: "Food & Dairy" },
  { value: "Pharma" },
  { value: "Others" },
];

export const softwareUsedOptions = [
  { value: "Software 1" },
  { value: "Software 2" },
];

//company signup

export const serviceCapabilitiesOptions = [
  { value: "Service 1" },
  { value: "Service 2" },
];

export const softwareCapabilitiesOptions = [
  { value: "Software 1" },
  { value: "Software 2" },
];

export const subCategoriesOptions = [
  { value: "Metallurgy Services" },
  { value: "Piping Services" },
  { value: "Skid Equipment Serices" },
  { value: "Static Equipment Services" },
  { value: "Structural Services" },
];

export const verticalOptions = [{ value: "Design & Engineering" }];

export const subVerticalOptions = [
  { value: "Modelling & Detailing" },
  { value: "Design & Validation" },
  { value: "Estimation & Proposal" },
  { value: "Documentation & Verification" },
  { value: "Design Calculation" },
  { value: "Analysis & Simulation" },
];
