import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";
import { BASE_URL } from "../../../../constants/Constants";
import { useHistory } from "react-router-dom";
import moment from "moment";

const CredentialsForm = ({ show, setShow }) => {
  const navigate = useHistory();

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };
  // define state variables for input values
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    // Get data from localStorage
    const storedData = JSON.parse(localStorage.getItem("SELECTED_CREDENTIAL"));

    // Update state variables with stored data
    if (storedData) {
      setEmail(storedData.email);
      setUsername(storedData.name);
      setPassword(storedData.password);
      setExpiryDate(moment(storedData.expired_at).format("YYYY-MM-DD"));
    }
  }, []);

  // define a function to handle input changes
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "username") {
      setUsername(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "expiry_date") {
      setExpiryDate(value);
    }
  };

  // define a handleSubmit function to pass the input values to the payload
  const handleSubmit = () => {
    if (!email | !username || !password || !expiryDate) {
      setError("Please fill all the fields.");
    } else {
      setLoading(true);
      const path = navigate.location.pathname;
      const pathArray = path.split("/");
      const id = pathArray[pathArray.length - 1];
      const payload = {
        email,
        name: username,
        password,
        expiry_date: expiryDate,
        type: "Customer",
        project_id: id,
        id: JSON.parse(localStorage.getItem("SELECTED_CREDENTIAL")).id,
      };

      // or pass the payload to an API endpoint
      axios
        .post(`${BASE_URL}/api/create/user`, payload, { headers })
        .then((res) => {
          toast.success("Success");
          setShow(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          // setIsDisabled(false);
        });
    }
  };
  return (
    <div>
      <div className="mt-5">
        {error && (
          <div
            className="bg-red-100 border res125:text-sm border-red-400 text-red-700 px-4 py-3  res150:py-2 res125:py-1.5 res125:h-9 res150:h-8 res150:text-xs text-center w-full rounded relative mb-4 res150:mb-2"
            role="alert"
          >
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <div className="w-full">
          <label
            htmlFor="Email"
            className="font-bold tracking-wider res125:text-sm res150:text-xs"
          >
            Email
          </label>
          <input
            className="w-full px-4 py-3 text-sm res125:h-9 res150:text-xs res150:h-8 placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8]"
            type="email"
            placeholder="Email"
            name="email"
            value={email}
            disabled
          />
        </div>
        <div className="w-full py-3  res125:text-sm res150:text-xs">
          <label htmlFor="Username" className="font-bold tracking-wider">
            User Name
          </label>
          <input
            className="w-full px-4 py-3 text-sm res125:h-9 res150:text-xs res150:h-8 placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] "
            type="text"
            placeholder="Username"
            name="username"
            value={username}
            disabled
          />
        </div>
        <div className="w-full pb-3 res125:text-sm res150:text-xs">
          <label htmlFor="Password" className="font-bold tracking-wider">
            Password
          </label>
          <input
            className="w-full px-4 py-3 text-sm res125:h-9 res150:text-xs res150:h-8 placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8]"
            type="text"
            placeholder="Password"
            name="password"
            value={password}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-full res125:text-sm res150:text-xs">
          <label htmlFor="Expiry Date" className="font-bold tracking-wider">
            Expiry Date
          </label>
          <input
            className="w-full px-4 py-3 text-sm res125:h-9 res150:text-xs res150:h-8 placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8]"
            type="date"
            placeholder="Expiry Date"
            name="expiry_date"
            value={expiryDate}
            onChange={handleInputChange}
            min={new Date().toISOString().split("T")[0]}
          />
        </div>
      </div>
      <div className="absolute top-[89%] left-[10%]">
        <div className="flex flex-row mx-[34px] res125:mx-4 res150:mx-3">
          <div>
            <button
              className="p-2 res150:p-0 mr-4 border res150:text-xs res150:h-7 border-[#03298C] hover:bg-[#72C27A] hover:text-white hover:border-none w-32 res125:w-28 res125:text-xs res150:w-24 font-medium text-sm"
              onClick={() => {
                setShow(!show);
                localStorage.removeItem("SELECTED_CREDENTIAL");
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className="p-2 res150:p-0 mr-4 border res150:text-xs res150:h-7 border-[#03298C] hover:bg-[#72C27A] hover:text-white hover:border-none w-32 res125:w-28 res125:text-xs res150:w-24 font-medium text-sm "
              // disabled={isDisabled}
              onClick={handleSubmit}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CredentialsForm;
