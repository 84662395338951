import React, { useState } from "react";
import VendorsRFQList from "./VendorsRFQList";
import SPQuotationsList from "./SPQuotationsList";
import CustomerQuotationList from "./CustomerQuotationList"

const QuotationsTab = ({ projectData }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [customerQuotation, setCustomerQuotation] = useState(true);


  return (
    <>
      <div className="border-b border-gray-200 w-full mt-6 res125:mt-4 res150:mt-3 res125:text-xs res150:text-xs">
        <nav className="flex">
          <button
            className={`${activeTab === 0
              ? "border-[#03298C] text-white bg-[#03298C] rounded-md mb-1"
              : "border-transparent text-[#C8C8C8] hover:text-gray-700 hover:border-gray-300"
              } w-40 res125:w-32 res150:w-28 res150:py-1.5 py-2 text-center border-b-2 font-bold  focus:outline-none mr-4`}
            onClick={() => setActiveTab(0)}
          >
            SP Quotations
          </button>
          <button
            className={`${activeTab === 1
              ? "border-[#03298C] text-white bg-[#03298C] rounded-md mb-1"
              : "border-transparent text-[#C8C8C8] hover:text-gray-700 hover:border-gray-300 "
              } w-40 res125:w-32 res150:w-28 res150:py-1.5 py-2 text-center border-b-2 font-bold focus:outline-none mr-4`}
            onClick={() => setActiveTab(1)}
          >
            SP RFQ
          </button>
          <button
            className={`${activeTab === 2
              ? "border-[#03298C] text-white bg-[#03298C] rounded-md mb-1"
              : "border-transparent text-[#C8C8C8] hover:text-gray-700 hover:border-gray-300 "
              } w-40 res125:w-32 res150:w-28 res150:py-1.5 py-2 text-center border-b-2 font-bold focus:outline-none`}
            onClick={() => setActiveTab(2)}
          >
            Customer Quotation
          </button>
        </nav>
      </div>

      <div>
        {/* SPs & Quotations */}
        {activeTab === 0 && <SPQuotationsList projectDetails={projectData} />}

        {/* RFQ */}
        {activeTab === 1 && <VendorsRFQList />}

        {/* customer qoutations */}

        {activeTab === 2 && <CustomerQuotationList projectDetails={projectData} />}
      </div>
    </>
  );
};

export default QuotationsTab;
