import React, { useState, useEffect, useContext } from "react";
import { PROJECT_INFO_STEPPER } from "../../../../constants/Constants";
import AppContext from "../../../../context/AppContext";

const CreateProjectForm2 = ({ error }) => {
  const { projectInfo, setProjectInfo } = useContext(AppContext);
  const [selectedCategoryValue, setSelectedCategoryValue] =
    useState("Process Plants");

  useEffect(() => {
    const existingProjectInfo = localStorage.getItem(PROJECT_INFO_STEPPER);
    if (existingProjectInfo !== null && existingProjectInfo !== "undefined") {
      setProjectInfo({
        ...JSON.parse(existingProjectInfo),
        category: selectedCategoryValue,
      });
      setSelectedCategoryValue(JSON.parse(existingProjectInfo).category);
    } else {
      setProjectInfo({ ...projectInfo, category: selectedCategoryValue });
    }
  }, []);

  const handleProjectInfoForm = (event) => {
    const existingProjectInfo = { ...projectInfo };
    const { name, value } = event.target;
    existingProjectInfo[name] = value;
    setProjectInfo(existingProjectInfo);
    if (
      !existingProjectInfo.name &&
      !existingProjectInfo.projectUniqueId &&
      !existingProjectInfo.description &&
      !existingProjectInfo.category &&
      !existingProjectInfo.tags
    ) {
      localStorage.removeItem(PROJECT_INFO_STEPPER);
    }
  };

  return (
    <div className="mt-8">
      <div className="flex flex-wrap max-w-md mx-12 -m-2 mb-5">
        {error && (
          <div
            className="bg-red-100 border text-center border-red-400 text-red-700 px-2 py-2 w-full rounded mb-2 mx-2 res125:py-1.5 res125:text-sm"
            role="alert"
          >
            <span className="block sm:inline">
              * Please fill all the fields.
            </span>
          </div>
        )}
        <div className="w-full p-2">
          <input
            className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res125:px-3 res150:px-2 res150:py-1 res150:text-xs res125:py-1.5 "
            type="text"
            placeholder="Project Name"
            name={"name"}
            value={projectInfo.name}
            onChange={handleProjectInfoForm}
          />
        </div>
        <div className="w-full p-2">
          <input
            className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res125:px-3 res150:px-2 res150:py-1 res150:text-xs res125:py-1.5 "
            type="text"
            placeholder="Project Unique Id"
            name={"projectUniqueId"}
            value={projectInfo.projectUniqueId}
            onChange={handleProjectInfoForm}
          />
        </div>
        <div className="w-full px-2 pt-2">
          <textarea
            className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res125:py-1 res150:py-1 res150:text-xs res125:px-3 res150:px-2"
            style={{ height: "165px", resize: "none" }}
            placeholder="Project Description"
            name={"description"}
            value={projectInfo.description}
            onChange={handleProjectInfoForm}
          />
          <select
            id="category"
            name="category"
            placeholder="Project Category"
            className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] mt-2 mb-2 res150:py-1 res150:text-xs res125:py-1.5 res125:px-3 res150:px-2"
          >
            <option
              value=""
              className="text-gray-500 cursor-not-allowed"
              selected
              disabled
            >
              Project Category
            </option>
            <option value="Process Plants">Process Plants</option>
          </select>
        </div>

        <div className="w-full p-2">
          <input
            className="numberbox w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res150:py-1 res150:text-xs res125:py-1.5 res125:px-3 res150:px-2"
            type="number"
            placeholder="# of Tags"
            name={"tags"}
            value={projectInfo.tags}
            onChange={handleProjectInfoForm}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateProjectForm2;
