import { useState, useEffect, useContext } from "react";
import React from "react";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import ProjectCollab from "../../Shared/Projects/ProjectsCollab";
import AppContext from "../../../context/AppContext";
import useDebounce from "../../../useDebounce";
import { Badge } from "flowbite-react";

const ProjectCollaborations = ({
  pdfUrl,
  handleAnnotate,
  fileName,
  selectedData,
  handleSelectedData,
  project_id,
  projectData,
}) => {
  const {
    comments,
    setComments,
    isTextChanged,
    setTextChanged,
    pageno,
    isCommentsUpdated,
  } = useContext(AppContext);
  const [viewComments, setViewComments] = useState([]);
  const { type, setLoadProject } = useContext(AppContext);
  const [search, setSearch] = useState("");
  useEffect(() => {}, [viewComments.length]);
  const randomColors = (index) => {
    var array = [
      "#ECFFFF",
      "#FFEEEE",
      "#E4FFEC",
      "#FFE4F4",
      "#EEF5FF",
      "#ECF3FF",
      "#FFF2D0",
      "#F1FFDF",
    ];
    var color = "";
    color = array[index % array.length];
    return color;
  };
  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    if (isTextChanged) {
      var commentsArr = comments.filter((obj) => obj.text !== "");
      setComments(commentsArr);
      setTextChanged(false);
    }
  }, [isTextChanged]);

  useEffect(() => {
    if (selectedData.comments && !isCommentsUpdated) {
      setComments([...selectedData.comments]);
    }
  }, [selectedData]);

  useEffect(() => {
    // console.log("comments", comments[0]);
    const result = comments.filter(
      (comment) => comment.pageNumber === pageno && comment.text
    );
    // console.log("result", result);
    setViewComments([...result]);
  }, [pageno, comments]);

  const handleCommentsChange = () => {
    const result = comments.filter(
      (comment) => comment.pageNumber === pageno && comment.text
    );
    setViewComments([...result]);
    let existingData = { ...selectedData };
    existingData.comments = comments;
    handleSelectedData(existingData);
  };

  const user = JSON.parse(localStorage.getItem("User"));
  const handleDiagramStatus = async (event) => {
    let existingData = { ...selectedData };
    existingData.adminStatus = event.target.value;
    handleSelectedData(existingData);

    const payload = {
      diagram_id: selectedData.id,
      status: event.target.value,
      project_id: project_id,
      admin_id: user.id,
      customer_id: projectData?.customer.id,
      vendor_id: selectedData.vendor.id,
    };

    // console.log(selectedData);
    await axios
      .put(`${BASE_URL}/api/diagram/status/update`, payload, { headers })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSearch = () => {
    const searchInput = search.toLowerCase();
    const filteredComments = comments.filter(
      (comment) => comment.text.toLowerCase().indexOf(searchInput) !== -1
    );
    setViewComments([...filteredComments]);
  };

  const debouceSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    handleSearch();
  }, [debouceSearchTerm]);


  const uploadflag = selectedData.adminStatus;

  return (
    <div>
      <div className="w-full h-11 flex mt-1">
        <div className="cursor-pointer py-2">
          <i
            className="fa fa-x fa-angle-left  text-[#03298C] mt-1"
            aria-hidden="true"
          ></i>
          <span
            className="pl-3 text-sm mr-6 res150:text-xs"
            onClick={() => {
              setLoadProject(true);
              handleAnnotate();
            }}
          >
            Back
          </span>
        </div>

        <div className="text-base font-bold py-2.5 text-[#03298C]  res125:text-sm res125:mt-px res150:mt-1 res150:text-xs">
          {fileName}
        </div>
        <select
          id="large"
          name="status"
          onChange={(e) => handleDiagramStatus(e)}
          value={selectedData.adminStatus}
          class="ml-auto w-1/6 px-4 py-2 res150:w-44 res125:rounded-md res150:rounded-md res125:w-48 res125:h-9 res125:text-sm res150:h-8 res150:text-xs text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="Select Status" selected disabled>
            Select Status
          </option>
          {type === "admin" && (
            <>
              <option value="In Review">In Review</option>
              <option value="Rework">Rework</option>
              <option value="Completed">Completed</option>
              <option value="Release to Customer">Release to Customer</option>
            </>
          )}
          {type === "service-provider" && (
            <>
              {uploadflag == "Completed" ||
              uploadflag == "Release to Customer" ? (
                <>{/* <Badge color="success">Completed</Badge> */}</>
              ) : (
                <>
                  <option value="Working">Working</option>
                  <option value="Updated">Updated</option>
                </>
              )}
            </>
          )}
        </select>
      </div>
      {/* empty box */}
      <div className="flex flex-col">
        <div className="w-full h-full border overflow-y-auto mt-5 res125:mt-2 res150:mt-0">
          {type === "admin" && (
            <ProjectCollab
              samplePDF={pdfUrl}
              fileName={fileName}
              selectedData={selectedData}
              handleCommentsChange={handleCommentsChange}
            />
          )}
          {(type === "service-provider" || type === "customer") && (
            <ProjectCollab
              samplePDF={pdfUrl}
              fileName={fileName}
              selectedData={selectedData}
              type="customer"
            />
          )}
        </div>

        <div className="my-5 flex">
          <div>
            {/* Search Box */}
            <div className="w-full">
              <input
                type="text"
                placeholder="Search comments"
                className="h-[46px] w-[350px] border border-[#C8C8C8] pl-2 mb-2 res125:h-9 res125:w-72 res125:text-sm res150:h-7 res150:w-60 res150:text-xs"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <span className="w-40 res125:text-sm res150:text-xs">
              Total comments: {comments.length || 0}
            </span>
            {/* Commments Card */}
            {viewComments.length ? (
              viewComments.map((value, index) => (
                <div
                  className="cursor-pointer grid-cols-1 mb-2"
                  style={{ background: randomColors(index) }}
                  //onClick={() => handleClick(value)}
                  key={index}
                >
                  <div className="h-auto my-2 w-[450px]">
                    <div className="px-2">
                      <div className="font-normal text-sm break-all res150:text-xs">
                        {value.text}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          <div className="w-1/3 ml-auto">
            <p className="text-base font-bold res125:text-sm res150:text-xs">
              Vendor Details
            </p>
            <div className="text-sm font-normal mt-1 text-[#5F5F5F] res125:text-sm res150:text-xs">
              <p>
                {" "}
                Name:{" "}
                <span className="text-black text-base ml-20 res125:text-sm res150:ml-16 res150:text-xs">
                  {selectedData?.vendor?.name}
                </span>
              </p>
              <p className=" whitespace-nowrap overflow-hidden text-ellipsis">
                Email_id:{" "}
                <span className="text-black text-base ml-16 res125:text-sm res150:ml-12 res150:text-xs">
                  {selectedData?.vendor?.email}
                </span>
              </p>
              <p>
                Phone No:{" "}
                <span className="text-black text-base ml-14 res125:text-sm res150:ml-11 res150:text-xs">
                  {selectedData?.vendor?.contact_no}
                </span>
              </p>
              <p>
                Sub_vertical:{" "}
                <span className="text-black text-base ml-6 res125:text-sm res150:ml-4 res150:text-xs">
                  {selectedData?.sub_vertical}
                </span>
              </p>
              <p>
                Microservice :{" "}
                <span className="text-black text-base ml-3 res125:text-sm res150:ml-2 res150:text-xs">
                  {selectedData?.scope}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCollaborations;
