import React from "react";

const Popup = ({ handleClose }) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-[19px] rounded-lg max-w-md">
        <div className="flex justify-between">
          <h3 className="text-xl font-semibold text-blue-900">
            Terms & Conditions
          </h3>
          <button
            className="text-gray-400 hover:text-gray-800"
            onClick={handleClose}
          >
            <svg
              className="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
          </button>
        </div>

        <p className="text-base leading-relaxed text-gray-500 mt-[10px]">
          • The Partner is obliged to treat confidential information exclusively
          in accordance with the confidentiality NDA concluded by Factreehub.
        </p>
        <p className="text-base leading-relaxed text-gray-500 mt-[10px]">
          • The Partner agrees that the confidentiality agreement is an integral
          part of these Terms and Conditions.
        </p>
      </div>
    </div>
  );
};

export default Popup;
