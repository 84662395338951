import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-hot-toast";
import Popup from "../../Customer/Signup/popup";
import {
  BASE_URL,
  serviceCapabilitiesOptions,
  softwareCapabilitiesOptions,
  verticalOptions,
  subCategoriesOptions,
  subVerticalOptions,
} from "../../../constants/Constants";
import swal from "sweetalert";
import Logo from "../../../assets/images/Factree Hub Logo.png";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import countryCodesData from "../../../assets/json/CountryCode.json";

export default function CompanySignupForm() {
  const [error, setError] = useState(null);
  const formArray = [1, 2, 3, 4, 5];
  const [formNo, setFormNo] = useState(formArray[0]);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setCofirmPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useHistory();
  const [countryCodes, setCountryCodes] = useState(countryCodesData.countries);
  const [selectedOption, setSelectedOption] = useState("+91");
  const [serviceRequiredOptions, setserviceRequiredOptions] = useState([]);
  const [softwareOptions, setSoftwareOptions] = useState([]);

  //terms and condition
  const openPopup = () => {
    setShowPopup(true);
  };
  const closePopup = () => {
    setShowPopup(false);
  };

  const [compUser, setcompUser] = useState({
    type: "company",
    name: "",
    contact: "",
    email: "",
    password: "",
    org_name: "",
    role: "",
    website: "",
    orgType: "",
    org_size: "",
    country: "",
    gst: "",
    msme: "",
    bankName: "",
    accountHolderName: "", // accname
    accountNumber: "",
    ifscCode: "",
    branchName: "",
    serviceCapabilities: [],
    softwareCapabilities: [],
    serviceAgreement: false,
    category: "",
    subCategory: "",
    vertical: "",
    subVertical: "",
    docs: [],
  });

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  compUser.serviceAgreement = isChecked;

  const handleChange = ({ target: { name, value } }) => {
    setcompUser((data) => {
      return { ...data, [name]: value };
    });
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = () => {
    setCofirmPassword(!showConfirmPassword);
  };
  //////service
  const handleSerSelect = (selectedList) => {
    let existingUser = compUser;
    existingUser.serviceCapabilities = selectedList;
    setcompUser(existingUser);
  };

  const handleSerRemove = (selectedList) => {
    resetSelectField();
    let existingUser = compUser;
    existingUser.serviceCapabilities = selectedList;
    setcompUser(existingUser);
  };

  //////softwareused
  const handleSoftSelect = (selectedList) => {
    let existingUser = compUser;
    existingUser.softwareCapabilities = selectedList;
    setcompUser(existingUser);
  };

  const handleSoftRemove = (selectedList) => {
    resetSelectField();
    let existingUser = compUser;
    existingUser.softwareCapabilities = selectedList;
    setcompUser(existingUser);
  };

  const dropDownChange = ({ target: { name, value } }) => {
    let existingUser = { ...compUser };
    switch (name) {
      case "category":
        existingUser.category = value;
        existingUser.subCategory = "";
        existingUser.vertical = "";
        existingUser.subVertical = "";
        break;
      case "subCategory":
        existingUser.subCategory = value;
        existingUser.vertical = "";
        existingUser.subVertical = "";
        break;
      case "vertical":
        existingUser.vertical = value;
        existingUser.subVertical = "";
        break;
      case "subVertical":
        existingUser.subVertical = value;
        break;
      default:
        break;
    }
    setcompUser({ ...existingUser });
    resetSelectField();
  };

  /////fileupload

  const handleFiles = async (event) => {
    setSelectedFile(event.target.files[0]);
    let obj = { ...compUser };
    obj[event.target.name] = event.target.files[0].name;
    setcompUser({ ...obj });
  };

  const fileUpload = async () => {
    let success = false;
    const s3Client = new S3Client({
      region: "ap-south-1",
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    });

    const params = {
      Bucket: "factreehub-docs",
      Key: `verification/${selectedFile.name}`,
      ContentType: selectedFile.type,
      Body: selectedFile,
    };

    try {
      const putObjectCommand = new PutObjectCommand(params);
      const reponse = await s3Client.send(putObjectCommand);
      toast.success("File uploaded successfully!");
      success = true;
    } catch (error) {
      console.error(error);
      toast.error("Please Try Again");
      success = false;
    }
    if (!success) {
      setSelectedFile();
    }
  };

  useEffect(() => {
    if (selectedFile) {
      fileUpload();
    }
  }, [selectedFile]);

  ///form start

  const next = async () => {
    if (formNo === 1) {
      if (!compUser.name) {
        setError("Full Name is required.");
        return;
      }
      if (!compUser.contact) {
        setError("Contact No. is required.");
        return;
      } else if (!/^\d{4,15}$/.test(compUser.contact)) {
        setError(
          "Contact should be number and it must contains 4 digits long."
        );
        return;
      }

      if (!compUser.email) {
        setError("Email is required.");
        return;
      } else if (!/^\S+@\S+\.\S+$/.test(compUser.email)) {
        setError("Please enter a valid email address.(EX:abcd@gmail.com)");
        return;
      } else if (compUser.email) {
        try {
          await axios.get(`${BASE_URL}/api/email-check`, {
            params: {
              email: compUser.email,
            },
          });
        } catch (error) {
          setError(error.response.data.message);
          return true;
        }
      }

      if (!compUser.password) {
        setError("Password is required.");
        return;
      } else if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/.test(
          compUser.password
        )
      ) {
        setError(
          "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character (!@#$%^&*)"
        );
        return;
      }

      if (!compUser.confirmedpassword) {
        setError("Confirm password is required.");
        return;
      }
      if (compUser.password !== compUser.confirmedpassword) {
        setError("Password and Confirm password do not match.");
        return;
      } else {
        setError(null);
      }
      setFormNo(formNo + 1);
    } else if (formNo === 2) {
      if (!compUser.org_name) {
        setError("Company Name is required.");
        return;
      }
      if (!compUser.role) {
        setError("Role is required.");
        return;
      }
      // if (!compUser.website) {
      //   setError("Website is required");
      //   return;
      // }
      if (!compUser.orgType) {
        setError("Organization Type is required.");
        return;
      }
      if (!compUser.org_size) {
        setError("Organization Size is required.");
        return;
      }
      if (!compUser.country) {
        setError("Country is required.");
        return;
      } else {
        setError(null);
      }
      setFormNo(formNo + 1);
    } else if (formNo === 3) {
      if (!compUser.category) {
        setError("Category is required.");
        return;
      }
      if (!compUser.subCategory) {
        setError("Sub-Category is required.");
        return;
      }
      if (!compUser.vertical) {
        setError("Vertical is required.");
        return;
      }
      if (!compUser.subVertical) {
        setError("SubVertical is required.");
        return;
      }
      if (!compUser.serviceCapabilities.length) {
        setError("ServiceCapabilities is required.");
        return;
      }
      if (!compUser.softwareCapabilities.length) {
        setError("SoftwareUsed is required.");
        return;
      } else {
        setError(null);
      }
      setFormNo(formNo + 1);
    } else if (formNo === 4) {
      if (!compUser.gst) {
        setError("GST No is required.");
        return;
      } 
      // else if (
      //   !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{4}$/.test(compUser.gst)
      // ) {
      //   setError("Please enter a valid GST NO.(Ex:12GGGGG1314R9Z)");
      //   return;
      // }
      if (!compUser.msme) {
        setError("MSME is required.");
        return;
      }

      if (!compUser.bankName) {
        setError("BankName is required.");
        return;
      } else if (!/^[a-zA-Z\s]{7,16}$/.test(compUser.bankName)) {
        setError(
          `BankName should only contain letters, spaces, and be between 7 and 16 characters long.`
        );
        return;
      }

      if (!compUser.accountHolderName) {
        setError("AccountName is required.");
        return;
      } else if (!/^[a-zA-Z\s]{2,30}$/.test(compUser.accountHolderName)) {
        setError(
          `AccountName should only contain letters, spaces, and be between 2 and 30 characters long.`
        );
        return;
      }

      if (!compUser.accountNumber) {
        setError("AccountNumber is required.");
        return;
      } else if (!/^\d{11,15}$/.test(compUser.accountNumber)) {
        setError(
          "AccountNumber contains only numbers between 11 to 15 digits."
        );
        return;
      } else {
        setError(null);
      }
      setFormNo(formNo + 1);
    } else if (formNo === 5) {
      if (!compUser.ifscCode) {
        setError("IFSC Code is required.");
        return;
      }
      if (!compUser.branchName) {
        setError("BranchName is required");
        return;
      } else {
        setError(null);
      }
    }
  };

  const multiselectRef = useRef();

  const resetSelectField = () => {
    multiselectRef.current?.resetSelectedValues();
  };

  const back = () => {
    setFormNo(formNo - 1);
  };
  const handleSubmit = async (e) => {
    if (!compUser.name) {
      setError("Full Name is required.");
    } else if (!compUser.contact) {
      setError("Contact No. is required.");
    } else if (!compUser.email) {
      setError("Email is required.");
    } else if (!compUser.password) {
      setError("Password is required.");
    } else if (!compUser.confirmedpassword) {
      setError("Confirm password is required.");
    } else if (compUser.password !== compUser.confirmedpassword) {
      setError("Password and confirm password do not match.");
    } else if (!compUser.org_name) {
      setError("CompanyName is required.");
    } else if (!compUser.role) {
      setError("Role is required.");
    } else if (!compUser.website) {
      setError("Website is required.");
    } else if (!compUser.orgType) {
      setError("Organization Type is required.");
    } else if (!compUser.org_size) {
      setError("Organization Size is required.");
    } else if (!compUser.country) {
      setError("Country is required.");
    } else if (!compUser.category) {
      setError("Category is required.");
    } else if (!compUser.subCategory) {
      setError("SubCategory is required.");
    } else if (!compUser.vertical) {
      setError("Vertical is required.");
    } else if (!compUser.subVertical) {
      setError("Sub-Vertical is required.");
    } else if (!compUser.gst) {
      setError("GST No is required.");
    } else if (!compUser.msme) {
      setError("MSME No is required.");
    } else if (!compUser.bankName) {
      setError("BankName is required.");
    } else if (!compUser.accountHolderName) {
      setError("AccountName is required.");
    } else if (!compUser.accountNumber) {
      setError("AccountNumber is required.");
    } else if (!compUser.ifscCode) {
      setError("IFSC Code is required.");
    } else if (!/^[A-Z]{4}[0][A-Z0-9]{6}$/.test(compUser.ifscCode)) {
      setError(
        "Please enter a valid IFSC code,it should be 11 characters long (EX:ABCD0XX5943)"
      );
    } else if (!compUser.branchName) {
      setError("Branch Name is required.");
    } else if (!/^[a-zA-Z\s]{2,30}$/.test(compUser.branchName)) {
      setError(
        `BranchName should only contain letters, spaces, and be between 7 and 16 characters long.`
      );
    } else if (!compUser.serviceCapabilities) {
      setError("Service is required.");
    } else if (!compUser.softwareCapabilities) {
      setError("SoftwareUsed is required.");
    } else if (!compUser.docs.length) {
      setError("Document is required.");
    } else if (!compUser.serviceAgreement) {
      setError("Please Accept the Terms and Conditons.");
    } else {
      e.preventDefault();
      const updatedUser = {
        ...compUser,
        contact: `${selectedOption} - ${compUser.contact}`,
      };
      setLoading(true);
      setIsDisabled(true);
      setError("");
      await axios
        .post(`${BASE_URL}/api/serviceprovider/create`, updatedUser)
        .then((res) => {
          navigate.push("/auth/signin");
          toast.success(" Company Details Register Successfully");
          swal(
            "Congratulations",
            "Your Account has register successfully",
            "success"
          );
          setLoading(false);
          setIsDisabled(false);
        })
        .catch((err) => {
          swal(
            `${err.response.data.message}`,
            "Please try again with a different email !",
            "error"
          );
          toast.error(`${err.response.data.message}`);
          setLoading(false);
          setIsDisabled(false);
          console.log(err.response.data.message);
        });
    }
  };

  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const serviceRequired = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getAllService`, {
        params: {
          service: JSON.stringify(compUser.serviceCapabilities),
        },
      });

      if (response.data && response.data.Allmicroserivce) {
        const microservices = response.data.Allmicroserivce;

        const options = microservices.map((microservice) => ({
          label: microservice._id,
          value: microservice.microservice,
        }));

        setserviceRequiredOptions(options);
      } else {
        console.error("API response format is unexpected.");
      }
    } catch (error) {
      console.error("Error fetching service options:", error);
    }
  };

  const fetchSoftwareOptions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/get/softwares`, {
        headers,
      });
      if (response.data && response.data.data) {
        const softwareData = response.data.data.map((item) => ({
          label: item.softwares,
          value: item.softwares,
        }));
        setSoftwareOptions(softwareData);
      }
    } catch (error) {
      console.error("Error fetching software options:", error);
    }
  };

  useEffect(() => {
    serviceRequired();
    fetchSoftwareOptions();
  }, []);

  return (
    // <div className="container flex mx-auto h-full max-w-full items-center justify-center bg-sky-200">

    //   <div className="card rounded-2xl mx-auto bg-white py-5 px-10">
    //     <img
    //       src={Logo}
    //       className="mx-auto w-[224px] res125:w-1/4 res150:w-1/4"
    //       alt="FactreeHub"
    //     />
    <div className="flex flex-col gap-3 pt-8 res125:pt-5 res150:pt-4 max-w-[390px] m-auto px-[20px]">
      {/* <p className="text-3xl font-bold mb-2 w-full h-16 res150:h-12 text-center res125:text-xl res125:mb-0 res150:text-lg res150:mb-0">
            Customer Sign-up
          </p> */}

      {error && (
        <div
          className="bg-red-100 border text-center relative  border-red-400 text-red-700 px-3 py-2  rounded mb-2  res150:mb-1 res150:text-[10px]   res125:mb-1 res125:text-xs"
          role="alert"
        >
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      {formNo === 1 && (
        <div>
          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="fullName"
              className="res150:text-[10px] res125:text-xs"
            >
              Full Name
            </label>
            <input
              value={compUser.name}
              onChange={handleChange}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px]  res150:h-8  res150:text-[10px]  res125:h-10  res125:text-xs"
              type="text"
              name="name"
              placeholder="Enter your full name"
            />
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="contact"
              className="res150:text-[10px] res125:text-xs"
            >
              Contact No
            </label>

            <div className="flex ">
              <select
                className="w-[34%] h-12 mt-[10px] rounded border-[#C8C8C8] uppercase res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
                value={selectedOption}
                onChange={(event) => setSelectedOption(event.target.value)}
              >
                {countryCodes?.map((code) => (
                  <option key={code.code} value={code.code}>
                    <span>{code.name.slice(0, 3)}</span> ({code.code})
                  </option>
                ))}
              </select>
              <input
                value={compUser.contact}
                onChange={handleChange}
                className="w-[64%] h-12 rounded border-[#C8C8C8] mt-[10px] ml-auto res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
                type="text"
                name="contact"
                placeholder="Enter your contact no"
              />
            </div>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="email"
              className="res150:text-[10px] res125:text-xs"
            >
              Email
            </label>
            <input
              value={compUser.email}
              onChange={handleChange}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:text-[10px] res125:text-xs res150:h-8 res125:h-10  "
              type="email"
              name="email"
              placeholder="Enter your email"
            />
          </div>
          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="password"
              className="res150:text-[10px] res125:text-xs"
            >
              Password
            </label>
            <div className="relative mt-[10px] h-[40px] ">
              <input
                type={showPassword ? "text" : "password"}
                className=" w-[100%] border-[#C8C8C8] rounded h-12 placeholder-gray-400  res150:h-8 res150:text-[10px] res125:h-10 res125:text-xs"
                placeholder="Enter password"
                value={compUser.password}
                name="password"
                onChange={handleChange}
              />
              <span
                type="button"
                className="w-1/12 ml-auto flex transform -translate-y-8 text-gray-500 focus:outline-none res150:my-2 res125:my-1"
                onClick={handleTogglePassword}
              >
                {showPassword ? (
                  <i
                    className="fa fa-eye cursor-pointer"
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye-slash  cursor-pointer"
                    aria-hidden="true"
                  ></i>
                )}
              </span>
            </div>
          </div>
          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="confirmPassword"
              className="res150:text-[10px] res125:text-xs"
            >
              Confirm Password
            </label>
            <div className="relative mt-[10px] h-[40px] ">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="w-[100%] border-[#C8C8C8] rounded h-12  placeholder-gray-400  res150:h-8 res150:text-[10px]  res125:h-10 res125:text-xs"
                placeholder="Confirm password"
                value={compUser.confirmedpassword}
                name="confirmedpassword"
                onChange={handleChange}
              />
              <span
                type="button"
                className="w-1/12 ml-auto flex transform -translate-y-8  text-gray-500 focus:outline-none res150:my-2 res125:my-1"
                onClick={handleToggleConfirmPassword}
              >
                {showConfirmPassword ? (
                  <i
                    className="fa fa-eye  cursor-pointer"
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye-slash  cursor-pointer"
                    aria-hidden="true"
                  ></i>
                )}
              </span>
            </div>
          </div>
        </div>
      )}

      {formNo === 2 && (
        <div>
          <div className="flex flex-col mb-[10px]">
            <label htmlFor=" " className="res150:text-[10px] res125:text-xs">
              Company
            </label>
            <input
              onChange={handleChange}
              value={compUser.org_name}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px]  res150:text-[10px] res125:text-xs res150:h-8 res125:h-10  "
              type="text"
              name="org_name"
              placeholder="Enter Company Name"
            ></input>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="role"
              className="block res150:text-[10px] res125:text-xs"
            >
              Role
            </label>
            <select
              id="role"
              name="role"
              value={compUser.role}
              onChange={handleChange}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
            >
              <option
                value=""
                className="text-gray-500 res150:text-[10px] res125:text-xs"
                selected
                disabled
              >
                Select
              </option>
              <option value="admin">Admin</option>
              <option value="sale">Sales</option>
            </select>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="website"
              className="res150:text-[10px] res125:text-xs"
            >
              Website
            </label>
            <input
              onChange={handleChange}
              value={compUser.website}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="text"
              name="website"
              placeholder="Enter your website"
            ></input>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="orgType"
              className="block res150:text-[10px] res125:text-xs"
            >
              Organization Type
            </label>
            <select
              id="orgType"
              name="orgType"
              value={compUser.orgType}
              onChange={handleChange}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
            >
              <option
                value=""
                className="text-gray-500 res150:text-[10px] res125:text-xs"
                selected
                disabled
              >
                Select
              </option>
              <option value="EPC">EPC</option>
              <option value="Manufacturer">Manufacturer</option>
              <option value="Engineering Design Company (EDC)">
                Engineering Design Company (EDC)
              </option>
              <option value="Others">Others</option>
            </select>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="org_size"
              className="block res150:text-[10px] res125:text-xs"
            >
              Organization Size
            </label>
            <select
              id="org_size"
              name="org_size"
              value={compUser.org_size}
              onChange={handleChange}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
            >
              <option
                value=""
                className="text-gray-500 res150:text-[10px] res125:text-xs"
                selected
                disabled
              >
                Select
              </option>
              <option value="Small">Small</option>
              <option value="Medium">Medium</option>
              <option value="Large">Large</option>
            </select>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="country"
              className="res150:text-[10px] res125:text-xs"
            >
              Country
            </label>
            <input
              onChange={handleChange}
              value={compUser.country}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="text"
              name="country"
              placeholder="Enter your Country"
            ></input>
          </div>
        </div>
      )}

      {/* form 3 */}
      {formNo === 3 && (
        <div>
          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="category"
              className="block res150:text-[10px] res125:text-xs"
            >
              Category
            </label>
            <select
              id="category"
              name="category"
              value={compUser.category}
              onChange={dropDownChange}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
            >
              <option
                value=""
                className="text-gray-500 res150:text-[10px] res125:text-xs"
                selected
                disabled
              >
                Select
              </option>
              <option value="Process Plants">Process Plants</option>
            </select>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="sub-category"
              className="block res150:text-[10px] res125:text-xs"
            >
              Sub-Category
            </label>
            <select
              id="sub-category"
              name="subCategory"
              value={compUser.subCategory}
              onChange={dropDownChange}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8   res125:h-10 "
              disabled={!compUser.category}
            >
              <option
                value=""
                className="text-gray-500 res150:text-[10px] res125:text-xs"
                selected
                disabled
              >
                Select{" "}
              </option>
              {subCategoriesOptions &&
                subCategoriesOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.value}
                  </option>
                ))}
            </select>
          </div>

          <div className="flex flex-col mb-[10px]">
            {compUser?.subCategory && (
              <>
                <label
                  htmlFor="vertical"
                  className="block res150:text-[10px] res125:text-xs res125:mb-3 res150:mb-1"
                >
                  Vertical
                </label>
                <select
                  id="vertical"
                  name="vertical"
                  value={compUser.vertical}
                  onChange={dropDownChange}
                  className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8   res125:h-10 "
                  disabled={!compUser.subCategory}
                >
                  <option
                    value=""
                    className="text-gray-500 res150:text-[10px] res125:text-xs"
                    selected
                    disabled
                  >
                    Select{" "}
                  </option>
                  {verticalOptions &&
                    verticalOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.value}
                      </option>
                    ))}
                </select>
              </>
            )}
          </div>

          <div className="flex flex-col mb-[10px]">
            {compUser?.vertical && (
              <>
                <label
                  htmlFor="subVertical"
                  className="block res150:text-[10px] res125:text-xs res125:mb-3 res150:mb-1"
                >
                  Sub-Vertical
                </label>
                <select
                  id="subVertical"
                  name="subVertical"
                  value={compUser.subVertical}
                  onChange={dropDownChange}
                  className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8   res125:h-10 "
                  disabled={!compUser.vertical}
                >
                  <option
                    value=""
                    className="text-gray-500 res150:text-[10px] res125:text-xs"
                    selected
                    disabled
                  >
                    Select{" "}
                  </option>
                  {subVerticalOptions &&
                    subVerticalOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.value}
                      </option>
                    ))}
                </select>
              </>
            )}
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="serviceCapabilities"
              className="block res150:text-[10px] res125:text-xs"
            >
              Service Capabilities
            </label>
            <div className="flex items-center justify-center  mb-1.5 bg-white  ">
              <div className=" w-[100%] border border-[#C8C8C8] ml-0 signup">
                <Multiselect
                  className="res125:text-xs res150:text-[10px]"
                  options={serviceRequiredOptions}
                  selectedValues={compUser.serviceCapabilities}
                  onSelect={handleSerSelect}
                  onRemove={handleSerRemove}
                  displayValue="value"
                  placeholder="Select Service"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="softwareUsed"
              className="block res150:text-[10px] res125:text-xs"
            >
              Software capabilities & ownership
            </label>
            <div className="flex items-center justify-center  mb-[10px] bg-white ">
              <div className="w-[100%] border border-[#C8C8C8] ml-0 signup">
                <Multiselect
                  className="res125:text-xs res150:text-[10px]"
                  options={softwareOptions}
                  selectedValues={compUser.softwareCapabilities}
                  onSelect={handleSoftSelect}
                  onRemove={handleSoftRemove}
                  displayValue="value"
                  placeholder="Select Software"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {formNo === 4 && (
        <div>
          <div className="flex flex-col mb-[10px]">
            <label htmlFor="gst" className="res150:text-[10px] res125:text-xs">
              GST No
            </label>
            <input
              onChange={handleChange}
              value={compUser.gst}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1  res150:h-8 res150:mb-1 res150:text-[10px]  res125:h-10 res125:mb-1 res125:text-xs"
              type="text"
              name="gst"
              placeholder="Enter GST No"
            ></input>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label htmlFor="msme" className="res150:text-[10px] res125:text-xs">
              MSME No
            </label>
            <input
              onChange={handleChange}
              value={compUser.msme}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="text"
              name="msme"
              placeholder="Enter MSME No"
            ></input>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="bankName"
              className="res150:text-[10px] res125:text-xs"
            >
              Bank Name
            </label>
            <input
              onChange={handleChange}
              value={compUser.bankName}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10"
              type="text"
              name="bankName"
              placeholder="Enter Bank Name"
            ></input>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="accountHolderName"
              className="res150:text-[10px] res125:text-xs"
            >
              A/C Name
            </label>
            <input
              onChange={handleChange}
              value={compUser.accountHolderName}
              className=" h-12 rounded border-[#C8C8C8] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="text"
              name="accountHolderName"
              placeholder="Enter Account Name"
            ></input>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="accountNumber"
              className="res150:text-[10px] res125:text-xs"
            >
              A/C Number
            </label>
            <input
              onChange={handleChange}
              value={compUser.accountNumber}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="text"
              name="accountNumber"
              placeholder="Enter Account Number"
            ></input>
          </div>
        </div>
      )}
      {formNo === 5 && (
        <div>
          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="ifscCode"
              className="res150:text-[10px] res125:text-xs"
            >
              IFSC Code
            </label>
            <input
              onChange={handleChange}
              value={compUser.ifscCode}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="text"
              name="ifscCode"
              placeholder="Enter IFSC No"
            ></input>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="branchName"
              className="res150:text-[10px] res125:text-xs"
            >
              Branch Name
            </label>
            <input
              onChange={handleChange}
              value={compUser.branchName}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="text"
              name="branchName"
              placeholder="Enter Company Name"
            ></input>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="country"
              className="res150:text-[10px] res125:text-xs"
            >
              Document
            </label>
            <input
              onChange={(e) => handleFiles(e)}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px]res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="file"
              name="docs"
            />
          </div>

          <div className="flex items-center mb-[10px]">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label className="ml-2">
              I agree with{" "}
              <span className="text-blue-800" onClick={openPopup}>
                terms & conditions
              </span>
            </label>
          </div>
          {showPopup && <Popup handleClose={closePopup} />}

          <button
            type="submit"
            className={`w-[100%] h-12 bg-[#03298C] text-white rounded tracking-wider font-bold text-lg mt-4 mb-4 hover:bg-[#03298C] res150:text-[10px] res125:text-xs res150:h-9 res125:h-10  res150:mt-1 ${
              isDisabled ? "opacity-50 cursor-not-allowed" : ""
            }`}
            id="companysubmit"
            disabled={isDisabled}
            onClick={handleSubmit}
          >
            {loading ? (
              <>
                <img
                  src="https://media1.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif"
                  className="w-[3rem] mx-auto res150:w-[1.5rem] res125:w-[2rem]"
                  alt=""
                />
              </>
            ) : (
              "SIGN UP"
            )}
          </button>
        </div>
      )}

      {/* </form> */}

      {/*steps number and line  */}
      <div className="flex justify-between">
        {formNo != 1 ? (
          <div className="flex justify-start items-center res125:mt-1 res150:mt-1">
            <p
              onClick={back}
              className="text-gray-400 cursor-pointer font-bold hover:text-[#03298C] res125:text-sm res150:text-[10px] whitespace-nowrap"
            >
              <i
                className="fa fa-angle-left text-lg pr-1 font-bold res150:text-xs res125:text-base"
                aria-hidden="true"
              ></i>{" "}
              Back
            </p>
          </div>
        ) : (
          <div></div>
        )}

        <div className="flex justify-center items-center px-[5px] res150:text-xs res125:text-xs  res150:w-[20rem] res125:w-[24rem]">
          {/* this is step number */}
          {formArray.map((v, i) => (
            <>
              <div
                className={`w-[40px] my-3 text-white rounded-full ${
                  formNo - 1 === i ||
                  formNo - 1 === i + 1 ||
                  formNo === formArray.length ||
                  formNo - 1 >= i
                    ? "bg-[#03298C]"
                    : "bg-gray-300"
                } h-[35px] flex justify-center items-center res150:w-[30px] res150:h-[30px]`}
              >
                {v}
              </div>
              {/*  line between number */}
              {i !== formArray.length - 1 && (
                <div
                  className={`w-[10px] h-[2px] ${
                    formNo === i + 5 || formNo === formArray.length
                      ? "bg-[#03298C] "
                      : "bg-gray-300"
                  }`}
                ></div>
              )}
            </>
          ))}
        </div>

        {formNo != 5 ? (
          <div className="flex justify-end items-center">
            <p
              onClick={next}
              className="text-gray-400 cursor-pointer font-bold hover:text-[#03298C] justify-end res150:text-[10px] res125:text-sm whitespace-nowrap"
            >
              Next{" "}
              <i
                class="fa fa-angle-right text-lg  font-bold res150:text-xs res125:text-base"
                aria-hidden="true"
              ></i>
            </p>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
    //   </div>
    // </div>
  );
}
