import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import moment from "moment";
import Loader from "../../../Loader";
import FeedbackChat from "../../Customer/Projects/FeedbackChat";
import swal from "sweetalert";
import QuotationTable from "../../Shared/Projects/QuotationsTable";
import { Badge, Button, Dropdown, Modal } from "flowbite-react";
import "../../../App.css";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

const CustomerQuotationList = ({ projectDetails }) => {
  const navigate = useHistory();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpens, setIsOpens] = useState(false);
  const [show, setShow] = useState(false);
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [quotations, setQuotations] = useState([]);
  const pathname = navigate.location.pathname.split("/");
  const project_id = pathname[pathname.length - 1];
  const [buttonLoad, setButtonLoad] = useState(false);
  const [comments, setComments] = useState([]);

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const getUserTypes = (user) => {
    if (user.is_admin) {
      return "Admin";
    } else if (user.is_company) {
      return "company";
    } else if (user.is_customer) {
      return "customer";
    } else if (user.is_freelancer) {
      return "service_provider";
    }
  };
  const user = JSON.parse(localStorage.getItem("User"));

  const userTypes = getUserTypes(user);

  useEffect(() => {
    getQuotations();
    fetchComments();
  }, []);

  useEffect(() => {
    if (isOpens === false) {
      getQuotations();
    }
  }, [isOpens]);

  const handleFeedback = (data) => {
    setProjectData(data);
    const userType = getUserTypes(user).toLowerCase();
    const payload = {
      quotation_id: data._id,
      userType,
    };

    axios
      .put(`${BASE_URL}/api/feedback/markUsRead`, payload, { headers })
      .then((response) => {
        const result = response.data;
        fetchComments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchComments = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/quotation/getAllFeedback/${project_id}/${userTypes.toLowerCase()}`,
        { headers }
      );
      const result = response?.data;
      setComments(result);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    getQuotations();
  }, []);

  const getQuotations = async () => {
    setLoading(true);
    const params = {
      project_id,
    };
    if (location.pathname.includes("admin")) {
      await axios
        .get(`${BASE_URL}/api/getCustomer/quotation/${params.project_id}`, {
          headers,
        })
        .then((res) => {
          // Sort quotations in descending order based on the createdAt property
          const sortedQuotations = res.data.getAllQuotes.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setQuotations([...sortedQuotations]);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    } else if (location.pathname.includes("service-provider")) {
      await axios
        .get(`${BASE_URL}/api/quotations`, { params, headers })
        .then((res) => {
          // Sort quotations in descending order based on the createdAt property
          const sortedQuotations = res.data.getAllQuotes.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setQuotations([...sortedQuotations]);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    }
  };

  const handleStatus = (quotation, value) => {
    const payload = {
      updateObj: {
        status: value,
      },
      admin_id: JSON.parse(localStorage.getItem("User")).id,
      vendors: [parseInt(quotation.vendor_id)],
      quotation_id: quotation._id,
      project_id,
    };
    axios
      .put(`${BASE_URL}/api/quotations/update`, payload, { headers })
      .then((response) => {
        // handlePostNotification();
        getQuotations();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // if(show){
  // getQuotations();
  // }

  const getFileUrl = async (filename) => {
    const params = {
      filter: "quotation",
      name: filename,
    };
    await axios
      .get(`${BASE_URL}/api/file-url`, { params, headers })
      .then((res) => {
        window.open(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (isOpen === false) {
      getQuotations();
    }
  }, [isOpen]);

  const getStatusColorClass = (status) => {
    switch (status) {
      case "Accepted":
        return "text-green-500";
      case "Rejected":
        return "text-red-500";
      case "Pending":
        return "text-blue-500";
      default:
        return "";
    }
  };

  const handleDelete = async (id, fileName, folder) => {
    const params = { id };
    if (location.pathname.includes("admin")) {
      await axios.delete(`${BASE_URL}/api/deleteCustomer/quotation/${id}`, {
        headers,
      });
      await axios
        .delete(`${BASE_URL}/api/deleteFileUrl/${folder}/${fileName}`, {
          headers,
        })

        .then((res) => {
          getQuotations();
          toast.success("Quotation Delete Successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios.delete(`${BASE_URL}/api/quotation/delete`, {
        params,
        headers,
      });
      await axios
        .delete(`${BASE_URL}/api/deleteFileUrl/${folder}/${fileName}`, {
          headers,
        })
        .then((res) => {
          getQuotations();
          toast.success("Quotaion Delete Successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const editclickButton = (row) => {
    setProjectData(row);
    setIsOpens(true);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const tempPayload = {
    project_id: project_id,
    userType: "Admin",
    file: selectedFile?.name,
    status: "Pending",
    totalPrice: "-",
  };

  const tempPayload2 = {
    project_id: project_id,
    totalPrice: "-",
    userType: "service-provider",
    file: selectedFile?.name,
    status: "Pending",
    vendor_id: user.id,
  };

  const sendQuotationTemp = async () => {
    try {
      if (location.pathname.includes("admin")) {
        await axios.post(
          `${BASE_URL}/api/customerQuotation/createORupdate`,
          tempPayload,
          { headers }
        );
      } else {
        await axios.post(
          `${BASE_URL}/api/quotation/createORupdate`,
          tempPayload2,
          { headers }
        );
      }

      toast.success(`Quotation created successfully`);
      getQuotations();
    } catch (error) {
      console.log(error);
    }
  };

  const fileSelectedHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const fileUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file!");
    } else if (!selectedFile.name.endsWith(".pdf")) {
      toast.error("Only PDF files are allowed");
    } else {
      setButtonLoad(true);
      let success = false;
      const s3Client = new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        },
      });

      const params = {
        Bucket: "factreehub-docs",
        Key: `quotation/${selectedFile?.name}`,
        ContentType: selectedFile.type,
        Body: selectedFile,
      };

      try {
        const putObjectCommand = new PutObjectCommand(params);
        await s3Client.send(putObjectCommand);
        toast.success("File uploaded successfully!");
        setSelectedFile(null);
        sendQuotationTemp();
        setButtonLoad(false);
        success = true;
      } catch (error) {
        console.error(error);
        toast.error("Please Try Again");
        setButtonLoad(false);
        success = false;
      }
      if (!success) {
        setSelectedFile();
      }
    }
  };

  const sendMail = async (quotation, status) => {
    const payload = {
      email: projectDetails?.customer?.email,
      projectName: projectDetails?.name,
      projectDescription: projectDetails?.description,
      URL: "http://localhost:3000/auth/signin",
    };
    await axios
      .post(`${BASE_URL}/api/quotation/sendMail`, payload, { headers })
      .then((res) => {
        if (status === "Hide") {
          toast.success("Hide Successfully");
        } else {
          toast.success("Success! Mail sented.");
        }
        const reqbody = {
          quotation_id: quotation._id,
          releaseStatus: status,
          user_id: JSON.parse(localStorage.getItem("User")).id,
          customer_id: projectDetails.customer_id,
          project_id: projectDetails._id,
        };
        if (location.pathname.includes("admin")) {
          axios
            .post(`${BASE_URL}/api/customerQuotation/createORupdate`, reqbody, {
              headers,
            })
            .then((res) => {
              getQuotations();
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          axios
            .post(`${BASE_URL}/api/quotation/createORupdate`, reqbody, {
              headers,
            })
            .then((res) => {
              getQuotations();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Thead = [
    "File",
    "Total Amount",
    "Created At",
    "Status",
    "Actions",
    "Feedback",
  ];

  return (
    <div>
      {loading ? (
        <Loader className="mx-auto" />
      ) : (
        <div className="relative sm:rounded-lg mt-6 res125:mt-4 res150:mt-3">
          {isOpens ? (
            <div className="absolute top-0 bg-white  res125:my-3 res150:my-2">
              <QuotationTable
                show={isOpens}
                setShow={setIsOpens}
                editProjectData={projectData}
                projectData={projectDetails}
                projectDetails={projectDetails}
              />
            </div>
          ) : (
            <>
              {/* {quotations.length === 0 ? (
                <div className="py-4 flex flex-col justify-center res150:text-xs border items-center text-lg">
                  Crafting an engaging Project Quotation for "
                  {projectDetails.name}"
                  <button
                    className="bg-[#03298C] text-white px-4 py-1 border border-[#03298C] rounded-full mt-2"
                    onClick={() => setIsOpens(true)}
                  >
                    Create Quotation
                  </button>
                </div> */}

              <div className="flex justify-end gap-3 mt-10 mb-5">
                <input
                  type="file"
                  className="border rounded w-64"
                  onChange={fileSelectedHandler}
                  accept=".pdf"
                />
                <button
                  className={`bg-[#03298C] text-white rounded px-6 py-2 ${
                    buttonLoad ? "cursor-not-allowed opacity-50" : ""
                  }`}
                  onClick={fileUpload}
                  disabled={buttonLoad}
                >
                  {buttonLoad ? "Uploading..." : "Upload"}
                </button>
              </div>
              {/* // ) :  */}
              {/* ( */}
              <>
                {quotations.length === 0 ? (
                  <>
                    <div className="text-center">
                      <span className="bg-yellow-100 text-black px-4 py-1 rounded">
                        No quotation uploaded yet
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <table className="w-full text-sm text-center text-black">
                      <thead className="text-base text-[#03298C] bg-[#ECFFFF] res125:text-sm res150:text-xs">
                        <tr className="text-center">
                          {Thead.map((label, index) => (
                            <th
                              key={index}
                              scope="col"
                              className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2"
                            >
                              {label}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {quotations.map((quotation, index) => (
                          <tr className="bg-white border-b text-center res150:text-xs">
                            <td
                              className=" w-24 overflow-hidden whitespace-nowrap text-ellipsis px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 text-blue-500 underline cursor-pointer"
                              onClick={() => getFileUrl(quotation.file)}
                            >
                              <p
                                className="w-64 overflow-hidden whitespace-nowrap text-ellipsis"
                                title={quotation.file}
                              >
                                {quotation.file}
                              </p>
                            </td>
                            <td className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2">
                              {quotation.totalPrice}
                            </td>

                            <td className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2">
                              {moment(quotation.createdAt).format(
                                "DD MMM YYYY hh:mm A"
                              )}
                            </td>
                            <td className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2">
                              {(() => {
                                const status = quotation.status;

                                let badgeComponent = null;

                                if (status === "Pending") {
                                  badgeComponent = (
                                    <Badge color="indigo">{status}</Badge>
                                  );
                                } else if (status === "Accepted") {
                                  badgeComponent = (
                                    <Badge color="success">{status}</Badge>
                                  );
                                } else if (status === "Rejected") {
                                  badgeComponent = (
                                    <Badge color="failure">{status}</Badge>
                                  );
                                }

                                return (
                                  <div className="flex justify-center items-center res150:text-xs">
                                    {badgeComponent}
                                  </div>
                                );
                              })()}
                            </td>

                            {/* <td
                            className={`px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 ${getStatusColorClass(
                              quotation.status
                            )}`}
                          >
                            {quotation.status}
                          </td> */}
                            <td className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2">
                              <Dropdown
                                label="More"
                                dismissOnClick={false}
                                style={{
                                  background: "transparent",
                                  color: "black",
                                  margin: "auto",
                                }}
                              >
                                {/* <Dropdown.Item
                                onClick={() => editclickButton(quotation)}
                              >
                                <i
                                  className="fa fa-pencil px-2 py-2 text-[#4277FF] cursor-pointer"
                                  aria-hidden="true"
                                ></i>{" "}
                                Edit Quotation
                              </Dropdown.Item> */}
                                <Dropdown.Item
                                  onClick={() => {
                                    swal({
                                      title: "Are you sure?",
                                      text: "Once deleted, you will not be able to recover this item!",
                                      icon: "warning",
                                      buttons: {
                                        cancel: "No",
                                        confirm: "Yes",
                                      },
                                      dangerMode: true,
                                    }).then((willDelete) => {
                                      if (willDelete) {
                                        handleDelete(
                                          quotation._id,
                                          quotation.file,
                                          "quotation"
                                        );
                                        swal(
                                          "Success!",
                                          "Item has been deleted successfully!",
                                          "success"
                                        );
                                      } else {
                                        swal("Your item is safe!");
                                      }
                                    });
                                  }}
                                >
                                  <i
                                    className="fa fa-trash px-2 py-2 text-red-500 cursor-pointer"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Delete Quotation
                                </Dropdown.Item>

                                <Dropdown.Item
                                  onClick={() =>
                                    sendMail(
                                      quotation,
                                      quotation.releaseStatus === "Pending"
                                        ? "Release"
                                        : quotation.releaseStatus === "Hide"
                                        ? "Release"
                                        : "Hide"
                                    )
                                  }
                                >
                                  <i
                                    className={`fa ${
                                      quotation.releaseStatus === "Pending"
                                        ? "fa-envelope-o"
                                        : quotation.releaseStatus === "Hide"
                                        ? "fa-envelope-o"
                                        : "fa-eye-slash"
                                    } px-2 py-2 text-[#4277FF] cursor-pointer`}
                                    aria-hidden="true"
                                    Send
                                  ></i>{" "}
                                  {quotation.releaseStatus === "Pending"
                                    ? "Send Quotation"
                                    : quotation.releaseStatus === "Hide"
                                    ? "Send Quotation"
                                    : "Hide Quotation"}
                                </Dropdown.Item>
                                {/* <Dropdown.Item
                                onClick={() => handleFeedback(quotation)}
                              >
                                <i
                                  class="fa fa-commenting-o px-2 py-2 text-[#4277FF]"
                                  aria-hidden="true"
                                ></i>
                                Feedback
                              </Dropdown.Item> */}
                              </Dropdown>
                            </td>
                            <td>
                              <button
                                onClick={() => {
                                  setOpenModal(true);
                                  handleFeedback(quotation);
                                }}
                                className={`${
                                  comments.getFeedback?.length > 0 &&
                                  comments.getFeedback.some(
                                    (item) =>
                                      item.quotation_id === quotation._id
                                  )
                                    ? "gradient-border"
                                    : "border-[#FD952D]"
                                } bg-[#FFE3C7] text-[#FD952D] px-4 py-1 border relative rounded-full`}
                              >
                                Feedback
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </>
              {/* )} */}
            </>
          )}

          <Modal show={openModal} onClose={() => setOpenModal(false)}>
            <Modal.Header>Feed Back</Modal.Header>
            <Modal.Body className="overflow-y-hidden">
              <div className="space-y-6">
                <FeedbackChat
                  projectData={projectData}
                  projectDetails={projectDetails}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default CustomerQuotationList;
