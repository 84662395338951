import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import Popup from "./popup";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";
import {
  BASE_URL,
  Logo,
  industrySegmentOptions,
} from "../../../constants/Constants";
// import Logo from "../../../assets/images/Factree Hub Logo.png";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Tooltip } from "flowbite-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../../Loader";
import countryCodesData from "../../../assets/json/CountryCode.json";
import { set } from "immutable";

export default function CustomerSignupForm() {
  const [error, setError] = useState(null);
  const formArray = [1, 2, 3];
  const [formNo, setFormNo] = useState(formArray[0]);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setCofirmPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [serviceRequiredOptions, setserviceRequiredOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useHistory();
  const [selectedOption, setSelectedOption] = useState("+91");
  const [countryCodes, setCountryCodes] = useState(countryCodesData.countries);
  const [softwareOptions, setSoftwareOptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  //terms and condition
  const openPopup = () => {
    setShowPopup(true);
  };
  const closePopup = () => {
    setShowPopup(false);
  };

  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const [custUser, setcustUser] = useState({
    name: "",
    contact_no: "",
    email: "",
    password: "",
    confirmedpassword: "",
    org_name: "",
    role: "",
    industrySegment: [],
    website: "",
    orgType: "",
    size: "",
    country: "",
    serviceRequired: [],
    softwareUsed: [],
    docs_file: "",
    agreement: false,
  });

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  custUser.agreement = isChecked;

  const handleChange = ({ target: { name, value } }) => {
    setcustUser((data) => {
      return { ...data, [name]: value };
    });
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = () => {
    setCofirmPassword(!showConfirmPassword);
  };
  //////industrysegment
  const handleIndSSelect = (selectedList) => {
    let existingUser = custUser;
    existingUser.industrySegment = selectedList;
    setcustUser(existingUser);
  };

  const handleIndSRemove = (selectedList) => {
    resetSelectField();
    let existingUser = custUser;
    existingUser.industrySegment = selectedList;
    setcustUser(existingUser);
  };
  ///softwareused
  const handleSoftSelect = (selectedList) => {
    let existingUser = custUser;
    existingUser.softwareUsed = selectedList;
    setcustUser(existingUser);
  };

  const handleSoftRemove = (selectedList) => {
    resetSelectField();
    let existingUser = custUser;
    existingUser.softwareUsed = selectedList;
    setcustUser(existingUser);
  };

  //service required API
  const serviceRequired = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getAllService`, {
        params: {
          service: JSON.stringify(custUser.serviceRequired),
        },
      });

      if (response.data && response.data.Allmicroserivce) {
        const microservices = response.data.Allmicroserivce;

        const options = microservices.map((microservice) => ({
          label: microservice._id,
          value: microservice.microservice,
        }));

        setserviceRequiredOptions(options);
      } else {
        console.error("API response format is unexpected.");
      }
    } catch (error) {
      console.error("Error fetching service options:", error);
    }
  };

  //software option API
  const fetchSoftwareOptions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/get/softwares`, {
        headers,
      });
      if (response.data && response.data.data) {
        const softwareData = response.data.data.map((item) => ({
          label: item.softwares,
          value: item.softwares,
        }));
        setSoftwareOptions(softwareData);
      }
    } catch (error) {
      console.error("Error fetching software options:", error);
    }
  };

  useEffect(() => {
    serviceRequired();
    fetchSoftwareOptions();
  }, []);

  const handleSelect = (selectedList) => {
    let existingUser = custUser;
    existingUser.serviceRequired = selectedList;
    setcustUser(existingUser);
  };

  const handleRemove = (selectedList) => {
    resetSelectField();
    let existingUser = custUser;
    existingUser.serviceRequired = selectedList;
    setcustUser(existingUser);
  };

  //fileupload
  const handleFiles = async (event) => {
    setSelectedFile(event.target.files[0]);
    let obj = { ...custUser };
    obj[event.target.name] = event.target.files[0].name;
    setcustUser({ ...obj });
  };

  const fileUpload = async () => {
    let success = false;
    const s3Client = new S3Client({
      region: "ap-south-1",
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    });

    const params = {
      Bucket: "factreehub-docs",
      Key: `verification/${selectedFile.name}`,
      ContentType: selectedFile.type,
      Body: selectedFile,
    };

    try {
      const putObjectCommand = new PutObjectCommand(params);
      const reponse = await s3Client.send(putObjectCommand);
      toast.success("File uploaded successfully!");
      success = true;
    } catch (error) {
      console.error(error);
      toast.error("Please Try Again");
      success = false;
    }
    if (!success) {
      setSelectedFile();
    }
  };

  useEffect(() => {
    if (selectedFile) {
      fileUpload();
    }
  }, [selectedFile]);

  ///form start

  const next = async () => {
    if (formNo === 1) {
      if (!custUser.name) {
        setError("Full Name is required.");
        return;
      }

      if (!custUser.contact_no) {
        setError("Contact No. is required.");
        return;
      } else if (!/^\d{4,15}$/.test(custUser.contact_no)) {
        setError(
          "Contact should be number and it must contains 4 digits long."
        );
        return;
      }

      if (!custUser.email) {
        setError("Email is required.");
        return;
      } else if (!/^\S+@\S+\.\S+$/.test(custUser.email)) {
        setError("Please enter a valid email address.(EX:abcd@gmail.com).");
        return;
      } else if (custUser.email) {
        try {
          await axios.get(`${BASE_URL}/api/email-check`, {
            params: {
              email: custUser.email,
            },
          });
        } catch (error) {
          setError(error.response.data.message);
          return true;
        }
      }

      if (!custUser.password) {
        setError("Password is required.");
        return;
      } else if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/.test(
          custUser.password
        )
      ) {
        setError(
          "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character (!@#$%^&*)"
        );
        return;
      }

      if (!custUser.confirmedpassword) {
        setError("Confirm Password is required.");
        return;
      }
      if (custUser.password !== custUser.confirmedpassword) {
        setError("Password and Confirm password do not match.");
        return;
      } else {
        // setError(null);
      }
      setError(null);
      setFormNo(formNo + 1);
    } else if (formNo === 2) {
      if (!custUser.org_name) {
        setError("Company Name is required.");
        return;
      }
      if (!custUser.role) {
        setError("Please select atleast one role.");
        return;
      }
      if (!custUser.industrySegment.length) {
        setError("Please select atleast one IndustrySegment.");
        return;
      }
      // if (!custUser.website) {
      //     setError("Website is required");
      //     return;
      // }
      if (!custUser.orgType) {
        setError("Please select atleast one Organization Type.");
        return;
      }
      if (!custUser.size) {
        setError("Please select atleast one Organization Size.");
        return;
      } else {
        setError(null);
      }
      setFormNo(formNo + 1);
    } else if (formNo === 3) {
      if (!custUser.country) {
        setError("Country is required.");
        return;
      }
      if (!custUser.serviceRequired.length) {
        setError("Please select atleast one field.");
        return;
      }
      if (!custUser.softwareUsed.length) {
        setError("SoftwareUsed is required.");
        return;
      } else {
        setError(null);
      }
    }
  };

  const multiselectRef = useRef();

  const resetSelectField = () => {
    multiselectRef.current?.resetSelectedValues();
  };

  const back = () => {
    setFormNo(formNo - 1);
  };
  const handleSubmit = async (e) => {
    if (!custUser.name) {
      setError("Full Name is required.");
    } else if (!custUser.contact_no) {
      setError("Contact No. is required.");
    } else if (!custUser.email) {
      setError("Email is required.");
    } else if (!custUser.password) {
      setError("Password is required.");
    } else if (!custUser.confirmedpassword) {
      setError("Confirm password is required.");
    } else if (custUser.password !== custUser.confirmedpassword) {
      setError("Password and confirm password do not match.");
    } else if (!custUser.org_name) {
      setError("CompanyName is required.");
    } else if (!custUser.role) {
      setError("Role is required.");
    } else if (!custUser.industrySegment.length) {
      setError("Industry Segment is required.");
    }
    // else if (!custUser.website) {
    //     setError("Website is required.");
    // }
    else if (!custUser.orgType) {
      setError("Organization Type is required.");
    } else if (!custUser.size) {
      setError("Organization Size is required.");
    } else if (!custUser.country) {
      setError("Country is required.");
    } else if (!custUser.serviceRequired.length) {
      setError("Service is required.");
    } else if (!custUser.softwareUsed.length) {
      setError("SoftwareUsed is required.");
    } else if (!custUser.docs_file.length) {
      setError("Document is required.");
    } else if (!custUser.agreement) {
      setError("Please Accept the Terms and Conditons");
    } else {
      e.preventDefault();
      const updatedUser = {
        ...custUser,
        contact_no: `${selectedOption} - ${custUser.contact_no}`,
      };
      setLoading(true);
      setIsDisabled(true);
      setError("");
      await axios
        .post(`${BASE_URL}/api/customer`, updatedUser)
        .then((res) => {
          navigate.push("/auth/signin");
          toast.success(" Customer Register Successfully");
          swal(
            "Congratulations",
            "Your Account has register successfully",
            "success"
          );
          setLoading(false);
          setIsDisabled(false);
        })
        .catch((err) => {
          swal(`${err.response.data.message}`, "Failed to register", "error");
          toast.error(`${err.response.data.message}`);
          setLoading(false);
          setIsDisabled(false);
          console.log(err.response.data.message);
        });
    }
  };

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const openModalAndLoadVideo = () => {
    setOpenModal(true);
    setLoading(true);
  };

  return (
    <div className="container flex min-h-screen mx-auto h-full max-w-full items-center justify-center bg-sky-200">
      <button
        className="help-icon border-1 fixed top-16 right-10"
        aria-label="Help"
        onClick={openModalAndLoadVideo}
      >
        <Tooltip content="Video Guide" placement="left">
          <FontAwesomeIcon
            icon={faCirclePlay}
            className="fa-3x text-[#03298C]"
          />
        </Tooltip>
      </button>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>Video Guide</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            {loading && <Loader className="mx-auto" />}
            <iframe
              className="w-[550px] mx-auto h-[400px] res125:w-[450px] res150:w-[350px] res125:h-[300px] res150:h-[250px]"
              src="https://www.loom.com/embed/9bc9f229d9be4a5987bc54392fad1b61?sid=682e2772-7869-4965-8d9b-e1a9ceea2aed"
              title="Video"
              onLoad={handleIframeLoad}
              style={{ display: loading ? "none" : "block" }}
            />
          </div>
        </Modal.Body>
      </Modal>
      <div className="card rounded-2xl mx-auto bg-white max-w-[440px] w-[100%] my-[50px] mb-[200px]">
        <img
          src={Logo}
          className="mx-auto mt-[15px] w-[224px] "
          alt="FactreeHub"
        />
        <div className="flex flex-col gap-3 pt-8 res125:pt-5 res150:pt-4 max-w-[390px] m-auto px-[20px]">
          <p className="text-3xl font-bold mb-2 w-full   text-center res125:text-xl  res150:text-lg ">
            Customer Sign-up
          </p>

          {error && (
            <div
              className="bg-red-100 border text-center relative  border-red-400 text-red-700 px-3 py-2  rounded mb-2  res150:mb-1 res150:text-[10px] res125:mb-1 res125:text-xs"
              role="alert"
            >
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          {formNo === 1 && (
            <div>
              <div className="flex flex-col mb-[10px]">
                <label
                  htmlFor="fullName"
                  className="res150:text-[10px] res125:text-xs"
                >
                  Full name
                </label>
                <input
                  value={custUser.name}
                  onChange={handleChange}
                  className="h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
                  // className="h-12 rounded border-[#C8C8C8] mt-3 res150:mt-1  res150:h-8 res150:mb-1 res150:text-[10px] res125:h-10 res125:mb-1 res125:text-xs"
                  type="text"
                  name="name"
                  placeholder="Enter your full name"
                />
              </div>

              <div className="flex flex-col mb-[10px]">
                <label
                  htmlFor="contact_no"
                  className="res150:text-[10px] res125:text-xs"
                >
                  Contact No
                </label>
                <div className="flex">
                  <select
                    className="w-[34%] h-12 mt-[10px] rounded border-[#C8C8C8] uppercase res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 res150:mt-1"
                    value={selectedOption}
                    onChange={(event) => setSelectedOption(event.target.value)}
                  >
                    {countryCodes?.map((code) => (
                      <option key={code.code} value={code.code}>
                        <span>{code.name.slice(0, 3)}</span> ({code.code})
                      </option>
                    ))}
                  </select>
                  <input
                    value={custUser.contact_no}
                    onChange={handleChange}
                    className="w-[64%] h-12 rounded border-[#C8C8C8] mt-[10px] ml-auto res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 res150:mt-1"
                    type="text"
                    name="contact_no"
                    placeholder="Enter your contact no"
                  />
                </div>
              </div>

              <div className="flex flex-col mb-[10px]">
                <label
                  htmlFor="email"
                  className="res150:text-[10px] res125:text-xs"
                >
                  Email
                </label>
                <input
                  value={custUser.email}
                  onChange={handleChange}
                  className="res150:mt-1 h-12 rounded border-[#C8C8C8] mt-[10px] res150:text-[10px] res125:text-xs res150:h-8 res125:h-10"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                />
              </div>
              <div className="flex flex-col mb-[10px]">
                <label
                  htmlFor="password"
                  className="res150:text-[10px] res125:text-xs"
                >
                  Password
                </label>
                <div className="relative mt-[10px] res150:mt-1 max-h-[40px]">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="w-[100%] border-[#C8C8C8] rounded h-12 placeholder-gray-400  res150:h-8 res150:text-[10px]  res125:h-10 res125:text-xs"
                    placeholder="Enter password"
                    value={custUser.password}
                    name="password"
                    onChange={handleChange}
                  />
                  <span
                    type="button"
                    className="w-1/12 ml-auto flex transform -translate-y-8 text-gray-500 focus:outline-none res150:my-2 res125:my-1"
                    onClick={handleTogglePassword}
                  >
                    {showPassword ? (
                      <i
                        className="fa fa-eye cursor-pointer"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye-slash  cursor-pointer"
                        aria-hidden="true"
                      ></i>
                    )}
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="confirmPassword"
                  className="res150:text-[10px] res125:text-xs"
                >
                  Confirm password
                </label>
                <div className="relative mt-[10px] res150:mt-1 max-h-[40px]">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="w-[100%] border-[#C8C8C8] rounded h-12  placeholder-gray-400 r res150:h-8 res150:text-[10px]  res125:h-10 res125:text-xs"
                    placeholder="Confirm password"
                    value={custUser.confirmedpassword}
                    name="confirmedpassword"
                    onChange={handleChange}
                  />
                  <span
                    type="button"
                    className="w-1/12 ml-auto flex transform -translate-y-8  text-gray-500 focus:outline-none res150:my-2 res125:my-1"
                    onClick={handleToggleConfirmPassword}
                  >
                    {showConfirmPassword ? (
                      <i
                        className="fa fa-eye  cursor-pointer"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye-slash  cursor-pointer"
                        aria-hidden="true"
                      ></i>
                    )}
                  </span>
                </div>
              </div>
            </div>
          )}

          {formNo === 2 && (
            <div>
              <div className="flex flex-col mb-[10px]">
                <label
                  htmlFor="org_name"
                  className="res150:text-[10px] res125:text-xs"
                >
                  Company Name
                </label>
                <input
                  onChange={handleChange}
                  value={custUser.org_name}
                  className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
                  type="text"
                  name="org_name"
                  placeholder="Enter Company Name"
                ></input>
              </div>

              <div className="flex flex-col mb-[10px]">
                <label
                  htmlFor="role"
                  className="block res150:text-[10px] res125:text-xs"
                >
                  Role
                </label>
                <select
                  id="role"
                  name="role"
                  value={custUser.role}
                  onChange={handleChange}
                  className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
                >
                  <option
                    value=""
                    className="text-gray-500 res150:text-[10px] res125:text-xs"
                    selected
                    disabled
                  >
                    Select
                  </option>
                  <option value="admin">Admin</option>
                  <option value="sale">Sales</option>
                </select>
              </div>

              <div className="flex flex-col mb-[10px]">
                <label
                  htmlFor="industrySegment"
                  className="block res150:text-[10px] res125:text-xs"
                >
                  Industry Segments
                </label>
                <div className=" items-center justify-center  mb-1.5 mt-[10px] bg-white">
                  <div className="  border-[#C8C8C8] ml-0 signup">
                    <Multiselect
                      className="res125:text-xs res150:text-[10px]"
                      options={industrySegmentOptions}
                      selectedValues={custUser.industrySegment}
                      onSelect={handleIndSSelect}
                      onRemove={handleIndSRemove}
                      displayValue="value"
                      placeholder="Select IndustrySegment"
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col mb-1">
                <label
                  htmlFor="website"
                  className="res150:text-[10px] res125:text-xs"
                >
                  Website
                </label>
                <input
                  onChange={handleChange}
                  value={custUser.website}
                  className=" h-12 rounded border-[#C8C8C8] mt-3 res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
                  type="text"
                  name="website"
                  placeholder="Enter your website"
                ></input>
              </div>

              <div className="flex flex-col mb-1">
                <label
                  htmlFor="orgType"
                  className="block res150:text-[10px] res125:text-xs"
                >
                  Organization Type
                </label>
                <select
                  id="orgType"
                  name="orgType"
                  value={custUser.orgType}
                  onChange={handleChange}
                  className=" h-12 rounded border-[#C8C8C8] mt-3 res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10"
                >
                  <option
                    value=""
                    className="text-gray-500 res150:text-[10px] res125:text-xs"
                    selected
                    disabled
                  >
                    Select
                  </option>
                  <option value="EPC">EPC</option>
                  <option value="Manufacturer">Manufacturer</option>
                  <option value="Engineering Design Company (EDC)">
                    Engineering Design Company (EDC)
                  </option>
                  <option value="Others">Others</option>
                </select>
              </div>

              <div className="flex flex-col mb-1">
                <label
                  htmlFor="size"
                  className="block res150:text-[10px] res125:text-xs"
                >
                  Organization Size
                </label>
                <select
                  id="size"
                  name="size"
                  value={custUser.size}
                  onChange={handleChange}
                  className=" h-12 rounded border-[#C8C8C8] mt-3 res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
                >
                  <option
                    value=""
                    className="text-gray-500 res150:text-[10px] res125:text-xs"
                    selected
                    disabled
                  >
                    Select
                  </option>
                  <option value="Small">Small</option>
                  <option value="Medium">Medium</option>
                  <option value="Large">Large</option>
                </select>
              </div>
            </div>
          )}

          {/* form 3 */}
          {formNo === 3 && (
            <div>
              <div className="flex flex-col mb-3">
                <label
                  htmlFor="country"
                  className="res150:text-[10px] res125:text-xs"
                >
                  Country
                </label>
                <input
                  onChange={handleChange}
                  value={custUser.country}
                  className=" h-12 rounded border-[#C8C8C8] mt-3 res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
                  type="text"
                  name="country"
                  placeholder="Enter your Country"
                ></input>
              </div>

              <div className="flex flex-col mb-3">
                <label
                  htmlFor="serviceRequired"
                  className="block res150:text-[10px] res125:text-xs"
                >
                  Services Required
                </label>
                <div className=" items-center justify-center  mb-1.5 bg-white ">
                  <div className=" border border-[#C8C8C8] ml-0 signup">
                    <Multiselect
                      className="res125:text-xs res150:text-[10px]"
                      options={serviceRequiredOptions}
                      selectedValues={custUser.serviceRequired}
                      onSelect={handleSelect}
                      onRemove={handleRemove}
                      displayValue="value"
                      placeholder="Select Service"
                      style={{
                        searchBox: {
                          border: "none",
                          fontSize: "10px",
                          minHeight: "40px",
                          width: "440px !important",
                        },
                        optionContainer: {
                          maxHeight: "250px",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col mb-3">
                <label
                  htmlFor="softwareUsed"
                  className="block res150:text-[10px] res125:text-xs"
                >
                  Software Used
                </label>
                <div className=" items-center justify-center  mb-1.5 bg-white ">
                  <div className=" border border-[#C8C8C8] ml-0 signup">
                    <Multiselect
                      className="res125:text-xs res150:text-[10px]"
                      options={softwareOptions}
                      selectedValues={custUser.softwareUsed}
                      onSelect={handleSoftSelect}
                      onRemove={handleSoftRemove}
                      displayValue="value"
                      placeholder="Select Software"
                      style={{
                        searchBox: {
                          border: "none",
                          fontSize: "10px",
                          minHeight: "40px",
                          width: "440px !important",
                        },
                        optionContainer: {
                          maxHeight: "250px",
                        },
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-col mb-3">
                  <label
                    htmlFor="docs_file"
                    className="res150:text-[10px] res125:text-xs"
                  >
                    Document
                  </label>
                  <input
                    onChange={(e) => handleFiles(e)}
                    // value={selectedFile}
                    className="w-[28rem] h-12 rounded border-[#C8C8C8] mt-3 res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 res150:w-[20rem] res125:w-[24rem]"
                    type="file"
                    name="docs_file"
                  />
                </div>
              </div>

              <div className="flex items-center mb-3">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label className="ml-2">
                  I agree with{" "}
                  <span className="text-blue-800" onClick={openPopup}>
                    terms & conditions
                  </span>
                </label>
              </div>
              {showPopup && <Popup handleClose={closePopup} />}

              <button
                type="submit"
                className={`w-[100%] h-12 bg-[#03298C] text-white rounded tracking-wider font-bold text-lg mt-4 mb-4 hover:bg-[#03298C] res150:text-[10px] res125:text-xs res150:h-9 res125:h-10  res150:mt-1 ${
                  isDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                id="companysubmit"
                disabled={isDisabled}
                onClick={handleSubmit}
              >
                {loading ? (
                  <>
                    <img
                      src="https://media1.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif"
                      className="w-[3rem] mx-auto res150:w-[1.5rem] res125:w-[2rem]"
                      alt=""
                    />
                  </>
                ) : (
                  "SIGN UP"
                )}
              </button>
            </div>
          )}

          {/* </form> */}

          {/*steps number and line  */}
          <div className="flex justify-between">
            {formNo != 1 && (
              <div className="flex justify-start items-center res125:mt-1 res150:mt-1">
                <p
                  onClick={back}
                  className="text-gray-400 cursor-pointer font-bold hover:text-[#03298C] res125:text-sm res150:text-[10px] whitespace-nowrap"
                >
                  <i
                    className="fa fa-angle-left text-lg  font-bold res150:text-xs res125:text-base"
                    aria-hidden="true"
                  ></i>{" "}
                  Back
                </p>
              </div>
            )}

            <div className="flex justify-center items-center px-4 res150:text-xs res125:text-xs res150:w-[20rem] res125:w-[24rem]">
              {/* this is step number */}
              {formArray.map((v, i) => (
                <>
                  <div
                    className={`w-[35px] my-3 text-white rounded-full ${
                      formNo - 1 === i ||
                      formNo - 1 === i + 1 ||
                      formNo === formArray.length
                        ? "bg-[#03298C]"
                        : "bg-gray-300"
                    } h-[35px] flex justify-center items-center res150:w-[30px] res150:h-[30px]`}
                  >
                    {v}
                  </div>
                  {/*  line between number */}
                  {i !== formArray.length - 1 && (
                    <div
                      className={`w-[60px] h-[2px] ${
                        formNo === i + 3 || formNo === formArray.length
                          ? "bg-[#03298C] "
                          : "bg-gray-300"
                      }`}
                    ></div>
                  )}
                </>
              ))}
            </div>

            {formNo != 3 && (
              <div className="flex justify-end items-center">
                <p
                  onClick={next}
                  className="text-gray-400 cursor-pointer font-bold hover:text-[#03298C] justify-end res150:text-[10px] res125:text-sm whitespace-nowrap"
                >
                  Next{" "}
                  <i
                    class="fa fa-angle-right text-lg  font-bold res150:text-xs res125:text-base"
                    aria-hidden="true"
                  ></i>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
