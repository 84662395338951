import React from "react";
import { Link } from "react-router-dom";

function Error404() {
  return (
    <div className="w-full flex items-center justify-center">
      <Link to="/">
        <img
          className="res125:w-[80%] res125:mx-auto res150:w-[70%] res150:mx-auto"
          src="https://freefrontend.com/assets/img/html-funny-404-pages/CodePen-404-Page.gif"
          alt=""
        />
      </Link>
    </div>
  );
}

export default Error404;
