import { Accordion } from 'flowbite-react'
import React from 'react'

export default function help() {
  return (
    <>
    
<div className="w-4/5 ml-auto mt-24">

<p className="text-3xl font-semibold text-center my-5 text-[#011A59] res125:text-lg res150:text-lg">Guide & Policy</p>
{/* res125:w-full res150:w-full res125:h-[600px] res150:h-[500px] */}
<Accordion collapseAll className="w-11/12 mx-auto" >
      <Accordion.Panel>
        <Accordion.Title className="res125:text-sm res150:text-xs">Sign up Video</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          <iframe className="w-[550px] mx-auto h-[400px]  res125:w-[450px] res150:w-[350px] res125:h-[300px] res150:h-[250px] "  src="https://www.loom.com/embed/9bc9f229d9be4a5987bc54392fad1b61?sid=682e2772-7869-4965-8d9b-e1a9ceea2aed">
</iframe>

          </p>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title className="res125:text-sm res150:text-xs">Offer & Order Generation</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Flowbite is first conceptualized and designed using the Figma software so everything you see in the library
            has a design equivalent in our Figma file.
          </p>
          <p className="text-gray-500 dark:text-gray-400">
            Check out the
            <a href="https://flowbite.com/figma/" className="text-cyan-600 hover:underline dark:text-cyan-500">
              Figma design system
            </a>
            based on the utility classes from Tailwind CSS and components from Flowbite.
          </p>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title className="res125:text-sm res150:text-xs">Teams & Conditions</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum autem aperiam laborum aliquam enim iste! Temporibus est dolor veniam eos debitis expedita, itaque mollitia neque praesentium possimus amet rerum saepe.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis quos, eos dolor sint fuga sit doloribus, explicabo fugiat, esse deserunt exercitationem sapiente voluptatem itaque. Ipsam dignissimos quam eaque laboriosam reiciendis.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos vero, ipsam delectus eveniet ea corrupti! Provident vero fuga quasi adipisci quis, explicabo consectetur magnam incidunt? Commodi, a mollitia. Molestias, tenetur?
          </p>
         
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>



</div>
    </>
  )
}

