
import React, { useState, useEffect } from 'react';
import { ReactComponent as Chat } from "../../../assets/svg/chat.svg";
import { BASE_URL } from "../../../constants/Constants";
import moment from 'moment';
import axios from 'axios';
import "./Projects.css";
import Loader from '../../../Loader';

function FeedbackChat({ projectData, onClose, projectDetails }) {
  const [sendQuotationFeedback, setSendQuotationFeedback] = useState({
    quotation_id: "",
    feedBack: "",
    project_id: "",
    user_id: "",
    userType: "",
    to_id: ""
  });
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [sendId, setSendId] = useState('');
  const [loading, setLoading] = useState(false);
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };
  const User = JSON.parse(localStorage.getItem("User"));

  const getUserType = (user) => {
    if (user.is_admin) {
      return "admin";
    } else if (user.is_company) {
      return "company";
    } else if (user.is_customer) {
      return "customer";
    } else if (user.is_freelancer) {
      return "service_provider";
    }
  };

  useEffect(() => {
    if (getUserType(User) === "admin") {
      if (projectData.vendor_id) {
        setSendId(projectData.vendor_id);
      } else {
        setSendId(projectDetails.customer_id);
      }
    } else {
      setSendId("");
    }

  }, []);

  const handleAddComment = () => {
    if (comment.trim() !== '') {
      if (projectData) {
        setSendQuotationFeedback((prevFeedback) => ({
          ...prevFeedback,
          quotation_id: projectData._id || projectData.id,
          feedBack: comment,
          project_id: projectData.project_id,
          user_id: User.id,
          userType: getUserType(User),
          to_id: sendId
        }));
      }
    }
  };

  useEffect(() => {
    const postComment = async () => {
      try {
        await axios.post(`${BASE_URL}/api/quotation/feedback`, sendQuotationFeedback, { headers });

        await fetchComments();

        setComment('');
      } catch (error) {
        console.error('Error adding comment:', error);
      }
    };

    if (comment.trim() !== '') {
      postComment();
    }
  }, [sendQuotationFeedback]);

  const fetchComments = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/api/quotation/getFeedback/${projectData._id || projectData.id}`, { headers });
      setComments(response.data.getFeedback);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching comments:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComments();
  }, [projectData]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddComment();
    }
  };

  return (
    <div className="flex flex-col h-full bg-white">
      <div className="flex flex-col flex-grow p-3 border-[#E8EFFE] min-h-[32rem] res125:min-h-[30rem] res150:min-h-[28rem] max-h-96 pb-16 overflow-y-auto scrolling">
        {loading ? (
          <div className="text-center mx-auto"><Loader /></div>
        ) : comments && comments.length > 0 ? (
          comments.map((c, index) => (
            <div
              key={index}
              className={`flex flex-col p-2 mb-2 res150:text-xs ${c.userType === getUserType(User) ? 'self-end bg-[#E8EFFE] text-[#011A59] rounded-bl-[15px] rounded-tr-[15px]' : 'bg-[#E2FFED] text-[#011A59] rounded-br-[15px] rounded-tl-[15px] self-start'}`}
            >
              <div className="mb-2">{c.feedBack}</div>
              <div className="flex justify-between">
                <span className='text-xs me-5 capitalize'>{c.userType === getUserType(User) ? 'you' : c.userType}</span>
                <span className='text-xs italic'>{moment(c.createdAt).format("DD MMM hh:mm a")}</span>
              </div>
            </div>
          ))
        ) : (
          <div className="text-gray-500 text-center">
            <img src="https://i.pinimg.com/originals/e3/1b/75/e31b752875679b64fce009922f9f0dda.gif" alt="gif" />
            No feedback yet. Be the first to share your thoughts!
          </div>
        )}
      </div>

      <div className='flex border bg-white border-blue-500 rounded ps-2 mt-5 ms-4 me-4 text-blue res150:fixed res150:bottom-16' style={{ width: "35rem" }}>
        <Chat className='h-5 my-auto' />
        <div className='w-100'>
          <textarea
            rows={1}
            type='text'
            className="border-0 add-comment text-blue form-control scrolling-add-comment place-holder w-full"
            placeholder='Add Comment'
            style={{ width: "33rem" }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        </div>
      </div>
    </div>
  );
}

export default FeedbackChat;


