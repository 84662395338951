import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import pencil from "../../../assets/svg/pencil.svg";
import AdminSettings from "./../../Admin/Settings/admin";
import CompanySetting from "./../../ServiceProvider/Settings/company";
import CustomerSetting from "./../../Customer/Settings/customer";
import FreeSetting from "./../../ServiceProvider/Settings/serviceprovider";
import { toast } from "react-hot-toast";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import AppContext from "../../../context/AppContext";

const Settings = () => {
  const { imgURL, setImgURL } = useContext(AppContext);
  const [selectedFile, setSelectedFile] = useState();
  const [isAdmin, setAdmin] = useState(false);
  const [isCompany, setCompany] = useState(false);
  const [isCustomer, setCustomer] = useState(false);
  const [isfree, setFree] = useState(false);
  const [fileName, setFileName] = useState("");
  const avatar = JSON.parse(localStorage.getItem("User"))?.profile_picture;
  const location = useLocation();
  const setting = JSON.parse(localStorage.getItem("User"));

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    if (location.pathname.includes("admin")) {
      setAdmin(true);
      setCustomer(false);
      setCompany(false);
      setFree(false);
    } else if (location.pathname.includes("service-provider")) {
      setAdmin(false);
      setCustomer(false);
      if (setting.is_company === true) {
        setCompany(true);
        setFree(false);
      } else if (setting.is_freelancer === true) {
        setCompany(false);
        setFree(true);
      }
    } else if (location.pathname.includes("customer")) {
      setAdmin(false);
      setCustomer(true);
      setCompany(false);
      setFree(false);
    }

    if (avatar) {
      getFileUrl(avatar);
    }
  }, []);

  const fileUpload = async () => {
    let success = false;
    const s3Client = new S3Client({
      region: "ap-south-1",
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    });

    const params = {
      Bucket: "factreehub-docs",
      Key: `ProfilePicture/${selectedFile.name}`,
      ContentType: selectedFile.type,
      Body: selectedFile,
    };

    try {
      const putObjectCommand = new PutObjectCommand(params);
      const reponse = await s3Client.send(putObjectCommand);
      toast.success("Profile picture uploaded successfully!");
      success = true;
    } catch (error) {
      console.error(error);
      toast.error("Please Try Again");
      success = false;
    }
    if (!success) {
      setSelectedFile();
    } else if (success) {
      getFileUrl(selectedFile.name);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      fileUpload();
    }
  }, [selectedFile]);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const getFileUrl = async (picName) => {
    const params = {
      filter: "ProfilePicture",
      name: picName,
    };
    await axios
      .get(`${BASE_URL}/api/file-url`, { params, headers })
      .then((res) => {
        setImgURL(res?.data?.message);
        setFileName(picName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="w-4/5 ml-auto">
      <div className="flex items-center justify-center  pt-16 mt-8 res125:mt-4 res150:mt-1">
        <div className="flex items-center justify-center flex-col">
          <div className=" w-36 h-36 res125:w-28 res125:h-28 res150:w-24 res150:h-24 rounded-full bg-gray-200 overflow-hidden">
            <img
              className="w-full h-full object-contain"
              src={imgURL}
              alt=""
              title="Avatar"
            />
          </div>
          <form className="flex items-center">
            <label htmlFor="fileInput" className="relative cursor-pointer">
              <img
                src={pencil}
                alt="Profile"
                className="w-10 h-8 res150:w-8 res150:h-6 rounded-full bg-[#03298C] p-1.5 -mt-16 ml-32 res125:ml-24 res125:-mt-14 res150:ml-20 res150:-mt-11"
                title="Upload Avatar"
              />
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={(event) => handleFileSelect(event)}
              id="fileInput"
              className="hidden"
            />
          </form>
          <p className="text-white font-medium bg-[#03298C] rounded-full h-7 res125:w-32 res125:text-sm res125:h-6 res150:w-28 res150:h-5 res150:text-xs w-40 absolute mt-40 res125:mt-32 res150:mt-28 text-center">
            {isAdmin && "Admin"}
            {isCustomer && "Customer"}
            {isCompany && "Company"}
            {isfree && "Freelancer"}
          </p>
        </div>
      </div>

      <div>
        {/* customer profile edit  */}
        {isCustomer && <CustomerSetting fileName={fileName} />}

        {/* service provider profile edit  */}
        {isCompany && <CompanySetting filename={fileName} />}

        {/* freelancer profile edit */}
        {isfree && <FreeSetting filename={fileName} />}

        {/* admin profile edit  */}
        {isAdmin && <AdminSettings fileName={fileName} />}
      </div>
    </div>
  );
};

export default Settings;
