import { useContext, useEffect, useState } from "react";
import {
  BASE_URL,
  CUSTOMER_INFO_STEPPER,
  PROJECT_INFO_STEPPER,
  TAG_INFO_STATE,
} from "../../../constants/Constants";
import AppContext from "../../../context/AppContext";
import EditProject from "../../Admin/Projects/EditProject";
import { ReactComponent as EditIcon } from "../../../assets/svg/edit.svg";
import axios from "axios";

const ProjectDetailsNavCard = (props) => {
  const { handleBack, details, loading, detailType } = props;
  const { loadTeamMember, type, setLoadTeamMember } = useContext(AppContext);
  const [show, setShow] = useState(false);

  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const handleCustomerStatus = async (status) => {
    // setLoading(true);
    const payload = {
      id: details._id,
      customerStatus: status,
      customerId: JSON.parse(localStorage.getItem("User")).id,
    };

    await axios
      .put(`${BASE_URL}/api/project/updateProjectStatus`, payload, { headers })
      .then((res) => {
        setLoadTeamMember(true);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  useEffect(() => {
    setShow(false);
  }, [loadTeamMember]);

  return (
    <div
      className={`
    mx-auto w-11/12 res150:mx-auto res150:w-[87%]
    ${detailType === "admin" ? "mt-24 res150:mt-[4.3rem]" : "my-6 res150:my-4"}
    bg-[${details?.bgColor}]
  `}
    >
      {loading ? (
        <div class="shadow rounded-md p-4 w-full mx-auto h-full">
          <div class="animate-pulse flex space-x-4">
            <div class="rounded-full bg-slate-200 h-10 w-[71%] res150:w-[%]"></div>
            <div class="flex-1 space-y-6 py-1">
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="space-y-3">
                <div class="grid grid-cols-3 gap-4">
                  <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                  <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div class="h-2 bg-slate-200 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="projectContainer" className="flex flex-row  pb-4 res150:pb-3">
          <div className="mt-5 ml-10 res150:ml-7">
            <i
              className="fa fa-2x fa-angle-left cursor-pointer text-[#03298C] res150:text-2xl"
              aria-hidden="true"
              title="Back"
              onClick={() => handleBack()}
            ></i>
          </div>
          <div className="flex flex-col ml-10">
            <div
              className="capitalize w-96  mt-7 text-[#000000] text-base whitespace-nowrap text-ellipsis overflow-hidden res125:text-sm res150:text-xs res150:mt-6"
              title={details?.projectUniqueId ? details?.projectUniqueId : null}
            >
              <b>Project No :</b> {details?.projectUniqueId ? details?.projectUniqueId : null}
            </div>
            <div
              className="capitalize w-96  mt-2 font-bold text-[#000000] text-base whitespace-nowrap text-ellipsis overflow-hidden res125:text-sm res150:text-xs res150:mt-6"
              title={details?.name}
            >
              {details?.name}
            </div>
            <div
              className="w-96 mt-2 text-[#5F5F5F] text-xs font-normal whitespace-nowrap text-ellipsis overflow-hidden"
              title={details?.description}
            >
              {details?.description}
            </div>
            <div className="text-start mr-2 mt-2 res150:mt-1 text-[#5F5F5F] font-normal text-sm res150:text-xs">
              {details?.tags?.map((tag, index) => (
                <span
                  key={index}
                  className="inline-block text-base font-bold text-[#5F5F5F] border-r-2 border-gray-400 pr-2 mr-2 last:border-r-0 res150:text-xs res125:text-xs"
                  style={{ lineHeight: "1" }}
                >
                  {tag?.name}
                </span>
              ))}
            </div>
          </div>
          {type === "admin" && (!["Completed", "Live"].includes(details?.status)) && (
            <div
              className={`${detailType === "customer" ? "hidden" : ""
                } ml-auto flex flex-row`}
            >
              <div
                className="px-6 pt-6 cursor-pointer"
                onClick={() => {
                  localStorage.removeItem(CUSTOMER_INFO_STEPPER);
                  localStorage.removeItem(PROJECT_INFO_STEPPER);
                  localStorage.removeItem(TAG_INFO_STATE);
                  setShow(true);
                }}
              >
                <EditIcon title="Edit Project" />
              </div>
              {show && <EditProject show={show} details={details} />}
            </div>
          )}

          {details?.customerStatus == "Pending" &&
            type === "customer" ? (
            <div className="flex flex-row ms-auto me-10 my-auto">
              <div>
                <button
                  className="p-2 mr-2 rounded-lg border border-gray-300 w-24 res150:w-[4.6rem] res150:p-1 flex flex-row bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900 group-hover:bg-green-200 dark:group-hover:bg-green-300 hover:scale-105 transition-transform items-center"
                  onClick={() =>
                    handleCustomerStatus("Accepted")
                  }
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-5 h-5 mr-0.5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  </div>
                  Accept
                </button>
              </div>
              <div>
                <button
                  className="p-2 rounded-lg border border-gray-300  w-24 res150:w-[4.6rem] res150:p-1 flex flex-row bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900 group-hover:bg-red-200 dark:group-hover:bg-red-300 hover:scale-105 transition-transform items-center"
                  onClick={() =>
                    handleCustomerStatus("Rejected")
                  }
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-5 h-5  mr-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  Reject
                </button>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ProjectDetailsNavCard;
