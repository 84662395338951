import "./Mails.css";
import { useState, useContext, useEffect } from "react";
import EmailCard from "./EmailCard";
import AppContext from "../../../context/AppContext";
import { BASE_URL } from "../../../constants/Constants";
import axios from "axios";
import useDebounce from "../../../useDebounce";
import Loader from "../../../Loader";

const EmailList = () => {
  const [hasMore, setHasMore] = useState(true);
  const {
    selectedMail,
    setSelectedMail,
    dropDownChanged,
    isDropDownChanged,
    type,
  } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [emails, setEmails] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [initialFunc, setInitialFunc] = useState(false);

  const handleScroll = (e) => {
    const { target } = e;
    if (
      target.scrollTop + target.clientHeight >= target.scrollHeight &&
      !loading &&
      hasMore
    ) {
      setPage(page + 1);
    }
  };

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const handleSelectedMail = (email, color) => {
    setSelectedMail({ ...email, color });
  };

  const handleSearchIems = (data, limit) => {
    setLoading(true);
    let params;
    if (limit) {
      params = {
        search,
        page: 1,
        limit: limit,
      };
    } else {
      params = {
        search,
        page,
        limit: 10,
      };
    }
    axios
      .get(`${BASE_URL}/api/messages`, { params, headers })
      .then((res) => {
        if (data) {
          setEmails((prevEmails) => [...prevEmails, ...res.data.message]);
        } else {
          setEmails([...res.data.message]);
        }
        if (res.data.message.length < 10) {
          setHasMore(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
      
  };

  useEffect(() => {
    if (type && page) {
      if (!initialFunc) {
        setInitialFunc(true);
      } else {
        setLoading(true);
        handleSearchIems(true);
      }
    }
  }, [page, type, initialFunc]);

  useEffect(() => {
    if (dropDownChanged) {
      setLoading(true);
      handleSearchIems(false, emails.length);
      isDropDownChanged(false);
    }
  }, [dropDownChanged]);

  useEffect(() => {
    if (emails.length > 0) {
      if (
        localStorage.getItem("SELECTED_MAIL") !== null &&
        localStorage.getItem("SELECTED_MAIL") !== undefined
      ) {
        const mail = JSON.parse(localStorage.getItem("SELECTED_MAIL"));
        const index = emails.findIndex(
          (email) => email.message_id === mail.message_id
        );
        if (index !== -1) {
          var array = [
            "#EA8989",
            "#58D7E9",
            "#A763EA",
            "#85C170",
            "#E7C368",
            "#6B9AF6",
          ];
          var color = "";
          color = array[index % array.length];
          setSelectedMail({ ...emails[index], color });
        } else {
          setSelectedMail({ ...emails[0], color: "#EA8989" });
        }
      } else {
        setSelectedMail({ ...emails[0], color: "#EA8989" });
      }
    }
  }, [emails]);

  useEffect(() => {
    if (Object.keys(selectedMail).length > 0) {
      localStorage.setItem("SELECTED_MAIL", JSON.stringify(selectedMail));
    }
  }, [selectedMail]);

  const debouceSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    handleSearchIems(false);
  }, [debouceSearchTerm]);

  return (
    <div className="mail flex flex-col w-1/3 mt-12 res125:mt-8 res150:mt-4">
      <div className="h-1/6 flex w-3/4 mx-auto">
        <input
          className="mail_search mx-auto w-full res125:text-xs res150:text-xs res150:w-full"
          placeholder="Search mails..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setLoading(true);
              handleSearchIems(false);
            }
          }}
        ></input>
      </div>
      <div className="flex w-full overflow-y-auto h-[740px] res125:h-[600px] res150:h-[500px]">
        <div
          className="flex flex-col mx-auto w-11/12 res150:mx-auto px-5 overflow-y-auto h-full"
          onScroll={handleScroll}
        >
          {emails.map((email, index) => {
            const isDuplicate =
              emails.findIndex((item) => item.message_id === email.message_id) <
              index;
            if (!isDuplicate) {
              return (
                <>
                  <EmailCard
                    key={email.message_id}
                    {...email}
                    handleSelectedMail={handleSelectedMail}
                    selectedMail={selectedMail}
                    handleSearchIems={handleSearchIems}
                    index={index}
                    length={emails.length}
                  />
                  <hr
                    className="mb-0 mx-4"
                    style={{ border: "0.5px solid #CAD9FF" }}
                  />
                </>
              );
            }
            return null;
          })}
          {loading && (
            <div role="status">
              <Loader className="ml-44 res150:ml-28 res125:ml-32" />
            </div>
          )}

          {!hasMore && (
            <div className="text-center mt-4 res125:text-sm res150:text-xs mx-auto">
              No more mails to load
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailList;
