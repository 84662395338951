import axios, { Axios } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";

import { useHistory, useParams } from "react-router-dom";

import { BASE_URL, Logo } from "../../constants/Constants";

import "../../App.css";

// import Logo from "../../assets/images/Factree Hub Logo.png";

// import { useParams } from 'react-router-dom';

export default function ForgetPass() {
  const { authtoken } = useParams();
  const navigate = useHistory();

  const [newPassword, setpassword] = useState({ newPassword: "" });
  const [error, seterror] = useState(null);
  const [loading, setloading] = useState(false);
  const [disabled, setdisabled] = useState(false);

  const handleInputChange = (event) => {
    setpassword({ newPassword: event.target.value });
  };

  const Submithandle = async (event) => {
    event.preventDefault();
    try {
      if (!newPassword.newPassword) {
        seterror("please enter valid password");
      } else {
        setloading(true);
        setdisabled(true);
        const response = await axios.post(
          `${BASE_URL}/api/resetpassword/${authtoken}`,
          newPassword
        );
        toast.success("password reset success");
        setloading(false);
        navigate.push("/auth/signin");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setdisabled(false);
      setloading(false);
    }
  };

  return (
    <div>
      <Toaster />

      <div className="  max-h-[800px] mx-auto mt-[100px] w-full max-w-[640px] bg-white rounded-[36px] pt-[40px]">
        {/* factreeHub logo */}
        <img
          src={Logo}
          className={` w-[202px]   res125:w-1/4 res150:w-1/4 m-0 mx-auto max-h-[86px] ${"mt-[40px]"}`}
          alt="FactreeHub"
          onClick={() => {
            // setVerfied(!verified);
          }}
        />
        <div className="mt-12 res150:mt-8">
          <p className="text-3xl font-bold mb-0 w-full h-16 mx-auto text-center res125:text-xl res125:h-12 res150:text-xl res150:h-10">
            {"Reset Password"}
          </p>
        </div>
        <form
          action=""
          className="flex flex-col gap-3 px-[65px] "
          onSubmit={Submithandle}
        >
          <label htmlFor="Email" className="res150:text-xs res125:text-xs">
            New Password
          </label>
          <input
            className="h-12 rounded border-[#C8C8C8]  mb-4 res150:w-[23rem] res150:h-8  res150:mb-1 res150:text-xs  res125:h-9  res125:mb-1 res125:text-xs w-full"
            placeholder="Enter New Password"
            type="text"
            name="email"
            onChange={handleInputChange}
          />

          <button
            type="submit"
            disabled={disabled}
            className={`bg-[#03298C] w-full h-[4rem] hover:bg-[#03298C] text-white font-bold  rounded res150:text-xs res150:h-11 res125:h-12 mb-[120px]  ${
              disabled ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {loading ? (
              <div className="flex justify-center ">
                <img
                  src="https://media1.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif"
                  className="w-[3rem] mx-auto res125:w-10 res150:w-9 "
                  alt=""
                />
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
