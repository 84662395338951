import React, { useState, useEffect, useContext } from "react";
import {
  PROJECT_INFO_STATE,
  PROJECT_INFO_STEPPER,
  BASE_URL,
} from "../../../../constants/Constants";
import AppContext from "../../../../context/AppContext";
import axios from "axios";
import Loader from "../../../../Loader";

const ModifyProjectForm2 = ({ error }) => {
  const [dropdownValues, setDropdownValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState("Select Project");
  const { customerInfo, projectInfo, setProjectInfo } = useContext(AppContext);
  const [isDropDownSelected, setDropdownSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedCategoryValue, setSelectedCategoryValue] =
    useState("Process Plants");
  // Bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };
  useEffect(() => {
    if (customerInfo.id) {
      setLoading(true);
      axios
        .get(
          `${BASE_URL}/api/customer/projects?customer_id=${customerInfo.id}`,
          { headers }
        )
        .then((res) => {
          setLoading(false);
          // console.log(res);
          setDropdownValues([...res?.data?.message]);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    const existingProjectInfo = localStorage.getItem(PROJECT_INFO_STEPPER);
    if (existingProjectInfo !== null && existingProjectInfo !== "undefined") {
      if (JSON.parse(existingProjectInfo).project_id) {
        setSelectedValue(JSON.parse(existingProjectInfo).name);
        setDropdownSelected(true);
      }
      setProjectInfo({
        ...JSON.parse(existingProjectInfo),
        category: selectedCategoryValue,
      });
      setSelectedCategoryValue(JSON.parse(existingProjectInfo).category);
    } else {
      setProjectInfo({ ...projectInfo, category: selectedCategoryValue });
    }
  }, []);



  const handleSelection = (value) => {
    if (value === "None") {
      setSelectedValue("None");
      localStorage.removeItem(PROJECT_INFO_STEPPER);
      setProjectInfo(PROJECT_INFO_STATE);
    } else {
      const selectedOption = dropdownValues.find(
        (option) => option.name === value
      );
      setSelectedValue(selectedOption.name);
      localStorage.setItem(PROJECT_INFO_STEPPER, JSON.stringify(selectedOption));
      setProjectInfo(selectedOption);
    }
  };

  const handleProjectInfoForm = (event) => {
    const existingProjectInfo = { ...projectInfo };
    const { name, value } = event.target;
    existingProjectInfo[name] = value;
    setProjectInfo(existingProjectInfo);
    if (
      !existingProjectInfo.name &&
      !existingProjectInfo.projectUniqueId &&
      !existingProjectInfo.description &&
      !existingProjectInfo.category &&
      !existingProjectInfo.tags
    ) {
      localStorage.removeItem(PROJECT_INFO_STEPPER);
    }
  };

  return (
    <div className="mt-12 res150:mt-8 res125:mt-6">
      <div className="mx-14">
        <select
          value={selectedValue}
          onChange={(event) => handleSelection(event.target.value)}
          className="font-normal bg-[#FFFFFF] py-3 px-5 inline-flex items-center w-full text-[#929292] focus:ring-1 focus:ring-[#C8C8C8] res125:py-2 res125:text-sm res150:h-7 res150:text-xs res150:py-1.5 res150:px-3 border border-[#C8C8C8]"
        >
          <option value="Select Project">Select Project</option>
          <option value="None">None</option>
          {dropdownValues.map((value, index) => (
            <option key={index} value={value.name}>
              {value.name}
            </option>
          ))}
        </select>
      </div>

      <div className="flex my-8 items-center mx-auto justify-center res125:my-4">
        <hr className="w-1/3 res150:w-1/4 res125:w-[30%]" />
        <span className="px-2 res150:text-xs">or</span>
        <hr className="w-1/3 res150:w-1/4 res125:w-[30%]" />
      </div>
      <div className="flex flex-wrap max-w-md mx-12 -m-2 mb-5">
        {error && (
          <div
            className="bg-red-100 border text-center border-red-400 text-red-700 px-2 py-2 w-full rounded mb-2 mx-2 res150:h-6 res150:py-0 res125:py-1.5 res125:text-sm"
            role="alert"
          >
            <div className="block sm:inline res150:text-xs">
              * Please fill all the fields.
            </div>
          </div>
        )}
        <div className="w-full p-2">
          <input
            className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res150:text-xs res150:py-1.5 res125:py-1.5 res125:px-3 res150:px-2"
            type="text"
            placeholder="Project Name"
            name={"name"}
            value={projectInfo.name}
            onChange={handleProjectInfoForm}
            disabled={!isDropDownSelected}
          />
        </div>
        <div className="w-full p-2">
          <input
            className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res150:text-xs res150:py-1.5 res125:py-1.5 res125:px-3 res150:px-2"
            type="text"
            placeholder="Project Unique Id"
            name={"projectUniqueId"}
            value={projectInfo.projectUniqueId}
            onChange={handleProjectInfoForm}
            disabled={!isDropDownSelected}
          />
        </div>
        <div className="w-full px-2 pt-2">
          <textarea
            className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res150:py-1.5 res150:text-xs res125:py-1.5 res125:px-3 res150:px-2"
            style={{ height: "165px", resize: "none" }}
            placeholder="Project Description"
            name={"description"}
            value={projectInfo.description}
            onChange={handleProjectInfoForm}
            disabled={!isDropDownSelected}
          />
        </div>
        <select
          id="category"
          name="category"
          disabled
          value={projectInfo.category}
          className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] my-2 res150:py-1 res150:text-xs res150:m-2 res125:m-2 res125:py-1.5 res125:px-3 res150:px-2"
        >
          <option value="" className="text-gray-500">
            Project Category
          </option>
          <option value="Process Plants">Process Plants</option>
        </select>

        <div className="w-full p-2">
          <input
            className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res150:py-1.5 res150:text-xs res125:py-1.5 res125:px-3 res150:px-2"
            type="text"
            placeholder="# of Tags"
            name={"tags"}
            value={projectInfo.tags}
            onChange={handleProjectInfoForm}
            disabled={!isDropDownSelected}
          />
        </div>
      </div>
    </div>
  );
};

export default ModifyProjectForm2;
