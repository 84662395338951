import React from "react";

const AuthLayout = (props) => {
  return (
    <div className="overflow-x-hidden w-screen bg-gradient-to-br from-[#A1C4FD] via-[transparent] to-[#C2E9FB] min-h-screen">
      {/* <Container className="d-flex flex-column"> */}
      {props.children}
      {/* </Container> */}
    </div>
  );
};
export default AuthLayout;
