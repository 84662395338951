import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import AppContext from "../../../context/AppContext";
import { useHistory } from "react-router-dom";

const TeamsCredentialsForm = ({ show, setShow, type }) => {
  // define state variables for input values
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [accessType, setAccessType] = useState("");
  const [role, setRole] = useState("");
  const { setLoadTeamMember } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useHistory();
  const path = navigate.location.pathname;
  const pathArray = path.split("/");
  const UserType = pathArray[pathArray.length - 2];
  const [customRole, setCustomRole] = useState("");

  useEffect(() => {
    // Get data from localStorage
    const storedData = JSON.parse(localStorage.getItem("Team_Credential"));

    // Update state variables with stored data
    if (storedData) {
      setEmail(storedData.email);
      setUsername(storedData.teamName);
      setPassword(storedData.password);
      setAccessType(storedData.accessType);
      setRole(storedData.role);
    }
  }, []);

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  // define a function to handle input changes
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "username") {
      setUsername(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleAccessTypeChange = (event) => {
    setAccessType(event.target.value);
  };

  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    setRole(selectedRole);

    if (selectedRole === "Any other") {
      setCustomRole("");
    }
  };

  const handleCustomRoleChange = (event) => {
    setCustomRole(event.target.value);
  };

  const fileUpload = async () => {
    let success = false;
    setError(null);
    const s3Client = new S3Client({
      region: "ap-south-1",
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    });

    if (!["image/jpeg", "image/png", "image/jpg"].includes(selectedFile.type)) {
      setError("Only JPEG and PNG files are allowed");
      setSelectedFile(null);
      return;
    }

    const params = {
      Bucket: "factreehub-docs",
      Key: `ProfilePicture/${selectedFile.name}`,
      ContentType: selectedFile.type,
      Body: selectedFile,
    };

    try {
      const putObjectCommand = new PutObjectCommand(params);
      const reponse = await s3Client.send(putObjectCommand);
      toast.success("File uploaded successfully!");
      success = true;
    } catch (error) {
      console.error(error);
      toast.error("Please Try Again");
      success = false;
    }

    if (!success) {
      setSelectedFile();
    }
  };

  useEffect(() => {
    if (selectedFile) {
      fileUpload();
    }
  }, [selectedFile]);

  const handleFiles = async (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // define a handleSubmit function to pass the input values to the payload
  const handleSubmit = () => {
    if (!email | !username || !accessType) {
      setError("Please fill all the fields.");
    } else if (type === "Edit Team Member" && !password) {
      setError("Please enter password.");
    } else {
      setLoading(true);
      if (UserType === "service-provider") {
        const payload = {
          team_id: JSON.parse(localStorage.getItem("Team_Credential"))?.userId,
          email,
          name: username,
          password,
          access_type: accessType,
          role: role === "Any other" ? customRole : role,
          profile_picture: selectedFile?.name,
          vendor_created: JSON.parse(localStorage.getItem("User")).id,
        };

        // pass the payload to an API endpoint
        setIsDisabled(true);
        setLoading(true);

        axios
          .post(`${BASE_URL}/api/teams/vendors/create`, payload, { headers })
          .then((res) => {
            localStorage.removeItem("Team_Credential");
            toast.success("Success");
            setLoadTeamMember(true);
            setLoading(false);
            setIsDisabled(false);
            setShow(false);
          })
          .catch((err) => {
            console.error(err);
            setError(err.response.data.message);
            setLoading(false);
            setIsDisabled(false);
          });
      } else if (UserType === "admin") {
        const payload = {
          team_id: JSON.parse(localStorage.getItem("Team_Credential"))?.userId,
          email,
          name: username,
          password,
          access_type: accessType,
          profile_picture: selectedFile?.name,
          admin_created: JSON.parse(localStorage.getItem("User")).id,
        };

        // pass the payload to an API endpoint
        axios
          .post(`${BASE_URL}/api/teams/create`, payload, { headers })
          .then((res) => {
            setLoading(false);
            localStorage.removeItem("Team_Credential");
            toast.success("Success");
            setLoadTeamMember(true);
            setShow(false);
          })
          .catch((err) => {
            console.error(err);
            setError(err.response.data.message);
            setLoading(false);
          });
      }
    }
  };
  return (
    <div>
      <div className="mt-5 res125:mt-4 res150:mt-2">
        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 w-full rounded relative mb-4 text-center res125:text-sm res125:py-1 res150:py-1.5 res150:text-xs res150:mb-1"
            role="alert"
          >
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <div className="w-full">
          <label
            htmlFor="Username"
            className="font-bold tracking-wider res125:text-xs res150:text-xs "
          >
            User Name
          </label>
          <input
            className="w-full px-4 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] mt-2 res125:text-xs res125:py-1.5 res150:py-1.5 res150:text-xs"
            type="text"
            placeholder="Name"
            name="username"
            value={username}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-full py-3 res125:py-1 res150:py-1">
          <label
            htmlFor="Email"
            className="font-bold tracking-wider res125:text-xs res150:text-xs"
          >
            Email
          </label>
          <input
            className="w-full px-4 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] mt-2 res125:text-xs res125:py-1.5 res150:py-1.5 res150:text-xs"
            type="email"
            placeholder="Email"
            name="email"
            value={email}
            onChange={handleInputChange}
          />
        </div>
        {type === "Edit Team Member" && (
          <div className="w-full pb-3 res150:pb-1">
            <label
              htmlFor="Password"
              className="font-bold tracking-wider res125:text-xs res150:text-xs"
            >
              Password
            </label>
            <input
              className="w-full px-4 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] mt-2 res125:text-xs res125:py-1.5 res150:py-1.5 res150:text-xs"
              type="text"
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleInputChange}
            />
          </div>
        )}
        <div className="w-full">
          <label
            htmlFor="Access"
            className="font-bold tracking-wider res125:text-xs res150:text-xs"
          >
            Access Type
          </label>
          <select
            className="w-full px-4 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] mt-2 res125:text-xs res150:py-1.5 res150:text-xs  res125:py-1.5"
            name="accessType"
            value={accessType}
            onChange={handleAccessTypeChange}
          >
            <option value="" disabled selected>
              Access Type
            </option>
            <option value="Admin">Admin</option>
            {UserType === "service-provider" ? (
              <option value="Member">Member</option>
            ) : (
              <option value="InHouse SP">InHouse SP</option>
            )}
          </select>
        </div>
        {UserType === "service-provider" ? (
          <div className="w-full">
            <label
              htmlFor="Role"
              className="font-bold tracking-wider res125:text-xs res150:text-xs"
            >
              Role
            </label>
            <select
              className="w-full px-4 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] mt-2 res125:text-xs res150:py-1.5 res150:text-xs  res125:py-1.5"
              name="role"
              value={role}
              onChange={handleRoleChange}
            >
              <option value="" disabled selected>
                Role
              </option>
              <option value="Engineering">Engineering</option>
              <option value="Procurement">Procurement</option>
              <option value="Finance">Finance</option>
              <option value="IT">IT</option>
              <option>Any other</option>
            </select>

            {role === "Any other" && (
              <input
                type="text"
                placeholder="Enter custom role"
                className="w-full px-4 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] mt-2 res125:text-xs res150:py-1.5 res150:text-xs  res125:py-1.5"
                name="customRole"
                value={customRole}
                onChange={handleCustomRoleChange}
              />
            )}
          </div>
        ) : null}

        <div className="w-full py-4 res150:py-3">
          <label
            className="block mb-2 text-sm font-medium text-gray-500 res125:text-xs res150:text-xs"
            for="file_input"
          >
            Add Profile Picture
          </label>
          <div className="flex items-center justify-center w-full mb-1">
            <label
              for="dropzone-file"
              className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 "
            >
              {!selectedFile ? (
                <>
                  <div className="flex flex-col items-center justify-center pt-10 pb-10 res150:pt-3 res150:pb-5">
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 mb-3 text-gray-400 res150:w-3 res150:h-3"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p className="mb-1 text-sm text-gray-500 dark:text-gray-400 res125:text-sm res150:text-xs">
                      <span className="font-semibold res125:text-xs res150:text-xs">
                        Click to upload
                      </span>{" "}
                      {/* or drag and drop */}
                    </p>
                  </div>
                  <input
                    id="dropzone-file"
                    name="file"
                    type="file"
                    className="hidden"
                    onChange={(e) => handleFiles(e)}
                    accept="image/*"
                    value={selectedFile}
                  />
                </>
              ) : (
                <>
                  <p className=" py-3 text-sm text-gray-500 dark:text-gray-400 res125:text-xs res150:px-3 res150:text-xs">
                    {selectedFile.name}
                  </p>
                </>
              )}
            </label>
          </div>
          <p
            className="text-sm text-gray-500 res125:text-xs res150:text-xs"
            id="file_input_help"
          >
            JPG or PNG files only.
          </p>
          {selectedFile && (
            <span
              className="mb-5 text-sm text-blue-500 underline cursor-pointer res125:text-xs res150:text-xs"
              onClick={() => setSelectedFile()}
            >
              Remove
            </span>
          )}
        </div>
      </div>
      <div className="absolute top-[89%] left-[10%]">
        <div className="flex flex-row mx-[34px] res125:mx-7 res150:mx-6 gap-4">
          <div>
            <button
              className="h-10 border border-[#03298C] hover:bg-[#72C27A] hover:text-white hover:border-none w-32 font-medium text-sm res125:w-24 res125:h-8 res125:text-xs res150:w-20 res150:h-7 res150:text-xs"
              onClick={() => {
                setShow(!show);
                localStorage.removeItem("Team_Credential");
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              type="submit"
              disabled={isDisabled}
              onClick={handleSubmit}
              className={` w-32 h-10 border border-[#03298C] hover:bg-[#72C27A] hover:text-white  hover:border-none font-medium text-sm res125:w-24 res125:h-8 res125:text-xs res150:w-20 res150:h-7 res150:text-xs  ${
                isDisabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {loading ? (
                <div className="flex justify-center">
                  <img
                    src="https://media1.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif"
                    className="w-[2rem] mx-auto res125:w-7 res150:w-5"
                    alt=""
                  />
                </div>
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamsCredentialsForm;
