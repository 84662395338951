import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { degrees, PDFDocument, rgb, StandardFonts, PDFOperator } from "pdf-lib";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { toast } from "react-hot-toast";
import AppContext from "../../../../context/AppContext";
import axios from "axios";
import { BASE_URL } from "../../../../constants/Constants";

function ModifyPage(props) {
  const navigate = useHistory();
  const { comments } = useContext(AppContext);

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    if (props.buttonType === "download" || props.buttonType === "upload") {
      modifyPdf(props.buttonType);
      props.resetButtonType();
    }
  }, [props.buttonType]);

  async function modifyPdf(buttonType) {
    const existingPdfBytes = await fetch(props.pdf).then((res) => {
      return res.arrayBuffer();
    });

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const pages = pdfDoc.getPages();
    const textSize = 16;
    const offsetLeft = document.getElementById("drawArea").offsetLeft;
    const offsetTop = document.getElementById("drawArea").offsetTop;
    const rotation = pages[0].getRotation().angle;
    props.result.forEach((res) => {
      if (res.type === "text") {
        if (rotation === 270) {
          //For landscape pdf files
          pages[res.page - 1].drawText(res.text, {
            x: pages[0].getWidth() - (res.y - offsetTop) - 25,
            y: pages[0].getHeight() - (res.x - offsetLeft),
            size: textSize,
            font: helveticaFont,
            color: rgb(0.95, 0.1, 0.1),
            maxWidth: res.ref.current.getBoundingClientRect().width,
            lineHeight: 15,
            rotate: degrees(-90),
          });
        } else {
          pages[res.page - 1].drawText(res.text, {
            x: res.x - offsetLeft + 10,
            y: pages[0].getHeight() - (res.y - offsetTop) - 25,
            size: textSize,
            font: helveticaFont,
            color: rgb(0.95, 0.1, 0.1),
            maxWidth: res.ref.current.getBoundingClientRect().width,
            lineHeight: 15,
          });
        }
      }
      if (res?.type === "freehand") {
        if (rotation === 270) {
          //For landscape pdf files
          const pathData =
            "M " +
            res.arr
              .map((p) => {
                return `${p.get("x")},${p.get("y") - 850}`;
              })
              .join(" L ");
          pages[res.page - 1].moveTo(0, pages[0].getHeight());
          pages[res.page - 1].drawSvgPath(pathData, {
            borderColor: rgb(0.95, 0.1, 0.1),
            rotate: { angle: -90, type: "degrees" }, // rotate by 45 degrees
          });
        } else {
          const pathData =
            "M " +
            res.arr
              .map((p) => {
                return `${p.get("x")},${p.get("y")}`;
              })
              .join(" L ");
          pages[res.page - 1].moveTo(0, pages[0].getHeight());
          pages[res.page - 1].drawSvgPath(pathData, {
            borderColor: rgb(0.95, 0.1, 0.1),
          });
        }
      }
    });

    const pdfBytes = await pdfDoc.save();
    let fileName;
    if (buttonType === "download") {
      let blob = new Blob([pdfBytes], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = props.fileName;
      link.download = fileName;
      link.click();
    } else if (buttonType === "upload") {
      const s3Client = new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        },
      });

      const versionRegex = /_v(\d+)/;
      let versionNumber = 1;
      let baseFilename = props.fileName;

      // Check if the filename already contains a version number
      const match = props.fileName.match(versionRegex);
      // console.log(match);
      if (match) {
        versionNumber = parseInt(match[1]) + 1;
        baseFilename = props.fileName.replace(versionRegex, "");
      }

      const array = baseFilename.split(".");

      // Construct the new filename with the updated version number
      const newFilename = array
        .slice(0, array.length - 1)
        .concat(`_v${versionNumber}.${array[array.length - 1]}`)
        .join("");

      fileName = newFilename;

      const params = {
        Bucket: "factreehub-docs",
        Key: `diagram/${fileName}`,
        Body: pdfBytes,
      };
      try {
        const putObjectCommand = new PutObjectCommand(params);
        const reponse = await s3Client.send(putObjectCommand);
        toast.success("File uploaded successfully!");
      } catch (error) {
        console.log("erroror", error);
        toast.error("Please Try Again");
      }
      storeDiagram(fileName);
    }
  }

  const storeDiagram = async (fileName) => {
    const { selectedData } = props;
    const path = navigate.location.pathname.split("/");
    const project_id = path[path.length - 1];
    const payload = {
      admin_id: JSON.parse(localStorage.getItem("User")).id,
      file: fileName,
      project_id: project_id,
      tag_id: selectedData.tag_id,
      scope_id: selectedData.scope_id,
      sp_status: "",
      vendor_id: selectedData.vendor_id,
      adminStatus: selectedData.adminStatus,
      comments: JSON.stringify(comments),
      created_by: "admin",
    };
    await axios
      .post(`${BASE_URL}/api/diagram/upload`, { ...payload }, { headers })
      .then((res) => {
        // handlePostNotification(project_id, selectedData);
        toast.success("Diagram Uploaded Successfully");
      })
      .catch((err) => {
        console.log("erorrr", err);
        toast.error("please try again");
      });
  };

  // const handlePostNotification = async (project_id, selectedData) => {
  //   const payload = {
  //     admin_id: JSON.parse(localStorage.getItem("User")).id,
  //     vendors: [selectedData.vendor_id],
  //     message: "New file has been uploaded by Factreehub",
  //     project_id: project_id,
  //     scope_id: selectedData.scope_id,
  //     tag_id: selectedData.tag_id,
  //   };

  //   await axios
  //     .post(`${BASE_URL}/api/notification/post`, payload, { headers })
  //     .then((res) => {
  //       toast.success("Success! Notification sent.");
  //     })
  //     .catch((err) => {
  //       toast.error("Notification failed to sent.");
  //     });
  // };

  return (
    <div>
      {/*<button style = {{marginTop: "1%"}} onClick = {modifyPdf}>Download PDF</button>*/}
    </div>
  );
}

export default ModifyPage;
