import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import ProjectCollab from "../../Shared/Projects/ProjectsCollab";

const ProjectCollabration = ({ projectId }) => {
  const [data, setData] = useState([]);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [info, setInfo] = useState({});
  const [selected, setSelected] = useState(null);

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    const params = {
      project_id: projectId,
    };
    axios
      .get(`${BASE_URL}/api/customer/diagrams`, { params, headers })
      .then((res) => {
        setData(res.data.message);
        if (res?.data?.message?.length > 0) {
          setFile(res.data.message[0]);
          setSelected(res.data.message[0].file);
          getFileUrl(res.data.message[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getFileUrl = async (value) => {
    const params = {
      filter: "diagram",
      name: value.file,
    };
    await axios
      .get(`${BASE_URL}/api/file-url`, { params, headers })
      .then((res) => {
        setFileName(value.file);
        setFile(res.data.message);
        setInfo({ ...value });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="flex flex-col mb-4">
      <div className=" max-h-[52px] overflow-hidden mt-4 border-b border-[#C8C8C8] mb-2">
        <div className="overflow-x-auto flex gap-3 res125:text-xs res150:text-xs">
          {data.length > 0 ? (
            data?.map((value, index) => (
              <button
                key={index}
                className={`border p-3 res150:p-2 text-start whitespace-nowrap  ${value.file === selected
                  ? "bg-[#ECF4FF] text-black border-none"
                  : ""
                  }`}
                style={{
                  borderBottom: `${value.file === selected ? "4px solid #03298C" : ""
                    }`,
                }}
                onClick={() => {
                  getFileUrl(value);
                  setSelected(value.file);
                }}
              >
                {value.file}
              </button>
            ))
          ) : (
            <p className="text-center text-2xl text-[#173788] res125:text-lg res150:text-base">
              Pdf is not available
            </p>
          )}
        </div>
      </div>

      {file && (
        <>
          <div className="flex flex-col  h-auto border my-4 res150:my-1">
            {!fileName.endsWith("zip") ? <ProjectCollab
              samplePDF={file}
              fileName={fileName}
              type="customer"
            /> :
              <div className="mx-auto my-5">
                <button className="flex mx-auto justify-center gap-14 res125:gap-11 res150:gap-9 text-white items-center bg-[#0D2970] w-[16.5rem] h-16 res125:w-56 res125:h-12 res125:text-sm res150:w-48 res150:h-10 res150:text-xs rounded cursor-pointer"
                  onClick={() => window.open(file)}>{"Download"}</button>
              </div>
            }
          </div>
          <div className="res125:text-xs res150:text-xs">
            <p className="font-bold">{info?.tag && "Diagram Details"}</p>
            <p>{info?.tag?.name}</p>
            <p>{info?.tag?.sub_vertical}</p>
            <p>{info?.scope?.microservice}</p>
            <p>{info?.tag?.team_type}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectCollabration;
