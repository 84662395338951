import EmailDetails from "../../Mails/EmailDetails";
import AppContext from "../../../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import CreateProjectForm3 from "./CreateProjectForm3";
import CreateProject from "./CreateProject";
import { PROJECT_INFO_STEPPER } from "../../../../constants/Constants";
import { useHistory } from "react-router-dom";

const CreateTagForm = () => {
  const navigate = useHistory();
  const { selectedMail, tagInfo, projectInfo, setProjectInfo } =
    useContext(AppContext);
  const [fileNames, setFileNames] = useState([]);
  const [tagNo, setTagNo] = useState(-1);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (navigate?.location?.state?.data) {
      setTagNo(navigate?.location?.state?.data);
    } else if (localStorage.getItem(PROJECT_INFO_STEPPER)) {
      setProjectInfo(JSON.parse(localStorage.getItem(PROJECT_INFO_STEPPER)));
      setTagNo(0);
    } else {
      setTagNo(-1);
    }
    let selectedMailFiles = JSON.parse(
      localStorage.getItem("SELECTED_MAIL")
    ).files;
    JSON.parse(selectedMailFiles).map((value, index) => {
      setFileNames((prevFileNames) => [
        ...prevFileNames,
        { id: index + 1, name: value.filename },
      ]);
    });
  }, []);

  const validateFields = () => {
    let error = "";
    for (let i = 0; i < projectInfo.tags; i++) {
      if (
        // !tagInfo[i].name ||
        // !tagInfo[i].description ||
        // !tagInfo[i].sub_category ||
        // // !tagInfo[i].team_type ||
        // !tagInfo[i].primary_scope ||
        // !tagInfo[i].technical_scope.length ||
        // !tagInfo[i].attachments
        !tagInfo[i].name ||
        !tagInfo[i].description ||
        !tagInfo[i].sub_category ||
        !tagInfo[i].verticals ||
        !tagInfo[i].sub_vertical ||
        !tagInfo[i].serviceRequired.length ||
        !tagInfo[i].attachments
      ) {
        error = "Please fill all the fields";
        setError(error);
      }
    }
    // let uniqueTag = (tagInfo.find(tag => {
    //   return tag.name === event.target.value
    // }))  c
    const uniqueTag = hasDuplicateName(tagInfo);


    if (uniqueTag) {
      error = "TagName should be unique";
      setError(error);

    }
    return error;
  };

  function hasDuplicateName(arr) {
    // Create an empty object to store unique names
    const nameMap = {};

    // Iterate through the array
    for (const obj of arr) {
      // Check if the name already exists in the nameMap
      if (nameMap[obj.name]) {
        // Duplicate name found
        return true;
      }

      // If not, add the name to the nameMap
      nameMap[obj.name] = true;
    }

    // No duplicate names found
    return false;
  }

  return (
    <div className="flex ml-auto w-4/5 overflow-hidden h-screen">
      <div className="h-screen bg-indigo-200 w-5"></div>
      <EmailDetails showMailList={false} selectedMail={selectedMail} />
      <CreateProject className={"flex ml-auto"} validateFields={validateFields}>
        <CreateProjectForm3 fileNames={fileNames} tagNo={tagNo} error={error} />
      </CreateProject>
    </div>
  );
};

export default CreateTagForm;
