import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import UploadDrawer from "../../Admin/Projects/UploadDrawer";
import AppContext from "../../../context/AppContext";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import FeedbackChat from "../../Customer/Projects/FeedbackChat";
import UploadQuotation from "../../ServiceProvider/Quotations/UploadQuotation";
import EditQuotations from "../../ServiceProvider/Quotations/EditQuotations";
import { Badge, Select, Tooltip } from "flowbite-react";
import "../../../App.css";

const Table = ({
  data,
  headers,
  showActions,
  getFileUrl,
  QuoteAction,
  handleShowPopup,
  customerActions,
  handleDelete,
  handleDeleteQuotation,
  project_id,
  projectDataTab,
  getDiagram,
  selectedData,
  handleSelectedData,
  editquotation,
}) => {
  const [showFeedbackChat, setShowFeedbackChat] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [feedbackAnimation, setFeedbackAnimation] =
    useState("animate-slide-in");

  const [isOpen, setIsOpen] = useState(false);
  const [isOpens, setIsOpens] = useState(false);
  const { type } = useContext(AppContext);
  const [clickedRow, setClickedRow] = useState(null);
  const [editProject, setEditProjectData] = useState({});
  const openDropdown = (values) => {
    setClickedRow(values);
  };

  const headers1 = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const handleFeedback = (row, data) => {
    setProjectData(row);
  };
  const editclickButton = (row) => {
    setProjectData(row);
    setIsOpens(true);
  };

  const user = JSON.parse(localStorage.getItem("User"));
  const handleDiagramStatus = async (event, row) => {
    // console.log('row', row)
    // let existingData = { ...selectedData };
    // existingData.adminStatus = event.target.value;
    // handleSelectedData(existingData);

    const payload = {
      diagram_id: row.id,
      status: event.target.value,
      project_id: project_id,
      admin_id: user.id,
      customer_id: projectDataTab.customer_id,
    };
    await axios
      .put(`${BASE_URL}/api/diagram/status/update`, payload, {
        headers: headers1,
      })
      .then((res) => { getDiagram() })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <table className="shadow-lg mb-20 ">
        <thead>
          <tr className="">
            {headers.map((header, index) => (
              <th
                className="bg-[#ECFFFF] text-[#03298C] w-96 h-16 res125:h-14 res150:h-9 capitalize  text-left pl-6 res125:text-sm res150:text-xs rounded"
                key={index}
              >
                {header}
              </th>
            ))}
            {showActions && (
              <th className="bg-[#ECFFFF] text-[#03298C] w-96 h-16 res125:h-12 res150:h-12 res150:text-xs capitalize rounded-lg text-center">
                Actions
              </th>
            )}
            {/* <th className="bg-[#ECFFFF] text-[#03298C] w-96 h-16 res125:h-12 res150:h-12 res150:text-xs capitalize rounded-lg text-center"></th> */}
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <tr>
              <td
                colSpan={7}
                className="text-center pt-3 text-blue-900 res125:text-sm res150:text-xs"
              >
                No datas found
              </td>
            </tr>
          ) : (
            <>
              {data.map((row, index) => (
                <tr
                  key={index}
                  className="border-b-[0.5px] border-[#C8C8C8] res125:text-sm res150:text-xs text-center"
                >
                  {Object.entries(row).map(([key, value], index) => {
                    if (key === "created_by") {
                      if (value === "admin") {
                        return (
                          <td
                            key={index}
                            className=" w-96 h-16 text-left capitalize text-[#72C27A] pl-6"
                          >
                            {(() => {
                              const status = "Factree Hub";

                              let badgeComponent = null;

                              if (status === "Factree Hub") {
                                badgeComponent = (
                                  <Badge color="dark">{status}</Badge>
                                );
                              }

                              return (
                                <div className="flex res150:text-xs">
                                  {badgeComponent}
                                </div>
                              );
                            })()}
                          </td>
                        );
                      } else if (value !== undefined && value !== null) {
                        return (
                          <td
                            key={index}
                            className=" w-96 h-16  capitalize  text-[#f16868] pl-6"
                          >
                            {(() => {
                              const status = "Service Provider";

                              let badgeComponent = null;

                              if (status === "Service Provider") {
                                badgeComponent = (
                                  <Badge color="warning">{status}</Badge>
                                );
                              }

                              return (
                                <div className="flex res150:text-xs">
                                  {badgeComponent}
                                </div>
                              );
                            })()}
                          </td>
                        );
                      }
                    }

                    if (key === "status") {
                      return (
                        <td
                          className={`w-96 ${value === "Completed" || value === "Accepted"
                            ? "text-[#72C27A] font-bold text-left pl-6"
                            : value === "Rework" || value === "Rejected"
                              ? "text-[#E96F6F] font-bold text-left pl-6"
                              : value === "Review" || value === "Pending"
                                ? "text-[#4277FF] font-bold text-left pl-6"
                                : "text-[#4277FF] text-left pl-6"
                            }  `}
                          key={index}
                        >
                          {" "}
                          {/* {value || "Pending"} */}
                          {(() => {
                            const status = value;

                            let badgeComponent = null;

                            if (
                              status === "Completed" ||
                              status === "Accepted"
                            ) {
                              badgeComponent = (
                                <Badge color="success">{status}</Badge>
                              );
                            } else if (
                              status === "Rework" ||
                              status === "Rejected"
                            ) {
                              badgeComponent = (
                                <Badge color="failure">{status}</Badge>
                              );
                            } else if (status === "In Review") {
                              badgeComponent = (
                                <Badge color="pink">{status}</Badge>
                              );
                            } else if (status === "Release to Customer") {
                              badgeComponent = (
                                <Badge color="indigo">
                                  {status?.length > 7
                                    ? status.slice(0, 7) + "..."
                                    : status}
                                </Badge>
                              );
                            }

                            return (
                              <Tooltip content={value}>
                                <div className="flex res150:text-xs badgeClass">
                                  {badgeComponent || (
                                    <Badge color="info">
                                      {value || "Pending"}
                                    </Badge>
                                  )}
                                </div>
                              </Tooltip>
                            );
                          })()}
                        </td>
                      );
                    } else if (key === "fileurl") {
                      return (
                        <td
                          key={index}
                          className="text-left underline text-[#4277FF] pl-6"
                        >
                          {" "}
                          <Link to="/fileurl">{value}</Link>{" "}
                        </td>
                      );
                    } else if (key === "quotation_File" || key === "diagram") {
                      return (
                        <td
                          key={index}
                          className="text-left underline text-[#4277FF] cursor-pointer whitespace-nowrap  pl-6"
                          onClick={() => {
                            if (value?.endsWith(".pdf")) {
                              getFileUrl(value, row.comments, row);
                            }
                          }}
                        >
                          <div onClick={() => openDropdown(value)}>{value}</div>
                          <div className="flex items-center justify-start">
                            {!value?.endsWith(".pdf") && (
                              <>
                                {clickedRow === value && (
                                  <div>
                                    {type === "admin" && (
                                      <Select
                                        name="status"
                                        onChange={(e) =>
                                          handleDiagramStatus(e, row)
                                        }
                                        // value={
                                        //   row.adminStatus ==
                                        //   "Release to Customer"
                                        //     ? row.adminStatus
                                        //     : "Select Status"
                                        // }
                                        value={row.adminStatus}
                                        style={{ width: "188px" }}
                                      >
                                        <option value="Select Status">
                                          Select Status
                                        </option>
                                        {row.adminStatus !==
                                          "Release to Customer" && (
                                            <option value="Completed">
                                              Completed
                                            </option>
                                          )}
                                        {row.adminStatus === "Completed" && (
                                          <option value="Release to Customer">
                                            Release to Customer
                                          </option>
                                        )}
                                      </Select>
                                    )}
                                  </div>
                                )}
                                <i
                                  className="fa fa-download ml-3 cursor-pointer"
                                  onClick={() =>
                                    getFileUrl(value, row.comments, row)
                                  }
                                ></i>
                                {type === "admin" && (
                                  <div>
                                    <i
                                      className="fa fa-upload ml-3 cursor-pointer"
                                      onClick={() => setIsOpen(!isOpen)}
                                    ></i>
                                    {isOpen && (
                                      <UploadDrawer
                                        show={isOpen}
                                        setShow={setIsOpen}
                                        selectedData={row}
                                      />
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </td>
                      );
                    } else if (key === "project_id") {
                      <td></td>;
                    } else {
                      return (
                        <>
                          {key !== "id" &&
                            key !== "tag_id" &&
                            key !== "created_at" &&
                            key !== "scope_id" &&
                            key !== "comments" &&
                            key !== "vendor_id" &&
                            key !== "adminStatus" &&
                            key !== "vendor" &&
                            key !== "sub_vertical" && (
                              <td
                                className="w-96 h-16 text-left pl-6"
                                key={index}
                              >
                                <Tooltip content={value}>
                                  <div className="w-32 overflow-hidden whitespace-nowrap text-ellipsis">
                                    {value}
                                  </div>
                                </Tooltip>
                              </td>
                            )}
                        </>
                      );
                    }
                  })}
                  {/* service-provider action */}
                  {showActions && (
                    <td className="w-96 h-16 text-center">
                      {/* edit */}
                      <span
                        onClick={() =>
                          row.adminStatus === "Release to Customer" ||
                            row.adminStatus === "Completed"
                            ? null
                            : handleShowPopup(row)
                        }
                      >
                        <i
                          className={`fa fa-2x fa-pencil-square-o ${row.adminStatus === "Release to Customer" ||
                            row.adminStatus === "Completed" ||
                            row.adminStatus === "In Review"
                            ? "text-gray-500"
                            : "text-[#4277FF] hover:bg-[#4277FF] hover:text-white border border-[#4277FF]"
                            } px-1 py-1 rounded mr-3`}
                          aria-hidden="true"
                          style={{
                            cursor:
                              row.adminStatus === "Release to Customer" ||
                                row.adminStatus === "Completed" ||
                                row.adminStatus === "In Review"
                                ? "not-allowed"
                                : "pointer",
                          }}
                        ></i>{" "}
                      </span>

                      {/* delete */}
                      <span
                        onClick={() =>
                          row.adminStatus === "Release to Customer" ||
                            row.adminStatus === "Completed" ||
                            row.adminStatus === "In Review"
                            ? null
                            : (() => {
                              swal({
                                title: "Are you sure?",
                                text: "Once deleted, you will not be able to recover this item!",
                                icon: "warning",
                                buttons: {
                                  cancel: "No",
                                  confirm: "Yes",
                                },
                                dangerMode: true,
                              }).then((willDelete) => {
                                if (willDelete) {
                                  handleDelete(
                                    row.id,
                                    row.diagram,
                                    "diagram"
                                  );
                                  swal(
                                    "Success!",
                                    "Item has been deleted successfully!",
                                    "success"
                                  );
                                } else {
                                  swal("Your item is safe!");
                                }
                              });
                            })()
                        }
                      >
                        <i
                          className={`fa fa-2x fa-trash cursor-pointer ${row.adminStatus === "Release to Customer" ||
                            row.adminStatus === "Completed" ||
                            row.adminStatus === "In Review"
                            ? "text-gray-500"
                            : "text-[#E96F6F] hover:text-white hover:bg-red-500 border border-[#E96F6F]"
                            }  rounded px-1 py-1`}
                          aria-hidden="true"
                          style={{
                            cursor:
                              row.adminStatus === "Release to Customer" ||
                                row.adminStatus === "Completed" ||
                                row.adminStatus === "In Review"
                                ? "not-allowed"
                                : "pointer",
                          }}
                        ></i>{" "}
                      </span>
                    </td>
                  )}
                  {/* customer addmail action*/}
                  {customerActions && (
                    <td className="w-96 h-16 text-center">
                      <Link to="/customer/edit">
                        <i
                          className="fa fa-2x fa-pencil-square-o cursor-pointer text-[#4277FF] hover:bg-[#4277FF] hover:text-white border border-[#4277FF] px-1 py-1 rounded mr-3"
                          aria-hidden="true"
                        ></i>{" "}
                      </Link>
                      <Link to="/customer/delete">
                        <i
                          className="fa fa-2x fa-trash cursor-pointer text-[#E96F6F] hover:text-white hover:bg-red-500 border border-[#E96F6F]  rounded px-1 py-1"
                          aria-hidden="true"
                          onClick={() => {
                            swal({
                              title: "Are you sure?",
                              text: "Once deleted, you will not be able to recover this item!",
                              icon: "warning",
                              buttons: {
                                cancel: "No",
                                confirm: "Yes",
                              },
                              dangerMode: true,
                            }).then((willDelete) => {
                              if (willDelete) {
                                handleDelete(row.id);
                                swal(
                                  "Success!",
                                  "Item has been deleted successfully!",
                                  "success"
                                );
                              } else {
                                swal("Your item is safe!");
                              }
                            });
                          }}
                        ></i>{" "}
                      </Link>
                    </td>
                  )}
                  {/* quotation action */}
                  {QuoteAction && (
                    <td className="w-96 h-16 pl-8">
                      {/* <Link to="/service/delete"> */}
                      <i
                        onClick={() => {
                          swal({
                            title: "Are you sure?",
                            text: "Once deleted, you will not be able to recover this item!",
                            icon: "warning",
                            buttons: {
                              cancel: "No",
                              confirm: "Yes",
                            },
                            dangerMode: true,
                          }).then((willDelete) => {
                            if (willDelete) {
                              handleDeleteQuotation(row.id);
                              swal(
                                "Success!",
                                "Item has been deleted successfully!",
                                "success"
                              );
                            } else {
                              swal("Your item is safe!");
                            }
                          });
                        }}
                        className="fa fa-2x fa-trash cursor-pointer text-[#E96F6F] hover:text-white hover:bg-red-500 border border-[#E96F6F]  rounded px-1 py-1"
                        aria-hidden="true"
                      ></i>{" "}
                      {type === "service-provider" && QuoteAction && (
                        // <td className="px-4 py-2 border-b border-indigo-200">
                        <span onClick={() => editclickButton(row)}>
                          <i
                            className="fa fa-2x fa-pencil-square-o cursor-pointer text-[#4277FF] hover:bg-[#4277FF] hover:text-white border border-[#4277FF] px-1 py-1 rounded mr-3"
                            aria-hidden="true"
                          ></i>{" "}
                        </span>
                        // </td>
                      )}
                    </td>
                  )}
                  {/* {type === "service-provider" && QuoteAction && (
                    
                    <td className="px-4 py-2 border-b border-indigo-200">
                     <span onClick={() => setIsOpen(true)}>
                        <i
                          className="fa fa-2x fa-pencil-square-o cursor-pointer text-[#4277FF] hover:bg-[#4277FF] hover:text-white border border-[#4277FF] px-1 py-1 rounded mr-3"
                          aria-hidden="true"
                        ></i>{" "}
                      </span> 
                    </td>
                  )} */}
                  {type === "service-provider" && QuoteAction && (
                    <td className="px-4 py-2 border-b border-indigo-200">
                      <button
                        className="bg-[#FFE3C7]  text-[#FD952D] px-4 py-1 border border-[#FD952D]  rounded-full"
                        onClick={() => handleFeedback(row, data)}
                      >
                        Feedback
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      <div className="">
        {/* {isOpen && (
          <UploadQuotation
            show={isOpen}
            setShow={setIsOpen}
            projectData={projectData}
          // EditProjectData ={editProject}
          /> */}
        {/* )} */}
      </div>
      <div className="">
        {isOpens && (
          <EditQuotations
            show={isOpens}
            setShow={setIsOpens}
            projectData={projectData}
          // EditProjectData ={editProject}
          />
        )}
      </div>
      {showFeedbackChat && (
        <div
          className={`fixed right-0 top-0 h-screen flex-col w-1/4 z-auto overflow-y-hidden mt-12 res150:mt-8 border-2 ${feedbackAnimation}`}
          style={{
            background: "linear-gradient(90deg, #F8F8F8 0%, #FFFFFF 74.69%)",
          }}
        >
          <FeedbackChat
            projectData={projectData}
          // onClose={closeFeedbackChatt}
          />
        </div>
      )}
    </>
  );
};

export default Table;
