import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../../constants/Constants";
import moment from "moment";
import { toast } from "react-hot-toast";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import swal from "sweetalert";
import Loader from "../../../Loader";
import { Badge } from "flowbite-react";

export default function ServiceOrder() {
  const navigate = useHistory();
  const [loading, setLoading] = useState(true);
  const [quotation, setQuotation] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [reloadpage, setReloadpage] = useState(false);

  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };
  const getFileUrl = async (filename) => {
    const user = JSON.parse(localStorage.getItem("User"));
    const getUserType = (user) => {
      if (user.is_admin) {
        return "Admin";
      } else if (user.is_company) {
        return "service_provider";
      } else if (user.is_customer) {
        return "customer";
      } else if (user.is_freelancer) {
        return "service_provider";
      }
    };

    const users = getUserType(user);
    let params = {};
    if (users === "Admin") {
      params = {
        filter: "order",
        name: filename,
      };
    } else if (users === "customer") {
      params = {
        filter: "order",
        name: filename,
      };
    } else {
      params = {
        filter: "order",
        name: filename,
      };
    }

    await axios
      .get(`${BASE_URL}/api/file-url`, { params, headers })
      .then((res) => {
        window.open(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const user = JSON.parse(localStorage.getItem("User"));
  const getUserType = (user) => {
    if (user.is_admin) {
      return "admin";
    } else if (user.is_company) {
      return "company";
    } else if (user.is_customer) {
      return "service_provider";
    } else if (user.is_freelancer) {
      return "service_provider";
    }
  };
  const type = getUserType(user);

  const handleStatus = (item, value) => {
    const payload = {
      orderStatus: value,
    };
    axios
      .put(`${BASE_URL}/api/order/updateConfirmORreject/${item._id}`, payload, {
        headers,
      })
      .then((response) => {
        getOrder();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOrder = async () => {
    const pathname = navigate.location.pathname.split("/");
    const project_id = pathname[pathname.length - 1];

        const getUserTypes = (user) => {
            if (user.is_admin) {
                return "Admin";
            } else if (user.is_company) {
                return "service_provider";
            } else if (user.is_customer) {
                return "customer";
            } else if (user.is_freelancer) {
                return "service_provider";
            }
        };
        const user = JSON.parse(localStorage.getItem("User"));
        const userTypes = getUserTypes(user)
        await axios
            .get(`${BASE_URL}/api/getOrder/${project_id}/${userTypes}`, { headers })
            .then((res) => {
                const datas = res.data.getOrders;
                let array = [];
                for (const orderData of datas) {

                    array.push({
                        email: orderData.email,
                        file: orderData.file,
                        gst: orderData.gst,
                        orderStatus: orderData.orderStatus,
                        org_name: orderData.org_name,
                        createddate: moment(orderData.createdAt).format(
                            "DD-MMM-YYYY h:mm A"
                        ),
                        price: orderData.price,
                        project_id: orderData.project_id,
                        scope: orderData.scope,
                        tag: orderData.tag,
                        totalAmount: orderData.totalAmount,
                        userType: orderData.userType,
                        user_id: orderData.user_id,
                        _id: orderData._id
                    });
                }
                setLoading(false);
                // setOrderData([...array]);
                const sortedQuotations = [...array].sort(
                    (a, b) => new Date(b.createddate) - new Date(a.createddate)
                  );
                  setOrderData(sortedQuotations)

            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        getOrder();
    }, []);

  const handleDelete = (orderId) => {
    const headers = {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
    };

    axios
      .delete(`${BASE_URL}/api/getOrder/${orderId}`, { headers })
      .then((response) => {
        setReloadpage(true);
        getOrder();
      })
      .catch((error) => {
        // Handle error
        console.error("Error deleting order", error);
        toast.error("Failed to delete order. Please try again.");
      });
  };

  const handleDeletefiles3 = async (fileName, folder) => {
    try {
      await axios.delete(
        `${BASE_URL}/api/deleteFileUrl/${folder}/${fileName}`,
        { headers }
      );
      toast.success("Order Deleted Successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const Theaders = ["S.No", "Org Name", "Create Date", "Order File", "Status"];

  return (
    <div>
      <div className="mt-5">
        {loading ? (
          <Loader className="mx-auto" />
        ) : (
          <table className="shadow-lg mb-20 ">
            <thead>
              <tr className="">
                {Theaders.map((header, index) => (
                  <th
                    className="bg-[#ECFFFF] text-[#03298C] w-96 h-16 res125:h-14 res150:h-9 capitalize  text-center res125:text-sm res150:text-xs rounded"
                    key={index}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {orderData.length === 0 ? (
                <tr>
                  <td
                    colSpan={7}
                    className="text-center pt-3 text-blue-900 res125:text-sm res150:text-xs"
                  >
                    No datas found
                  </td>
                </tr>
              ) : (
                <>
                  {orderData.map((item, index) => (
                    <tr
                      key={index}
                      className="border-b-[0.5px] border-[#C8C8C8] res125:text-sm res150:text-xs text-center"
                    >
                      <td className="py-2 border-b border-indigo-200">
                        {index + 1}
                      </td>
                      <td className="py-2 border-b border-indigo-200">
                        {item.org_name}
                      </td>
                      <td className="py-2 border-b border-indigo-200">
                        {moment(item.createddate).format("DD-MMM-YYYY hh:mm a")}
                      </td>
                      <td
                        className="py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2  text-blue-500 underline cursor-pointer"
                        onClick={() => {
                          getFileUrl(item.file);
                        }}
                      >
                        {item.file}
                      </td>
                      <td className="py-2 border-b border-indigo-200">
                        {(() => {
                          const status = item.orderStatus;

                          let badgeComponent = null;

                          if (status === "Accepted") {
                            badgeComponent = (
                              <Badge color="success">{status}</Badge>
                            );
                          } else if (status === "Rejected") {
                            badgeComponent = (
                              <Badge color="failure">{status}</Badge>
                            );
                          }

                          return (
                            <div className="flex justify-center items-center res150:text-xs">
                              {badgeComponent}
                            </div>
                          );
                        })()}
                      </td>

                      {/* <td className="px-4 py-2 border-b border-indigo-200">
                                                <i
                                                    className="fa fa-2x fa-trash cursor-pointer text-[#E96F6F] hover:text-white hover:bg-red-500 border border-[#E96F6F] rounded px-1 py-1"
                                                    aria-hidden="true"
                                                    onClick={() => {
                                                        swal({
                                                            title: "Are you sure?",
                                                            text: "Once deleted, you will not be able to recover this item!",
                                                            icon: "warning",
                                                            buttons: {
                                                                cancel: "No",
                                                                confirm: "Yes",
                                                            },
                                                            dangerMode: true,
                                                        }).then((willDelete) => {
                                                            if (willDelete) {
                                                                handleDelete(item._id);
                                                                handleDeletefiles3(item.file, "order");
                                                                swal(
                                                                    "Success!",
                                                                    "Item has been deleted successfully!",
                                                                    "success"
                                                                );
                                                            } else {
                                                                swal("Your item is safe!");
                                                            }
                                                        });
                                                    }}
                                                ></i>{" "}

                                            </td> */}
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
