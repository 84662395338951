import React, { useState ,Fragment } from "react";
import NavBar from "../Shared/NavBar/NavBar";
import SideBar from "../Shared/SideBar/SideBar";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import JoyrideTour from "../Shared/takeATour";


const DefaultLayout = (props) => {
  // const { startTour } = props;
  const {  setCurrentStepIndex, CurrentStepIndex } = useContext(AppContext);

  const handleNextStep = () => {

    setCurrentStepIndex((prevIndex) => prevIndex + 1); 
    
  };
  const [runTour, setRunTour] = useState(false);

  const startTour = () => {
    setRunTour(true);
  };
  return (
    <div className="h-screen flex">
      <SideBar 
      onNextSteps={handleNextStep}/>
      <NavBar startTour={startTour} />

      <JoyrideTour runTour={runTour} setRunTour={setRunTour} />
      <div className="mx-auto w-full">{props.children}</div>
    </div>
  );
};

export default DefaultLayout;
