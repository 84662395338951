import EmailDetails from "../../Mails/EmailDetails";
import AppContext from "../../../../context/AppContext";
import { useContext } from "react";
import ModifyPreviewProject from "./ModifyPreviewProject";
import ModifyProject from "./ModifyProject";

const ModifyProjectTagPreview = () => {
  const { selectedMail } = useContext(AppContext);

  return (
    <div className="flex ml-auto w-4/5 overflow-hidden h-screen">
      <div className="h-screen bg-indigo-200 w-5"></div>
      <EmailDetails showMailList={false} selectedMail={selectedMail} />
      <ModifyProject>
        <ModifyPreviewProject />
      </ModifyProject>
    </div>
  );
};

export default ModifyProjectTagPreview;
