import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { BASE_URL } from "../../../constants/Constants";
import UploadQuotation from "../Quotations/UploadQuotation";
import moment from "moment";
import Table from "../../Shared/Table/Table";
import { useHistory } from "react-router-dom";
import Loader from "../../../Loader";
import CustomerQuotationList from "../../Admin/Quotations/CustomerQuotationList";

export default function ServiceYourQuote({ projectData }) {
  const navigate = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [editFlag, SetEditFlag] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isUploadButtonEnabled, setIsUploadButtonEnabled] = useState(false);

  // const editFlag = true

  useEffect(() => {
    getQuotation();
  }, []);

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const getQuotation = async () => {
    const pathname = navigate.location.pathname.split("/");
    const project_id = pathname[pathname.length - 1];
    const user = JSON.parse(localStorage.getItem("User"));
    const params = {
      vendor_id: user.id,
      project_id,
    };
    await axios
      .get(`${BASE_URL}/api/quotations`, { params, headers })
      .then((res) => {
        const datas = res.data.message;
        let array = [];
        for (const quotation of datas.quotations) {
          array.push({
            // name: quotation.tag_name,
            price: quotation.price,
            tag_name: quotation.tag_name,
            scope_name: quotation.scope_name,
            quotation_File: quotation.file,
            createddate: moment(quotation.created_at).format(
              "DD-MMM-YYYY h:mm A"
            ),
            status: quotation.status,
            id: quotation._id,
            project_id: quotation.project_id,
          });
        }
        setLoading(false);
        setData([...array]);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // Check if any of the technical scopes have a vendorStatus of "Accepted"
    const hasAcceptedStatus = projectData?.tags?.reduce((acc, tag) => {
      const hasAccepted = tag?.serviceRequired?.some(
        (serviceRequired) => serviceRequired.vendorStatus === "Accepted"
      );
      return acc || hasAccepted;
    }, false);

    // Update the state of the "Upload Quotation" button accordingly
    setIsUploadButtonEnabled(hasAcceptedStatus);
  }, [projectData]);

  const getFileUrl = async (filename) => {
    const params = {
      filter: "quotation",
      name: filename,
    };
    await axios
      .get(`${BASE_URL}/api/file-url`, { params, headers })
      .then((res) => {
        window.open(res.data.message);
      })
      .catch((err) => {});
  };

  const handleDeleteQuotation = async (id) => {
    const params = { id };
    await axios
      .delete(`${BASE_URL}/api/quotation/delete`, { params, headers })
      .then((res) => {
        getQuotation();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // table head
  const Theaders = [
    "price",
    "tag name",
    "Scope Name",
    "Quotation File",
    "Created Date / Time",
    "Status",
    "Actions",
    "",
  ];
  return (
    <div>
      {/* <button
        className="bg-[#03298C] w-44 h-12 res125:w-36 res125:h-9 res125:text-sm res150:w-32 res150:h-8 res150:mt-2 text-white ml-auto flex rounded mt-4"
        onClick={() => setIsOpen(!isOpen)}
        disabled={!isUploadButtonEnabled}
      >
        <span className="flex m-auto res125:text-sm res150:text-xs">
          Upload Quotation
        </span>
      </button> */}
      {/* <button
        className="bg-[#03298C] w-44 h-12 res125:w-36 res125:h-9 res125:text-sm res150:w-32 res150:h-8 res150:mt-2 text-white ml-auto flex rounded mt-4"
        onClick={() => setIsOpen(!isOpen)}
        // disabled={!isUploadButtonEnabled}
      >
        <span className="flex m-auto res125:text-sm res150:text-xs">Upload </span>
      </button> */}
      {/* <div className="relative">
        {isOpen && (
          <UploadQuotation
            show={isOpen}
            setShow={setIsOpen}
            projectData={projectData}
          /> 
        )}
      </div> */}

      <CustomerQuotationList projectDetails={projectData} />

      {/* <div className="my-4 res125:my-3 res150:my-2">
        {loading ? (
          <Loader className="mx-auto" />
        ) : (
          <Table
            data={data}
            headers={Theaders}
            showActions={false}
            getFileUrl={getFileUrl}
            handleDeleteQuotation={handleDeleteQuotation}
            QuoteAction={true}
            editquotation={projectData}
          />
        )}
      </div> */}
    </div>
  );

  // return (
  //   <div>
  //     <button
  //       className="bg-[#03298C] w-44 h-12 res125:w-36 res125:h-9 res125:text-sm res150:w-32 res150:h-8 res150:mt-2 text-white ml-auto flex rounded mt-4"
  //       onClick={() => setIsOpen(!isOpen)}
  //       disabled={!isUploadButtonEnabled}
  //       >
  //       <span className="flex m-auto res125:text-sm res150:text-xs">Create Quotation</span>
  //     </button>
  //     {/* <div className="relative">
  //       {isOpen && (
  //         <UploadQuotation
  //           show={isOpen}
  //           setShow={setIsOpen}
  //           projectData={projectData}
  //         />
  //       )}
  //     </div> */}

  //     {/* <div className="my-4 res125:my-3 res150:my-2">
  //       {loading ? (
  //         <Loader className="mx-auto" />
  //       ) : (
  //         <Table
  //           data={data}
  //           headers={Theaders}
  //           showActions={false}
  //           getFileUrl={getFileUrl}
  //           handleDeleteQuotation={handleDeleteQuotation}
  //           QuoteAction={true}
  //           editquotation={projectData}
  //         />
  //       )}
  //     </div> */}
  //   </div>
  // );

}
