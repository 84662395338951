import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../../../Loader";
import { Badge } from "flowbite-react";

const VendorsRFQList = () => {
  const navigate = useHistory();
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState("false");

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    setLoading(true);
    const path = navigate.location.pathname;
    const pathArray = path.split("/");
    const id = pathArray[pathArray.length - 1];
    const params = {
      project_id: id,
    };
    axios
      .get(`${BASE_URL}/api/vendors`, { params, headers })
      .then((res) => {
        setResponseData(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);


  function getStatusColor(status, index) {
    const statusArray = JSON.parse(status);
    const currentStatus = statusArray[index];
    if (currentStatus === "Accepted") {
      return <Badge color="indigo">Pending</Badge>;
    } else if (currentStatus === "Rejected" || currentStatus === "Expired") {
      return "text-red-500";
    } else {
      return "text-blue-500";
    }
  }

  return (
    <div>
      {loading ? (
        <Loader className="mx-auto" />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-6">
          <table className="w-full text-sm text-center text-black">
            <thead className="text-base text-[#03298C] bg-[#ECFFFF] res125:text-sm res150:text-xs ">
              <tr className="text-center">
                <th scope="col" className="px-6 py-4 res150:px-5 res150:py-3">
                  S.No
                </th>
                <th scope="col" className="px-6 py-4 res150:px-5 res150:py-3">
                  SP Name
                </th>
                <th scope="col" className="px-6 py-4 res150:px-5 res150:py-3">
                  SP Contact
                </th>
                <th scope="col" className="px-6 py-4 res150:px-5 res150:py-3">
                  SP Email
                </th>
                <th scope="col" className="px-6 py-4 res150:px-5 res150:py-3">
                  Tag
                </th>
                <th scope="col" className="px-6 py-4 res150:px-5 res150:py-3">
                  Microservice
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 res150:px-5 res150:py-3 text-center"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {responseData.map((value, i) =>
                value.vendors.map((vendor, index) => (
                  <tr className="bg-white border-b text-center res125:text-xs">
                    <td className="px-6 py-4 res150:px-5 res150:py-2">
                      {i + 1}
                    </td>
                    <th
                      scope="row"
                      className="px-6 py-4 capitalize res150:px-5 res150:py-2"
                    >
                      {vendor.name}
                    </th>
                    <td className="px-6 py-4 res150:px-5 res150:py-2">
                      {vendor.contact_no}
                    </td>
                    <td className="px-6 py-4 res150:px-5 res150:py-2">
                      {vendor?.email}
                    </td>
                    <td className="px-6 py-4 res150:px-5 res150:py-2">
                      {value.tag.name}
                    </td>
                    <td
                      className="px-6 py-4 res150:px-5 res150:py-2 "
                      title={value?.scope?.microservice}
                    >
                      {value?.scope?.microservice?.length > 10
                        ? value?.scope?.microservice.slice(0, 16) + "..."
                        : value?.scope?.microservice}
                    </td>
                    <td className="px-6 py-4 res150:px-5 res150:py-2 res150:text-xs">
                      {(() => {
                        const status = JSON.parse(value.projectObj.status)[
                          index
                        ];

                        let badgeComponent = null;

                        if (status === "Pending") {
                          badgeComponent = (
                            <Badge color="indigo">Pending</Badge>
                          );
                        } else if (status === "Accepted") {
                          badgeComponent = (
                            <Badge color="success">Accepted</Badge>
                          );
                        } else if (
                          status === "Rejected" ||
                          status === "Expired"
                        ) {
                          badgeComponent = (
                            <Badge color="failure">Expired</Badge>
                          );
                        }

                        return (
                          <div className="flex justify-center items-center res150:text-xs">
                            {badgeComponent}
                          </div>
                        );
                      })()}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {responseData.length === 0 && (
            <div className="py-4 flex justify-center res150:text-sm">
              No RFQ Found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VendorsRFQList;
