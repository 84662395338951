import { useState } from "react";
import axios from "axios";
// import Logo from "../../../assets/images/Factree Hub Logo.png";
import Logo from "../../../assets/svg/logowithtgline.svg";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../../constants/Constants";
import swal from "sweetalert";

const AdminSignupForm = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setCofirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    contact: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = () => {
    setCofirmPassword(!showConfirmPassword);
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      !formData.username ||
      !formData.email ||
      !formData.contact ||
      !formData.password ||
      !formData.confirmPassword
    ) {
      setError("Please fill all the fields.");
    } else if (formData.password !== formData.confirmPassword) {
      setError("Password and Confirm Password do not match.");
    } else {
      setLoading(true);
      setIsDisabled(true);
      const payload = {
        name: formData.username,
        email: formData.email,
        contact: formData.contact,
        password: formData.password,
      };
      axios
        .post(`${BASE_URL}/api/admin/create`, payload)
        .then((res) => {
          setLoading(false);
          setError(null);
          setIsDisabled(false);
          history.push("/auth/signin");
          toast.success("Register Successfully");
        })
        .catch((err) => {
          setError(err.response.data.message);
          toast.error(`${err.response.data.message}`);
          swal(
            `${err.response.data.message}`,
            "Please try again with a different email !",
            "error"
          );
          setLoading(false);
          setIsDisabled(false);
        });
    }
  };

  return (
    <div className="flex flex-col w-1/2 mx-auto h-full items-center justify-center">
      <img
        src={Logo}
        className=" w-[224px] res125:w-1/4 res150:w-1/4"
        alt="FactreeHub"
      />
      <div className="flex flex-col gap-3 pt-8 res125:pt-5 res150:pt-4">
        <p className="text-3xl font-bold mb-2 w-full h-16 res150:h-12 text-center res125:text-xl res125:mb-0 res150:text-lg res150:mb-0">
          Administrator Sign-up
        </p>
        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 w-[28rem] rounded relative mb-2 text-center res150:text-xs res150:py-1.5 res125:py-1.5 res125:text-xs res125:w-96 res125:mb-1 res150:w-80"
            role="alert"
          >
            <span class="block sm:inline">{error}</span>
          </div>
        )}
        {/* input form  */}
        <form
          action=""
          className="flex flex-col gap-2 res125:gap-1 res150:gap-1 mx-auto"
        >
          <label htmlFor="Username" className="res125:text-sm res150:text-xs">
            Full Name
          </label>
          <input
            className="w-[28rem] h-12 rounded border-[#C8C8C8] placeholder-[#C8C8C8] mb-2 res125:h-9 res125:w-96 res125:text-sm res150:h-8 res150:w-80 res150:text-xs"
            placeholder="Enter your full name"
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
          />
          <label htmlFor="Email" className="res125:text-sm res150:text-xs">
            Email
          </label>
          <input
            className="w-full h-12 rounded border-[#C8C8C8] placeholder-[#C8C8C8] mb-2 res125:h-9 res125:w-96 res125:text-sm res150:h-8 res150:w-80 res150:text-xs"
            placeholder="Enter your email"
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <label htmlFor="Contact" className="res125:text-sm res150:text-xs">
            Contact No
          </label>
          <input
            className="w-full h-12 rounded border-[#C8C8C8] placeholder-[#C8C8C8] mb-2 res125:h-9 res125:w-96 res125:text-sm res150:h-8 res150:w-80 res150:text-xs"
            placeholder="Enter your contact no."
            type="text"
            id="contact"
            name="contact"
            value={formData.contact}
            onChange={handleInputChange}
          />
          <div className="flex flex-col">
            <label htmlFor="password" className="res125:text-sm res150:text-xs">
              Password
            </label>
            <div className="relative mt-3 res150:mt-1">
              <input
                type={showPassword ? "text" : "password"}
                className="w-full border-[#C8C8C8] rounded h-12  placeholder-gray-400 res125:h-9 res125:w-96 res125:text-sm res150:h-8 res150:w-80 res150:text-xs"
                placeholder="Enter your password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <span
                type="button"
                className="w-1/12 ml-auto flex transform -translate-y-8 res150:mt-2 res125:mt-1 text-gray-500 focus:outline-none"
                onClick={handleTogglePassword}
              >
                {showPassword ? (
                  <i
                    className="fa fa-eye cursor-pointer"
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye-slash cursor-pointer"
                    aria-hidden="true"
                  ></i>
                )}
              </span>
            </div>
          </div>

          <div className="flex flex-col mb-3 res150:mb-2">
            <label
              htmlFor="confirmpassword"
              className="res125:text-sm res150:text-xs"
            >
              Confirm Password
            </label>
            <div className="relative mt-3 res150:mt-1">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="w-full border-[#C8C8C8] rounded h-12  placeholder-gray-400 res125:h-9 res125:w-96 res125:text-sm res150:h-8 res150:w-80 res150:text-xs"
                placeholder="Confirm password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
              <span
                type="button"
                className="w-1/12 ml-auto flex transform -translate-y-8 res125:mt-1 res150:mt-1.5 text-gray-500 focus:outline-none"
                onClick={handleToggleConfirmPassword}
              >
                {showConfirmPassword ? (
                  <i
                    className="fa fa-eye cursor-pointer"
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye-slash cursor-pointer"
                    aria-hidden="true"
                  ></i>
                )}
              </span>
            </div>
          </div>

          <button
            type="submit"
            className={`bg-[#03298C] w-full res125:h-9 res125:w-96 res125:text-sm res150:w-80 res150:text-xs res150:h-8  h-12 hover:bg-[#03298C] text-white font-bold  rounded ${
              isDisabled ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleSubmit}
            disabled={isDisabled}
          >
            {loading ? (
              <>
                <img
                  src="https://media1.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif"
                  className="w-[3rem] mx-auto res125:w-8 res150:w-7"
                  alt=""
                />
              </>
            ) : (
              "SIGN UP"
            )}
          </button>
          <Link
            to="/auth/signin"
            className="text-blue-300 underline mb-3 text-end mt-4 res125:text-sm res150:text-xs"
          >
            Already have an account ?
          </Link>
        </form>
      </div>
    </div>
  );
};

export default AdminSignupForm;
