import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Mails.css";
import moment from "moment/moment";
import AppContext from "../../../context/AppContext";
import axios from "axios";
import "./Mails.css";
import {
  CUSTOMER_INFO_STEPPER,
  TAG_INFO_STEPPER,
  PROJECT_INFO_STEPPER,
  BASE_URL,
} from "../../../constants/Constants";
import { ReactComponent as DownloadSymbolIcon } from "../../../assets/svg/download_symbol.svg";
import { ReactComponent as Download } from "../../../assets/svg/download.svg";

export default function EmailDetails(props) {
  const history = useHistory();
  const {
    setSelectedMail,
    isDropDownChanged,
    setCustomerInfo,
    setProjectInfo,
    setTagInfo,
  } = useContext(AppContext);
  const { selectedMail, showMailList } = props;
  const [selectedValue, setSelectedValue] = useState("Continue");
  const [selectedLabelValue, setSelectedLabelValue] = useState("Select Label");
  const [selectedStatusValue, setSelectedStatusValue] =
    useState("Select Status");

  function handleSelectChange(event) {
    setSelectedValue(event.target.value);
  }

  const handleSelectLabelChange = (event) => {
    const selectedLabelValue = event.target.value;

    setSelectedLabelValue(selectedLabelValue);

    // make API call with the selected label
    const payload = {
      updateObj: {
        label: selectedLabelValue,
      },
      message_id: selectedMail.message_id,
    };
    axios
      .put(`${BASE_URL}/api/messages/update`, payload, { headers })
      .then((response) => {
        isDropDownChanged(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectStatusChange = (event) => {
    const selectedStatusValue = event.target.value;

    setSelectedStatusValue(selectedStatusValue);

    // make API call with the selected status
    const payload = {
      updateObj: {
        status: selectedStatusValue,
      },
      message_id: selectedMail.message_id,
    };
    axios
      .put(`${BASE_URL}/api/messages/update`, payload, { headers })
      .then((response) => {
        // console.log(response);
        isDropDownChanged(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const HandleModifyProject = () => {
    localStorage.removeItem(CUSTOMER_INFO_STEPPER);
    localStorage.removeItem(PROJECT_INFO_STEPPER);
    localStorage.removeItem(TAG_INFO_STEPPER);
    setCustomerInfo("");
    setProjectInfo("");
    setTagInfo("");
    history.push("/admin/mails/modify-project/1");
  };

  const HandleCreateProject = () => {
    localStorage.removeItem(CUSTOMER_INFO_STEPPER);
    localStorage.removeItem(PROJECT_INFO_STEPPER);
    localStorage.removeItem(TAG_INFO_STEPPER);
    setCustomerInfo("");
    setProjectInfo("");
    setTagInfo("");
    history.push("/admin/mails/create-project/1");
  };

  const handleDownload = (file) => {
    const params = {
      filename: file,
    };
    axios
      .get(`${BASE_URL}/api/attachment-download`, { params, headers })
      .then((res) => {
        window.open(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const existingMail = localStorage.getItem("SELECTED_MAIL");
    if (existingMail !== null && existingMail !== "undefined") {
      setSelectedMail(JSON.parse(existingMail));
    }
  }, []);

  useEffect(() => {
    if (selectedMail.status) {
      setSelectedStatusValue(selectedMail.status);
    } else {
      setSelectedStatusValue("Select Status");
    }
    if (selectedMail.label) {
      setSelectedLabelValue(selectedMail.label);
    } else {
      setSelectedLabelValue("Select Label");
    }
  }, [selectedMail]);

  return (
    <>
      {Object.keys(selectedMail).length > 0 && (
        <div className="flex flex-col overflow-y-auto bg-white ml-12 pr-12 w-2/3 mt-12 res125:mt-10 res150:mt-6">
          <span className="text-2xs text-center text-light-600"></span>
          <div className="flex items-center px-0 mt-10">
            <div
              className="w-10 h-10 rounded-full mr-4 pt-2 res125:w-8 res125:h-8 res125:pt-1 res150:w-8 res150:h-8 res150:pt-1 text-center"
              style={{ backgroundColor: selectedMail.color }}
            >
              {selectedMail.from.replace(/[^a-zA-Z0-9]/g, "").charAt(0)}
            </div>
            <span className="text-xl text-[#011A59] font-bold res150:text-sm">
              {selectedMail.from.substring(0, selectedMail.from.indexOf("<"))}
            </span>
            {/* select label dropdown */}
            <div className="flex ml-auto">
              <div className="dropdown inline-block relative">
                <select
                  value={selectedLabelValue}
                  onChange={handleSelectLabelChange}
                  className="bg-white border border-[#03298C] text-[#03298C] text-sm focus:ring-[#03298C] focus:border-[#03298C] block w-[200px] p-2.5 rounded-md res150:p-1.5 res150:text-xs res150:w-36 res125:w-40 res125:p-2"
                >
                  <option value="Select Label" disabled selected>
                    Select Label
                  </option>
                  <option value="RFQ">RFQ</option>
                  <option value="Query">Query</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              {/* status dropdown */}
              <div className="dropdown inline-block relative ml-2">
                <select
                  value={selectedStatusValue}
                  onChange={handleSelectStatusChange}
                  className="bg-white border border-[#03298C] text-[#03298C] text-sm focus:ring-[#03298C] focus:border-[#03298C] block w-[200px] p-2.5 rounded-md res150:w-36 res150:p-1.5 res150:text-xs res125:w-40 res125:p-2"
                >
                  <option value="Select Status" disabled selected>
                    Select Status
                  </option>
                  <option value="Pending">Pending</option>
                  <option value="Awaiting Info">Awaiting Info</option>
                  <option value="Live">Live</option>
                  <option value="Rejected">Rejected</option>
                </select>
              </div>
            </div>
          </div>
          <hr className="mt-4 " style={{ border: "0.5px solid #CAD9FF" }} />
          <span className="px-0 text-sm text-[#929292] italic font-normal mt-8 res125:text-xs res150:text-xs">
            {moment(selectedMail.time).format("dddd, D MMMM YYYY, hh:mm a")}
          </span>

          <span className="px-0 text-3xl text-[#011A59] font-normal mb-6 mt-2 res125:text-2xl res150:text-xl">
            {selectedMail.subject}
          </span>
          <hr
            className="mt-2 res125:mt-0 res150:mt-0"
            style={{ border: "0.5px solid #CAD9FF" }}
          />
          <div className="px-0 text-base text-black font-normal my-6 whitespace-pre-wrap">
            <iframe
              srcDoc={`<html><head><style>*{margin:0;padding:0;border:0;  @media (-webkit-min-device-pixel-ratio: 1.25) {font-size: .9rem;} @media (-webkit-min-device-pixel-ratio: 1.5) {font-size: .83rem;}}</style></head><body>${selectedMail.body}</body></html>`}
              width="100%"
              id="mail-iframe"
              height="600"
              className="res150:w-full"
            />
          </div>

          <div className=" w-3/4  mb-10 h-full">
            <div className="flex flex-wrap">
              {JSON.parse(selectedMail.files)?.map((file, index) => (
                <div
                  className="w-2/5 px-2 border border-[#CAD9FF] text-center mr-8 mb-4 grid grid-cols-5 row rounded-lg res125:rounded res150:rounded items-center"
                  key={index}
                >
                  <div className="p-3 res125:p-1 res150:p-0">
                    <Download className="res125:w-5 res150:w-4" />
                  </div>
                  <div
                    className="py-3 px-1 overflow-hidden col-span-3 whitespace-nowrap text-ellipsis text-left res125:text-xs res150:py-2 res150:px-1 res150:text-xs"
                    title={file.filename}
                  >
                    <div>{file.filename}</div>
                    <div className="uppercase text-sm font-bold res125:text-xs res150:text-xs">
                      {
                        file.filename.split(".")[
                        file.filename.split(".").length - 1
                        ]
                      }
                    </div>
                  </div>
                  <div
                    className="ml-auto p-3 cursor-pointer res150:p-2"
                    onClick={() => handleDownload(file.filename)}
                  >
                    <DownloadSymbolIcon />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <hr style={{ border: "0.5px solid #CAD9FF" }} />
          <div className="flex mt-auto ml-auto mb-8 pt-7">
            {showMailList && (
              <select
                value={selectedValue}
                onChange={handleSelectChange}
                className="bg-white border border-[#03298C] text-[#03298C] text-sm focus:ring-[#03298C] focus:border-[#03298C] block w-full p-2.5 res150:p-2 res150:text-xs res125:p-1.5"
              >
                <option selected disabled>
                  Continue
                </option>
                <option>Create Project</option>
                <option>Modify Project</option>
              </select>
            )}
            {selectedValue === "Create Project" && HandleCreateProject()}
            {selectedValue === "Modify Project" && HandleModifyProject()}
          </div>
        </div>
      )}
    </>
  );
}
