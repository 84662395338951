import axios from "axios";
import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../constants/Constants";
import FactreehubLogo from "../../../assets/images/Factree Hub Logo.png";
import useDebounce from "../../../useDebounce";
import { ReactComponent as ListViewIcon } from "../../../assets/svg/list_view.svg";
import { ReactComponent as GridViewIcon } from "../../../assets/svg/grid_view.svg";
import Loader from "../../../Loader";
import ProjectList from "../../Shared/Projects/ProjectList";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import CreateProjectForm from "../../Customer/CreateOrModifyProject/CreateProject/CreateProjectForm";
import CustomerTicket from "../Tickets/Ticket";
import AppContext from "../../../context/AppContext";

export default function CustomerDashboard() {
  const [gridMode, setGridMode] = useState(false);
  const [listMode, setListMode] = useState(false);
  const [create, setCreate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpens, setIsOpens] = useState(false);
  const { setCurrentStepIndex, CurrentStepIndex } = useContext(AppContext);
  const [inviteUser, setInviteUser] = useState(false);


  const history = useHistory();

  // total project state
  const [project, setProject] = useState([]);
  const [activeButton, setActiveButton] = useState("grid");

  // tab state
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);

  // completed project filter
  const limitedData =
    data &&
    data
      ?.filter((data) => data.status.toLowerCase() === "completed")
      .sort((a, b) => new Date(b.createtime) - new Date(a.createtime))
      .slice(0, 6);

  // Bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const searchFunc = async () => {
    const params = {
      customer_id: custId.id,
      search,
    };
    setLoading(true);
    axios
      .get(`${BASE_URL}/api/customer/projects`, { params, headers })
      .then((res) => {
        setLoading(false);
        setData(res.data.message);
        setProject(res.data.message);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const custId = JSON.parse(localStorage.getItem("User"));

  const debouceSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    searchFunc();
  }, [debouceSearchTerm]);

  // apicall
  // useEffect(() => {
  //   const apiCall = async () => {
  //     try {
  //       const {
  //         data: { message },
  //       } = await axios.get(
  //         `${BASE_URL}/api/customer/projects?customer_id=${custId.id}`,
  //         { headers }
  //       );
  //       setProject(message);
  //     } catch (error) {
  //       console.log(error.response.data.message);
  //     }
  //   };
  //   apiCall();
  // }, []);

  const apiCall = async (customerId) => {
    try {
      const { data: { message } } = await axios.get(
        `${BASE_URL}/api/customer/projects?customer_id=${customerId}`,
        { headers }
      );

      setProject(message);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const fetchInvitedUser = async () => {
    try {
      const { data: { data } } = await axios.get(`${BASE_URL}/api/invitedUser?id=${custId.id}`, { headers });
      setInviteUser(data?.customer_created);

      await apiCall(data?.customer_created || custId.id);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchInvitedUser();
  }, []);

  // const apiCall = async (customerId) => {
  //   try {
  //     const { data: { message } } = await axios.get(
  //       `${BASE_URL}/api/customer/projects?customer_id=${customerId}`,
  //       { headers }
  //     );

  //     setProject(message);
  //   } catch (error) {
  //     console.log(error.response.data.message);
  //   }
  // };

  // const fetchInvitedUser = async () => {
  //   try {
  //     const { data: { data } } = await axios.get(`${BASE_URL}/api/invitedUser?id=${custId.id}`);
  //     // console.log("invotedefegwegwgw", data?.customer_created);
  //     // setInviteUser(data?.customer_created);

  //     await apiCall(data?.customer_created || custId.id);
  //   } catch (error) {
  //     console.log(error.response.data.message);
  //   }
  // };

  // useEffect(() => {
  //   fetchInvitedUser();
  // }, []); 
  const handleNextStep = () => {
    setCurrentStepIndex((prevIndex) => prevIndex + 1);

  };

  const handleClick = () => {
    history.push('/customer/ticket');
  };
  return (
    <>
      <div className="relative">

      </div>
      {isOpen ? (<CreateProjectForm
        show={isOpen}
        setShow={setIsOpen}
      />
      ) : (
        <div className="w-4/5 h-auto  ml-auto mt-24 res125:mt-20 res150:mt-16">
          <div className=" h-11 mt-7 flex mx-28">
            <div className="font-bold text-3xl py-1 text-[#1E1E1E] tracking-wide res125:text-2xl res150:text-lg res125:mt-1 res150:mt-2">
              {project.length} Projects
            </div>
            <button
              className={`ml-auto mr-8 py-3 ${activeButton === "grid" ? "active" : "inactive"
                }`}
              onClick={() => {
                setActiveButton("grid");
                setGridMode(!gridMode);
                setListMode(false);
              }}
              disabled={gridMode}
              title="Grid view"
            >
              <GridViewIcon />
            </button>
            <button
              className={`py-3 ${activeButton === "list" ? "active" : "inactive"}`}
              onClick={() => {
                setActiveButton("list");
                setListMode(!listMode);
                setGridMode(false);
              }}
              disabled={listMode}
              title="list view"
            >
              <ListViewIcon />
            </button>
          </div>
          <div className=" h-11 flex mt-1 res125:mt-0 res150:mt-0 ml-0 w-full">
            <div className="text-base font-bold py-2.5 ml-28 res125:text-sm res150:text-xs">
              Category
            </div>

            <div className="ml-auto flex mr-28 ">
              {/* Search Box */}
              <div className="w-full pr-5">
                <input
                  id="customerSearch"
                  className="h-[46px] w-[320px] pl-2 border border-[#C8C8C8] res125:w-64 res125:h-[2.1rem] res125:text-sm res150:text-xs res150:w-52 res150:h-[1.8rem]"
                  placeholder="Search projects..."
                  name="search"
                  // value={filter.search}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                ></input>
              </div>
              {/* Status */}

              {inviteUser ? null : (
                <>
                  <button type="button" id="createProjectButton"
                    className="h-[46px] w-[320px] px-5 mx-2 text-blue-800 rounded border-2 border-blue-800 hover:text-white hover:bg-blue-800 res125:w-64 res125:h-[2.1rem] res125:text-sm res150:text-xs res150:w-52 res150:h-[1.8rem]"
                    onClick={() => {
                      setIsOpen(!isOpen);
                      history.push("/customer/dashboard/create-project/1");
                    }}
                  >Create Project</button>
                  <button type="button"
                    id="createTicketButton"
                    className="h-[46px] w-[320px] px-5 mx-2 text-blue-800 rounded border-2 border-blue-800 hover:text-white hover:bg-blue-800 res125:w-64 res125:h-[2.1rem] res125:text-sm res150:text-xs res150:w-52 res150:h-[1.8rem]"
                    onClick={() => handleClick()}
                  >Generate Tickets</button>
                </>
              )}

            </div>
          </div>
          <hr className=" border border-[#C8C8C8] my-8 mx-28 res150:mb-5 res125:mb-5 res125:my-4 res150:my-1" />
          {loading ? (
            <Loader className="mx-auto mt-10" />
          ) : (
            <ProjectList
              projectList={project}
              gridMode={gridMode}
              listMode={listMode}
              onNextStep={handleNextStep}
            // custProject="true"
            />
          )}
          {/* {create && <CreateProject>
            <CreateProjectForm1 />
          </CreateProject>} */}
        </div>
      )}

      {/* {
        <div className="relative">
          {isOpen && (
            <CreateProjectForm
              show={isOpen}
              setShow={setIsOpen}
            />
          )}
        </div>
      } */}
      {/* {
        <div className="">
          {isOpens && (
            <CustomerTicket
              show={isOpens}
              setShow={setIsOpens}
            />
          )}
        </div>
      } */}
    </>

  );
}

