import "./Mails.css";
import moment from "moment/moment";

export default function EmailCard(props) {
  const {
    from,
    time,
    subject,
    handleSelectedMail,
    selectedMail,
    message_id,
    index,
  } = props;
  var array = [
    "#EA8989",
    "#58D7E9",
    "#A763EA",
    "#85C170",
    "#E7C368",
    "#6B9AF6",
  ];
  var color = "";
  color = array[index % array.length];

  return (
    <div
      className={`${
        selectedMail.message_id === message_id
          ? "bg-[#F7FAFF] cursor-pointer rounded-2xl shadow-md  shadow-[#dfe9fb]"
          : ""
      } flex flex-row pt-2 pb-4 px-6 res150:pt-0 res125:px-3 res125:pb-3 res125:pt-1 res125:rounded-lg res150:px-3 res150:pb-2 hover:bg-[#F7FAFF] cursor-pointer rounded-2xl res150:rounded-xl shadow-md  shadow-[#dfe9fb]`}
      onClick={() => {
        handleSelectedMail(props, color);
      }}
    >
      <div
        className="w-9 h-9 mt-4 rounded-full pt-1.5 res150:w-7 res150:h-7 res150:pt-[5px] res150:text-xs text-center inline-block align-middle res125:text-sm res125:p-2"
        style={{ background: color }}
      >
        {from.replace(/[^a-zA-Z0-9]/g, "").charAt(0)}
      </div>
      <div className="flex flex-col w-5/6  ml-3">
        <div className="flex items-center mt-2">
          <span className="text-base text-[#011A59] font-bold mr-auto w-7/12 res125:text-sm res150:text-xs whitespace-nowrap text-ellipsis overflow-hidden ">
            {from.substring(0, from.indexOf("<"))}
          </span>
          <span className="text-[#011A59] italic text-sm res125:text-xs res150:text-xs font-normal px-0 py-1">
            {moment(time).format("MMM DD, YYYY")}
          </span>
        </div>
        <div className="flex items-center">
          <span className="clamp text-sm font-normal text-black mt-2 w-10/12 res125:text-xs res150:text-xs">
            {subject ? subject : "<No Subject>"}
          </span>
        </div>
      </div>
    </div>
  );
}
