import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import AppContext from "../../../context/AppContext";
import moment from "moment";

const CustomerCredentialsForm = ({ show, setShow, type }) => {
  // define state variables for input values
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setLoadTeamMember } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    // Get data from localStorage
    const storedData = JSON.parse(localStorage.getItem("Team_Credential"));

    // Update state variables with stored data
    if (storedData) {
      setEmail(storedData.email);
      setUsername(storedData.teamName);
      setPassword(storedData.password);
      setExpiryDate(moment(storedData.expired_at).format("YYYY-MM-DD"));
    }
  }, []);

  // define a function to handle input changes
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "username") {
      setUsername(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "expiry_date") {
      setExpiryDate(value);
    }
  };

  // Bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };
  // define a handleSubmit function to pass the input values to the payload
  const handleSubmit = async () => {
    if (!email | !username) {
      setError("Please fill all the fields.");
    } else if (type === "Account Details" && !password) {
      setError("Please enter password.");
    } else {
      setLoading(true);
      setIsDisabled(true);

      const payload = {
        team_id: JSON.parse(localStorage.getItem("Team_Credential"))?.userId,
        email,
        name: username,
        password,
        expired_at: expiryDate,
        customer_created: JSON.parse(localStorage.getItem("User")).id,
      };

      // pass the payload to an API endpoint
      await axios
        .post(`${BASE_URL}/api/customer/create`, payload, { headers })
        .then((res) => {
          setLoading(false);
          setIsDisabled(false);
          localStorage.removeItem("Team_Credential");
          toast.success("Updated Successfully");
          setLoadTeamMember(true);
          setShow(false);
        })
        .catch((error) => {
          console.error(error);
          setError(error.response.data.message);
          setLoading(false);
          setIsDisabled(false);
        });
    }
  };
  return (
    <div>
      <div className="mt-10 res125:mt-7 res150:mt-5">
        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 w-full rounded relative mb-4 text-center res125:py-1 res125:text-sm res150:py-1 res150:text-xs"
            role="alert"
          >
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <div className="w-full mb-3 res125:mb-1 res150:mb-0">
          <label
            htmlFor="Username"
            className="font-bold tracking-wider res125:text-sm res150:text-xs "
          >
            User Name
          </label>
          <input
            className="w-full px-4 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] mt-2 res125:py-1.5 res125:text-sm res150:py-1.5 res150:text-xs "
            type="text"
            placeholder="Name"
            name="username"
            value={username}
            onChange={handleInputChange}
          />
        </div>
        {type === "Account Details" && (
          <div className="w-full py-3 res125:py-2 res150:py-1">
            <label
              htmlFor="password"
              className="font-bold tracking-wider res125:text-sm res150:text-xs"
            >
              Password
            </label>
            <input
              className="w-full px-4 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] mt-2 res125:py-1.5 res125:text-sm res150:py-1.5 res150:text-xs"
              type="text"
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleInputChange}
            />
          </div>
        )}
        <div className="w-full py-3 res125:py-2 res150:py-1">
          <label
            htmlFor="Email"
            className="font-bold tracking-wider res125:text-sm res150:text-xs"
          >
            Mail Id
          </label>
          <input
            className="w-full px-4 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] mt-2 res125:py-1.5 res125:text-sm res150:py-1.5 res150:text-xs"
            type="email"
            placeholder="Email"
            name="email"
            value={email}
            onChange={handleInputChange}
          />
        </div>
        {type === "Account Details" && (
          <div className="w-full py-3 res125:py-1 res150:py-0">
            <label
              htmlFor="Date"
              className="font-bold tracking-wider res125:text-sm res150:text-xs"
            >
              Expiry Date
            </label>
            <input
              type="date"
              className="w-full px-4 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] mt-2 res125:py-1.5 res125:text-sm res150:py-1.5 res150:text-xs"
              value={expiryDate}
              name="expiry_date"
              onChange={handleInputChange}
              min={new Date().toISOString().split("T")[0]}
            />
          </div>
        )}
      </div>
      <div className="absolute top-[89%] left-[10%]">
        <div className="flex flex-row mx-[34px] res150:mx-7 gap-4">
          <div>
            <button
              className="w-[8rem] h-10  border border-[#03298C] hover:bg-[#03298C] hover:text-white hover:border-none  font-medium text-sm rounded res125:w-24 res125:h-8 res150:w-20 res150:h-7 res150:text-xs"
              onClick={() => {
                setShow(!show);
                localStorage.removeItem("Team_Credential");
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              type="submit"
              disabled={isDisabled}
              onClick={handleSubmit}
              className={`bg-[#03298C] w-[8rem] h-10 hover:bg-[#72C27A] text-white  res150:w-20 res150:h-7 res150:text-xs rounded res125:w-24 res125:h-8 ${
                isDisabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {loading ? (
                <div className="flex justify-center ">
                  <img
                    src="https://media1.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif"
                    className="w-[2rem] mx-auto res125:w-7 res150:w-6"
                    alt=""
                  />
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerCredentialsForm;
