import EmailDetails from "../../Mails/EmailDetails";
import AppContext from "../../../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import CreatePreviewProject from "./CreatePreviewProject";
import CreateProject from "./CreateProject";

const CreateProjectTagPreview = () => {
  const { selectedMail } = useContext(AppContext);

  return (
    <div className="flex ml-auto w-4/5 overflow-hidden h-screen">
      <div className="h-screen bg-indigo-200 w-5"></div>
      <EmailDetails showMailList={false} selectedMail={selectedMail} />
      <CreateProject className={"flex ml-0"}>
        <CreatePreviewProject />
      </CreateProject>
    </div>
  );
};

export default CreateProjectTagPreview;
