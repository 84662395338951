
import { useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import { toast } from "react-hot-toast";
import Loader from "../../../Loader";
import { Button, Modal, Select } from 'flowbite-react';
import { faL } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { subCategoriesOptions, verticalOptions, subVerticalOptions } from "../../../../src/constants/Constants"


const MicroserviceTable = () => {
    const [openModal, setOpenModal] = useState(false);
    const [fields, setFields] = useState([
        { category: "", subCategory: "", vertical: "", subVertical: "", microservices: "" },
    ]);
    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(false);
    const [microservice, setMicroservices] = useState([])
    const [showPopup, setShowPopup] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    // bearer token
    const headers = {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
    };

    function handleChange(i, field, value) {
        const values = [...fields];
        values[i][field] = value;
        setFields(values);
    }
    function handleAdd() {
        const values = [...fields];
        values.push({ category: "", subCategory: "", vertical: "", subVertical: "", microservices: "" });
        setFields(values);
    }
    function handleRemove(i) {
        const values = [...fields];
        values.splice(i, 1);
        setFields(values);
    }

    const checkFormValidity = () => {
        // Check if any field is empty
        const isAnyFieldEmpty = fields.some((field) =>
            Object.values(field).some((value) => !value)
        );
        setIsFormValid(!isAnyFieldEmpty);
    };
    useEffect(() => {
        checkFormValidity()
    }, [fields])

    const submitHandler = async (event) => {
        event.preventDefault();
        checkFormValidity();

        if (!isFormValid) {
            toast.error('Please fill all the fields');
            return;
        }

        let microservicesArray = [];

        fields.forEach((field) => {
            microservicesArray.push({
                category: field.category,
                sub_category: field.subCategory,
                vertical: field.vertical,
                sub_vertical: field.subVertical,
                microservice: field.microservices,
            })
        });


        const payload = {
            microservices: microservicesArray,
        };

        await axios
            .post(`${BASE_URL}/api/createMicroservice`, payload, {
                headers,
            })
            .then((res) => {
                setError(null)
                toast.success("save Successfully !");
            })
            .catch((err) => {
                // console.log(err.response);
                toast.error(err?.response?.data?.message || "Error Creating Microservice");
            });
    };

    const getMicroService = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${BASE_URL}/api/getAllService`, {
                headers,
            });
            setMicroservices(res.data.Allmicroserivce);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.error('Error fetching microservices:', err);
        }
    };


    // useEffect(() => {
    //     getMicroService();
    // }, []);

    useEffect(() => {
        if (openModal === false) {
            getMicroService();
        }

    }, [openModal]);


    const handleDelete = async (_id) => {
        try {
            const res = await axios.delete(
                `${BASE_URL}/api/DeleteMicroService?_id=${_id}`, { headers }
            );
            toast.success(res.data.message);
            console.log("first");
            getMicroService();
        } catch (error) {
            console.log(error);
        }
    };



    return (
        <>
            <div className="w-8/12 h-auto mr-28 ml-auto mt-24 res125:mt-20 res150:mt-16 res150:mr-20 res125:mr-24 pb-10">
                <div className="w-full h-11 flex mt-1">
                    <div className="text-base font-bold py-2.5  ">
                        Add Micro Services
                    </div>

                    <div className="ml-auto">
                        <button className="p-2 bg-[#0d2970] text-white rounded" onClick={() => setOpenModal(true)}>Add Microservices</button>
                        <Modal show={openModal} size="7xl" onClose={() => setOpenModal(false)}>
                            <Modal.Header>Add Microservices</Modal.Header>
                            {/* {error && (
                                <div
                                    className="bg-red-100 border res125:text-sm border-red-400 text-red-700 px-4 py-3  res150:py-2 res125:py-1.5 res125:h-9 res150:h-8 res150:text-xs text-center w-full rounded relative mb-4 res150:mb-2"
                                    role="alert"
                                >
                                    <span className="block sm:inline">{error}</span>
                                </div>
                            )} */}
                            <Modal.Body>
                                <div className="space-y-6 p-6">
                                    <form onSubmit={submitHandler}>


                                        {fields.map((field, idx) => (
                                            <div key={`${idx}`} className="mb-4 p-1 border rounded">
                                                <select
                                                    className="mr-2 mb-2 py-2 border rounded"
                                                    value={field.category}
                                                    onChange={(e) => handleChange(idx, "category", e.target.value)}
                                                >
                                                    <option value="">Select Category</option>
                                                    <option value="Process Plants">Process Plants</option>
                                                </select>

                                                <select
                                                    className="m-2 mb-2 py-2 border rounded"
                                                    value={field.subCategory}
                                                    onChange={(e) => handleChange(idx, "subCategory", e.target.value)}
                                                >
                                                    <option value="">Select Sub-Category</option>
                                                    {subCategoriesOptions.map((option, index) => (

                                                        <option key={index} value={option.value}>
                                                            {option.value}
                                                        </option>
                                                    ))}
                                                </select>

                                                <select
                                                    className="mr-2 mb-2 py-2 border rounded"
                                                    value={field.vertical}
                                                    onChange={(e) => handleChange(idx, "vertical", e.target.value)}
                                                >
                                                    <option value="">Select Vertical</option>
                                                    {verticalOptions.map((option, index) => (

                                                        <option key={index} value={option.value}>
                                                            {option.value}
                                                        </option>
                                                    ))}
                                                </select>
                                                <select
                                                    className="mr-2 mb-2 py-2 border rounded"
                                                    value={field.subVertical}
                                                    onChange={(e) => handleChange(idx, "subVertical", e.target.value)}
                                                >
                                                    <option value="">Select Sub Vertical</option>
                                                    {subVerticalOptions.map((option, index) => (

                                                        <option key={index} value={option.value}>
                                                            {option.value}
                                                        </option>
                                                    ))}
                                                </select>

                                                <input
                                                    type="text"
                                                    placeholder="Enter Microservices"
                                                    value={field.microservices}
                                                    onChange={(e) => handleChange(idx, "microservices", e.target.value)}
                                                />

                                                <button className="m-2  p-1 bg-red-500 text-white rounded" type="button" onClick={() => handleRemove(idx)}>
                                                    REMOVE
                                                </button>
                                            </div>
                                        ))}
                                        <div className="flex justify-between mt-4">
                                            <button
                                                className={`p-2 bg-[#0d2970] text-white rounded ${isFormValid ? '' : 'cursor-not-allowed opacity-50'}`}
                                                type="submit"
                                                disabled={!isFormValid} // Disable the button based on form validity
                                            >
                                                Submit
                                            </button>

                                            <button
                                                className="p-2 bg-blue-500 text-white rounded"
                                                type="button"
                                                onClick={() => handleAdd()}
                                            >
                                                Add More
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                {/* <Button onClick={() => setOpenModal(false)}>I accept</Button>
                                <Button color="gray" onClick={() => setOpenModal(false)}>
                                    Decline
                                </Button> */}
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
                <hr className="w-full border border-[#C8C8C8] my-3 res150:mt-0 res125:mt-0" />
                {loading ? (
                    <Loader className="mx-auto" />
                ) : (
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-6 re res150:mt-4">
                        <table className="text-sm text-center text-black w-full">
                            <thead className="text-base text-[#03298C] bg-[#ECFFFF] res125:text-sm res150:text-xs">
                                <tr>
                                    <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                                        S No
                                    </th>
                                    <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                                        Micro Service
                                    </th>
                                    <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                                        Sub Category
                                    </th>
                                    <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                                        Vertical
                                    </th>
                                    <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                                        Sub Vertical
                                    </th>
                                    <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {microservice.length === 0 ? (
                                    <tr>
                                        <td
                                            colSpan="6"
                                            className="px-6 py-4 text-center text-base res125:text-sm res150:text-xs"
                                        >
                                            No data
                                        </td>
                                    </tr>
                                ) : (
                                    microservice.map((value, index) => (
                                        <tr
                                            className="bg-white border-b text-center res150:text-xs"
                                            key={index}
                                        >
                                            <td className="px-6 py-4 res150:px-4 res150:py-3">{index + 1}</td>
                                            <td className="px-6 py-4 res150:px-4 res150:py-3">{value.microservice}</td>
                                            <td className="px-6 py-4 res150:px-4 res150:py-3">{value.sub_category}</td>
                                            <td className="px-6 py-4 res150:px-4 res150:py-3">{value.vertical}</td>
                                            <td className="px-6 py-4 res150:px-4 res150:py-3">{value.sub_vertical}</td>
                                            <td className="">
                                                <i
                                                    className="fa fa-2x fa-trash px-2 py-2 text-red-500 cursor-pointer"
                                                    aria-hidden="true"
                                                    onClick={() => {
                                                        swal({
                                                            title:
                                                                "Are you sure you want to delete this microservice?",
                                                            text: "Once deleted, you will not be able to recover this item!",
                                                            icon: "warning",
                                                            buttons: {
                                                                cancel: "No",
                                                                confirm: "Yes",
                                                            },
                                                            dangerMode: true,
                                                        }).then((willDelete) => {
                                                            if (willDelete) {
                                                                handleDelete(value._id);
                                                                swal(
                                                                    "Success!",
                                                                    "Item has been deleted successfully!",
                                                                    "success"
                                                                );
                                                            } else {
                                                                swal("Your item is safe!");
                                                            }
                                                        });
                                                    }}
                                                ></i>
                                            </td>


                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </>
    );
}

export default MicroserviceTable;
