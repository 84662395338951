import React, { useContext } from "react";
import { ReactComponent as ContactIcon } from "../../../assets/svg/contact.svg";
import AppContext from "../../../context/AppContext";

export default function DetailsCard({ projectData }) {
  const { type } = useContext(AppContext);
  return (
    <div>
      {projectData && type === "admin" && (
        <div className=" h-20  ml-16 mt-6 res125:ml-14 res150:ml-16 res150:mt-3">
          <div className="flex flex-col">
            <div className="h-10 w-[40%] flex flex-row">
              <div className="text-[#011A59] font-bold text-xl tracking-wide pt-1 res125:text-lg res150:text-sm" title={projectData?.company?.name}>
                {/* {projectData?.company?.name} */}
                {projectData?.company?.name?.length > 10 ? projectData.company.name.slice(0, 10) + '...' : projectData?.company?.name}

              </div>
              <div className="ml-4 bg-[#FFCECE] mt-1  mb-1.5 rounded-2xl w-24 font-normal text-[13px] text-center pt-[5px] text-[#F16868] res150:text-xs res150:mb-2 res150:w-20 ">
                Industry
              </div>
              <div
                className="ml-4 mt-1 mb-1.5 rounded-2xl w-24 font-normal text-[13px] text-center pt-[5px] res150:text-xs res150:mb-2 res150:w-20"
                style={
                  projectData?.customerStatus === "Accepted"
                    ? { backgroundColor: "#E2FFED", color: "#06AC5D" }
                    : projectData?.customerStatus === "Rejected"
                      ? { backgroundColor: "#FFEDEC", color: "#D66868" }
                      : projectData?.customerStatus === "Pending"
                        ? { backgroundColor: "#E8EFFE", color: "#3356C1" }
                        : {}
                }
              >
                {projectData?.customerStatus}
              </div>

            </div>
            <div className="flex flex-row">
              <div className="h-10 w-auto flex flex-row mr-8">
                <div className="pt-1.5 res150:pt-0 pr-2">
                  <ContactIcon />
                </div>
                <div className="text-black font-normal text-base pt-1 res150:pt-0 res150:text-xs">
                  {projectData?.company?.contact_no}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {projectData && type === "service-provider" && (
        <div className="flex flex-col">
          <div className="h-10 w-[40%] flex flex-row">
            <div className="text-[#011A59] font-bold text-xl tracking-wide pt-1 res125:text-lg res150:text-sm" title={projectData?.customer?.name}>
              {projectData?.customer?.name}
              {projectData?.customer?.name.length > 10 ? projectData.customer.name.slice(0, 10) + '...' : projectData?.customer?.name}
            </div>
            <div className="ml-4 bg-[#FFCECE] mt-1  mb-1.5 rounded-2xl w-24 font-normal text-[13px] text-center pt-[5px] text-[#F16868] res150:text-xs res150:mb-2 res150:w-20">
              Industry
            </div>
          </div>
          <div className="flex flex-row">
            <div className="h-10 w-auto flex flex-row mr-8">
              <div className="pt-1.5 res150:pt-0 pr-2">
                <ContactIcon />
              </div>
              <div className="text-black font-normal text-base pt-1 res150:pt-0 res150:text-xs">
                {projectData?.customer?.contact_no}
              </div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}
