import { useEffect, useState } from "react";
import NewCredentialsDrawer from "../../../Shared/PopupDrawer/NewCredentialsDrawer";
import axios from "axios";
import { BASE_URL } from "../../../../constants/Constants";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Loader from "../../../../Loader";

const Credentials = () => {
  const navigate = useHistory();
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [responseData, setResponseData] = useState();
  const [loading, setLoading] = useState("false");

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    getCredentials();
  }, [show]);

  const getCredentials = async () => {
    setLoading(true);
    const pathname = navigate.location.pathname;
    const array = pathname.split("/");
    const project_id = array[array.length - 1];
    const params = {
      project_id: project_id,
    };
    await axios
      .get(`${BASE_URL}/api/customers/credentials`, { params, headers })
      .then((res) => {
        setResponseData([...res.data.message]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleEdit = (value, type) => {
    localStorage.setItem("SELECTED_CREDENTIAL", JSON.stringify(value));
    setType(type);
    setShow(true);
  };

  return (
    <div>
      {loading ? (
        <Loader className="mx-auto" />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-6 res150:mt-4">
          <table className="w-full text-center text-black res125:text-sm res150:text-xs">
            <thead className="text-base text-[#03298C] bg-[#ECFFFF] res125:text-sm text-center res150:text-xs">
              <tr>
                <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                  S.No
                </th>
                <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3 ">
                  Email
                </th>
                <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                  Password
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 text-center res150:px-4 res150:py-3"
                >
                  Expiry Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 text-center res150:px-4 res150:py-3"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 text-center res150:px-4 res150:py-3"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {responseData.map((value, index) => (
                <tr className="bg-white border-b text-center" key={index}>
                  <td className="px-6 py-4 res150:px-4 res150:py-3">
                    {index + 1}
                  </td>
                  <th
                    scope="row"
                    className="px-6 py-4 whitespace-nowrap font-bold res150:px-4 res150:py-3"
                  >
                    <div className="flex flex-col">
                      <p>{value.name}</p>
                    </div>
                  </th>

                  <td className="px-6 py-4 res150:px-4 res150:py-3">
                    {value.email}
                  </td>
                  <td className="px-6 py-4 res150:px-4 res150:py-3">
                    {value.password}
                  </td>
                  <td className="px-6 py-4 text-center res150:px-4 res150:py-3">
                    {value.expired_at === null
                      ? "-"
                      : moment(value?.expired_at).format("DD-MMM-YYYY")}
                  </td>

                  <td className="px-6 py-4 text-center res150:px-4 res150:py-3">
                    {value.is_active ? (
                      <span className="bg-green-500 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded mx-2.5">
                        Active
                      </span>
                    ) : (
                      <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
                        Expired
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 cursor-pointer text-center res150:px-4 res150:py-3">
                    {value.password ? (
                      <a
                        className="font-medium text-blue-600  hover:underline"
                        onClick={() => handleEdit(value, "Edit Credentials")}
                      >
                        Edit
                      </a>
                    ) : (
                      <a
                        href="#"
                        className="font-medium text-blue-600 hover:underline"
                        onClick={() =>
                          handleEdit(value, "Create New Credentials")
                        }
                      >
                        Create
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {show && (
        <NewCredentialsDrawer
          show={show}
          setShow={setShow}
          type={type}
          cred_type="Credentials"
        />
      )}
    </div>
  );
};

export default Credentials;
