import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import { toast } from "react-hot-toast";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import "../../../App.css";
import jsPDF from "jspdf";
import { useHistory } from "react-router-dom";

// import 'jspdf-autotable';
import { componentsToColor } from "pdf-lib";

const EditQuotations = (props) => {
  const navigate = useHistory();

  const { show, setShow, projectData } = props;
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [availableScopes, setAvailableScopes] = useState([]);
  const [awss3file, setAwss3file] = useState([]);
  const [uploadObj, setUploadObj] = useState({
    file: "",
    quantity: "",
    price: "",
  });

  const [pdfdata, setPdfData] = useState({
    vendor_id: JSON.parse(localStorage.getItem("User")).id,
  });

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  //

  const fileUpload = async () => {
    let success = false;
    const s3Client = new S3Client({
      region: "ap-south-1",
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    });
    const params = {
      Bucket: "factreehub-docs",
      Key: `serviceProviderOrder/${awss3file[0].name}`,
      ContentType: awss3file[0].type,
      Body: awss3file[0],
    };
    try {
      const putObjectCommand = new PutObjectCommand(params);
      const reponse = await s3Client.send(putObjectCommand);
      toast.success("File uploaded successfully!");
      success = true;
    } catch (error) {
      console.error(error);
      toast.error("Please Try Again");
      success = false;
    }
    if (!success) {
      setSelectedFile();
    }
  };

  useEffect(() => {
    if (awss3file.length > 0) {
      fileUpload();
    }
  }, [awss3file]);

  // const handleDropdown = ({ target: { name, value } }) => {
  //   let obj = { ...uploadObj };
  //   obj[name] = value;
  //   if (name === "tag_id") {

  //     const selectedTag = projectData.tags.find((tag) => tag._id === value);
  //     if (selectedTag) {
  //       const filteredScopes = selectedTag.technical_scopes.filter(
  //         (scope) => scope.vendorStatus === "Accepted"
  //       );
  //       setAvailableScopes(filteredScopes);
  //       obj["scope_id"] = "";
  //     }
  //   }

  //   setUploadObj(obj);

  // };
  const handlePricingChange = (e) => {
    const value = e.target.value;
    setUploadObj((prevObj) => ({ ...prevObj, price: value }));
  };
  const handleQuantityChange = (e) => {
    const value = e.target.value;
    setUploadObj((prevObj) => ({ ...prevObj, quantity: value }));
  };
  // useEffect(() => {
  //   console.log("uploadObj", uploadObj);
  // }, [uploadObj]);

  const getQuotation = async () => {
    try {
      const pathname = navigate.location.pathname.split("/");
      const project_id = pathname[pathname.length - 1];
      const user = JSON.parse(localStorage.getItem("User"));
      const params = {
        vendor_id: user.id,
        project_id,
      };

      const response = await axios.get(`${BASE_URL}/api/quotations`, {
        params,
        headers,
      });
      const datas = response.data.message;

      // Return the quotations data
      return datas.quotations;
    } catch (err) {
      console.error("Error fetching quotations:", err);
      throw err; // Propagate the error up to the caller if needed
    }
  };

  let blob;
  const user = JSON.parse(localStorage.getItem("User"));

  const generateAndUploadPDF = async () => {
    // const pdf = new jsPDF();

    const generateRandomString = async (length) => {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    };

    let baseFileName = "EditserviceProvider";
    let version = await generateRandomString(Math.floor(Math.random() * 3) + 4); // Random length between 4 and 6 characters

    let fileName = `${baseFileName}_${version}.pdf`;
    return fileName;
    // const existingQuotations = await getQuotation();
    // // console.log(existingQuotations, "existingQuotations")
    // const existingFileNames = existingQuotations.map((quotation) => quotation.file);

    // let fileName = "EditQuotation.pdf";
    // let version = 1;
    // while (existingFileNames.includes(fileName)) {
    //   version += 1;
    //   fileName = `quotation_v${version}.pdf`;
    // }

    // pdf.text("MAAS Factree Solution Private Limited", 90, 10);
    // pdf.text("B-70/1, SIPCOT Industrial Park,", 90, 20);
    // pdf.text("irungattukottai Sriperumbudur,", 90, 30);
    // pdf.text("Tamilnadu - 602105", 90, 40);
    // pdf.text("Email: info@factreehub.com", 90, 50);

    // pdf.setLineWidth(0.5);
    // pdf.line(10, 60, 200, 60);

    // pdf.text("GST IN : 33AAQCM2060H1ZJ                         PAN No.: AAQCM2060H                     IEC CODE : AAQCM2060H", 10, 65);
    // pdf.text("CIN No.: -", 10, 70);

    // pdf.line(10, 80, 200, 80);

    // pdf.text("QUOTATION", 40, 90);
    // pdf.line(10, 95, 200, 95);

    // pdf.text(`Customer Name: ${user.name}`, 10, 105);
    // pdf.text(`Contact Number: ${user.contact_no}`, 10, 115);
    // pdf.text(`Email: ${user.email}`, 10, 125);

    // pdf.line(10, 140, 200, 140);

    // pdf.text("Tag Name", 10, 150);
    // pdf.text("Quantity", 50, 150);
    // pdf.text("Unit Price", 90, 150);
    // pdf.text("Total Price", 130, 150);

    // // ... (add your custom text here)

    // // Calculation
    // const Price = uploadObj.price; // Calculate your total amount here
    // const Qty = uploadObj.quantity; // Calculate your total amount here
    // const totalAmount = Price * Qty; // Calculate your total amount here

    // pdf.text(`${projectData.tag_name}`, 10, 160); // Adjust the position as needed
    // pdf.text(`${Qty}`, 50, 160);
    // pdf.text(`${Price}`, 90, 160);
    // pdf.text(`${totalAmount}`, 130, 160);

    // pdf.text('GST 18%:', 170, 190);

    // const totalWithGST = totalAmount + (totalAmount * 18) / 100;
    // pdf.text(`Total with GST: ${totalWithGST}`, 145, 200);

    // const pdfBase64 = pdf.output("datauristring");
    // const binaryString = atob(pdfBase64.split(",")[1]);
    // const uint8Array = new Uint8Array(binaryString.length);

    // for (let i = 0; i < binaryString.length; i++) {
    //   uint8Array[i] = binaryString.charCodeAt(i);
    // }
    // const blob = new Blob([uint8Array], { type: "application/pdf" });

    // if (blob) {
    //   blob.name = fileName;
    //   uploadObj.file = fileName;
    //   setAwss3file([blob]);
    //   // blob.name = `Quotation.pdf`;
    //   // uploadObj.file = blob.name
    //   setAwss3file([blob]);
    // }
  };

  const editsubmit = async () => {
    if (!uploadObj.price) {
      setError("Fill the pricing !");
    } else if (!uploadObj.quantity) {
      setError("Fill the quantity !");
    } else {
      setLoading(true);
      setIsDisabled(true);

      uploadObj.file = await generateAndUploadPDF();

      const updateQuotationsPrice = {
        price: uploadObj.price,
        file: uploadObj.file,
        quantity: uploadObj.quantity,
      };
      axios
        .put(
          `${BASE_URL}/api/quotations/updatePrice/${projectData?.id}`,
          updateQuotationsPrice,
          { headers }
        )
        .then((_res) => {
          // Handle successful response
          setShow(false);
          setIsDisabled(false);
          setLoading(false);
          toast.success("Quotation Price Updated Successfully !");
        })
        .catch((err) => {
          // Handle error
          console.log(err);
          setIsDisabled(false);
          setLoading(false);
          toast.error("Failed to update quotation price. Please try again.");
        });
      // }
    }
  };
  return (
    <div>
      {/* Overlay */}
      {show ? (
        <div className="bg-black/50 fixed w-full h-full z-10 top-0 right-0"></div>
      ) : (
        ""
      )}

      {/* Side drawer*/}
      <div
        className={
          show
            ? "fixed top-0 right-0 w-[630px] res125:w-[30.5rem] res150:w-[26.5rem] h-screen z-10 duration-300"
            : "fixed top-0 right-[-100%] w-[630px] h-screen z-10 duration-300"
        }
        style={{
          background: "linear-gradient(90deg, #F8F8F8 0%, #FFFFFF 74.69%)",
        }}
      >
        <AiOutlineClose
          onClick={() => setShow(!show)}
          size={20}
          className="absolute right-5 top-5 cursor-pointer"
        ></AiOutlineClose>
        <div className="h-[490px] mt-5 ml-[43px] w-[550px] res125:w-[25rem] res150:w-80">
          <div className="font-bold text-[#011A59] text-xl tracking-wide text-center mb-5 res150:mb-3  mt-20 res150:mt-16 res125:text-base res150:text-base">
            Edit Quotation
          </div>
          {error && (
            <div
              className="bg-red-100 border w-full mx-auto border-red-400 text-red-700 px-4 py-3  rounded relative mb-2 text-center res150:py-1.5 res125:w-[25rem] res125:py-1.5 res125:text-sm res150:w-[21rem] res150:text-xs"
              role="alert"
            >
              <span class="block sm:inline">{error}</span>
            </div>
          )}

          <label
            for="large"
            class="mt-5 res150:mt-3 block mb-2 text-base font-medium text-gray-900 dark:text-white res150:text-xs res125:text-sm"
          >
            Tag Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={projectData.tag_name}
            placeholder=""
            disabled
            className="block w-full px-4 py-3 res125:py-1.5 res125:w-[25rem] res125:text-sm res125:rounded res150:px-3 res150:w-[21rem] res150:py-2 text-base text-gray-900 border border-gray-300 res150:rounded rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 res150:text-xs"
          />

          <label
            for="large"
            class="mt-5 res150:mt-3 block mb-2 text-base font-medium text-gray-900 dark:text-white res150:text-xs res125:text-sm"
          >
            Scope Name
          </label>
          <input
            type="text"
            id="scope_name"
            name="scope_name"
            value={projectData.scope_name}
            placeholder="Enter price details"
            disabled
            className="block w-full px-4 py-3 res125:py-1.5 res125:w-[25rem] res125:text-sm res125:rounded res150:px-3 res150:w-[21rem] res150:py-2 text-base text-gray-900 border border-gray-300 res150:rounded rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 res150:text-xs"
          />
          <label
            for="large"
            class="mt-5 res150:mt-3 block mb-2 text-base font-medium text-gray-900 dark:text-white res150:text-xs res125:text-sm"
          >
            Pricing
          </label>
          <input
            type="text"
            id="price"
            name="price"
            onChange={handlePricingChange}
            value={uploadObj.price}
            placeholder={projectData.price}
            className="block w-full px-4 py-3 res125:py-1.5 res125:w-[25rem] res125:text-sm res125:rounded res150:px-3 res150:w-[21rem] res150:py-2 text-base text-gray-900 border border-gray-300 res150:rounded rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 res150:text-xs"
          />
          <label
            for="large"
            class="mt-5 res150:mt-3 block mb-2 text-base font-medium text-gray-900 dark:text-white res150:text-xs res125:text-sm"
          >
            quantity
          </label>
          <input
            type="text"
            id="quantity"
            name="quantity"
            onChange={handleQuantityChange}
            value={uploadObj.quantity}
            placeholder={projectData.quantity}
            className="block w-full px-4 py-3 res125:py-1.5 res125:w-[25rem] res125:text-sm res125:rounded res150:px-3 res150:w-[21rem] res150:py-2 text-base text-gray-900 border border-gray-300 res150:rounded rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 res150:text-xs"
          />

          <div className="flex flex-row  fixed bottom-10 right-10">
            <div
              className="ml-auto border border-[#03298C] w-28 mt-32 p-2 text-center hover:text-white hover:bg-[#03298C] rounded-sm cursor-pointer res125:w-24 res125:text-sm res150:w-20 res150:text-xs"
              onClick={() => setShow(!show)}
            >
              Cancel
            </div>

            <button
              type="submit"
              disabled={isDisabled}
              onClick={editsubmit}
              className={`ml-4 border border-[#03298C] w-52 mt-32 res150:w-44 res150:text-xs  text-center hover:text-white hover:bg-[#03298C] res125:w-40 res125:text-sm rounded-sm cursor-pointer ${
                isDisabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {loading ? (
                <div className="flex justify-center ">
                  <img
                    src="https://media1.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif"
                    className="w-[2rem] mx-auto res125:w-9 res150:w-7"
                    alt=""
                  />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditQuotations;
