import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Home = () => {
  const navigate = useHistory();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("User"));
    if (user) {
      if (user.is_admin) {
        navigate.push("/admin/mails");
      } else if (user.is_freelancer || user.is_company) {
        navigate.push("/service-provider/projects");
      } else if (user.is_customer) {
        navigate.push("/customer/projects");
      }
    } else {
      navigate.push("/auth/signin");
    }
  }, []);

  return null;
};

export default Home;
