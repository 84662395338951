import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../../constants/Constants";
import ProjectCollaborations from "./ProjectsCollaborations";
import Table from "../../Shared/Table/Table";
import AppContext from "../../../context/AppContext";
import Loader from "../../../Loader";

export default function DiagramTable({ projectData }) {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useHistory();
  const [showAnnotate, canShowAnnotate] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const { setComments, type, loadProject, setLoadProject } =
    useContext(AppContext);
  const [filter, setFilter] = useState("All");

  useEffect(() => {
    if (type) {
      getDiagram();
    }
    setLoadProject(false);
  }, [type, filter, loadProject]);

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const getDiagram = async () => {
    const pathname = navigate.location.pathname.split("/");
    const project_id = pathname[pathname.length - 1];
    const params = {
      project_id,
      filter,
    };
    setLoading(true);
    await axios
      .get(`${BASE_URL}/api/admin/diagrams`, { params, headers })
      .then((res) => {
        const datas = res.data.message;
        let array = [];
        for (const diagram of datas) {
          array.push({
            id: diagram._id,
            diagram: diagram.file,
            tag_id: diagram.tag._id,
            tag: diagram.tag.name,
            scope_id: diagram.scope._id,
            scope: diagram.scope?.microservice,
            createddate: moment(diagram.created_at).format(
              "DD-MM-YYYY hh:mm a"
            ),
            created_at: diagram.created_at,
            created_by: diagram.created_by,
            status: diagram.adminStatus,
            comments: JSON.parse(diagram.comments) || [],
            vendor_id: diagram.vendor_id,
            adminStatus: diagram.adminStatus,
            vendor: { ...diagram.vendor },
            sub_vertical: diagram.tag.sub_vertical,
          });
          setLoading(false);
        }
        setLoading(false);
        setData([...array]);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getFileUrl = async (filename, comments, viewData) => {
    const params = {
      filter: "diagram",
      name: filename,
    };
    await axios
      .get(`${BASE_URL}/api/file-url`, { params, headers })
      .then((res) => {
        setPdfUrl(res?.data?.message);
        setFileName(filename);
        viewData.diagram.endsWith(".pdf")
          ? canShowAnnotate(true)
          : window.open(res?.data?.message);
        setSelectedData(viewData);
      })
      .catch((err) => { });
    setComments(viewData.comments);
  };

  const handleDelete = async (id) => {
    const params = { id };
    await axios
      .delete(`${BASE_URL}/api/diagram/delete`, { params, headers })
      .then((res) => {
        getDiagram();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAnnotate = () => {
    canShowAnnotate(false);
  };

  const handleShowPopup = (data) => {
    setSelectedData({ ...data });
    setIsOpen(true);
  };

  const Theaders = [
    "File Name",
    "Tag",
    "Microservice",
    "Created date",
    "Created By",
    "status",
  ];

  useEffect(() => {
    if (isOpen === false) {
      getDiagram();
    }
  }, [isOpen]);

  const handleSelectedData = (datas) => {
    setSelectedData(datas);
  };

  const pathname = navigate.location.pathname.split("/");
  const project_id = pathname[pathname.length - 1];

  return (
    <div className="mt-5 res125:mt-4 res150:mt-3 overflow-y-auto">
      {loading ? (
        <Loader className="mx-auto" />
      ) : (
        <>
          {showAnnotate ? (
            <ProjectCollaborations
              fileName={fileName}
              pdfUrl={pdfUrl}
              handleAnnotate={handleAnnotate}
              selectedData={selectedData}
              handleSelectedData={handleSelectedData}
              project_id={project_id}
              projectData={projectData}
            />
          ) : (

            <>

              <div className="flex items-center">
                <label
                  className="block mr-4 ml-auto mb-3 res125:text-sm res150:text-xs"
                  htmlFor="category"
                >
                  Filter For Created By:
                </label>
                <select
                  className={` mb-5 res150:mb-3 block appearance-none w-[16rem] h-12 res125:w-48 res125:h-10 res125:text-sm res150:w-40 res150:text-xs res150:h-8 bg-white border border-[#C8C8C8] px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline`}
                  id="category"
                  name="category"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  required
                >
                  <option value="All">All</option>
                  <option value="admin">FactreeHub</option>
                  <option value="sp">Service Provider</option>
                </select>
              </div>
              <Table
                headers={Theaders}
                data={data}
                showActions={false}
                status={true}
                getFileUrl={getFileUrl}
                handleShowPopup={handleShowPopup}
                selectedData={selectedData}
                handleSelectedData={handleSelectedData}
                project_id={project_id}
                projectDataTab={projectData}
                getDiagram={getDiagram}
              />

            </>
          )}
        </>
      )}
    </div>
  );
}
