import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  BASE_URL,
  primaryScopeOptions,
  subCategoryOptions,
} from "../../../constants/Constants";
import { toast } from "react-hot-toast";
import countryCodesData from "../../../assets/json/CountryCode.json";

export default function CompanySetting({ filename }) {
  const [email, setEmail] = useState("");
  const [gst, setGst] = useState("");
  const [hours, setHours] = useState("");
  const [primaryScope, setPrimaryScope] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyContact, setCompanyContact] = useState("");
  const navigate = useHistory();
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subCategory, setSubCategory] = useState("");
  const [category, setCategory] = useState("Process Plants");
  const [technicalScope, setTechnicalScope] = useState([]);
  const [technicalScopeOptions, setTechnicalScopeOptions] = useState([]);
  const [showTechScope, canShowTechScope] = useState(false);
  const [countryCodes, setCountryCodes] = useState(countryCodesData.countries);
  const [selectedOption, setSelectedOption] = useState("+91");
  const multiselectRef = useRef();
  const [enterKeyPressed, setEnterKeyPressed] = useState(false);
  const submitButtonRef = useRef();

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    // Get data from localStorage
    const storedData = JSON.parse(localStorage.getItem("User"));

    // Update state variables with stored data
    if (storedData) {
      setEmail(storedData.email);
      setCompanyName(storedData.company?.name);
      setCompanyContact(storedData.company?.contact_no);
      if (storedData?.contact_no) {
        const [countryCode, phoneNumber] = storedData.contact_no.split(" - ");
        setSelectedOption(countryCode);
        setCompanyContact(phoneNumber);
      }
    }
  }, []);

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "email") {
      setEmail(value);
    } else if (name === "primary") {
      setPrimaryScope(value);
    } else if (name === "hours") {
      setHours(value);
    } else if (name === "companyname") {
      setCompanyName(value);
    } else if (name === "companycontact") {
      setCompanyContact(value);
    } else if (name === "gst") {
      setGst(value);
    } else if (name === "subCategory") {
      setSubCategory(value);
    }
  };

  const handleCancel = () => {
    navigate.push("/service-provider/projects");
  };

  const storedData = JSON.parse(localStorage.getItem("User"));

  useEffect(() => {
    const params = { vendor_id: storedData.id };
    axios
      .get(`${BASE_URL}/api/vendor/details`, { params, headers })
      .then((res) => {
        setGst(res.data.data.gst);
        setSubCategory(res.data.data.sub_category);
        setPrimaryScope([...JSON.parse(res.data.data.primary_scope)]);
        setHours(res.data.data.totalhourstoworks);
        setTechnicalScope([...JSON.parse(res.data.data.technical_scope)]);
      });
  }, []);

  const techScopes = async () => {
    await axios
      .get(`${BASE_URL}/api/tech-scopes`, {
        params: {
          primary_scope_type: JSON.stringify(primaryScope),
          sub_category_type: subCategory,
        },
      })
      .then((res) => {
        setTechnicalScopeOptions([...res.data.message]);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/tech-scopes`, {
        params: {
          primary_scope_type: JSON.stringify(primaryScope),
          sub_category_type: subCategory,
        },
      })
      .then((res) => {
        setTechnicalScopeOptions([...res.data.message]);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [primaryScope, subCategory]);

  const dropDownChange = ({ target: { name, value } }) => {
    switch (name) {
      case "category":
        setCategory(value);
        setSubCategory("");
        setPrimaryScope([]);
        setTechnicalScope([]);
        break;
      case "subCategory":
        setSubCategory(value);
        setPrimaryScope([]);
        setTechnicalScope([]);
        break;
      default:
        break;
    }
    resetSelectField();
  };

  const resetSelectField = () => {
    multiselectRef.current?.resetSelectedValues();
  };

  const handleSelect = (selectedList) => {
    setTechnicalScope(selectedList);
  };

  const handleRemove = (selectedList) => {
    setTechnicalScope(selectedList);
  };

  const handlePSSelect = (selectedList) => {
    if (selectedList.length > 0) {
      canShowTechScope(true);
    } else {
      canShowTechScope(false);
    }
    techScopes();
    let existingUser = primaryScope;
    existingUser = selectedList;
    setPrimaryScope(existingUser);
    setTechnicalScope([]);
  };

  const handlePSRemove = (selectedList) => {
    if (selectedList.length > 0) {
      canShowTechScope(true);
    } else {
      canShowTechScope(false);
    }
    techScopes();
    let existingUser = primaryScope;
    existingUser = selectedList;
    setPrimaryScope(existingUser);
    setTechnicalScope([]);
  };

  useEffect(() => {
    if (primaryScope.length > 0) {
      canShowTechScope(true);
    }
  }, [primaryScope]);

const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      setEnterKeyPressed(true);
      event.preventDefault(); // Prevent form submission if necessary
    }
  };

  // define a handleSubmit function to pass the input values to the payload
  const handleSubmit = async () => {
    if (
      !email ||
      !companyName ||
      !companyContact ||
      !category ||
      !subCategory ||
      !primaryScope.length ||
      !hours ||
      !technicalScope.length ||
      !gst
    ) {
      setError("Please fill the all fields");
    } else {
      const payload = {
        email,
        profile_picture: filename,
        category,
        companyName,
        companyContact: `${selectedOption} - ${companyContact}`,
        primary_scope: primaryScope,
        totalhourstoworks: hours,
        technical_scope: technicalScope,
        gst,
        subCategory,
        vendor_id: storedData.id,
      };

      setError("");
      setLoading(true);
      setIsDisabled(true);
      await axios
        .put(`${BASE_URL}/api/company/update`, payload, { headers })
        .then((res) => {
          const updatedUser = {
            ...storedData,
            email: res.data.user.email,
            name: res.data.user.name,
            contact_no: res.data.user.contact_no,
            profile_picture: res.data.user.profile_picture,
          };
          localStorage.setItem("User", JSON.stringify(updatedUser));
          setLoading(false);
          setIsDisabled(false);
          toast.success("Profile updated successfully");
        })
        .catch((err) => {
          setLoading(false);
          setIsDisabled(false);
          toast.error("Profile Not Updated");
          setError(err.response.data.message);
          console.error(err);
        });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submitButtonRef.current.click(); // Programmatically click the submit button
    }
  };
  useEffect(() => {
    if (enterKeyPressed) {
      handleSubmit();
      setEnterKeyPressed(false);
    }
  }, [enterKeyPressed]);

  return (
    <>
      {error && (
        <div
          className="bg-red-100 border mx-auto w-2/6 border-red-400 text-red-700 px-4 py-3  rounded mt-10 mb-2 text-center res125:py-1.5 res125:text-sm res150:py-1 res150:text-xs res150:mt-6  res125:mt-7"
          role="alert"
        >
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      <div className="grid grid-cols-2 gap-7 mx-auto w-4/5 mt-10 res125:mt-6.5 res150:mt-7">
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 res125:text-sm res150:text-xs"
          >
            Company Email
          </label>
          <input
            className="mt-1 px-4 py-2 rounded res125:h-9 res125:text-sm res150:h-8 res150:text-xs  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14"
            type="email"
            placeholder="Enter your email"
            name="email"
            value={email}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress} 
          />
        </div>

        <div>
          <label
            htmlFor="companyname"
            className="block text-sm font-medium text-gray-700 res125:text-sm res150:text-xs"
          >
            Company Name
          </label>
          <input
            className="mt-1 px-4 py-2 rounded  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14 res125:h-9 res125:text-sm res150:h-8 res150:text-xs"
            type="text"
            placeholder="Enter your company name"
            value={companyName}
            name="companyname"
            onChange={handleInputChange}
            onKeyDown={handleKeyPress} 
          />
        </div>

        <div>
          <label
            htmlFor="companycontact"
            className="block text-sm font-medium text-gray-700 res125:text-sm res150:text-xs"
          >
            Company Contact No
          </label>
          <div className="flex gap-4">
            <select
              className="w-[9rem] h-14 mt-1 rounded border-[#C8C8C8] uppercase res125:w-[7rem] res125:h-9 res125:text-sm res150:w-[6.5rem] res150:h-8 res150:text-xs"
              value={selectedOption}
              onChange={(event) => setSelectedOption(event.target.value)}
            >
              {countryCodes?.map((code) => (
                <option key={code.code} value={code.code}>
                  {code.name.slice(0, 3)} ({code.code})
                </option>
              ))}
            </select>
            <input
              className="mt-1 px-4 py-2 rounded  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14 ml-auto res125:h-9 res125:text-sm res150:h-8 res150:text-xs"
              type="text"
              placeholder="Contact no"
              name="companycontact"
              value={companyContact}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress} 
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="companygst"
            className="block text-sm font-medium text-gray-700 res125:text-sm res150:text-xs"
          >
            Company GST
          </label>
          <input
            className="mt-1 px-4 py-2 rounded  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14 res125:h-9 res125:text-sm res150:h-8 res150:text-xs"
            type="text"
            placeholder="Company GST"
            name="gst"
            value={gst}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress} 
          />
        </div>

        <div className="">
          <label
            htmlFor="Total Work Hours"
            className="block text-sm font-medium text-gray-700 res125:text-sm res150:text-xs"
          >
            Total Work Hours(In a week)
          </label>
          <input
            className="mt-1 px-4 py-2 rounded border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14 res125:h-9 res125:text-sm res150:h-8 res150:text-xs"
            type="text"
            placeholder="Total Work Hours"
            name="hours"
            value={hours}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress} 
          />
        </div>

        <div>
          <label
            htmlFor="category"
            className="block mb-1 text-sm font-medium text-gray-700 res125:text-sm res150:text-xs"
          >
            Category:
          </label>
          <select
            id="category"
            name="category"
            value={category}
            onChange={dropDownChange}
            onKeyDown={handleKeyPress} 
            className="px-4 py-2 rounded  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14 res125:h-9 res125:text-sm res150:h-8 res150:text-xs"
          >
            <option value="" selected disabled>
              Select Category
            </option>
            <option value="Process Plants">Process Plants</option>
          </select>
        </div>

        <div>
          <label
            htmlFor="sub-category"
            className="block text-sm font-medium text-gray-700 mb-2 res125:text-sm res150:text-xs"
          >
            Sub-Category:
          </label>
          <select
            id="sub-category"
            name="subCategory"
            value={subCategory}
            onChange={dropDownChange}
            onKeyDown={handleKeyPress} 
            className="px-4 py-2 rounded  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14 res125:h-9 res125:text-sm res150:h-8 res150:text-xs"
            disabled={!category}
          >
            <option value="" selected disabled>
              Select
            </option>
            {subCategoryOptions &&
              subCategoryOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
        </div>

        <div>
          {subCategory && (
            <>
              <label
                htmlFor="primary-scope"
                className="block mb-1 text-sm font-medium text-gray-700 res125:text-sm res150:text-xs"
              >
                Primary Scope:
              </label>
              <div className=" flex items-center justify-center w-full bg-white res125:text-sm res150:text-xs">
                <div className="w-full border border-[#C8C8C8] ml-0 mr-2 signup  ">
                  <Multiselect
                    ref={multiselectRef}
                    options={primaryScopeOptions[subCategory]}
                    selectedValues={primaryScope}
                    onSelect={handlePSSelect}
                    onRemove={handlePSRemove}
                    displayValue="value"
                    placeholder="Select primary scope"
                    style={{
                      searchBox: {
                        border: "none",
                        fontSize: "10px",
                        minHeight: "40px",
                        width: "440px !important",
                      },
                      optionContainer: {
                        maxHeight: "250px",
                      },
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <div>
          {showTechScope && (
            <>
              <label
                htmlFor="technical-scope"
                className="block mb-1 text-sm font-medium text-gray-700 res125:text-sm res150:text-xs"
              >
                Technical Scope:
              </label>
              <div className=" flex items-center justify-center w-full bg-white res125:text-sm res150:text-xs">
                <div className="w-full border border-[#C8C8C8] ml-0 mr-2 signup  ">
                  <Multiselect
                    ref={multiselectRef}
                    options={technicalScopeOptions}
                    selectedValues={technicalScope}
                    onSelect={handleSelect}
                    onRemove={handleRemove}
                    displayValue="name"
                    placeholder="Select technical scope"
                    style={{
                      searchBox: {
                        border: "none",
                        fontSize: "10px",
                        minHeight: "40px",
                        width: "440px !important",
                      },
                      optionContainer: {
                        maxHeight: "250px",
                      },
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="flex gap-10 res150:gap-8 mx-auto justify-center mt-16  res150:text-xs h-28">
        <button
          className="w-[10rem] res125:w-36 res125:h-10 res125:text-sm res150:w-28 res150:h-7 res150:text-xs  border-[0.5px] border-[#03298C] h-12 rounded hover:bg-[#03298C] hover:text-white font-bold"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isDisabled}
          onClick={handleSubmit}
          ref={submitButtonRef}
          onKeyUp={handleKeyUp}
          className={`bg-[#03298C] w-[10rem] h-12 res125:w-36 res125:h-10 res125:text-sm res150:w-28 res150:h-7 res150:text-xs hover:bg-[#72C27A] text-white font-bold  rounded ${
            isDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {loading ? (
            <div className="flex justify-center ">
              <img
                src="https://media1.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif"
                className="w-[2rem] mx-auto res150:w-7"
                alt=""
              />
            </div>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </>
  );
}
