import React from "react";

const AppContext = React.createContext({
  selectedMail: undefined,
  setSelectedMail: undefined,
  customerInfo: undefined,
  setCustomerInfo: undefined,
  projectInfo: undefined,
  setProjectInfo: undefined,
  tagInfo: undefined,
  custagInfo: undefined,
  setTagInfo: undefined,
  dropDownChanged: undefined,
  isDropDownChanged: undefined,
  type: undefined,
  setType: undefined,
  loadTeamMember: undefined,
  setLoadTeamMember: undefined,
  comments: undefined,
  setComments: undefined,
  isTextChanged: undefined,
  setTextChanged: undefined,
  pageno: undefined,
  setPageno: undefined,
  tagFiles: undefined,
  setTagFiles: undefined,
  imgURL: undefined,
  setImgURL: undefined,
  user: undefined,
  setUser: undefined,
  token: undefined,
  setToken: undefined,
  isAuthenticated: undefined,
  setIsAuthenticated: undefined,
  isInitialSet: undefined,
  setInitialState: undefined,
  step: undefined,
  setStep: undefined,
  loadProject: undefined,
  setLoadProject: undefined,
  isCommentsUpdated: undefined,
  setCommentsUpdated: undefined,
  xaxis: undefined,
  yaxis: undefined,
  setXaxis: undefined,
  setYaxis: undefined,
  rect: undefined,
  setRect: undefined,
  CurrentStepIndex: undefined,
  setCurrentStepIndex: undefined,
});

export default AppContext;
