import AppContext from "../../../../context/AppContext";
import { useContext, useState } from "react";
import CreateProjects from "./CreateProject";
import CreateProjectForms1 from "../../../Customer/CreateOrModifyProject/CreateProject/CreateProjectForm1";
const CreateProjectForms = () => {
  const { selectedMail, projectInfo } = useContext(AppContext);
  const [error, setError] = useState(false);

  const validateFields = () => {
    let error = "";
    if (
      !projectInfo.name ||
      !projectInfo.projectUniqueId ||
      !projectInfo.description ||
      !projectInfo.category ||
      !projectInfo.tags
    ) {
      error = "Please fill all the fields";
      setError(true);
    }
    return error;
  };

  return (
    <div className="flex ml-auto w-4/5 overflow-hidden h-screen">
      <div className="h-screen bg-indigo-200 w-5"></div>
      {true && (
        <div className="bg-black/50  w-full h-full z-10 top-0 right-0"></div>
      )}
      {/* <EmailDetails showMailList={false} selectedMail={selectedMail} /> */}
      <CreateProjects
        className={"flex ml-auto mt-12"}
        validateFields={validateFields}
      >
        <CreateProjectForms1 error={error} show={true} />
      </CreateProjects>
    </div>
  );
};

export default CreateProjectForms;
