import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import "../CreateOrModifyProject.css";
import { toast } from "react-hot-toast";
import AppContext from "../../../../context/AppContext";
import {
  //   CUSTOMER_INFO_STEPPER,
  PROJECT_INFO_STEPPER,
  TAG_INFO_STEPPER,
  BASE_URL,
  PROJECT_INFO_STATE,
  TAG_INFO_STATE,
} from "../../../../constants/Constants";
import axios from "axios";

const CreateProjects = ({
  validateFields,
  children,
  className,
  type,
  msgID,
}) => {
  const steps = [
    // "Organization / Customer Details",
    "New Project Details",
    "Tag Details",
    "New Project Preview",
  ];
  const [currentStep, setCurrentStep] = useState(1);
  const [complete, setComplete] = useState(false);
  const history = useHistory();
  const {
    setProjectInfo,
    // customerInfo,
    projectInfo,
    tagInfo,
    tagFiles,
    setTagInfo,
    setTagFiles,
    setLoadTeamMember,
  } = useContext(AppContext);
  const selectedMailFiles = JSON.parse(localStorage.getItem("SELECTED_MAIL"));
  const [loading, setLoading] = useState(false);
  const { setStep } = useContext(AppContext);

  // Bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  //backnew
  const handleBack = () => {
    // console.log("step finding",currentStep)
    if (currentStep === 1) {
      localStorage.setItem(PROJECT_INFO_STEPPER, JSON.stringify(projectInfo));
      {
        type === "edit-project"
          ? setLoadTeamMember(true)
          : history.push("/customer/projects");
      }
    } else {
      setCurrentStep((prev) => prev - 1);
    }

    if (currentStep === 2) {
      localStorage.setItem(TAG_INFO_STEPPER, JSON.stringify(tagInfo));
      {
        type === "edit-project"
          ? setStep(0)
          : history.push("/customer/dashboard/create-project/1");
      }
    }
    if (currentStep === 3) {
      const array = tagInfo.map((tag) => {
        return {
          file: tag.attachments,
        };
      });
      setTagFiles([...array]);
      const tagAttachments = tagInfo.map((tag) => {
        return { ...tag, attachments: [] };
      });
      setTagInfo([...tagAttachments]);
      localStorage.setItem(TAG_INFO_STEPPER, JSON.stringify(tagAttachments));
      {
        type === "edit-project"
          ? setStep(1)
          : history.push("/customer/dashboard/create-project/2");
      }
    }
  };

  const handleNext = async () => {
    // if (currentStep === 1) {
    //     const error = await validateFields();
    //     if (Object.keys(error).length === 0) {
    //         localStorage.setItem(
    //             CUSTOMER_INFO_STEPPER,
    //             JSON.stringify(customerInfo)
    //         );
    //         setCurrentStep((prev) => prev + 1);
    //         {
    //             type === "edit-project"
    //                 ? setStep(currentStep)
    //                 : history.push("/admin/mails/create-project/2");
    //         }
    //     }
    // } else
    if (currentStep === 1) {
      const error = validateFields();
      if (Object.keys(error).length === 0) {
        localStorage.setItem(PROJECT_INFO_STEPPER, JSON.stringify(projectInfo));
        setCurrentStep((prev) => prev + 1);
        {
          type === "edit-project"
            ? setStep(currentStep)
            : history.push("/customer/dashboard/create-project/2");
        }
      }
    } else if (currentStep === 2) {
      const error = validateFields();
      if (Object.keys(error).length === 0) {
        if (tagFiles.length > 0) {
          const mergedArrays = tagInfo.map((info, index) => {
            return {
              ...info,
              attachments: [...info.attachments, ...tagFiles[index].file],
            };
          });
          setTagInfo([...mergedArrays]);
          localStorage.setItem(TAG_INFO_STEPPER, JSON.stringify(mergedArrays));
        } else {
          localStorage.setItem(TAG_INFO_STEPPER, JSON.stringify(tagInfo));
        }
        setCurrentStep((prev) => prev + 1);
        {
          type === "edit-project"
            ? setStep(currentStep)
            : history.push("/customer/dashboard/create-project/3");
        }
      }
    }
    if (currentStep === steps.length) {
      setComplete(true);
    }
  };

  const handleSubmit = async () => {
    const customerInfo = JSON.parse(localStorage.getItem("User"));

    let payload = {
      customer: {
        id: customerInfo.id,
        name: customerInfo.name,
        contact_no: customerInfo.contact_no,
        email: customerInfo.email,
        org_id: customerInfo.company.id,
        org_name: customerInfo.company.name,
        org_size: customerInfo.company.size,
      },

      project: {
        id: projectInfo.id,
        projectUniqueId: projectInfo.projectUniqueId,
        name: projectInfo.name,
        description: projectInfo.description,
        category: projectInfo.category,
        tags: projectInfo.tags,
      },
      tags: tagInfo.map((tag) => {
        return {
          id: tag._id,
          name: tag.name,
          description: tag.description,
          sub_category: tag.sub_category,
          verticals: tag.verticals,
          sub_vertical: tag.sub_vertical,

          // technical_scope: tag.serviceRequired.map((scope) => {
          serviceRequired: tag.serviceRequired.map((scope) => {
            return {
              id: scope.id,
              name: scope.value,
            };
          }),
          files: JSON.stringify(tag.attachments.map((file) => file.name)),
        };
      }),

      admin_id: JSON.parse(localStorage.getItem("User")).id,
      // mail_id: type === "edit-project" ? msgID : selectedMailFiles.message_id,
      mail_id: type === "edit-project" ? msgID : "customer",

      status: "RFQ",
    };

    setLoading(true);
    await axios
      .post(`${BASE_URL}/api/project/createorupdate`, payload, { headers })
      .then((res) => {
        // localStorage.removeItem("customer_info");
        localStorage.removeItem("project_info");
        localStorage.removeItem("tag_info");
        toast.success("Success");
        setTagFiles([]);
        history.push("/customer/projects");
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    // if (history.location.pathname === "/admin/mails/create-project/1") {
    //     setCurrentStep(1);
    // }
    if (history.location.pathname === "/customer/dashboard/create-project/1") {
      setCurrentStep(1);
    }
    if (history.location.pathname === "/customer/dashboard/create-project/2") {
      setCurrentStep(2);
    }
    if (history.location.pathname === "/customer/dashboard/create-project/3") {
      setCurrentStep(3);
    }
  }, [history.location.pathname]);

  return (
    <div
      className={`h-screen flex-col w-1/2 z-10 ${className} overflow-y-hidden mt-12 res150:mt-8 `}
      style={{
        background: "linear-gradient(90deg, #F8F8F8 0%, #FFFFFF 74.69%)",
      }}
    >
      <div className=" w-[100%] font-bold text-xl text-[#011A59] mt-10 mx-[46px]  h-[49px] res150:text-base">
        {steps[currentStep - 1]}
        <button className="ml-[40px]">
          <i
            className="fa fa-repeat"
            aria-hidden="true"
            title="Reset Fields"
            onClick={() => {
              localStorage.removeItem("tag_info");
              setTagFiles([]);
              let tagDatas = [];
              for (let i = 0; i < projectInfo.tags; i++) {
                tagDatas.push(TAG_INFO_STATE);
              }
              setTagInfo([...tagDatas]);
              setProjectInfo(PROJECT_INFO_STATE);
              history.push("/customer/dashboard/create-project/1");
            }}
          ></i>
        </button>
      </div>

      <div
        className={`flex items-center w-full  res150:mx-auto res125:mx-auto res150:w-72 res125:w-[360px] res150:text-xs res150:mt-1  mt-4 mb-0 bg-transparent ${steps[currentStep - 1] === "Preview" ? "hidden" : "block"
          }`}
      >
        {steps.slice(0, 3)?.map((step, i) => (
          <div
            key={i}
            className={`step-item ${currentStep === i + 1 && "active"} ${(i + 1 < currentStep || complete) && "complete"
              }`}
          >
            <div className="step">
              {i + 1 < currentStep || complete ? i + 1 : i + 1}
            </div>
          </div>
        ))}
      </div>
      {/* {currentStep === 1 && <>{children}</>} */}
      {currentStep === 1 && <>{children}</>}
      {currentStep === 2 && <>{children}</>}
      {currentStep === 3 && <>{children}</>}
      <div className="mx-auto mt-auto mb-28 flex">
        {!complete && (
          <>
            <button
              type="button"
              className="border border-[rgb(1,26,89)] rounded bg-transparent text-[#011A59] hover:bg-[#72C27A] hover:text-white hover:border-none py-[8px] px-[45px] mr-[20px] text-sm font-medium res125:h-8 res125:py-1 res150:text-xs res150:px-8 res150:py-1 res150:h-7"
              onClick={handleBack}
            >
              Back
            </button>
            <button
              type="button"
              className="border border-[#011A59] rounded bg-transparent text-[#011A59] hover:bg-[#72C27A] hover:text-white hover:border-none py-[8px] px-[45px] text-sm font-medium text-center res125:h-8 res125:py-1 res150:text-xs res150:px-8 res150:py-1 res150:h-7"
              onClick={() =>
                currentStep === steps.length ? handleSubmit() : handleNext()
              }
              disabled={loading}
            >
              {currentStep === steps.length ? "Confirm" : "Next"}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateProjects;
