import { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/AppContext";
import CreatePreviewProject from "../../Admin/CreateOrModifyProject/CreateProject/CreatePreviewProject";
import CreateProject from "../../Admin/CreateOrModifyProject/CreateProject/CreateProject";
import CreateProjectForm1 from "../../Admin/CreateOrModifyProject/CreateProject/CreateProjectForm1";
import CreateProjectForm2 from "../../Admin/CreateOrModifyProject/CreateProject/CreateProjectForm2";
import CreateProjectForm3 from "../../Admin/CreateOrModifyProject/CreateProject/CreateProjectForm3";
import {
  BASE_URL,
  CUSTOMER_INFO_STEPPER,
  PROJECT_INFO_STEPPER,
  TAG_INFO_STEPPER,
} from "../../../constants/Constants";
import { toast } from "react-hot-toast";
import axios from "axios";

const EditProject = (props) => {
  const { show, details } = props;
  const {
    customerInfo,
    projectInfo,
    tagInfo,
    step,
    setStep,
    setCustomerInfo,
    setProjectInfo,
    setTagInfo,
  } = useContext(AppContext);
  const [customerError, setCustomerError] = useState(false);
  const [projectError, setProjectError] = useState(false);
  const [tagError, setTagError] = useState(false);
  const [errorMicro, setErrorMicro] = useState([]);

  useEffect(() => {
    setStep(0);

    setCustomerInfo({
      ...details.customer,
      organizations: {
        id: details.company.id,
        name: details.company.name,
        size: details.company.size,
      },
    });

    setProjectInfo({
      project_id: details._id,
      name: details.name,
      projectUniqueId: details.projectUniqueId,
      description: details.description,
      category: details.category,
      tags: details.tags.length,
    });

    const modifiedTags = details.tags.map((tag) => {
      const modifiedTechnicalScopes = tag.serviceRequired.map((ts) => ({
        name: ts.microservice,
        id: ts.scopeId,
      }));

      const modifiedAttachments = JSON.parse(tag.attachments).map(
        (attachment, index) => ({
          id: index,
          name: attachment,
        })
      );

      return {
        ...tag,
        serviceRequired: modifiedTechnicalScopes,
        attachments: modifiedAttachments,
      };
    });
    setTagInfo(modifiedTags);
    localStorage.setItem(
      CUSTOMER_INFO_STEPPER,
      JSON.stringify({
        ...details.customer,
        organizations: {
          id: details.company.id,
          name: details.company.name,
          size: details.company.size,
        },
      })
    );
    localStorage.setItem(
      PROJECT_INFO_STEPPER,
      JSON.stringify({
        id: details._id,
        name: details.name,
        projectUniqueId: details.projectUniqueId,
        description: details.description,
        category: details.category,
        tags: details.tags.length,
      })
    );
    localStorage.setItem(TAG_INFO_STEPPER, JSON.stringify(modifiedTags));
  }, [details]);

  const validateCustomerFields = async () => {
    let error = "";
    if (
      !customerInfo.name ||
      !customerInfo.contact_no ||
      !customerInfo.organizations.name ||
      !customerInfo.organizations.size
    ) {
      error = "Please fill all the fields";
      setCustomerError(true);
    } else if (!customerInfo.email) {
      error = "Please enter an email";
      setCustomerError(true);
    } else if (!customerInfo.id) {
      error = await axios
        .get(`${BASE_URL}/api/email-check`, {
          params: {
            email: customerInfo.email,
          },
        })
        .then((res) => {
          return "";
        })
        .catch((error) => {
          error = "Email Already exist";
          setCustomerError(true);
          console.error(error);
          toast.error(error);
          // swal(`${error}`,"Please try some other email","warning")
          return error;
        });
    }

    return error;
  };

  const validateProjectFields = () => {
    let error = "";
    if (
      !projectInfo.name ||
      !projectInfo.description ||
      !projectInfo.category ||
      !projectInfo.tags
    ) {
      error = "Please fill all the fields";
      setProjectError(true);
    }
    return error;
  };

  const validateTagFields = () => {
    let error = "";
    for (let i = 0; i < projectInfo.tags; i++) {
      if (
        !tagInfo[i].name ||
        !tagInfo[i].description ||
        !tagInfo[i].sub_category ||
        // !tagInfo[i].team_type ||
        !tagInfo[i].sub_vertical ||
        !tagInfo[i].verticals ||
        !tagInfo[i].serviceRequired ||
        !tagInfo[i].attachments
      ) {
        error = "Please fill all the fields";
        setTagError(true);
      }
    }
    return error;
  };

  return (
    <div>
      {/* Overlay */}
      {show && (
        <div className="bg-black/50 fixed w-full h-full z-10 top-0 right-0"></div>
      )}

      {/* Side drawer*/}
      <div
        className={
          show
            ? "fixed top-0 right-0 w-1/4 h-full z-10 duration-300"
            : "fixed top-0 right-[-100%] w-1/4 h-full z-10 duration-300"
        }
        style={{
          background: "linear-gradient(90deg, #F8F8F8 0%, #FFFFFF 74.69%)",
        }}
      >
        <CreateProject
          className={"flex ml-auto mt-16 w-full"}
          validateFields={
            step === 0
              ? validateCustomerFields
              : step === 1
                ? validateProjectFields
                : validateTagFields
          }
          type="edit-project"
          msgId={details.message_id}
          errorMicro={errorMicro}
          setErrorMicro={setErrorMicro}
        >
          {step === 0 && <CreateProjectForm1 error={customerError} />}
          {step === 1 && <CreateProjectForm2 error={projectError} />}
          {step === 2 && <CreateProjectForm3 error={tagError} setErrorMicro={setErrorMicro} errorMicro={errorMicro} />}
          {step === 3 && <CreatePreviewProject type="edit-project" />}
        </CreateProject>
      </div>
    </div>
  );
};

export default EditProject;
