import { useState, useEffect, useContext } from "react";
import React from "react";
import axios from "axios";
import { BASE_URL, Logo } from "../../../constants/Constants";
import NewCredentialsDrawer from "../../Shared/PopupDrawer/NewCredentialsDrawer";
import AppContext from "../../../context/AppContext";
import { toast } from "react-hot-toast";
import moment from "moment/moment";
// import FactreehubLogo from "../../../assets/images/Factree Hub Logo.png";
import FactreehubLogo from "../../../assets/svg/logowithtgline.svg";
import swal from "sweetalert";
import Loader from "../../../Loader";

const CustomerMail = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [type, setType] = useState(false);
  const { loadTeamMember, setLoadTeamMember } = useContext(AppContext);
  const [teamList, setTeamList] = useState([]);
  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    const params = {
      customer_id: JSON.parse(localStorage.getItem("User")).id,
    };
    setLoading(true);
    axios
      .get(`${BASE_URL}/api/customer/list`, { params, headers })
      .then((res) => {
        setLoading(false);
        setTeamList(res.data.message);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    setLoadTeamMember(false);
  }, [loadTeamMember]);

  const handleDelete = (value) => {
    localStorage.setItem("Delete_account", JSON.stringify(value));
    swal({
      title: "Are you sure want to delete this Mail id ?",
      text: "Once deleted, you will not be able to recover this Mail id !",
      icon: "warning",
      buttons: {
        cancel: "No",
        confirm: "Yes",
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleConfirmDelete();
        swal(
          "Deleted Success!",
          "Mail id has been deleted successfully!",
          "success"
        );
      } else {
        swal("Your Mail id is safe !");
      }
    });
  };

  const handleConfirmDelete = () => {
    var account = JSON.parse(localStorage.getItem("Delete_account"));
    const params = {
      user_id: account.userId,
    };
    setLoading(true);
    axios
      .delete(`${BASE_URL}/api/teams/delete`, { params, headers })
      .then((res) => {
        localStorage.removeItem("Delete_account");
        setLoadTeamMember(true);
        toast.success("Deleted Successfully");
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Please Try Again");
        setLoading(false);
      });
  };

  return (
    <>
      <div className="w-9/12 flex flex-col ml-auto h-auto mr-16 pl-8 mt-20 res125:mt-16 res150:mt-14">
        <div className=" mx-0 h-11 mt-3 res125:mt-1 res150:mt-0">
          {/* logo */}
          <img
            className=" flex ml-auto w-36 res125:w-28 res150:w-24"
            src={Logo}
            alt="FactreeHub"
          />
          <div className="flex mt-8 res125:mt-6 res150:mt-5">
            <div className="text-base font-bold pt-4 res150:pt-2 res125:text-sm res150:text-xs">
              All Mail ids
            </div>

            <div className="ml-auto">
              <button
              id="emailbutton"
                className="bg-[#03298C] w-40 h-10 text-white rounded res125:text-sm res125:w-32 res125:h-8 res150:text-xs res150:w-28 res150:h-7"
                onClick={() => {
                  setShow(true);
                  setType("Add New Mail Id");
                }}
              >
                <i
                  className="fa fa-plus mr-4 res125:text-sm res150:text-xs"
                  aria-hidden="true"
                ></i>
                Add Mail Id
              </button>
            </div>
          </div>
        </div>
        {/* <hr className=" border border-[#C8C8C8] mt-20" /> */}

        {loading ? (
          <Loader className="mt-40 mx-auto res125:w-7 res125:mt-32 res150:w-6 res150:mt-20" />
        ) : (
          <div id="emailTable" className="relative overflow-x-auto shadow-md sm:rounded-lg mt-28 res125:mt-[4.5rem] res150:mt-14">
            <table  className="w-full text-sm text-left text-black">
              <thead className="text-base text-[#03298C] bg-[#ECFFFF] text-left res125:text-sm res150:text-xs">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-4 res125:px-5 res125:py-3 res150:px-4 res150:py-3"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 res125:px-5 res125:py-3 res150:px-4 res150:py-3"
                  >
                    Email ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 res125:px-5 res125:py-3 res150:px-4 res150:py-3"
                  >
                    Password
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-4 text-center res125:px-5 res125:py-3 res150:px-4 res150:py-3"
                  >
                    Created Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-center res125:px-5 res125:py-3 res150:px-4 res150:py-3"
                  >
                    Expiry Date / Days Left
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-center res125:px-5 res125:py-3 res150:px-4 res150:py-3"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {teamList.length === 0 && (
                  <tr className="border-b ">
                    <td
                      colSpan="6"
                      className="px-6 py-4 res150:px-4 res150:py-3  res150:text-sm text-center text-lg"
                    >
                      No Mail ids added yet !
                    </td>
                  </tr>
                )}
                {teamList.map((value, index) => (
                  <tr
                    className="border-b res125:text-sm res150:text-xs"
                    key={index}
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 whitespace-nowrap font-bold text-left res125:px-5 res125:py-3 res150:px-4 res150:py-3"
                    >
                      <p>{value.teamName}</p>
                    </th>

                    <td className="px-6 py-4  text-left res125:px-5 res125:py-3 res150:px-4 res150:py-3">
                      {value.email}
                    </td>
                    <td className="px-6 py-4  text-left res125:px-5 res125:py-3 res150:px-4 res150:py-3">
                      {value.password}
                    </td>
                    <td className="px-6 py-4 text-center res125:px-5 res125:py-3 res150:px-4 res150:py-3">
                      {moment(value.created_at).format("DD-MM-YYYY hh:mm a")}
                    </td>
                    <td className="px-6 py-4 text-center res125:px-5 res125:py-3 res150:px-4 res150:py-3">
                      {value.expired_at < Date.now()
                        ? "Expired"
                        : moment(value.expired_at).format("DD-MM-YYYY")}
                      <br />
                      <span className="text-red-500">
                        {value.expired_at < Date.now()
                          ? "Expired"
                          : moment(value.expired_at).diff(moment(), "days") <= 0
                          ? "Expired"
                          : moment(value.expired_at).diff(moment(), "days") +
                            " days left"}
                      </span>
                    </td>
                    <td className="py-4 flex justify-center res125:px-5 res125:py-3 res150:px-4 res150:py-3">
                      <div
                        onClick={() => {
                          setShow(true);
                          setType("Account Details");
                          localStorage.setItem(
                            "Team_Credential",
                            JSON.stringify(value)
                          );
                        }}
                      >
                        <i
                          className="fa fa-2x fa-pencil-square-o cursor-pointer text-[#4277FF] hover:bg-[#4277FF] hover:text-white border border-[#4277FF] px-1 py-1 rounded mr-3"
                          aria-hidden="true"
                        ></i>{" "}
                      </div>
                      <div
                        onClick={() => {
                          handleDelete(value);
                        }}
                      >
                        <i
                          className="fa fa-2x fa-trash cursor-pointer text-[#E96F6F] hover:text-white hover:bg-red-500 border border-[#E96F6F]  rounded px-1 py-1"
                          aria-hidden="true"
                        ></i>{" "}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {show && (
        <NewCredentialsDrawer
          show={show}
          setShow={setShow}
          type={type}
          cred_type="Customer"
        />
      )}
    </>
  );
};

export default CustomerMail;
