import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import { toast } from "react-hot-toast";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { useHistory } from "react-router-dom";
import AppContext from "../../../context/AppContext";

const UploadDrawer = ({ show, setShow, selectedData }) => {
  const navigate = useHistory();
  const [selectedFile, setSelectedFile] = useState(null);
  const { setLoadProject } = useContext(AppContext);

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const handleFiles = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      if (fileType === "pdf" || fileType === "zip") {
        setSelectedFile(file);
      } else {
        toast.error("Please select a PDF or ZIP file.");
      }
    }
  };

  const fileUpload = async () => {
    const s3Client = new S3Client({
      region: "ap-south-1",
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    });

    const params = {
      Bucket: "factreehub-docs",
      Key: `diagram/${selectedFile.name}`,
      ContentType: selectedFile.type,
      Body: selectedFile,
    };

    try {
      const putObjectCommand = new PutObjectCommand(params);
      await s3Client.send(putObjectCommand);
      toast.success("File uploaded successfully!");
    } catch (error) {
      console.error(error);
      toast.error("Please Try Again");
    }
  };

  useEffect(() => {
    if (selectedFile) {
      fileUpload();
    }
  }, [selectedFile]);

  const storeDiagram = async (fileName) => {
    const path = navigate.location.pathname.split("/");
    const project_id = path[path.length - 1];
    const payload = {
      admin_id: JSON.parse(localStorage.getItem("User")).id,
      file: selectedFile.name,
      project_id: project_id,
      tag_id: selectedData.tag_id,
      scope_id: selectedData.scope_id,
      sp_status: "",
      vendor_id: selectedData.vendor_id,
      adminStatus: selectedData.adminStatus,
      comments: JSON.stringify([]),
      created_by: "admin",
    };

    await axios
      .post(`${BASE_URL}/api/diagram/upload`, { ...payload }, { headers })
      .then((res) => {
        // handlePostNotification(project_id, selectedData);
        toast.success("Diagram Uploaded Successfully");
        setLoadProject(true);
        setShow(false);
      })
      .catch((err) => {
        toast.error("please try again");
      });
  };

  // const handlePostNotification = async (project_id, selectedData) => {
  //   const payload = {
  //     admin_id: JSON.parse(localStorage.getItem("User")).id,
  //     vendors: [selectedData.vendor_id],
  //     message: "New file has been uploaded by Factreehub",
  //     project_id: project_id,
  //     scope_id: selectedData.scope_id,
  //     tag_id: selectedData.tag_id,
  //   };

  //   await axios
  //     .post(`${BASE_URL}/api/notification/post`, payload, { headers })
  //     .then((res) => {
  //       toast.success("Success! Notification sent.");
  //     })
  //     .catch((err) => {
  //       toast.error("Notification failed to sent.");
  //     });
  // };

  return (
    <div>
      {/* Overlay */}
      {show && (
        <div className="bg-black/50 fixed w-full h-full z-10 top-0 right-0"></div>
      )}

      {/* Side drawer*/}
      <div
        className={
          show
            ? "fixed top-0 right-0 w-[420px] h-full z-10 duration-300 res125:w-[25rem] "
            : "fixed top-0 right-[-100%] w-[420px] h-full z-10 duration-300"
        }
        style={{
          background: "linear-gradient(90deg, #F8F8F8 0%, #FFFFFF 74.69%)",
        }}
      >
        <div className="h-[85%] mt-24 ml-10 w-[340px] res150:mt-16">
          <p className="text-[#011A59] font-bold text-xl tracking-wide res150:text-sm">
            Upload File
          </p>

          <div>
            <div className="mt-5">
              <div class="flex items-center justify-center w-full mb-5">
                <label
                  for="dropzone-file"
                  class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 "
                >
                  {!selectedFile ? (
                    <>
                      <div class="flex flex-col items-center justify-center pt-10 pb-10 res125:pt-7 res125:pb-7 res150:pt-2 res150:pb-2 ">
                        <svg
                          aria-hidden="true"
                          class="w-4 h-4 mb-3 text-gray-400 res150:w-2 res150:h-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p class="mb-1 text-sm text-gray-500 dark:text-gray-400 res125:text-sm res150:text-xs">
                          <span class="font-semibold res125:text-xs res150:text-xs">
                            Click to upload
                          </span>
                          {/* drag and drop */}
                        </p>
                      </div>
                      <input
                        id="dropzone-file"
                        name="file"
                        type="file"
                        class="hidden"
                        onChange={(e) => handleFiles(e)}
                        accept=".pdf,.zip"
                        value={selectedFile}
                      />
                    </>
                  ) : (
                    <>
                      <p class=" py-3 text-sm text-gray-500 dark:text-gray-400 res150:text-xs res125:text-xs">
                        {selectedFile.name}
                      </p>
                    </>
                  )}
                </label>
              </div>
              {selectedFile && (
                <span
                  className="mb-5 text-sm text-blue-500 underline cursor-pointer res150:text-xs"
                  onClick={() => setSelectedFile()}
                >
                  Remove
                </span>
              )}
            </div>
            <div className="absolute top-[89%] left-[10%]">
              <div className="flex flex-row mx-[34px] gap-4 res125:mx-16 res150:mx-14">
                <div>
                  <button
                    className="h-10 border border-[#03298C] hover:bg-[#72C27A] hover:text-white hover:border-none w-32 font-medium text-sm res125:w-24 res125:h-8 res125:text-xs res150:w-24 res150:text-xs res150:h-7"
                    onClick={() => {
                      setShow(!show);
                      //   localStorage.removeItem("Team_Credential");
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    onClick={() => storeDiagram()}
                    className={` w-32 h-10 border border-[#03298C] hover:bg-[#72C27A] hover:text-white  hover:border-none font-medium text-sm res125:w-24 res125:h-8 res125:text-xs res150:w-24 res150:text-xs res150:h-7`}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDrawer;
