import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import Loader from "../../../Loader";
import moment from "moment";
import FeedbackChat from "../../Customer//Projects/FeedbackChat";
import "../../../App.css";
import { result } from "lodash";
import "../../../App.css"
import { Badge, Button, Modal } from "flowbite-react";
import { HiCheck, HiClock, HiMinus } from 'react-icons/hi';
const SPQuotationsList = ({ projectDetails }) => {
  const [orderData, setOrderData] = useState([]);
  const navigate = useHistory();
  const [reqfile, setQeqfile] = useState("");
  const [loading, setLoading] = useState("false");
  const [quotationsData, setQuotationsData] = useState({});
  const [projectData, setProjectData] = useState("");
  const [quotations, setQuotations] = useState([]);
  const pathname = navigate.location.pathname.split("/");
  const project_id = pathname[pathname.length - 1];
  const [openModal, setOpenModal] = useState(false);
  const [comments, setComments] = useState([]);


  // let quotation_id;

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const generateAndUploadPDF = async (orderData, quotationsData) => {

    const generateRandomString = async (length) => {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    };

    let baseFileName = "Order";
    let version = await generateRandomString(Math.floor(Math.random() * 3) + 4);
    let fileName = `${baseFileName}_${version}.pdf`;

    return fileName
  };

  useEffect(() => {
    getQuotations();
    fetchComments();
  }, []);

  const getUserTypes = (user) => {
    if (user.is_admin) {
      return "Admin";
    } else if (user.is_company) {
      return "company";
    } else if (user.is_customer) {
      return "customer";
    } else if (user.is_freelancer) {
      return "service_provider";
    }
  };
  const user = JSON.parse(localStorage.getItem("User"));

  const userTypes = getUserTypes(user);

  const getOrder = async () => {
    const pathname = navigate.location.pathname.split("/");
    const project_id = pathname[pathname.length - 1];

    await axios
      .get(`${BASE_URL}/api/getOrder/${project_id}/${userTypes}`, { headers })
      .then((res) => {
        const datas = res.data.getOrders;
        let array = [];
        for (const orderData of datas) {
          array.push({
            email: orderData.email,
            file: orderData.file,
            gst: orderData.gst,
            orderStatus: orderData.orderStatus,
            org_name: orderData.org_name,
            createddate: moment(orderData.createdAt).format(
              "DD-MMM-YYYY h:mm A"
            ),
            price: orderData.price,
            project_id: orderData.project_id,
            scope: orderData.scope,
            tag: orderData.tag,
            totalAmount: orderData.totalAmount,
            userType: orderData.userType,
            user_id: orderData.user_id,
            _id: orderData._id,
          });
        }
        setLoading(false);
        setOrderData([...array]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFeedback = (data) => {
    setProjectData(data);
    const userType = getUserTypes(user).toLowerCase(); // Convert userType to lowercase
    const payload = {
      quotation_id: data._id,
      userType // Shorthand property to set userType as lowercase
    };

    axios
      .put(`${BASE_URL}/api/feedback/markUsRead`, payload, { headers })
      .then((response) => {
        const result = response.data;
        fetchComments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchComments = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/quotation/getAllFeedback/${project_id}/${userTypes.toLowerCase()}`, { headers });
      const result = response.data
      setComments(result);

    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const getQuotations = async () => {
    setLoading(true);
    const params = {
      project_id,
    };
    await axios
      .get(`${BASE_URL}/api/admin/quotations`, { params, headers })
      .then((res) => {
        setQuotations(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };
  const handleStatus = (quotation, value) => {
    setLoading(true);
    const payload = {
      updateObj: {
        status: value,
      },
      admin_id: JSON.parse(localStorage.getItem("User")).id,
      vendors: [parseInt(quotation.vendor_id)],
      quotation_id: quotation._id,
      project_id,
    };
    axios
      .put(`${BASE_URL}/api/quotations/update`, payload, { headers })
      .then((response) => {
        // handlePostNotification();
        setQuotationsData(response.data.message);
        getOrder();
        getQuotations();
        if (value === 'Accepted') {
          getQuotations();
          // toast.success("The quotation has been accepted, and your order has been generated.");
          toast.success("Quotation has been accepted")
        } else if (value === 'Rejected') {
          getQuotations();
          toast.success("The quotation has been Rejected.");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // getOrder()
  // useEffect(() => {
  //   if (quotationsData.status === "Accepted") {
  //     ordergenerate(orderData, quotationsData);
  //   }
  // }, [quotationsData]);
  const ordergenerate = async (orderData, quotationsData) => {
    const user = JSON.parse(localStorage.getItem("User"));

    const fileName = await generateAndUploadPDF(quotations, quotationsData);
    const company = JSON.parse(localStorage.getItem("customer_info"));


    let gstValueData = parseFloat(quotationsData.totalPrice);
    let gstAmount = gstValueData * 0.18;
    let roundedGST = gstAmount.toFixed(2);
    let totalAmount = parseInt(gstValueData) + parseInt(roundedGST);

    const payload = {
      file: fileName,
      project_id: quotationsData.project_id,
      userType: "service_provider",
      user_id: user.id,
      orderStatus: "Accepted",
      tags: JSON.parse(quotationsData.tags),
      email: user.email,
      totalPrice: quotationsData.totalPrice,
      org_name: 'Organization',
      totalPriceWithGst: totalAmount,
      GST: gstAmount,
      vendor_id: quotationsData.vendor_id
    };
    axios
      .post(`${BASE_URL}/api/createOrder`, payload, { headers })
      .then((response) => {
        // handlePostNotification();
        console.log(response, "res");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFileUrl = async (filename) => {
    const params = {
      filter: "quotation",
      name: filename,
    };
    await axios
      .get(`${BASE_URL}/api/file-url`, { params, headers })
      .then((res) => {
        window.open(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {loading ? (
        <Loader className="mx-auto" />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-6 res125:mt-4 res150:mt-3 scroll-width">
          <table className="w-full text-sm text-center text-black">
            <thead className="text-base text-[#03298C] bg-[#ECFFFF] res125:text-sm res150:text-xs">
              <tr className="text-center">
                <th
                  scope="col"
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2"
                >
                  SP Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2"
                >
                  SP Contact
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2"
                >
                  SP Email
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2"
                >
                  Quotation File
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2"
                >
                  Total Price
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2"
                >
                  Created Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2"
                >
                  Actions/Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2"
                >
                  Feedback
                </th>
              </tr>
            </thead>
            <tbody>
              {quotations.map((quotation, index) => (
                <tr className="bg-white border-b text-center res150:text-xs">
                  <th
                    scope="row"
                    className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 capitalize"
                  >
                    {quotation.vendor?.name}
                  </th>
                  <td className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2">
                    {quotation.vendor?.contact_no}
                  </td>
                  <td className="px-6 py-4 res125:px-4 res125:py-2">
                    <p
                      className="w-40 overflow-hidden whitespace-nowrap text-ellipsis"
                      title={quotation.vendor?.email}
                    >
                      {quotation.vendor?.email}
                    </p>
                  </td>
                  <td
                    className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 text-blue-500 underline cursor-pointer"
                    onClick={() => getFileUrl(quotation.file)}
                  >
                    <p
                      className="w-48 overflow-hidden whitespace-nowrap text-ellipsis"
                      title={quotation.file}
                    >
                      {quotation.file}
                    </p>
                  </td>
                  <td className="px-6 py-4 res125:px-4 res125:py-2">
                    {quotation.totalPrice}
                  </td>
                  <td className=" whitespace-nowrap px-6 py-4 res125:px-4 res125:py-2">
                    {moment(quotation.createdAt).format("DD-MM-YYYY hh:mm A")}
                  </td>

                  <td className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2">
                    {quotation.status === "Pending" ? (
                      <div className="flex flex-row mx-auto">
                        <div>
                          <button
                            className="p-2 mr-2 rounded border border-gray-300 w-24 res150:w-[4.6rem] res150:p-1 flex flex-row bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900 group-hover:bg-green-200 dark:group-hover:bg-green-300 hover:scale-105 transition-transform res150:text-xs"
                            onClick={() => handleStatus(quotation, "Accepted")}
                          >
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 mr-0.5 "
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M4.5 12.75l6 6 9-13.5"
                                />
                              </svg>
                            </div>
                            Accept
                          </button>
                        </div>
                        <div>
                          <button
                            className="p-2 rounded border border-gray-300  w-24 res150:w-[4.6rem] res150:p-1 flex flex-row bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900 group-hover:bg-red-200 dark:group-hover:bg-red-300 hover:scale-105 transition-transform res150:text-xs"
                            onClick={() => handleStatus(quotation, "Rejected")}
                          >
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="w-5 h-5  mr-1"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </div>
                            Reject
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-wrap gap-2 justify-center">
                        {quotation.status === "Rejected" ? (
                          <Badge color="failure">{quotation.status}</Badge>
                        ) : (
                          <Badge color="success">{quotation.status}</Badge>
                        )}
                      </div>
                    )}
                  </td>
                  <td className="px-4 py-2 border-b border-indigo-200">
                    <button
                      onClick={() => {
                        setOpenModal(true);
                        handleFeedback(quotation);
                      }}
                      className={`${comments.getFeedback?.length > 0 && comments.getFeedback.some(item => item.quotation_id === quotation._id) ? 'gradient-border' : 'border-[#FD952D]'} bg-[#FFE3C7] text-[#FD952D] px-4 py-1 border relative rounded-full`}
                    >
                      FeedBack
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Modal show={openModal} onClose={() => setOpenModal(false)}>
            <Modal.Header>Feed Back</Modal.Header>
            <Modal.Body className="overflow-y-hidden">
              <div className="space-y-6">
                <FeedbackChat projectData={projectData} />
              </div>
            </Modal.Body>
          </Modal>
          {quotations.length === 0 && (
            <div className="py-4 flex justify-center res150:text-xs">
              No Quotation Found
            </div>
          )}
        </div>
      )
      }
    </div >
  );
};

export default SPQuotationsList;