import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../../context/AppContext";
import { ReactComponent as DownloadIcon } from "../../../../assets/svg/download.svg";
import { ReactComponent as EditIcon } from "../../../../assets/svg/edit.svg";
import {
  PROJECT_INFO_STEPPER,
  TAG_INFO_STEPPER,
} from "../../../../constants/Constants";

const CreatePreviewProject = ({ type }) => {
  const navigate = useHistory();
  const { projectInfo, tagInfo, setProjectInfo, setTagInfo } =
    useContext(AppContext);

  useEffect(() => {
    if (localStorage.getItem(PROJECT_INFO_STEPPER)) {
      setProjectInfo(JSON.parse(localStorage.getItem(PROJECT_INFO_STEPPER)));
    }
    if (localStorage.getItem(TAG_INFO_STEPPER)) {
      setTagInfo([...JSON.parse(localStorage.getItem(TAG_INFO_STEPPER))]);
    }
  }, []);

  return (
    <div class=" flex-wrap max-w-md mx-auto mb-5 overflow-y-auto max-h-[70%] h-[80%] overflow-hidden">
      <div className="w-[310px] p-5 mb-2 row items-center overflow-y-auto h-auto border border-[#C8C8C8] bg-white res150:w-52 res150:mx-auto res125:w-72 res125:mx-auto">
        <div className="flex mb-5 res150:mb-3">
          <div className="w-2/3 text-[#03298C] font-bold text-base res150:text-sm">
            Project Details
          </div>
          {type !== "edit-project" && (
            <div
              className="ml-auto cursor-pointer"
              onClick={() => navigate.push("/admin/mails/create-project/2")}
            >
              <EditIcon className="res150:w-4" />
            </div>
          )}
        </div>
        <hr className="text-[#CAD9FF] mb-5 res150:mb-3" />
        <div className="flex flex-grow flex-col">
          <div className="text-black font-normal text-sm res150:text-xs">
            <p className="pb-2">{projectInfo.name}</p>
            <p>{projectInfo?.projectUniqueId}</p>
            <p className="h-auto py-2">{projectInfo.description}</p>
            <p className="pb-2">No. of Tags: {projectInfo.tags}</p>
            <p>{projectInfo.category}</p>
          </div>
        </div>
      </div>
      {tagInfo?.length > 0 &&
        tagInfo?.map((value, index) => {
          return (
            <div
              className="w-[310px] p-5 mb-2 row items-center overflow-y-auto h-auto border border-[#C8C8C8] bg-white res150:w-52 res150:mx-auto res125:w-72 res125:mx-auto"
              key={index}
            >
              <div className="flex mb-5 res150:mb-3">
                <div className="w-2/3 text-[#03298C] font-bold text-base res150:text-sm">
                  Tag {index + 1} Details
                </div>
                {type !== "edit-project" && (
                  <div
                    className="ml-auto cursor-pointer"
                    onClick={() =>
                      navigate.push({
                        pathname: "/admin/mails/create-project/3",
                        state: { data: index },
                      })
                    }
                  >
                    <EditIcon className="res150:w-4" />
                  </div>
                )}
              </div>
              <hr className="text-[#CAD9FF] mb-5 res150:mb-3" />
              <div className="flex flex-grow flex-col">
                <div className="text-black font-normal text-sm res150:text-xs">
                  <p>{value.name}</p>
                  {/* <p className="h-auto py-2">{value.description}</p>
                  <p className="pb-2">Sub Category: {value.sub_category}</p>
                  <p className="pb-2">primary Scope: {value.primary_scope}</p> */}
                  <p className="h-auto py-2">{value.description}</p>
                  <p className="pb-2">Sub Category : {value.sub_category}</p>
                  <p className="pb-2">Vertical : {value.verticals}</p>
                  <p className="pb-2">Sub Vertical : {value.sub_vertical}</p>

                  {value.serviceRequired.map((tech, index) => {
                    return (
                      <ul key={index}>
                        <li className="pb-2">{tech.name}</li>
                      </ul>
                    );
                  })}
                  {value.attachments.map((attach, index) => {
                    return (
                      <ul key={index}>
                        <li className="pb-2 flex text-[#4277FF] text-sm font-normal text-center res150:text-xs">
                          <DownloadIcon className="pr-1.5 res150:w-5 res150:pb-1" />
                          {attach.name}
                        </li>
                      </ul>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default CreatePreviewProject;
