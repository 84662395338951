import React, { useState, useEffect, useContext } from "react";
import {
  CUSTOMER_INFO_STEPPER,
  DEFAULT_CUSTOMER_INFO_STATE,
  BASE_URL,
} from "../../../../constants/Constants";
import AppContext from "../../../../context/AppContext";
import axios from "axios";
import Loader from "../../../../Loader";

const ModifyProjectForm1 = ({ error }) => {
  const [dropdownValues, setDropdownValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState("Select Customer");
  const { customerInfo, setCustomerInfo } = useContext(AppContext);
  const [isDropDownSelected, setDropdownSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  // Bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    setLoading(true);
    const existingCustomerInfo = localStorage.getItem(CUSTOMER_INFO_STEPPER);
    if (existingCustomerInfo !== null && existingCustomerInfo !== "undefined") {
      if (JSON.parse(existingCustomerInfo).customer_id) {
        setCustomerInfo(JSON.parse(existingCustomerInfo));
        setSelectedValue(JSON.parse(existingCustomerInfo).name);
        setDropdownSelected(true);
      }
    }
    axios
      .get(`${BASE_URL}/api/customers`, { headers })
      .then((res) => {
        setLoading(false);
        setDropdownValues([...res.data.message]);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const handleSelection = (value) => {
    if (value === "None") {
      setSelectedValue("None");
      localStorage.removeItem(CUSTOMER_INFO_STEPPER);
      setCustomerInfo(DEFAULT_CUSTOMER_INFO_STATE);
    } else {
      const selectedOption = dropdownValues.find(
        (option) => option.name === value
      );
      setSelectedValue(selectedOption.name);
      localStorage.setItem(
        CUSTOMER_INFO_STEPPER,
        JSON.stringify(selectedOption)
      );
      setCustomerInfo(selectedOption);
    }
  };

  const handleCustomerInfoForm = (event, type) => {
    let existingCustomerInfo = { ...customerInfo };
    if (type === "organizations") {
      const { name, value } = event.target;
      existingCustomerInfo[type] = {
        ...existingCustomerInfo[type],
        [name]: value,
      };
      setCustomerInfo(existingCustomerInfo);
    } else {
      const { name, value } = event.target;
      existingCustomerInfo[name] = value;
      setCustomerInfo(existingCustomerInfo);
    }
    if (
      !existingCustomerInfo.name &&
      !existingCustomerInfo.contact_no &&
      !existingCustomerInfo.email &&
      !existingCustomerInfo.organizations.name &&
      !existingCustomerInfo.organizations.size
    ) {
      localStorage.removeItem(CUSTOMER_INFO_STEPPER);
    }
  };

  return (
    <div className="mt-12 res150:mt-8 res125:mt-6">
      <div className="mx-14">
  <select
    value={selectedValue}
    onChange={(event) => handleSelection(event.target.value)}
    className="font-normal bg-[#FFFFFF] py-3 px-5 inline-flex items-center w-full text-[#929292] focus:ring-1 focus:ring-[#C8C8C8] res125:py-2 res125:text-sm res150:h-7 res150:text-xs res150:py-1.5 res150:px-3 border border-[#C8C8C8]"
  >
    <option value="Select Customer" selected disabled>Select Customer</option>
    {loading ? (
  <option disabled>Please Wait...</option>
) : (
  dropdownValues.map((value, index) => (
    <option key={index} value={value.name}>
      {value.name}
    </option>
  ))
)}
    {/* <option value="Select Customer">Select Customer</option>
    <option value="None">None</option>
    {dropdownValues.map((value, index) => (
      <option key={index} value={value.name}>
        {value.name}
      </option>
    ))} */}
  </select>
</div>

      <div className="flex my-8 items-center mx-auto justify-center res125:my-4">
        <hr className="w-1/3 res150:w-1/4 res125:w-[30%]" />
        <span className="px-2">or</span>
        <hr className="w-1/3 res150:w-1/4 res125:w-[30%]" />
      </div>

      <div class="flex flex-wrap max-w-md mx-12 -m-2 mb-5 ">
        {error && (
          <div
            className="bg-red-100 border text-center border-red-400 text-red-700 px-2 py-2 w-full rounded mb-2 mx-2 res150:text-xs res150:py-1.5 res125:text-sm res125:py-1"
            role="alert"
          >
            <span className="block sm:inline">
              * Please fill all the fields.
            </span>
          </div>
        )}
        <div class="w-full p-2">
          <input
            class="w-full px-5 py-3 text-sm  placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res150:text-xs res150:py-1.5  res125:py-2 res125:px-3 res150:px-2"
            type="text"
            placeholder="Customer Name"
            name={"name"}
            value={customerInfo.name || ""}
            onChange={handleCustomerInfoForm}
            disabled={!isDropDownSelected}
          />
        </div>
        <div class="w-full p-2">
          <input
            class="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res150:text-xs res150:py-1.5 res125:py-2 res125:px-3 res150:px-2"
            type="text"
            placeholder="Contact No."
            name={"contact_no"}
            value={customerInfo.contact_no || ""}
            onChange={handleCustomerInfoForm}
            disabled={!isDropDownSelected}
          />
        </div>
        <div class="w-full p-2">
          <input
            class="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res150:text-xs res150:py-1.5 res125:py-2 res125:px-3 res150:px-2"
            type="email"
            placeholder="Email ID"
            name={"email"}
            value={customerInfo.email || ""}
            onChange={handleCustomerInfoForm}
            disabled={!isDropDownSelected}
          />
        </div>
        <div class="w-full p-2">
          <input
            class="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res150:text-xs res150:py-1.5 res125:py-2 res125:px-3 res150:px-2"
            type="text"
            placeholder="Organization Name"
            name={"name"}
            value={customerInfo.organizations?.name || ""}
            onChange={(e) => handleCustomerInfoForm(e, "organizations")}
            disabled={!isDropDownSelected}
          />
        </div>
        <div class="w-full p-2">
          <input
            class="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res150:text-xs res150:py-1.5 res125:py-2 res125:px-3 res150:px-2"
            type="text"
            placeholder="Organization Size"
            name={"size"}
            value={customerInfo.organizations?.size || ""}
            onChange={(e) => handleCustomerInfoForm(e, "organizations")}
            disabled={!isDropDownSelected}
          />
        </div>
      </div>
    </div>
  );
};

export default ModifyProjectForm1;
