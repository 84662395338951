import EmailDetails from "../../Mails/EmailDetails";
import AppContext from "../../../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import CreateProjectForm1 from "./CreateProjectForm1";
import CreateProject from "./CreateProject";
import axios from "axios";
import { BASE_URL } from "../../../../constants/Constants";
import { toast } from "react-hot-toast";
import swal from "sweetalert";

const CreateCustomerForm = () => {
  const { selectedMail, customerInfo } = useContext(AppContext);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [error, setError] = useState(false);
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/customers`, { headers })
      .then((res) => {
        setDropdownValues([...res.data.message]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const validateFields = async () => {
    let error = "";
    if (
      !customerInfo.name ||
      !customerInfo.contact_no ||
      !customerInfo.organizations.name ||
      !customerInfo.organizations.size
    ) {
      error = "Please fill all the fields.";
      // setError(true);
      setError(error);

    } else if (!customerInfo.email) {
      error = "Please enter an email.";
      // setError(true);
      setError(error);

    } else {
      // Email format validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(customerInfo.email)) {
        error = 'Please enter a valid email.';
        setError(error);

      } else if (!customerInfo.id) {
        error = await axios
          .get(`${BASE_URL}/api/email-check`, {
            params: {
              email: customerInfo.email,
            },
          })
          .then((res) => {
            return "";
          })
          .catch((error) => {
            error = "Email Already exist.";
            // setError(true);
            setError(error);
            toast.error(error);
            swal(`${error}`, "Please try some other email", "warning");
            return error;
          });
      }
    }
    return error;
  };

  return (
    <div className="flex ml-auto w-4/5 overflow-hidden h-screen">
      <div className="h-screen bg-indigo-200 w-5"></div>
      <EmailDetails showMailList={false} selectedMail={selectedMail} />
      <CreateProject className="flex ml-auto" validateFields={validateFields}>
        <CreateProjectForm1 dropdownValues={dropdownValues} error={error} />
      </CreateProject>
    </div>
  );
};

export default CreateCustomerForm;
