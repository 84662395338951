import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useRef, useState } from "react";
import {
  BASE_URL,
  primaryScopeOptions,
  subCategoryOptions,
} from "../../../constants/Constants";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import countryCodesData from "../../../assets/json/CountryCode.json";

export default function FreeSetting({ filename }) {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [contact, setContact] = useState("");
  const [primaryScope, setPrimaryScope] = useState("");
  const [hours, setHours] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [category, setCategory] = useState("Process Plants");
  const [subCategory, setSubCategory] = useState("");
  const [technicalScope, setTechnicalScope] = useState([]);
  const [technicalScopeOptions, setTechnicalScopeOptions] = useState([]);
  const [showTechScope, canShowTechScope] = useState(false);
  const multiselectRef = useRef();
  const [enterKeyPressed, setEnterKeyPressed] = useState(false);
  const submitButtonRef = useRef();

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const navigate = useHistory();

  const storedData = JSON.parse(localStorage.getItem("User"));
  const [countryCodes, setCountryCodes] = useState(countryCodesData.countries);
  const [selectedOption, setSelectedOption] = useState("+91");

  useEffect(() => {
    getVendorDetails();
  }, []);

  const getVendorDetails = async () => {
    const params = { vendor_id: storedData.id };
    await axios
      .get(`${BASE_URL}/api/vendor/details`, { params, headers })
      .then((res) => {
        setPrimaryScope([...JSON.parse(res.data.data.primary_scope)]);
        setSubCategory(res.data.data.sub_category);
        setHours(res.data.data.totalhourstoworks);
        setTechnicalScope([...JSON.parse(res.data.data.technical_scope)]);
      })
      .catch((err) => {
        console.error(err);
        //setError(err.response.data.message);
      });
  };

  const dropDownChange = ({ target: { name, value } }) => {
    switch (name) {
      case "category":
        setCategory(value);
        setSubCategory("");
        setPrimaryScope("");
        break;
      case "subCategory":
        setSubCategory(value);
        setPrimaryScope([]);
        setTechnicalScope([]);
        break;
      default:
        break;
    }
    resetSelectField();
  };

  const resetSelectField = () => {
    multiselectRef.current?.resetSelectedValues();
  };

  const techScopes = async () => {
    await axios
      .get(`${BASE_URL}/api/tech-scopes`, {
        params: {
          primary_scope_type: JSON.stringify(primaryScope),
          sub_category_type: subCategory,
        },
      })
      .then((res) => {
        setTechnicalScopeOptions([...res.data.message]);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/tech-scopes`, {
        params: {
          primary_scope_type: JSON.stringify(primaryScope),
          sub_category_type: subCategory,
        },
      })
      .then((res) => {
        setTechnicalScopeOptions([...res.data.message]);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [primaryScope, subCategory]);

  useEffect(() => {
    if (storedData) {
      setEmail(storedData.email);
      setUsername(storedData.name);
      if (storedData?.contact_no) {
        const [countryCode, phoneNumber] = storedData.contact_no.split(" - ");
        setSelectedOption(countryCode);
        setContact(phoneNumber);
      }
    }
  }, []);

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "username") {
      setUsername(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "contact") {
      setContact(value);
    } else if (name === "hours") {
      setHours(value);
    } else if (name === "primaryscope") {
      setPrimaryScope(value);
    } else if (name === "subCategory") {
      setSubCategory(value);
    }
  };

  const handleSelect = (selectedList) => {
    setTechnicalScope(selectedList);
  };

  const handleRemove = (selectedList) => {
    setTechnicalScope(selectedList);
  };

  const handleCancel = () => {
    navigate.push("/service-provider/projects");
  };

  const handlePSSelect = (selectedList) => {
    if (selectedList.length > 0) {
      canShowTechScope(true);
    } else {
      canShowTechScope(false);
    }
    techScopes();
    let existingUser = primaryScope;
    existingUser = selectedList;
    setPrimaryScope(existingUser);
    setTechnicalScope([]);
  };

  const handlePSRemove = (selectedList) => {
    resetSelectField();
    if (selectedList.length > 0) {
      canShowTechScope(true);
    } else {
      canShowTechScope(false);
    }
    techScopes();
    let existingUser = primaryScope;
    existingUser = selectedList;
    setPrimaryScope(existingUser);
    setTechnicalScope([]);
  };

  useEffect(() => {
    if (primaryScope.length > 0) {
      canShowTechScope(true);
    }
  }, [primaryScope]);

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      setEnterKeyPressed(true);
      event.preventDefault(); // Prevent form submission if necessary
    }
  };


  // define a handleSubmit function to pass the input values to the payload
  const handleSubmit = async () => {
    if (
      !email ||
      !username ||
      !contact ||
      !hours ||
      !primaryScope.length ||
      !subCategory ||
      !category ||
      !technicalScope.length
    ) {
      setError("Please Fill the all field");
    } else {
      const payload = {
        email,
        name: username,
        contact_no: `${selectedOption} - ${contact}`,
        profile_picture: filename,
        totalhourstoworks: hours,
        primary_scope: primaryScope,
        technical_scope: technicalScope,
        category: category,
        subCategory: subCategory,
        vendor_id: storedData.id,
      };

      // console.log(payload);
      setLoading(true);
      setIsDisabled(true);
      setError("");
      await axios
        .put(`${BASE_URL}/api/vendor/update`, payload, { headers })
        .then((res) => {
          // console.log(res);
          const updatedUser = {
            ...storedData,
            email: res.data.user.email,
            name: res.data.user.name,
            contact_no: res.data.user.contact_no,
            profile_picture: res.data.user.profile_picture,
          };
          localStorage.setItem("User", JSON.stringify(updatedUser));
          setLoading(false);
          setIsDisabled(false);
          toast.success("Profile updated successfully");
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          setIsDisabled(false);
          toast.error("Please Try Again");
          setError(err.response.data.message);
        });
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submitButtonRef.current.click(); // Programmatically click the submit button
    }
  };
  useEffect(() => {
    if (enterKeyPressed) {
      handleSubmit();
      setEnterKeyPressed(false);
    }
  }, [enterKeyPressed]);

  return (
    <>
      {error && (
        <div
          class="bg-red-100 border w-2/6 border-red-400 text-red-700 px-4 py-3  mx-auto mt-10 rounded relative text-center "
          role="alert"
        >
          <span class="block sm:inline">{error}</span>
        </div>
      )}
      <div className="grid grid-cols-2 gap-7 mx-auto w-4/5 mt-10">
        <div>
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700 res125:text-sm res150:text-xs"
          >
            Full name
          </label>
          <input
            id="first-name"
            className="mt-1 px-4 py-2 rounded  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14 res125:h-9 res150:h-8 res125:text-sm res150:text-xs"
            type="text"
            placeholder="Enter your first name"
            value={username}
            name="username"
            onChange={handleInputChange}
            onKeyDown={handleKeyPress} 
          />
        </div>

        <div>
          <label
            htmlFor="contact"
            className="block text-sm font-medium text-gray-700 res125:text-sm res150:text-xs"
          >
            Contact No
          </label>
          <div className="flex w-full gap-4">
            <select
              className="w-[8rem] h-14 mt-1 rounded border-[#C8C8C8] uppercase res125:h-9 res150:h-8 res150:w-[7rem] res125:text-sm res150:text-xs"
              value={selectedOption}
              onChange={(event) => setSelectedOption(event.target.value)}
            >
              {countryCodes?.map((code) => (
                <option key={code.code} value={code.code}>
                  {code.name.slice(0, 3)} ({code.code})
                </option>
              ))}
            </select>
            <input
              className="mt-1 px-4 py-2 rounded border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14 ml-auto res125:text-sm res150:text-xs res125:h-9 res150:h-8"
              type="text"
              placeholder="Enter your Contact no"
              name="contact"
              value={contact}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress} 
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 res125:text-sm res150:text-xs"
          >
            Email
          </label>
          <input
            className="mt-1 px-4 py-2 rounded border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14 res125:text-sm res150:text-xs res125:h-9 res150:h-8"
            type="email"
            placeholder="Enter your email"
            name="email"
            value={email}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress} 
          />
        </div>

        <div>
          <label
            htmlFor="Total Work Hours"
            className="block text-sm font-medium text-gray-700 res125:text-sm res150:text-xs"
          >
            Total Work Hours (In a week)
          </label>
          <input
            className="mt-1 px-4 py-2 rounded border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14 res125:text-sm res150:text-xs res125:h-9 res150:h-8"
            type="text"
            placeholder="Total Work Hours"
            name="hours"
            value={hours}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress} 
          />
        </div>

        <div>
          <label
            htmlFor="category"
            className="block font-medium text-gray-700 text-sm mb-2 res125:text-sm res150:text-xs"
          >
            Category:
          </label>
          <select
            id="category"
            name="category"
            value={category}
            onChange={dropDownChange} 
            onKeyDown={handleKeyPress}
            className="px-4 py-2 rounded  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14 res125:text-sm res150:text-xs res125:h-9 res150:h-8"
          >
            <option value="" selected disabled>
              Select Category
            </option>
            <option value="Process Plants">Process Plants</option>
          </select>
        </div>

        <div>
          <label
            htmlFor="sub-category"
            className="block font-medium text-gray-700 text-sm mb-2 res125:text-sm res150:text-xs"
          >
            Sub Category:
          </label>
          <select
            id="sub-category"
            name="subCategory"
            value={subCategory}
            onChange={dropDownChange}
            onKeyDown={handleKeyPress} 
            className="px-4 py-2 rounded  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14 res125:text-sm res150:text-xs res125:h-9 res150:h-8"
            disabled={!category}
          >
            <option value="" selected disabled>
              Select Sub Category
            </option>
            {subCategoryOptions &&
              subCategoryOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
        </div>

        <div>
          {subCategory && (
            <>
              <label
                htmlFor="primary-scope"
                className="block text-sm font-medium text-gray-700 mb-2 res125:text-sm res150:text-xs"
              >
                Primary Scope:
              </label>
              <div className=" flex items-center justify-center w-full bg-white res125:text-sm res150:text-xs">
                <div className="w-full border border-[#C8C8C8] ml-0 mr-2 signup  ">
                  <Multiselect
                    ref={multiselectRef}
                    options={primaryScopeOptions[subCategory]}
                    selectedValues={primaryScope}
                    onSelect={handlePSSelect}
                    onRemove={handlePSRemove}
                    displayValue="value"
                    placeholder="Select primary scope"
                    style={{
                      searchBox: {
                        border: "none",
                        fontSize: "10px",
                        minHeight: "40px",
                        width: "440px !important",
                      },
                      optionContainer: {
                        maxHeight: "250px",
                      },
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <div className="">
          {showTechScope && (
            <>
              <label
                htmlFor="technical-scope"
                className="block mb-2 res125:text-sm res150:text-xs"
              >
                Technical Scope:
              </label>
              <div className=" flex items-center justify-center w-full bg-white res125:text-sm res150:text-xs">
                <div className="w-full border border-[#C8C8C8] ml-0 mr-2 signup  ">
                  <Multiselect
                    ref={multiselectRef}
                    options={technicalScopeOptions}
                    selectedValues={technicalScope}
                    onSelect={handleSelect}
                    onRemove={handleRemove}
                    displayValue="name"
                    placeholder="Select technical scope"
                    style={{
                      searchBox: {
                        border: "none",
                        fontSize: "10px",
                        minHeight: "40px",
                        width: "440px !important",
                      },
                      optionContainer: {
                        maxHeight: "250px",
                      },
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex gap-10 mx-auto justify-center mt-20 res150:mt-8 res125:mt-8">
        <button
          className="w-[10rem] border-[0.5px] border-[#03298C] h-12 res125:text-sm res150:text-xs rounded hover:bg-[#03298C] hover:text-white font-bold res150:h-8 res125:h-8"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isDisabled}
          onClick={handleSubmit}
          ref={submitButtonRef}
          onKeyUp={handleKeyUp}
          className={`bg-[#03298C] w-[10rem] h-12 hover:bg-[#72C27A] text-white font-bold res125:text-sm res150:text-xs rounded res150:h-8 res125:h-8 ${
            isDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {loading ? (
            <div className="flex justify-center ">
              <img
                src="https://media1.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif"
                className="w-[2rem] mx-auto res125:w-9 res150:w-6"
                alt=""
              />
            </div>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </>
  );
}
