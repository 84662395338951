import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../../constants/Constants";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
// import "jspdf-autotable";
// import html2PDF from "jspdf-html2canvas";
import moment from "moment";
import AppContext from "../../../context/AppContext";

const TheadData = [
  "S.No",
  "Tag Name",
  "Description",
  "Microservice",
  "Price",
  "Tag Total",
  "Remarks",
];

export default function QuotationTable({
  show,
  setShow,
  projectData,
  editProjectData,
}) {
  //   console.log(projectData,"projectdata")
  const { id } = useParams();
  const [tagData, setTagData] = useState([]);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [splitData, setSplitData] = useState([]);
  const location = useLocation();
  const [error, SetError] = useState(null);
  // const { user } = useContext(AppContext);

  const handleBack = () => {
    // Logic to handle going back or closing the component
    setShow(false);
  };
  useEffect(() => {
    if (tagData.length > 0) {
      let count = 0;
      let array = [];
      let index1 = 0;

      tagData.forEach((tag, index) => {
        // Initialize the array[index1] if it's undefined
        if (!array[index1]) {
          array[index1] = { tags: [] };
          count = 0; // Reset count for each new group
        }

        tag.serviceRequired.forEach((scope) => {
          const strLen = scope.microservice.length;
          const linesNeeded = Math.ceil(strLen / 34);

          if (count + linesNeeded <= 33) {
            count += linesNeeded;
            const tagIndex = array[index1].tags.findIndex(
              (tag1) => tag1.name === tag.name
            );

            if (tagIndex === -1) {
              array[index1].tags.push({
                count: index,
                description: tag.description,
                grandTotal: tag.grandTotal,
                name: tag.name,
                remarks: tag.remarks,
                tagTotal: tag.tagTotal,
                serviceRequired: [scope],
              });
            } else {
              array[index1].tags[tagIndex].serviceRequired.push(scope);
            }
          } else {
            count = linesNeeded;
            index1 += 1;
            array[index1] = { tags: [] };
            array[index1].tags.push({
              count: index,
              description: tag.description,
              grandTotal: tag.grandTotal,
              name: tag.name,
              remarks: tag.remarks,
              tagTotal: tag.tagTotal,
              serviceRequired: [scope],
            });
          }
        });
      });

      setSplitData([...array]);
    }
  }, [tagData]);
  const user = JSON.parse(localStorage.getItem("User"));

  useEffect(() => {
    if (editProjectData) {
      const processTags = (tags) => {
        return JSON.parse(tags).map((tag) => ({
          name: tag.name,
          grandTotal: editProjectData.totalPrice,
          tagTotal: tag.tag_price,
          remarks: tag.remarks,
          description: tag.description,
          serviceRequired: tag.scopes.map((scope) => ({
            microservice: scope.microservice,
            price: scope.price,
            existing: scope.existing,
          })),
        }));
      };

      if (editProjectData.tags) {
        const tagsArray = processTags(editProjectData.tags);
        setTagData(tagsArray);
      }
    } else {
      if (projectData.tags) {
        const convertData = projectData.tags.map((tag) => {
          const { name, serviceRequired, description } = tag;
          const scopes = serviceRequired?.map((scope) => ({
            microservice: scope.microservice,
            price: 0,
          }));

          return {
            name: name,
            serviceRequired: scopes,
            tagTotal: 0,
            description,
            remarks: "",
            grandTotal: 0,
            // total: 0
          };
        });

        setTagData(convertData);
      }
    }
  }, [projectData, editProjectData]);

  const handlePriceChange = (tagName, microserviceName, newPrice) => {
    setTagData((prevTagData) => {
      const updatedTagData = prevTagData.map((tag) => {
        if (tag.name === tagName) {
          const updatedScopes = tag.serviceRequired.map((scope) =>
            scope.microservice === microserviceName
              ? { ...scope, price: newPrice }
              : scope
          );
          const tagTotal = updatedScopes.reduce(
            (total, scope) => total + parseFloat(scope.price) || 0,
            0
          );
          return {
            ...tag,
            serviceRequired: updatedScopes,
            tagTotal: tagTotal.toFixed(0),
          };
        }
        return tag;
      });

      const grandTotal = updatedTagData.reduce(
        (total, tag) => total + parseFloat(tag.tagTotal) || 0,
        0
      );

      setTagData((prevTagData) =>
        prevTagData.map((tag) => {
          return { ...tag, grandTotal: grandTotal.toFixed(0) };
        })
      );

      return updatedTagData;
    });
  };

  const handleRemarksChange = (tagName, microserviceName, newRemarks) => {
    setTagData((prevTagData) => {
      const updatedTagData = prevTagData.map((tag) => {
        if (tag.name === tagName) {
          return {
            ...tag,
            remarks: newRemarks,
          };
        }
        return tag;
      });
      return updatedTagData;
    });
  };

  const fileUpload = async (pdfBlob, file) => {
    let success = false;
    const s3Client = new S3Client({
      region: "ap-south-1",
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    });

    const params = {
      Bucket: "factreehub-docs",
      Key: `quotation/${file}`,
      ContentType: "application/pdf",
      Body: pdfBlob,
    };

    try {
      const putObjectCommand = new PutObjectCommand(params);
      await s3Client.send(putObjectCommand);
      toast.success("File uploaded successfully!");
      success = true;
    } catch (error) {
      toast.error("Please Try Again");
      success = false;
      console.error(error);
    }
  };

  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const handleSubmit = async () => {
    const hasEmptyPrice = tagData.some((tag) =>
      tag.serviceRequired.some((scope) => scope.price === "")
    );

    if (hasEmptyPrice) {
      SetError("Please fill in all prices before submitting.");
      return;
    }
    SetError(null);
    const quotationData = tagData.map((tag) => ({
      name: tag.name,
      tag_price: tag.tagTotal,
      description: tag.description,
      remarks: tag.remarks,
      scopes: tag.serviceRequired.map((scope) => ({
        microservice: scope.microservice,
        price: scope.price,
        // existing: ["3000"],
      })),
    }));

    // console.log("Send to API Combined object", payload);

    const generateRandomString = (length) => {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    };

    const projectName = projectData.name.replace(/[^\w\s]/gi, "");
    const formattedDate = moment(new Date()).format("MMM YYYY");
    const file = `${projectName}_${formattedDate}_Quotation`;
    let version = generateRandomString(Math.floor(Math.random() * 3) + 4);
    let fileName = `${file}_${version}.pdf`;

    const payload = {
      project_id: id,
      tags: quotationData,
      totalPrice: tagData.length > 0 ? tagData[0].grandTotal : 0,
      remarks: "stacked",
      userType: "Admin",
      file: fileName,
      status: "Pending",
    };

    const payload2 = {
      project_id: id,
      tags: quotationData,
      totalPrice: tagData.length > 0 ? tagData[0].grandTotal : 0,
      remarks: "stacked",
      userType: "service-provider",
      file: fileName,
      status: "Pending",
      vendor_id: user.id,
    };

    if (location.pathname.includes("admin")) {
      axios
        .post(`${BASE_URL}/api/customerQuotation/createORupdate`, payload, {
          headers,
        })
        .then((res) => {
          toast.success("Quotation Upload Successfully !");
          setShow(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Please try again");
        });
    } else if (location.pathname.includes("service-provider")) {
      axios
        .post(`${BASE_URL}/api/quotation/createORupdate`, payload2, {
          headers,
        })
        .then((res) => {
          toast.success("Quotation Upload Successfully !");
          setShow(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Please try again");
        });
    }
  };
  return (
    <div className="my-5">
      {/* {editProjectData ? ( */}
      <button className="justify-start text-blue-900 " onClick={handleBack}>
        <b> &lt; Back</b>
      </button>
      {/* ) : (
        <></>
      )} */}

      <div className="flex justify-end">
        <button
          className="bg-[#3c5db4] text-white font-bold py-2 px-4 rounded m-1"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
      {error && (
        <div
          class="bg-red-100 border border-red-400 text-red-700 px-4 py-3  text-center rounded relative mb-2 res125:py-2 res125:text-sm res150:py-2 res150:text-xs res125:h-9 res150:h-8"
          role="alert"
        >
          <span class="block sm:inline">{error}</span>
          {/* {expire && <p>Your account has expired</p>} */}
        </div>
      )}
      <table
        className="w-full text-sm text-left text-black border-3"
        // id="quotation-table"
      >
        <thead className="text-base text-[#03298C] bg-[#ECFFFF] res125:text-sm res150:text-xs">
          <tr className="text-left">
            {TheadData.map((heading, i) => {
              return (
                <>
                  <th
                    key={i}
                    scope="col"
                    className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 border text-center"
                  >
                    {heading}
                  </th>
                </>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {tagData.map((tag, i) => (
            <React.Fragment key={tag.name}>
              <tr>
                <td
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 border"
                  rowSpan={tag.serviceRequired?.length}
                  style={{ verticalAlign: "middle" }}
                >
                  {i + 1}
                </td>
                <td
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 border"
                  rowSpan={tag.serviceRequired?.length}
                  style={{ verticalAlign: "middle" }}
                >
                  {tag.name}
                </td>
                <td
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 border"
                  rowSpan={tag.serviceRequired?.length}
                  style={{ verticalAlign: "middle" }}
                >
                  {tag.description}
                </td>
                <td className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 border">
                  {" "}
                  {tag.serviceRequired[0]?.microservice}
                </td>
                <td className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 border text-center">
                  {" "}
                  <input
                    type="number"
                    value={tag.serviceRequired[0]?.price}
                    className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 border-none text-center"
                    placeholder="Enter your price"
                    onChange={(e) =>
                      handlePriceChange(
                        tag.name,
                        tag.serviceRequired[0]?.microservice,
                        e.target.value
                      )
                    }
                  />
                </td>
                <td
                  rowSpan={tag.serviceRequired?.length}
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 border text-center"
                  style={{ verticalAlign: "middle" }}
                >
                  {tag.tagTotal}
                </td>
                <td
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 border text-center"
                  rowSpan={tag.serviceRequired?.length}
                >
                  <input
                    type="text"
                    placeholder="Remarks"
                    value={tag.remarks}
                    className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 border-none text-center"
                    onChange={(e) =>
                      handleRemarksChange(
                        tag.name,
                        tag.serviceRequired[0]?.microservice,
                        e.target.value
                      )
                    }
                  />
                </td>
              </tr>
              {tag.serviceRequired?.slice(1).map((scope) => (
                <tr key={scope.microservice}>
                  <td className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 border">
                    {scope.microservice}
                  </td>
                  <td className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 border text-center">
                    <input
                      className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 border-none text-center"
                      type="number"
                      value={scope.price}
                      placeholder="Enter your price"
                      onChange={(e) =>
                        handlePriceChange(
                          tag.name,
                          scope.microservice,
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
          <tr>
            <td
              className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 border font-semibold"
              colSpan={6}
              style={{ textAlign: "center" }}
            >
              Grand Total
            </td>
            <td
              colSpan={1}
              className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 border text-center"
            >
              {tagData.length > 0 ? tagData[0].grandTotal : 0}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
