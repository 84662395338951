import { Switch } from "react-router-dom";
import DefaultLayout from "../components/layouts/DefaultLayout";
import AuthLayout from "../components/layouts/AuthLayout";
import ProtectedRoute from "./ProtectedRoute";
import SignIn from "./../components/Signin/index"
// import SignIn from "../components/Signin";
import Error404 from "../components/404";
import Home from "../components/Home";
import ModifyProject from "../components/Admin/CreateOrModifyProject/ModifyProject/ModifyProject";
import CreateProject from "../components/Admin/CreateOrModifyProject/CreateProject/CreateProject";
import CreateCustomerForm from "../components/Admin/CreateOrModifyProject/CreateProject/CreateCustomerForm";
import CreateProjectForm from "../components/Admin/CreateOrModifyProject/CreateProject/CreateProjectForm";
import CreateTagForm from "../components/Admin/CreateOrModifyProject/CreateProject/CreateTagForm";
import Projects from "../components/Shared/Projects/ProjectDashboard";
import Mails from "../components/Admin/Mails/Mails";
import MicroserviceTable from "../components/Admin/AddMicro/MicroServiceTable";
import CustomerDashboard from "../components/Customer/Projects/Dashboard";
import CustomerTicket from "../components/Customer/Tickets/Ticket";
import CustomerMail from "../components/Customer/MailIds/MailCustomer";
import ProjectDetails from "../components/Shared/Projects/ProjectDetails";
import ModifyCustomerForm from "../components/Admin/CreateOrModifyProject/ModifyProject/ModifyCustomerForm";
import ModifyProjectForm from "../components/Admin/CreateOrModifyProject/ModifyProject/ModifyProjectForm";
import ModifyTagForm from "../components/Admin/CreateOrModifyProject/ModifyProject/ModifyTagForm";
import ModifyProjectTagPreview from "../components/Admin//CreateOrModifyProject/ModifyProject/ModifyProjectTagPreview";
import CreateProjectTagPreview from "../components/Admin/CreateOrModifyProject/CreateProject/CreateProjectTagPreview";
import AdminSignupForm from "../components/Admin/Signup/AdminSignupForm";
import Organization from "../components/Admin/Organizations/Organizations";
import ServiceProvider from "../components/Admin/ServiceProviderList/ServiceProvider";
import Teams from "../components/Shared/Teams/Teams";
import Settings from "../components/Shared/Settings/Settings";
import ServiceSignUp from "../components/ServiceProvider/Signup/ServiceSignup";
import Notifications from "../components/Shared/Notifications/Notifications";
import ForgetPass from "../components/Signin/ForgetPass";
import CustomerSignupForm from "../components/Customer/Signup/CustomerSignup";
import CreateProjectForms from "../components/Customer/CreateOrModifyProject/CreateProject/CreateProjectForm";
import CreateProjects from "../components/Customer/CreateOrModifyProject/CreateProject/CreateProject";
import CreateProjectForms1 from "../components/Customer/CreateOrModifyProject/CreateProject/CreateProjectForm1";
import CreateTagForms from "../components/Customer/CreateOrModifyProject/CreateProject/CreateTagForm";
import CreateProjectTagPreviews from "../components/Customer/CreateOrModifyProject/CreateProject/CreateProjectTagPreview";
import CreateQuotation from "../components/ServiceProvider/Quotations/CreateQuotation"
import help from "../components/Admin/Settings/help";
const AllRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute exact path="/" layout={DefaultLayout} component={Home} />
      <ProtectedRoute
        exact
        path="/admin/mails"
        layout={DefaultLayout}
        component={Mails}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/add"
        layout={DefaultLayout}
        component={MicroserviceTable}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/projects"
        layout={DefaultLayout}
        component={Projects}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/organizations"
        layout={DefaultLayout}
        component={Organization}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/service-providers"
        layout={DefaultLayout}
        component={ServiceProvider}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/teams"
        layout={DefaultLayout}
        component={Teams}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/projects/:id"
        layout={DefaultLayout}
        component={ProjectDetails}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/settings"
        layout={DefaultLayout}
        component={Settings}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/mails/modify-project"
        layout={DefaultLayout}
        component={ModifyProject}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/mails/modify-project/1"
        layout={DefaultLayout}
        component={ModifyCustomerForm}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/mails/modify-project/2"
        layout={DefaultLayout}
        component={ModifyProjectForm}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/mails/modify-project/3"
        layout={DefaultLayout}
        component={ModifyTagForm}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/mails/modify-project/4"
        layout={DefaultLayout}
        component={ModifyProjectTagPreview}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/mails/create-project"
        layout={DefaultLayout}
        component={CreateProject}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/mails/create-project/1"
        layout={DefaultLayout}
        component={CreateCustomerForm}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/mails/create-project/2"
        layout={DefaultLayout}
        component={CreateProjectForm}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/mails/create-project/3"
        layout={DefaultLayout}
        component={CreateTagForm}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/admin/mails/create-project/4"
        layout={DefaultLayout}
        component={CreateProjectTagPreview}
        role="admin"
      />
      <ProtectedRoute
        exact
        path="/auth/signin"
        layout={AuthLayout}
        component={SignIn}
      />
      <ProtectedRoute
        exact
        path="/auth/customer/signup"
        layout={AuthLayout}
        component={CustomerSignupForm}
      />
      <ProtectedRoute
        exact
        path="/api/resetpassword/:authtoken"
        layout={AuthLayout}
        component={ForgetPass}
      />
      <ProtectedRoute
        exact
        path="/auth/admin/signup"
        layout={AuthLayout}
        component={AdminSignupForm}
      />
      {/* customer routes start */}
      <ProtectedRoute
        exact
        path="/customer/projects"
        // layout={(props) => <DefaultLayout {...props} startTour={startTour} />}
        layout={DefaultLayout}
        component={CustomerDashboard}
        role="customer"
      />
      <ProtectedRoute
        exact
        path="/customer/ticket"
        // layout={(props) => <DefaultLayout {...props} startTour={startTour} />}
        layout={DefaultLayout}
        component={CustomerTicket}
        role="customer"
      />
      <ProtectedRoute
        exact
        path="/customer/mails"
        // layout={(props) => <DefaultLayout {...props} startTour={startTour} />}
        layout={DefaultLayout}
        component={CustomerMail}
        role="customer"
      />
      <ProtectedRoute
        exact
        path="/customer/projects/:id"
        // layout={(props) => <DefaultLayout {...props} startTour={startTour} />}
        layout={DefaultLayout}
        component={ProjectDetails}
        role="customer"
      />

      {/* ///create project */}

      <ProtectedRoute
        exact
        path="/customer/dashboard/create-project"
        // layout={(props) => <DefaultLayout {...props} startTour={startTour} />}
        layout={DefaultLayout}
        component={CreateProjects}
        role="customer"
      />
      <ProtectedRoute
        exact
        path="/customer/dashboard/create-project/1"
        // layout={(props) => <DefaultLayout {...props} startTour={startTour} />}
        layout={DefaultLayout}
        component={CreateProjectForms}
        role="customer"
      />
      <ProtectedRoute
        exact
        path="/customer/dashboard/create-project/2"
        // layout={(props) => <DefaultLayout {...props} startTour={startTour} />}
        layout={DefaultLayout}
        component={CreateTagForms}
        role="customer"
      />
      <ProtectedRoute
        exact
        path="/customer/dashboard/create-project/3"
        // layout={(props) => <DefaultLayout {...props} startTour={startTour} />}
        layout={DefaultLayout}
        component={CreateProjectTagPreviews}
        role="customer"
      />

      {/* customer route end */}

      {/* Service Provider routes start */}
      <ProtectedRoute
        exact
        path="/service-provider/projects"
        // layout={(props) => <DefaultLayout {...props} startTour={startTour} />}
        layout={DefaultLayout}
        component={Projects}
        role="service-provider"
      />
      <ProtectedRoute
        exact
        path="/service-provider/projects/:id"
        // layout={(props) => <DefaultLayout {...props} startTour={startTour} />}
        layout={DefaultLayout}
        component={ProjectDetails}
        role="service-provider"
      />
      <ProtectedRoute
        exact
        path="/service-provider/teams"
        // layout={(props) => <DefaultLayout {...props} startTour={startTour} />}
        layout={DefaultLayout}
        component={Teams}
        role="service-provider"
      />

      <ProtectedRoute
        exact
        path="/service-provider/settings"
        // layout={(props) => <DefaultLayout {...props} startTour={startTour} />}
        layout={DefaultLayout}
        component={Settings}
        role="service-provider"
      />
      <ProtectedRoute
        exact
        path="/service-provider/help"
        layout={DefaultLayout}
        component={help}
        role="service-provider"
      />
      <ProtectedRoute
        exact
        path="/customer/settings"
        // layout={(props) => <DefaultLayout {...props} startTour={startTour} />}
        layout={DefaultLayout}
        component={Settings}
        role="customer"
      />

      <ProtectedRoute
        exact
        path="/auth/service-provider/signup"
        layout={AuthLayout}
        component={ServiceSignUp}
      />

      <ProtectedRoute
        exact
        path="/service-provider/notifications"
        layout={DefaultLayout}
        component={Notifications}
        role="service-provider"
      />

      <ProtectedRoute
        exact
        path="/admin/notifications"
        layout={DefaultLayout}
        component={Notifications}
        role="admin"
      />

      <ProtectedRoute
        exact
        path="/customer/notifications"
        layout={DefaultLayout}
        component={Notifications}
        role="customer"
      />

      {/* <ProtectedRoute
        exact
        path="/service-provider/create-quotation/:id"
        layout={DefaultLayout}
        component={CreateQuotation}
        role="service-provider"
      /> */}

      <ProtectedRoute layout={AuthLayout} component={Error404} />
    </Switch>
  );
};

export default AllRoutes;