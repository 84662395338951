import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../constants/Constants";
import moment from "moment";
import { toast } from "react-hot-toast";
import jsPDF from "jspdf";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import swal from "sweetalert";
import Loader from "../../../Loader";
import { type } from "@testing-library/user-event/dist/type";
import { Badge } from "flowbite-react";

export default function YourOrder({ projectData }) {
  const navigate = useHistory();
  const [loading, setLoading] = useState(true);
  const [quotation, setQuotation] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [orderFile, setOrderFile] = useState([]);
  const [awss3file, setAwss3file] = useState([]);
  const [reloadpage, setReloadpage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [buttonLoad, setButtonLoad] = useState(false);
  const [flag, setflag] = useState(false);
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };
  const getFileUrl = async (filename) => {
    const user = JSON.parse(localStorage.getItem("User"));
    const getUserType = (user) => {
      if (user.is_admin) {
        return "Admin";
      } else if (user.is_company) {
        return "service_provider";
      } else if (user.is_customer) {
        return "customer";
      } else if (user.is_freelancer) {
        return "service_provider";
      }
    };

    const users = getUserType(user);
    let params = {};
    if (users === "Admin") {
      params = {
        filter: "order",
        name: filename,
      };
    } else if (users === "customer") {
      params = {
        filter: "order",
        name: filename,
      };
    } else {
      params = {
        filter: "serviceProverdatOorder",
        name: filename,
      };
    }

    await axios
      .get(`${BASE_URL}/api/file-url`, { params, headers })
      .then((res) => {
        window.open(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const user = JSON.parse(localStorage.getItem("User"));
  const getUserType = (user) => {
    if (user.is_admin) {
      return "admin";
    } else if (user.is_company) {
      return "company";
    } else if (user.is_customer) {
      return "service_provider";
    } else if (user.is_freelancer) {
      return "service_provider";
    }
  };

  const fileSelectedHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const fileUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file!");
    } else if (!selectedFile.name.endsWith(".pdf")) {
      toast.error("Only PDF files are allowed");
    } else {
      setButtonLoad(true);
      setLoading(true);
      let success = false;
      const s3Client = new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        },
      });

      const params = {
        Bucket: "factreehub-docs",
        Key: `order/${selectedFile?.name}`,
        ContentType: selectedFile.type,
        Body: selectedFile,
      };

      try {
        const putObjectCommand = new PutObjectCommand(params);
        await s3Client.send(putObjectCommand);
        toast.success("File uploaded successfully!");
        setSelectedFile(null);
        // sendQuotationTemp();
        ordergenerate(selectedFile);

        setButtonLoad(false);
        success = true;
        setflag(true);
      } catch (error) {
        console.error(error);
        toast.error("Please Try Again");
        setButtonLoad(false);
        success = false;
      }

      if (!success) {
        setSelectedFile();
      }
    }
    // await getOrder();
  };

  const ordergenerate = async (updateData) => {
    const path = navigate.location.pathname;
    const pathArray = path.split("/");
    const id = pathArray[pathArray.length - 1];

    const payload = {
      file: updateData.name,
      project_id: id,
      userType: "service_provider",
      user_id: user.id,
      orderStatus: "Accepted",
      tags: "",
      email: user.email,
      totalPrice: "",
      org_name: "Organization",
      totalPriceWithGst: "",
      GST: "",
    };
    axios
      .post(`${BASE_URL}/api/createOrder`, payload, { headers })
      .then((response) => {
        // handlePostNotification();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const type = getUserType(user);

  const handleStatus = (item, value) => {
    const payload = {
      orderStatus: value,
      admin_id: user.id,
      customer_id: projectData.customer_id,
      project_id: projectData._id,
      userType: item.userType,
    };
    axios
      .put(`${BASE_URL}/api/order/updateConfirmORreject/${item._id}`, payload, {
        headers,
      })
      .then((response) => {
        getOrder();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOrder = async () => {
    const pathname = navigate.location.pathname.split("/");
    const project_id = pathname[pathname.length - 1];

    const getUserTypes = (user) => {
      if (user.is_admin) {
        return "Admin";
      } else if (user.is_company) {
        return "service_provider";
      } else if (user.is_customer) {
        return "customer";
      } else if (user.is_freelancer) {
        return "service_provider";
      }
    };
    const user = JSON.parse(localStorage.getItem("User"));
    const userTypes = getUserTypes(user);
    await axios
      .get(`${BASE_URL}/api/getOrder/${project_id}/${userTypes}`, { headers })
      .then((res) => {
        const datas = res.data.getOrders;
        let array = [];
        for (const orderData of datas) {
          array.push({
            email: orderData.email,
            file: orderData.file,
            gst: orderData.gst,
            orderStatus: orderData.orderStatus,
            org_name: orderData.org_name,
            createddate: moment(orderData.createdAt).format(
              "DD-MMM-YYYY h:mm A"
            ),
            price: orderData.price,
            project_id: orderData.project_id,
            scope: orderData.scope,
            tag: orderData.tag,
            totalAmount: orderData.totalAmount,
            userType: orderData.userType,
            user_id: orderData.user_id,
            _id: orderData._id,
          });
        }
        setLoading(false);
        setOrderData([...array]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getOrder();
  });

  // useEffect(() => {
  //   getOrder();
  // }, [flag]);

  const handleDelete = async (orderId, fileName, folder) => {
    const headers = {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
    };
    try {
      await axios.delete(`${BASE_URL}/api/getOrder/${orderId}`, { headers });
      await axios.delete(
        `${BASE_URL}/api/deleteFileUrl/${folder}/${fileName}`,
        { headers }
      );
      setReloadpage(true);
      toast.success("Order Deleted Successfully");
      getOrder();
    } catch (error) {
      console.error("Error deleting order", error);
      toast.error("Failed to delete order. Please try again.");
    }
  };

  const Theaders = [
    "S.No",
    "Org Name",
    "Order File",
    "User Type",
    "Create Date",
    "Status",
    "Action",
  ];

  return (
    <div>
      <div className="mt-5">
        {
          <div className="flex justify-end gap-3 mt-10 mb-5">
            <input
              type="file"
              className="border rounded w-64"
              onChange={fileSelectedHandler}
              accept=".pdf"
            />
            <button
              className={`bg-[#03298C] text-white rounded px-6 py-2 ${
                buttonLoad ? "cursor-not-allowed opacity-50" : ""
              }`}
              onClick={() => {
                fileUpload();
                getOrder();
              }}
              disabled={buttonLoad}
            >
              {buttonLoad ? "Uploading..." : "Upload"}
            </button>
          </div>
        }
        {loading ? (
          <Loader className="mx-auto" />
        ) : (
          <table className="shadow-lg mb-20 ">
            <thead>
              <tr className="">
                {Theaders.map((header, index) => (
                  <th
                    className="bg-[#ECFFFF] text-[#03298C] w-96 h-16 res125:h-14 res150:h-9 capitalize  text-center res125:text-sm res150:text-xs rounded"
                    key={index}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {orderData.length === 0 ? (
                <tr>
                  <td
                    colSpan={7}
                    className="text-center pt-3 text-blue-900 res125:text-sm res150:text-xs"
                  >
                    No datas found
                  </td>
                </tr>
              ) : (
                <>
                  {orderData.map((item, index) => (
                    <tr
                      key={index}
                      className="border-b-[0.5px] border-[#C8C8C8] res125:text-sm res150:text-xs text-center"
                    >
                      <td className="px-4 py-2 border-b border-indigo-200">
                        {index + 1}
                      </td>
                      <td className="px-4 py-2 border-b border-indigo-200">
                        {item.org_name}
                      </td>

                      <td
                        className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2  text-blue-500 underline cursor-pointer"
                        onClick={() => {
                          getFileUrl(item.file);
                        }}
                      >
                        {item.file}
                      </td>
                      <td className="px-4 py-2 border-b border-indigo-200">
                        {(() => {
                          const status = item.userType;

                          let badgeComponent = status;

                          if (status === "service_provider") {
                            badgeComponent = (
                              <Badge color="warning">Service Provider</Badge>
                            );
                          } else if (status === "customer") {
                            badgeComponent = (
                              <Badge color="dark">Customer</Badge>
                            );
                          }

                          return (
                            <div className="flex justify-center items-center res150:text-xs">
                              {badgeComponent}
                            </div>
                          );
                        })()}
                      </td>
                      <td className="px-4 py-2 border-b border-indigo-200">
                        {moment(item.createddate).format("DD-MMM-YYYY hh:mm a")}
                      </td>
                      <td className="px-4 py-2 border-b border-indigo-200">
                        {type == "admin" && item.orderStatus === "Pending" ? (
                          <div className="flex flex-row mx-auto">
                            <div>
                              <button
                                className="p-2 mr-2 rounded border border-gray-300 w-24 res150:w-[4.6rem] res150:p-1 flex flex-row bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900 group-hover:bg-green-200 dark:group-hover:bg-green-300 hover:scale-105 transition-transform res150:text-xs"
                                onClick={() => handleStatus(item, "Accepted")}
                              >
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 mr-0.5"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M4.5 12.75l6 6 9-13.5"
                                    />
                                  </svg>
                                </div>
                                Accept
                              </button>
                            </div>
                            <div>
                              <button
                                className="p-2 rounded border border-gray-300  w-24 res150:w-[4.6rem] res150:p-1 flex flex-row bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900 group-hover:bg-red-200 dark:group-hover:bg-red-300 hover:scale-105 transition-transform res150:text-xs"
                                onClick={() => handleStatus(item, "Rejected")}
                              >
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5  mr-1"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </div>
                                Reject
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-wrap gap-2 justify-center">
                            {item.orderStatus === "Rejected" ? (
                              <Badge color="failure">{item.orderStatus}</Badge>
                            ) : (
                              <Badge color="success">{item.orderStatus}</Badge>
                            )}
                          </div>
                        )}
                      </td>
                      <td className="px-4 py-2 border-b border-indigo-200">
                        <i
                          className="fa fa-2x fa-trash cursor-pointer text-[#E96F6F] hover:text-white hover:bg-red-500 border border-[#E96F6F] rounded px-1 py-1"
                          aria-hidden="true"
                          onClick={() => {
                            swal({
                              title: "Are you sure?",
                              text: "Once deleted, you will not be able to recover this item!",
                              icon: "warning",
                              buttons: {
                                cancel: "No",
                                confirm: "Yes",
                              },
                              dangerMode: true,
                            }).then((willDelete) => {
                              if (willDelete) {
                                handleDelete(item._id, item.file, "order");
                                swal(
                                  "Success!",
                                  "Item has been deleted successfully!",
                                  "success"
                                );
                              } else {
                                swal("Your item is safe!");
                              }
                            });
                          }}
                        ></i>{" "}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
