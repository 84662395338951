import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import { toast } from "react-hot-toast";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import "../../../App.css";

const UploadDiagram = (props) => {
  const { show, setShow, projectData, selectedData } = props;
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [availableScopes, setAvailableScopes] = useState([]);
  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };
  const [uploadObj, setUploadObj] = useState({
    file: "",
    tag_id: "",
    scope_id: "",
    project_id: projectData._id,
    vendor_id: JSON.parse(localStorage.getItem("User")).id,
    comments: JSON.stringify([]),
    adminStatus: "",
    sp_status: "",
  });

  useEffect(() => {
    if (selectedData && Object.keys(selectedData).length > 0) {
      setUploadObj({
        id: selectedData.id,
        file: selectedData.diagram,
        tag_id: selectedData.tag_id,
        scope_id: selectedData.scope_id,
        project_id: projectData._id,
        vendor_id: JSON.parse(localStorage.getItem("User")).id,
        comments: JSON.stringify([]),
        adminStatus: "",
        sp_status: selectedData.status,
        created_at: selectedData.created_at,
      });
      setAvailableScopes([selectedData.scope]);
      setSelectedFile({ name: selectedData.diagram });
    }
  }, [selectedData]);

  // const handleFiles = async (event) => {
  //   setSelectedFile(event.target.files[0]);
  //   let obj = { ...uploadObj };
  //   obj[event.target.name] = event.target.files[0].name;
  //   setUploadObj({ ...obj });
  // };

  const handleFiles = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      if (fileType === "pdf" || fileType === "zip") {
        setSelectedFile(file);
        let obj = { ...uploadObj };
        obj[event.target.name] = file.name;
        setUploadObj(obj);
        setError("");
      } else {
        setError("Please select a PDF or ZIP file.");
        toast.error("Please select a PDF or ZIP file.");
      }
    }
  };

  const fileUpload = async () => {
    if (selectedFile.type) {
      let success = false;
      const s3Client = new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        },
      });

      const params = {
        Bucket: "factreehub-docs",
        Key: `diagram/${selectedFile.name}`,
        ContentType: selectedFile.type,
        Body: selectedFile,
      };

      try {
        const putObjectCommand = new PutObjectCommand(params);
        const reponse = await s3Client.send(putObjectCommand);
        toast.success("File uploaded successfully!");
        success = true;
      } catch (error) {
        console.error(error);
        toast.error("Please Try Again");
        success = false;
      }
      if (!success) {
        setSelectedFile();
      }
    }
  };

  useEffect(() => {
    if (selectedFile) {
      fileUpload();
    }
  }, [selectedFile]);

  const handleDropdown = ({ target: { name, value } }) => {
    let obj = { ...uploadObj };
    obj[name] = value;

    if (name === "tag_id") {
      const selectedTag = projectData.tags.find((tag) => tag._id === value);
      if (selectedTag) {
        const filteredScopes = selectedTag.serviceRequired.filter(
          (scope) => scope.vendorStatus === "Accepted"
        );
        setAvailableScopes(filteredScopes);
        // Reset
        obj["scope_id"] = "";
      }
    }
    setUploadObj(obj);
  };

  const handleSubmit = () => {
    if (!uploadObj.file) {
      setError("Please upload file");
    } else if (!uploadObj.tag_id) {
      setError("Please select the tag");
    } else if (!uploadObj.scope_id) {
      setError("Please select the scope");
    } else {
      setIsDisabled(true);
      setLoading(true);
      axios
        .post(`${BASE_URL}/api/diagram/upload`, { ...uploadObj }, { headers })
        .then((res) => {
          // handlePostNotification();
          setShow(false);
          setIsDisabled(false);
          setLoading(false);
          toast.success("Diagram Updated Successfully !");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setIsDisabled(false);
        });
    }
  };

  // const handlePostNotification = async () => {
  //   const payload = {
  //     vendors: [JSON.parse(localStorage.getItem("User")).id],
  //     message: "New file has been uploaded to you",
  //     project_id: uploadObj.project_id,
  //     tag_id: uploadObj.tag_id,
  //     scope_id: uploadObj.scope_id,
  //   };
  //   await axios
  //     .post(`${BASE_URL}/api/notification/post`, payload, { headers })
  //     .then((res) => {
  //       toast.success("Success! Notification sent.");
  //     })
  //     .catch((err) => {
  //       toast.error("Notification failed to sent.");
  //     });
  // };

  return (
    <div>
      {/* Overlay */}
      {show ? (
        <div className="bg-black/50 fixed w-full h-full z-10 top-0 right-0"></div>
      ) : (
        ""
      )}

      {/* Side drawer*/}
      <div
        className={
          show
            ? "fixed top-0 right-0 w-[630px] res125:w-[30.5rem] res150:w-[26.5rem] h-screen z-10 duration-300"
            : "fixed top-0 right-[-100%] w-[630px] h-screen z-10 duration-300"
        }
        style={{
          background: "linear-gradient(90deg, #F8F8F8 0%, #FFFFFF 74.69%)",
        }}
      >
        <AiOutlineClose
          onClick={() => setShow(!show)}
          size={20}
          className="absolute right-5 top-5 cursor-pointer"
        ></AiOutlineClose>
        <div className="h-[490px] mt-5 ml-[43px] w-[550px] res125:w-[25rem] res150:w-80">
          <div className="font-bold text-[#011A59] text-xl tracking-wide text-center mb-5 mt-20 res150:mb-3 res150:mt-16 res125:text-base res150:text-base">
            Upload Diagram
          </div>
          {error && (
            <div
              className="bg-red-100 border w-full mx-auto border-red-400 text-red-700 px-4 py-3  rounded relative mb-2 text-center res150:py-1.5 res125:w-[25rem] res125:py-1.5 res125:text-sm res150:w-[21rem] res150:text-xs"
              role="alert"
            >
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          <label
            for="large"
            className="block mb-2 text-base font-medium text-gray-900 dark:text-white res150:text-xs res125:text-sm"
          >
            Upload File
          </label>
          <div className="flex items-center justify-center w-full mb-5 res125:mb-3 res150:mb-3 res125:w-[25rem] res150:w-[21rem]">
            <label
              for="dropzone-file"
              className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              {!selectedFile ? (
                <>
                  <div className="flex flex-col items-center justify-center pt-10 pb-10 res125:pt-7 res125:pb-7 res150:pt-5 res150:pb-5">
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 mb-3 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p className="mb-1 text-sm text-gray-500 dark:text-gray-400 res125:text-xs res150:text-xs">
                      <span className="font-semibold">Click to upload</span>
                      {/* drag and drop */}
                    </p>
                  </div>
                  <input
                    id="dropzone-file"
                    name="file"
                    type="file"
                    className="hidden"
                    onChange={(e) => handleFiles(e)}
                    accept=".pdf,.zip"
                    value={selectedFile}
                  />
                </>
              ) : (
                <>
                  <p className=" py-3 text-sm text-gray-500 dark:text-gray-400 res150:text-xs res125:text-sm">
                    {selectedFile.name}
                  </p>
                </>
              )}
            </label>
          </div>
          {selectedFile && (
            <span
              className="mb-5 res150:mb-3 text-sm text-blue-500 underline cursor-pointer res125:text-sm res150:text-xs"
              onClick={() => setSelectedFile()}
            >
              Remove
            </span>
          )}

          <label
            for="large"
            className="mt-5 block mb-2 text-base font-medium text-gray-900 dark:text-white res150:text-xs res125:text-sm"
          >
            Select Tag Details
          </label>
          <select
            id="large"
            name="tag_id"
            value={uploadObj.tag_id || "Select Tag"}
            onChange={(e) => handleDropdown(e)}
            className=" mb-5 block w-full res150:text-xs res125:w-[25rem] res125:py-1.5 res125:text-sm res125:rounded res150:w-[21rem] res150:px-3 res150:py-2 px-4 py-3 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option selected disabled>
              Select Tag
            </option>
            {projectData.tags.map((tag) => (
              <option key={tag._id} value={tag._id}>
                {tag.name}
              </option>
            ))}
          </select>

          <label
            for="large"
            className="block mb-2 text-base font-medium text-gray-900 dark:text-white res150:text-xs res125:text-sm"
          >
            Select Scope Detail
          </label>
          <select
            id="large"
            name="scope_id"
            value={uploadObj.scope_id || "Select Scope"}
            onChange={(e) => handleDropdown(e)}
            className="block w-full res150:text-xs res125:w-[25rem] res125:py-1.5 res125:text-sm res125:rounded res150:w-[21rem] res150:px-3 res150:py-2 px-4 py-3 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option selected disabled>
              Select Scope
            </option>
            {availableScopes.map((scope) => (
              <option key={scope._id} value={scope._id}>
                {scope.microservice || scope}
              </option>
            ))}
          </select>

          <div className="flex flex-row gap-9 res150:gap-3 fixed bottom-10 right-10">
            <div
              className="ml-auto border border-[#03298C] w-28 mt-32 p-2 text-center hover:text-white hover:bg-[#03298C] rounded-sm cursor-pointer res125:w-24 res125:text-sm res150:w-20 res150:text-xs"
              onClick={() => setShow(!show)}
            >
              Cancel
            </div>
            <button
              type="submit"
              disabled={isDisabled}
              onClick={handleSubmit}
              className={` border border-[#03298C] res150:w-44 res150:text-xs res125:w-40 res125:text-sm w-52 mt-32 text-center hover:text-white hover:bg-[#03298C] rounded-sm cursor-pointer ${
                isDisabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {loading ? (
                <div className="flex justify-center ">
                  <img
                    src="https://media1.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif"
                    className="w-[2rem] mx-auto"
                    alt=""
                  />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDiagram;
