import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../constants/Constants";
import moment from "moment";
import { toast } from "react-hot-toast";
import jsPDF from "jspdf";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import swal from "sweetalert";
import Loader from "../../../Loader";
import { type } from "@testing-library/user-event/dist/type";
import { Badge } from "flowbite-react";

export default function YourOrder() {
  const navigate = useHistory();
  const [loading, setLoading] = useState(true);
  const [quotation, setQuotation] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [orderFile, setOrderFile] = useState([]);
  const [awss3file, setAwss3file] = useState([]);
  const [reloadpage, setReloadpage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [buttonLoad, setButtonLoad] = useState(false);
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };
  const getFileUrl = async (filename) => {
    const user = JSON.parse(localStorage.getItem("User"));
    const getUserType = (user) => {
      if (user.is_admin) {
        return "Admin";
      } else if (user.is_company) {
        return "service_provider";
      } else if (user.is_customer) {
        return "customer";
      } else if (user.is_freelancer) {
        return "service_provider";
      }
    };

    const users = getUserType(user);
    let params = {};
    if (users === "Admin") {
      params = {
        filter: "order",
        name: filename,
      };
    } else if (users === "customer") {
      params = {
        filter: "order",
        name: filename,
      };
    } else {
      params = {
        filter: "order",
        name: filename,
      };
    }

    await axios
      .get(`${BASE_URL}/api/file-url`, { params, headers })
      .then((res) => {
        window.open(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const user = JSON.parse(localStorage.getItem("User"));
  const getUserType = (user) => {
    if (user.is_admin) {
      return "admin";
    } else if (user.is_company) {
      return "service_provider";
    } else if (user.is_customer) {
      return "customer";
    } else if (user.is_freelancer) {
      return "service_provider";
    }
  };
  const type = getUserType(user);

  const handleStatus = (item, value) => {
    const payload = {
      orderStatus: value,
    };
    axios
      .put(`${BASE_URL}/api/order/updateConfirmORreject/${item._id}`, payload, {
        headers,
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const fileSelectedHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const fileUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file!");
    } else if (!selectedFile.name.endsWith(".pdf")) {
      toast.error("Only PDF files are allowed");
    } else {
      setButtonLoad(true);
      let success = false;
      const s3Client = new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        },
      });

      const params = {
        Bucket: "factreehub-docs",
        Key: `order/${selectedFile?.name}`,
        ContentType: selectedFile.type,
        Body: selectedFile,
      };

      try {
        const putObjectCommand = new PutObjectCommand(params);
        await s3Client.send(putObjectCommand);
        toast.success("File uploaded successfully!");
        setSelectedFile(null);
        // sendQuotationTemp();
        ordergenerate(selectedFile);
        setButtonLoad(false);
        getOrder();
        success = true;
      } catch (error) {
        console.error(error);
        toast.error("Please Try Again");
        success = false;
      }
      if (!success) {
        setSelectedFile();
      }
    }
  };

  const ordergenerate = async (updateData) => {
    const path = navigate.location.pathname;
    const pathArray = path.split("/");
    const id = pathArray[pathArray.length - 1];

    const payload = {
      file: updateData.name,
      project_id: id,
      userType: getUserType(user),
      user_id: user.id,
      orderStatus: "Pending",
      tags: "",
      email: user.email,
      totalPrice: "",
      org_name: "Organization",
      totalPriceWithGst: "",
      GST: "",
    };
    axios
      .post(`${BASE_URL}/api/createOrder`, payload, { headers })
      .then((response) => {
        // handlePostNotification();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOrder = async () => {
    const pathname = navigate.location.pathname.split("/");
    const project_id = pathname[pathname.length - 1];

    const getUserTypes = (user) => {
      if (user.is_admin) {
        return "Admin";
      } else if (user.is_company) {
        return "service_provider";
      } else if (user.is_customer) {
        return "customer";
      } else if (user.is_freelancer) {
        return "service_provider";
      }
    };
    const user = JSON.parse(localStorage.getItem("User"));
    const userTypes = getUserTypes(user);
    await axios
      .get(`${BASE_URL}/api/getOrder/${project_id}/${userTypes}`, { headers })
      .then((res) => {
        const datas = res.data.getOrders;
        let array = [];
        for (const orderData of datas) {
          array.push({
            email: orderData.email,
            file: orderData.file,
            gst: orderData.gst,
            orderStatus: orderData.orderStatus,
            org_name: orderData.org_name,
            createddate: moment(orderData.createdAt).format(
              "DD-MMM-YYYY h:mm A"
            ),
            price: orderData.price,
            project_id: orderData.project_id,
            scope: orderData.scope,
            tag: orderData.tag,
            totalAmount: orderData.totalAmount,
            userType: orderData.userType,
            user_id: orderData.user_id,
            _id: orderData._id,
          });
        }
        setLoading(false);
        // setOrderData([...array]);
        const sortedQuotations = [...array].sort(
          (a, b) => new Date(b.createddate) - new Date(a.createddate)
        );
        setOrderData(sortedQuotations);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getOrder();
  }, []);

  const handleDelete = (orderId) => {
    const headers = {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
    };
    axios
      .delete(`${BASE_URL}/api/getOrder/${orderId}`, { headers })
      .then((response) => {
        setReloadpage(true);
        getOrder();
      })
      .catch((error) => {
        // Handle error
        console.error("Error deleting order", error);
        toast.error("Failed to delete order. Please try again.");
      });
  };

  const Theaders = ["S.No", "Org Name", "Create Date", "Order File", "Status"];

  return (
    <div>
      <div className="mt-5">
        {
          <div className="flex justify-end gap-3 mt-10 mb-5">
            <input
              type="file"
              className="border rounded w-64"
              onChange={fileSelectedHandler}
              accept=".pdf"
            />
            <button
              className={`bg-[#03298C] text-white rounded px-6 py-2 ${
                buttonLoad ? "cursor-not-allowed opacity-50" : ""
              }`}
              onClick={fileUpload}
              disabled={buttonLoad}
            >
              {buttonLoad ? "Uploading..." : "Upload"}
            </button>
          </div>
        }
        {loading ? (
          <Loader className="mx-auto" />
        ) : (
          <table className="shadow-lg mb-20 ">
            <thead>
              <tr className="text-center">
                {Theaders.map((header, index) => (
                  <th
                    className="bg-[#ECFFFF] text-[#03298C] w-96 h-16 res125:h-14 res150:h-9 capitalize res125:text-sm res150:text-xs rounded"
                    key={index}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {orderData.length === 0 ? (
                <tr>
                  <td
                    colSpan={7}
                    className="text-center pt-3 text-blue-900 res125:text-sm res150:text-xs"
                  >
                    No datas found
                  </td>
                </tr>
              ) : (
                <>
                  {orderData.map((item, index) => (
                    <tr
                      key={index}
                      className="border-b-[0.5px] border-[#C8C8C8] res125:text-sm res150:text-xs text-center"
                    >
                      <td className="px-4 py-2 border-b border-indigo-200">
                        {index + 1}
                      </td>
                      <td className="px-4 py-2 border-b border-indigo-200">
                        {item.org_name}
                      </td>
                      <td className="px-4 py-2 border-b border-indigo-200">
                        {moment(item.createddate).format("DD-MM-YYYY hh:mm a")}
                      </td>
                      <td
                        className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2  text-blue-500 underline cursor-pointer"
                        onClick={() => {
                          getFileUrl(item.file);
                        }}
                      >
                        {item.file}
                      </td>
                      <td className="px-4 py-2 border-b border-indigo-200">
                        <div className="flex flex-wrap gap-2 justify-center">
                          {item.orderStatus === "Rejected" ? (
                            <Badge color="failure">{item.orderStatus}</Badge>
                          ) : item.orderStatus === "Accepted" ? (
                            <Badge color="success">{item.orderStatus}</Badge>
                          ) : item.orderStatus === "Pending" ? (
                            <Badge color="indigo">{item.orderStatus}</Badge>
                          ) : (
                            item.orderStatus
                          )}
                        </div>
                      </td>
                      {/* <td className="px-4 py-2 border-b border-indigo-200">
                                                <i
                                                    className="fa fa-2x fa-trash cursor-pointer text-[#E96F6F] hover:text-white hover:bg-red-500 border border-[#E96F6F] rounded px-1 py-1"
                                                    aria-hidden="true"
                                                    onClick={() => {
                                                        swal({
                                                            title: "Are you sure?",
                                                            text: "Once deleted, you will not be able to recover this item!",
                                                            icon: "warning",
                                                            buttons: {
                                                                cancel: "No",
                                                                confirm: "Yes",
                                                            },
                                                            dangerMode: true,
                                                        }).then((willDelete) => {
                                                            if (willDelete) {
                                                                handleDelete(item._id);
                                                                // Note: You might want to update the state or trigger a reload here
                                                                swal(
                                                                    "Success!",
                                                                    "Item has been deleted successfully!",
                                                                    "success"
                                                                );
                                                            } else {
                                                                swal("Your item is safe!");
                                                            }
                                                        });
                                                    }}
                                                ></i>{" "}

                                            </td> */}
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
