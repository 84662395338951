import { useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import ProjectList from "../../Shared/Projects/ProjectList";
import { useHistory } from "react-router-dom";
import "../../Shared/Projects/Projects.css";
import useDebounce from "../../../useDebounce";
import Loader from "../../../Loader";
import { ReactComponent as ListViewIcon } from "../../../assets/svg/list_view.svg";
import { ReactComponent as GridViewIcon } from "../../../assets/svg/grid_view.svg";
import { useContext } from "react";
import AppContext from "../../../context/AppContext";

const ProjectDashboard = () => {
  const navigate = useHistory();
  const [gridMode, setGridMode] = useState(false);
  const [listMode, setListMode] = useState(false);
  const [dropdownCategoryValues, setDropdownCategoryValues] = useState([]);
  const [dropdownStatusValues, setDropdownStatusValues] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [selectedCategoryValue, setSelectedCategoryValue] =
    useState("Category");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [filter, setFilter] = useState({
    search: "",
    status: "",
    category: "",
  });
  const [activeButton, setActiveButton] = useState("grid");
  const [selectedStatusValue, setSelectedStatusValue] = useState("Status");
  const debouceSearchTerm = useDebounce(filter?.search, 1000);
  const {  setCurrentStepIndex } = useContext(AppContext);


  const handleFilterChange = async (name, value) => {
    let existingFilter = { ...filter };
    existingFilter[name] = value;
    setFilter({ ...existingFilter });
    name === "category" && setSelectedCategoryValue(value);
    // name === "status" && setSelectedStatusValue(value);
    name === "status" && setSelectedStatusValue(value === "All" ? null : value);
  };

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const listProjects = async () => {
    setLoading(true);
    if (type === "service") {
      const custId = JSON.parse(localStorage.getItem("User"));
      axios
        .get(`${BASE_URL}/api/serviceproviders/projects`, {
          params: { ...filter, status: filter.status === "All" ? null : filter.status, vendor_id: custId.id },
          headers,
        })
        .then((res) => {
          setLoading(false);
          setProjectList(res.data.message);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (type === "customer") {
      const custId = JSON.parse(localStorage.getItem("User"));
      axios
        .get(`${BASE_URL}/api/customer/projects?customer_id=${custId.id}`, {
          headers,
        })
        .then((res) => {
          setLoading(false);
          setProjectList(res.data.message);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (type === "admin") {
      axios
        .get(`${BASE_URL}/api/project/list`, { params: { ...filter, status: filter.status === "All" ? null : filter.status, }, headers })
        .then((res) => {
          setLoading(false);
          setProjectList(res.data.message);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    listProjects();
  }, [filter?.category, filter?.status, type]);

  useEffect(() => {
    if (navigate.location.pathname.includes("admin")) {
      setType("admin");
    } else if (navigate.location.pathname.includes("service-provider")) {
      setType("service");
    }
  }, []);

  useEffect(() => {
    setDropdownCategoryValues(["Process Plants"]);
    setDropdownStatusValues(["All", "RFQ", "Live", "Completed", "Rejected"]);
  }, []);

  useEffect(() => {
    listProjects();
  }, [debouceSearchTerm]);

  const handleNextStep = () => {
    setCurrentStepIndex((prevIndex) => prevIndex + 1);   
    
  };

  return (
    <div className="w-4/5 h-auto  ml-auto mt-24 res125:mt-20 res150:mt-16">
      <div className=" h-11 mt-7 flex mx-28">
        <div className="font-bold text-3xl py-1 text-[#1E1E1E] tracking-wide res125:text-2xl res150:text-lg res125:mt-1 res150:mt-2">
          {projectList.length} Projects
        </div>

        <button
          className={`ml-auto mr-8 py-3 ${activeButton === "grid" ? "active" : "inactive"
            }`}
          onClick={() => {
            setActiveButton("grid");
            setGridMode(!gridMode);
            setListMode(false);
          }}
          disabled={gridMode}
          title="Grid view"
        >
          <GridViewIcon />
        </button>

        <button
          className={`py-3 ${activeButton === "list" ? "active" : "inactive"}`}
          onClick={() => {
            setActiveButton("list");
            setListMode(!listMode);
            setGridMode(false);
          }}
          disabled={listMode}
          title="list view"
        >
          <ListViewIcon />
        </button>
      </div>
      <div className=" h-11 flex mt-1 res125:mt-0 res150:mt-0 ml-0 w-full">
        <div className="text-base font-bold py-2.5 ml-28 res125:text-sm res150:text-xs">
          Category
        </div>

        <div className="ml-auto flex mr-28">
          {/* Search Box */}
          <div 
          className="w-full pr-5">
            <input id="projectDashboardsearchbox"
              className="h-[46px] w-[320px] pl-2 border border-[#C8C8C8] res125:w-64 res125:h-[2.1rem] res125:text-sm res150:text-xs res150:w-52 res150:h-[1.8rem]"
              placeholder="Search projects..."
              name="search"
              value={filter.search}
              onChange={(e) => handleFilterChange("search", e.target.value)}
            ></input>
          </div>

          {/* Status */}
          <div
          // id="statusfilter"
           className="dropdown inline-block relative w-full pr-5 ">
            <select id="statusfilter"
              value={selectedStatusValue}
              onChange={(event) =>
                handleFilterChange("status", event.target.value)
              }
              className="bg-white border border-[#03298C] text-[#03298C] text-sm focus:ring-[#03298C] focus:border-[#03298C] block w-[200px] py-3 px-5 res125:w-36 res125:py-1.5 res125:text-sm res150:w-32 res150:py-1.5 res150:text-xs"
            >
              <option value="Status" disabled selected>
                Status
              </option>
              {dropdownStatusValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>

          {/* Category */}
          <div 
          id="categroyfilter"
          className="dropdown inline-block relative w-full">
            <select
              value={selectedCategoryValue}
              onChange={(event) =>
                handleFilterChange("category", event.target.value)
              }
              className="bg-white border border-[#03298C] text-[#03298C] text-sm focus:ring-[#03298C] focus:border-[#03298C] block w-[200px] py-3 px-5 res125:w-36 res125:py-1.5 res125:text-sm res150:w-32 res150:py-1.5 res150:text-xs"
            >
              <option value="Category" disabled selected>
                Category
              </option>
              {dropdownCategoryValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <hr className=" border border-[#C8C8C8] my-8 mx-28 res150:mb-5 res125:mb-5 res125:my-4 res150:my-1" />
      {loading ? (
        <Loader className="mx-auto" />
      ) : (
        <ProjectList
          projectList={projectList}
          gridMode={gridMode}
          listMode={listMode}
          onNextStep={handleNextStep}
        />
      )}
    </div>
  );
};

export default ProjectDashboard;
