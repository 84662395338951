import React, { useState, useEffect } from "react";
import "./SideBar.css";
// import FactreehubLogo from "../../../assets/images/Factree Hub Logo.png";
import FactreehubLogo from "../../../assets/svg/logowithtgline.svg";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  BASE_URL,
  Logo,
  ADMIN_SIDEBAR_MENU as adminmenus,
  CUSTOMER_SIDEBAR_MENU as customermenus,
  CUSTOMER_SIDEBAR_MENU_TEAMS as customerTeamMenus,
} from "../../../constants/Constants";
import { ReactComponent as Exit } from "../../../assets/svg/exit.svg";
import { toast } from "react-hot-toast";
import swal from "sweetalert";
import { ReactComponent as Project } from "../../../assets/svg/project.svg";
import { ReactComponent as Team } from "../../../assets/svg/team.svg";
import axios from "axios";

const SideBar = ({ onNextSteps }) => {
  const location = useLocation();
  const navigate = useHistory();
  const [isAdmin, setAdmin] = useState(false);
  const [isCustomer, setCustomer] = useState(false);
  const [isServiceProvider, setServiceProvider] = useState(false);
  const [serviceMenu, setServiceMenu] = useState([]);
  const [inviteUser, setInviteUser] = useState(false);

  const logOut = () => {
    swal({
      title: "Are you sure you want to log out?",
      text: "You will be logged out of your account.",
      icon: "warning",
      buttons: {
        cancel: "No",
        confirm: "Yes",
      },
      dangerMode: true,
    }).then((willLogout) => {
      if (willLogout) {
        localStorage.clear();
        navigate.push("/auth/signin");

        toast.success("SignOut Successfully");
      }
    });
  };

  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };
  const userId = JSON.parse(localStorage.getItem("User"));

  const fetchInvitedUser = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`${BASE_URL}/api/invitedUser?id=${userId.id}`, {
        headers,
      });
      setInviteUser(data?.customer_created);
    } catch (error) {
      console.log(error.response?.data.message);
    }
  };

  useEffect(() => {
    // Admin
    if (location.pathname.includes("admin")) {
      setAdmin(true);
      setCustomer(false);
      setServiceProvider(false);
    }
    // Customer
    else if (location.pathname.includes("customer")) {
      setAdmin(false);
      setCustomer(true);
      setServiceProvider(false);
    }
    // Serviceprovider
    else if (location.pathname.includes("service")) {
      setAdmin(false);
      setCustomer(false);
      setServiceProvider(true);
    }

    fetchInvitedUser();
  });


  const isCompany = JSON.parse(localStorage.getItem("User"))?.is_company;
  const companyName = JSON.parse(localStorage.getItem("User"))?.company?.name;
  const company = JSON.parse(localStorage.getItem("User"))?.name;
  useEffect(() => {
    setServiceMenu([
      {
        route: "/service-provider/projects",
        name: "Projects",
        icon: <Project />,
        condition: true,
      },
      {
        route: "/service-provider/teams",
        name: "Teams",
        icon: <Team />,
        condition:
          isCompany &&
          (JSON.parse(localStorage.getItem("User"))?.team === null ||
            JSON.parse(localStorage.getItem("User"))?.team === undefined ||
            JSON.parse(localStorage.getItem("User"))?.team?.access_type ===
            "Admin"),
      },
    ]);
  }, []);

  return (
    <div className="fixed z-0 w-1/5">
      <div
        id="menubarID"
        className=" w-full bg-[#011A59] -mt-0"
        style={{ height: "93.5vh" }}
      >
        <div className="sidebar_admin h-1/6 res150:h-[15%]  w-full flex">
          <div className="sidebar_text whitespace-nowrap my-auto text-left w-full mx-12 res125:mx-10 res150:mx-8 ">
            {isAdmin && "Admin"}
            {isCustomer &&
              (companyName ? (
                <div
                  className="text-ellipsis overflow-hidden w-3/4"
                  title={companyName}
                >
                  {companyName}
                </div>
              ) : (
                <div
                  className="text-ellipsis overflow-hidden w-3/4"
                  title={company}
                >
                  {company}
                </div>
              ))}

            {isServiceProvider && "Service Provider"}
            <div className="text-[#ADC4FF] text-lg whitespace-nowrap res125:text-sm res150:text-sm">
              Dashboard
            </div>
          </div>
        </div>

        <aside
          id="separator-sidebar"
          className="mt-16 h-[85%] res125:h-[90%] res150:h-[90%] res125:mt-9 res150:mt-7 w-full z-0 transition-transform -translate-x-full sm:translate-x-0 bg-[#011A59]"
          aria-label="Sidebar"
        >
          <div className="h-full px-0 py-4 overflow-y-auto text-white flex flex-col">
            <ul className="space-y-2 flex flex-col items-center w-full">
              {isAdmin &&
                adminmenus?.map((menu) => (
                  <>
                    <li className="w-full mx-auto">
                      <Link
                        to={menu.route}
                        className={` mx-auto pl-24 res125:pl-20 res125:text-sm res125:p-3 res150:pl-14 res150:text-xs res150:p-2 w-full flex items-center p-4 text-base font-normal  rounded-lg hover:bg-blue-800 ${location.pathname.includes(menu.route)
                          ? "text-[#ff9696]"
                          : "text-white"
                          }`}
                      >
                        {menu.icon}
                        <span className="ml-3">{menu.name}</span>
                      </Link>
                    </li>
                    <hr className="sidebar_line my-0" />
                  </>
                ))}

              {isServiceProvider &&
                serviceMenu.map((menu) => (
                  <>
                    {menu.condition && (
                      <li className="w-full">
                        <Link
                          to={menu.route}
                          className={`pl-20 w-full flex items-center p-4 text-base font-normal rounded-lg hover:bg-blue-800 res150:pl-14 res150:text-xs res150:p-2  ${location.pathname.includes(menu.route)
                            ? "text-[#ff9696]"
                            : "text-white"
                            }`}
                        >
                          {menu.icon}
                          <span className="ml-3">{menu.name}</span>
                        </Link>
                      </li>
                    )}
                    {menu.condition && <hr className="sidebar_line my-0" />}
                  </>
                ))}
              {inviteUser
                ? isCustomer &&
                customerTeamMenus.map((menu) => (
                  <>
                    <li
                      className="w-full"
                      id={menu.name === "Projects" ? "cusprojects" : ""}
                    >
                      <Link
                        to={menu.route}
                        onClick={() => onNextSteps()}
                        className={`pl-20 w-full flex items-center p-4 text-base font-normal rounded-lg hover:bg-blue-800 res125:pl-20 res125:text-sm res125:p-3 res150:pl-14 res150:text-xs res150:p-2 ${location.pathname.includes(menu.route)
                          ? "text-[#ff9696]"
                          : "text-white"
                          }`}
                      >
                        {menu.icon}
                        <span className="ml-3">{menu.name}</span>
                      </Link>
                    </li>
                    <hr className="sidebar_line my-0" />
                  </>
                ))
                : isCustomer &&
                customermenus.map((menu) => (
                  <>
                    <li
                      className="w-full"
                      id={
                        menu.name === "Projects"
                          ? "cusprojects"
                          : menu.name === "Mail ids"
                            ? "cusmailid"
                            : ""
                      }
                    // id={menu.name === "Mail ids" ? "cusmailid" : menu.name === "Projects" ? "cusprojects" : ""}
                    >
                      <Link
                        to={menu.route}
                        onClick={() => onNextSteps()}
                        className={`pl-20 w-full flex items-center p-4 text-base font-normal rounded-lg hover:bg-blue-800 res125:pl-20 res125:text-sm res125:p-3 res150:pl-14 res150:text-xs res150:p-2 ${location.pathname.includes(menu.route)
                          ? "text-[#ff9696]"
                          : "text-white"
                          }`}
                      >
                        {menu.icon}
                        <span className="ml-3">{menu.name}</span>
                      </Link>
                    </li>
                    <hr className="sidebar_line my-0" />
                  </>
                ))}
            </ul>
            <button
              className="mx-auto flex mt-auto cursor-pointer"
              onClick={logOut}
            >
              <div className="flex gap-14 res125:gap-11 res150:gap-9 items-center bg-[#0D2970] w-[16.5rem] h-16 res125:w-56 res125:h-12 res125:text-sm res150:w-48 res150:h-10 res150:text-xs rounded cursor-pointer">
                <div className="flex ml-7">
                  <Exit />
                </div>
                <span>Logout</span>
              </div>
            </button>

            <footer
              className={`mt-10 mb-4 res125:mb-7 flex-col flex res125:mt-7 res150:mt-4 res150:mb-2`}
            >
              {/* {isAdmin && ( */}
              <img
                src={Logo}
                className="mt-0 mb-4 mx-auto bg-white w-32 rounded p-1 res125:w-24 res150:w-20"
              />
              {/* )} */}
              {/* {isServiceProvider && (
                <img
                  src={Logo}
                  className="mx-auto mb-4 bg-white w-32 rounded p-1 res125:w-24 res150:w-20"
                />
              )} */}

            </footer>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default SideBar;
