import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import Loader from "../../../Loader";
import moment from "moment";
import FeedbackChat from "../../Customer/Projects/FeedbackChat";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { Badge, Button, Modal } from "flowbite-react";
import "../../../App.css";

const CustomerQuotationList = ({ projectDetails }) => {
  const navigate = useHistory();
  const [loading, setLoading] = useState("false");
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [projectData, setProjectData] = useState("");
  const [quotations, setQuotations] = useState([]);
  const pathname = navigate.location.pathname.split("/");
  const project_id = pathname[pathname.length - 1];
  const [orderFile, setOrderFile] = useState("");
  const [updateData, setUpdateData] = useState({});
  const [comments, setComments] = useState([]);
  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const user = JSON.parse(localStorage.getItem("User"));

  const generateAndUploadPDF = async (quotation) => {
    const generateRandomString = async (length) => {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    };

    let baseFileName = "Order";
    let version = await generateRandomString(Math.floor(Math.random() * 3) + 4);

    let fileName = `${baseFileName}_${version}.pdf`;

    return fileName;
  };

  const getUserTypes = (user) => {
    if (user.is_admin) {
      return "Admin";
    } else if (user.is_company) {
      return "company";
    } else if (user.is_customer) {
      return "customer";
    } else if (user.is_freelancer) {
      return "service_provider";
    }
  };
  const userTypes = getUserTypes(user);

  useEffect(() => {
    getQuotations();
    fetchComments();
  }, []);

  const handleFeedback = (data) => {
    setProjectData(data);

    const userType = getUserTypes(user).toLowerCase(); // Convert userType to lowercase
    const payload = {
      quotation_id: data._id,
      userType, // Shorthand property to set userType as lowercase
    };

    axios
      .put(`${BASE_URL}/api/feedback/markUsRead`, payload, { headers })
      .then((response) => {
        const result = response.data;
        fetchComments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchComments = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/quotation/getAllFeedback/${project_id}/${userTypes.toLowerCase()}`,
        { headers }
      );
      const result = response.data;
      setComments(result);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const getQuotations = async () => {
    setLoading(true);
    await axios
      .get(`${BASE_URL}/api/getCustomer/ListQuotation/${project_id}`, {
        headers,
      })
      .then((res) => {
        const sortedQuotations = res.data.getAllQuotes.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setQuotations([...sortedQuotations]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const handleStatus = (quotation, value) => {
    const payload = {
      status: value,
      quotation_id: quotation._id,
      customer_id: user.id,
      project_id: project_id,
    };
    axios
      .post(`${BASE_URL}/api/customerQuotation/createORupdate`, payload, {
        headers,
      })
      .then((response) => {
        setUpdateData(response.data.message);
        getQuotations();
        if (value === "Accepted") {
          // toast.success(
          //   "The quotation has been accepted, and your order has been generated."
          // );
          toast.success("The quotation has been accepted");
        } else if (value === "Rejected") {
          toast.success("The quotation has been Rejected.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect(() => {
  //   if (updateData && updateData.status === "Accepted") {
  //     ordergenerate(updateData);
  //   }
  // }, [updateData]);

  const ordergenerate = async (updateData) => {
    const getUserType = (user) => {
      if (user.is_admin) {
        return "admin";
      } else if (user.is_company) {
        return "company";
      } else if (user.is_customer) {
        return "customer";
      } else if (user.is_freelancer) {
        return "freelancer";
      }
    };
    const fileName = await generateAndUploadPDF(updateData);
    let gstValueData = parseFloat(updateData.totalPrice);
    let gstAmount = gstValueData * 0.18;
    let roundedGST = gstAmount.toFixed(2);
    let totalAmount = parseInt(gstValueData) + parseInt(roundedGST);

    const payload = {
      file: fileName,
      project_id: updateData.project_id,
      userType: getUserType(user),
      user_id: user.id,
      orderStatus: "Pending",
      tags: JSON.parse(updateData.tags),
      email: user.email,
      totalPrice: updateData.totalPrice,
      org_name: "Organization",
      totalPriceWithGst: totalAmount,
      GST: gstAmount,
    };
    axios
      .post(`${BASE_URL}/api/createOrder`, payload, { headers })
      .then((response) => {
        // handlePostNotification();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFileUrl = async (filename) => {
    const params = {
      filter: "quotation",
      name: filename,
    };
    await axios
      .get(`${BASE_URL}/api/file-url`, { params, headers })
      .then((res) => {
        window.open(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (isOpen === false) {
      getQuotations();
    }
  }, [isOpen]);

  const getStatusColorClass = (status) => {
    switch (status) {
      case "accepted":
        return "text-green-500";
      case "rejected":
        return "text-red-500";
      case "pending":
        return "text-blue-500";
      default:
        return "";
    }
  };

  return (
    <div>
      {loading ? (
        <Loader className="mx-auto" />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-6 res125:mt-4 res150:mt-3">
          <table className="w-full text-sm text-left text-black">
            <thead className="text-base text-[#03298C] bg-[#ECFFFF] res125:text-sm res150:text-xs">
              <tr className="text-center">
                <th
                  scope="col"
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2"
                >
                  Files
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2"
                >
                  Total Price
                </th>

                <th
                  scope="col"
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2"
                >
                  Created At
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2"
                >
                  Feedback
                </th>
              </tr>
            </thead>
            <tbody>
              {quotations.length === 0 && (
                <tr>
                  <td colSpan="7" className="py-4 text-center res150:text-xs">
                    No Quotation Found
                  </td>
                </tr>
              )}

              {quotations.map((quotation, index) => (
                <tr className="bg-white border-b res150:text-xs text-center">
                  <td
                    className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2 text-blue-500 underline cursor-pointer text-center"
                    onClick={() => getFileUrl(quotation.file)}
                  >
                    <p
                      className="w-48 mx-auto overflow-hidden whitespace-nowrap text-ellipsis"
                      title={quotation.file}
                    >
                      {quotation.file}
                    </p>
                  </td>
                  <td className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2">
                    {quotation.totalPrice}
                  </td>

                  <td className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2">
                    {moment(quotation.createdAt).format("DD-MM-YYYY hh:mm A")}
                  </td>
                  <td className="px-6 py-4 res125:px-4 res125:py-2 res150:px-4 res150:py-2">
                    {quotation.status === "Pending" ? (
                      <div className="flex flex-row justify-center">
                        <div>
                          <button
                            className="p-2 mr-2 rounded border border-gray-300 w-24 res150:w-[4.6rem] res150:p-1 flex flex-row bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900 group-hover:bg-green-200 dark:group-hover:bg-green-300 hover:scale-105 transition-transform res150:text-xs"
                            onClick={() => handleStatus(quotation, "Accepted")}
                          >
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 mr-0.5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M4.5 12.75l6 6 9-13.5"
                                />
                              </svg>
                            </div>
                            Accept
                          </button>
                        </div>
                        <div>
                          <button
                            className="p-2 rounded border border-gray-300  w-24 res150:w-[4.6rem] res150:p-1 flex flex-row bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900 group-hover:bg-red-200 dark:group-hover:bg-red-300 hover:scale-105 transition-transform res150:text-xs"
                            onClick={() => handleStatus(quotation, "Rejected")}
                          >
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="w-5 h-5  mr-1"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </div>
                            Reject
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        {(() => {
                          const status = quotation.status;

                          let badgeComponent = null;

                          if (status === "Pending") {
                            badgeComponent = (
                              <Badge color="indigo">{status}</Badge>
                            );
                          } else if (status === "Accepted") {
                            badgeComponent = (
                              <Badge color="success">{status}</Badge>
                            );
                          } else if (status === "Rejected") {
                            badgeComponent = (
                              <Badge color="failure">{status}</Badge>
                            );
                          }

                          return (
                            <div className="flex justify-center items-center res150:text-xs">
                              {badgeComponent}
                            </div>
                          );
                        })()}
                      </>
                    )}
                  </td>
                  <td className="px-4 py-2 border-b border-indigo-200">
                    <button
                      onClick={() => {
                        setOpenModal(true);
                        handleFeedback(quotation);
                      }}
                      className={`${
                        comments.getFeedback?.length > 0 &&
                        comments.getFeedback.some(
                          (item) => item.quotation_id === quotation._id
                        )
                          ? "gradient-border"
                          : "border-[#FD952D]"
                      } bg-[#FFE3C7] text-[#FD952D] px-4 py-1 border relative rounded-full`}
                    >
                      FeedBack
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Modal show={openModal} onClose={() => setOpenModal(false)}>
            <Modal.Header>Feed Back</Modal.Header>
            <Modal.Body className="overflow-y-hidden">
              <div className="space-y-6">
                <FeedbackChat projectData={projectData} />
              </div>
            </Modal.Body>
          </Modal>
          <div className="relative">
            {/* {isOpen && (
                            <UploadQuotations
                                show={isOpen}
                                setShow={setIsOpen}
                                projectData={projectDetails}
                            />
                        )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerQuotationList;