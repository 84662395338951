import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { BASE_URL, Logo } from "../../../constants/Constants";
import { toast } from "react-hot-toast";
// import FactreehubLogo from "../../../assets/images/Factree Hub Logo.png";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { useHistory } from "react-router-dom";

const CustomerTicket = () => {
  const history = useHistory();
  const [errors, setErrors] = useState("");
  const [formData, setFormData] = useState({
    projectName: "",
    projectDescription: "",
    email: "",
    contact: "",
    files: [],
    userName: "",
    customerId: "",
  });
  const fileInputRef = useRef(null);
  const navigate = useHistory();

  const custId = JSON.parse(localStorage.getItem("User"));

  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };
  const [search, setSearch] = useState("");
  const [resdata, setresdata] = useState([]);
  // handle changes
  // handle changes
  const params = {
    customer_id: custId.id,
    search,
  };

  // useEffect(() => {
  //   axios
  //     .get(`${BASE_URL}/api/customer/projects`, { params, headers })
  //     .then((res) => {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         projectName: res.data.message[0]?.name,
  //         projectDescription: res.data.message[0]?.description,
  //       }));
  //       setresdata(res.data.message);
  //       //   setProjectData(res.data.message);
  //       //   setLoading(false);
  //     });
  // }, []);
  const handleChange = (e) => {
    const { name, type } = e.target;

    if (type === "file") {
      const newFiles = Array.from(e.target.files);
      setFormData((prevData) => ({
        ...prevData,
        files: [
          ...(prevData.files || []),
          ...newFiles.filter((file) => file.name),
        ], // Ensure 'name' property exists
      }));
    } else {
      const { value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const fileUpload = async () => {
    if (!formData.files || formData.files.length === 0) {
      console.error("No file selected.");
      return;
    }
    let success = false;
    const s3Client = new S3Client({
      region: "ap-south-1",
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    });

    try {
      // Loop through each file in the array and upload it
      for (const file of formData.files) {
        const params = {
          Bucket: "factreehub-docs",
          Key: `customerTicket/${file.name}`,
          ContentType: file.type,
          Body: file,
        };

        const putObjectCommand = new PutObjectCommand(params);
        await s3Client.send(putObjectCommand);
      }

      toast.success("Files uploaded successfully!");
      success = true;
    } catch (error) {
      console.error(error);
      toast.error("Please Try Again");
      success = false;
    }

    if (success) {
      const fileNames = formData.files.map((file) => file.name);
      setFormData({
        ...formData,
        files: fileNames,
      });
    }
  };

  // Validation for form fileds
  const validateNumber = (input) => {
    const contactno = /^[0-9]+$/;
    return contactno.test(input);
  };
  const validateEmail = (email) => {
    const emailPattern = /^\S+@\S+\.\S+$/;
    return emailPattern.test(email);
  };

  // submit button click event
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      formData.projectName === "" ||
      formData.projectDescription === "" ||
      formData.email === "" ||
      formData.contact === ""
      // formData.files.length === 0
    ) {
      setErrors("Please fill all required fields");
      return;
    }
    if (!validateNumber(formData.contact)) {
      setErrors("Please enter a valid contact number.");
      return;
    }
    if (!validateEmail(formData.email)) {
      setErrors("Please enter a valid email address.");
      return;
    } else {
      fileUpload();
      formData.files = formData.files.map((file) => file.name);
      setErrors("");
    }

    const User = JSON.parse(localStorage.getItem("User"));
    if (User) {
      formData.customerId = User.id;
      formData.userName = User.name;
    }

    axios
      .post(`${BASE_URL}/api/customer/createTicket`, formData, { headers })
      .then((res) => {
        toast.success("Ticket generated successfully!");
        setFormData({
          projectName: "",
          projectDescription: "",
          email: "",
          contact: "",
          files: [],
        });
        history.push("/customer/projects");
      })
      .catch((error) => {
        console.error("Error creating ticket:", error);
        toast.error("Error generating ticket. Please try again later.", {
          className: "text-red-500",
        });
      });
  };

  // const handleCancel = () => {
  //   setFormData({
  //     projectName: "",
  //     projectDescription: "",
  //     email: "",
  //     contact: "",
  //     files: [],
  //   });
  // };

  const handleClick = () => {
    history.push("/customer/projects");
  };

  return (
    <>
      <div className="w-9/12 flex flex-col ml-auto h-auto mr-16 pl-8 mt-20 res125:mt-16 res150:mt-14">
        <div className="mx-0 h-11 mt-3 res125:mt-1 res150:mt-0">
          <form onSubmit={handleSubmit}>
            {/* logo */}
            <img
              className="flex ml-auto w-36 res125:w-28 res150:w-24"
              src={Logo}
              alt="FactreeHub"
            />
            <button
              className="justify-start text-blue-900 "
              onClick={handleClick}
            >
              <b> &lt; Back</b>
            </button>
            <div className="font-bold pt-4 res150:pt-2 res125:text-3xl res150:text-2xl">
              Create Ticket
            </div>
            <div className="mt-8 res125:mt-6 res150:mt-5">
              <label htmlFor="projectName" className="text-base font-bold">
                Project Name
              </label>
              <input
                type="text"
                name="projectName"
                value={formData.projectName}
                onChange={handleChange}
                id="projectName"
                placeholder="Enter project name"
                className="border border-gray-400 p-2 mt-2 w-full"
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="projectDescription"
                className="text-base font-bold"
              >
                Project Description
              </label>
              <textarea
                id="projectDescription"
                name="projectDescription"
                value={formData.projectDescription}
                onChange={handleChange}
                placeholder="Enter project description"
                className="border border-gray-400 p-2 mt-2 w-full h-32"
              />
            </div>
            <div className="flex justify-between mt-4">
              <div className="w-1/2 pr-2">
                <label htmlFor="email" className="text-base font-bold">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  id="email"
                  placeholder="Enter email address"
                  className="border border-gray-400 p-2 mt-2 w-full"
                />
              </div>
              <div className="w-1/2 pl-2">
                <label htmlFor="contact" className="text-base font-bold">
                  Contact
                </label>
                <input
                  type="text"
                  name="contact"
                  value={formData.contact}
                  onChange={handleChange}
                  id="contact"
                  placeholder="Enter contact information"
                  className="border border-gray-400 p-2 mt-2 w-full"
                />
              </div>
            </div>
            {/* <div className="m-2">
              <b>Attachment</b>
            </div>
            <div className="mt-2 flex items-center">
              <label className="cursor-pointer bg-blue-900 text-white py-2 px-4 rounded">
                Choose File
                <input
                  type="file"
                  name="files"
                  onChange={handleChange}
                  id="files"
                  ref={fileInputRef}
                  className="hidden"
                  multiple // Allow multiple file selection
                />
              </label>
              {formData.files && formData.files.length > 0 && (
                <div className="flex mt-2">
                  {formData.files.map((file, index) => (
                    <div
                      key={index}
                      className="m-2 flex items-center mt-1 border-2"
                    >
                      <span className="mr-2">{file.name}</span>
                      <button
                        type="button"
                        onClick={() => {
                          const updatedFiles = [...formData.files];
                          updatedFiles.splice(index, 1);
                          setFormData({
                            ...formData,
                            files: updatedFiles,
                          });
                        }}
                        className=" py-1 px-2 rounded"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 384 512"
                        >
                          <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div> */}

            {errors && (
              <div className="mt-2 text-red-500 text-center">{errors} !!</div>
            )}
            <div className="flex justify-end mt-10">
              <button
                type="button"
                onClick={handleClick}
                className="border border-blue-500 text-blue py-2 px-4 mr-4 rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-[#0D2970] text-white py-2 px-4 rounded"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CustomerTicket;
