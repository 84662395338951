import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../context/AppContext";
import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../../constants/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faComments } from "@fortawesome/free-solid-svg-icons";
import { Badge, Button, Dropdown, Modal, Tooltip } from "flowbite-react";
import "./Projects.css";

const ProjectList = (props) => {
  const { type } = useContext(AppContext);
  const navigate = useHistory();
  const { gridMode, listMode, projectList, custProject, onNextStep } = props;
  const [openModal, setOpenModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [responseColor, setResponseColor] = useState("");

  const randomColors = (index) => {
    var array = [
      "#ECFFFF",
      "#FFEEEE",
      "#E4FFEC",
      "#FFE4F4",
      "#EEF5FF",
      "#ECF3FF",
      "#FFF2D0",
      "#F1FFDF",
    ];
    var color = "";
    color = array[index % array.length];
    return color;
  };

  // const getColorData = async () => {
  //   const projectIds = projectList.map((project) => project._id);

  //   const promises = projectIds.map(async (projectId, index) => {
  //     try {
  //       const response = await axios.get(`${BASE_URL}/api/project/projectBgColor/${projectId}`, { headers });
  //       const color = getColorFromResponse(response);
  //       setResponseColor((prevColors) => ({ ...prevColors, [projectId]: color }));
  //     } catch (error) {
  //       console.error(`Error fetching color for project ID ${projectId}:`, error);
  //     }
  //   });

  //   await Promise.all(promises);
  // };

  const getColorFromResponse = (response) => {
    // Adjust this function based on your API response structure
    const color = response.data.color;

    switch (color) {
      case "Red":
        return "#FFEEEE";
      case "Blue":
        return "#EEF8FF";
      case "Orange":
        return "#FEE9B0";
      case "Yellow":
        return "#FEFAB0";
      case "Green":
        return "#CEFFC7";
      default:
        return "white";
    }
  };

  const handleClick = (value) => {
    if (type === "admin") {
      navigate.push(`/admin/projects/${value._id}`);
    } else if (type === "service-provider") {
      navigate.push(`/service-provider/projects/${value._id}`);
    } else if (type === "customer") {
      navigate.push(`/customer/projects/${value._id}`);
    }
  };

  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const handleSubmit = async (project_id) => {
    const user = JSON.parse(localStorage.getItem("User"));
    const payload = {
      vendor_id: user.id,
      project_id,
    };

    axios
      .post(`${BASE_URL}/api/notification/postExpiryNotification`, payload, {
        headers,
      })
      .then((_res) => {
        toast.success("Message sent Successfully !");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Please try again");
      });
  };
  const user = JSON.parse(localStorage.getItem("User"));
  const sendChat = async (data) => {
    const payload = {
      vendor_id: user.id,
      userName: data.userName,
      email: data.email,
      subject: data.subject,
      message: data.message,
    };

    axios
      .post(`${BASE_URL}/api/helpChat`, payload, { headers })
      .then((_res) => {
        toast.success("Mail sent Successfully !");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Please try again");
      });
  };

  const calculate = (date) => {
    const startDate = new Date(Date.now());
    const endDate = new Date(date);
    const diffMs = startDate - endDate;
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const diffMonths = Math.floor(diffDays / 30.44);
    const diffYears = Math.floor(diffDays / 365);

    if (diffYears <= 0) {
      if (diffMonths <= 0) {
        if (diffDays <= 0) {
          return "Today";
        }
        return diffDays + " days ago";
      } else {
        return diffMonths + " months ago";
      }
    } else {
      return diffYears + " years ago";
    }
  };



  return (
    <>
      {projectList.length === 0 ? (
        <div className="text-center text-xl mt-10 res150:text-base res125:text-lg">
          No Project is Created Yet !
        </div>
      ) : (
        <>
          <div className="mr-28 mb-4 flex justify-end items-center">
            {user.is_customer === true ? null : (<>
              <div
                className="h-8 w-8 border-gray-300 border-2 bg-[#FFEEEE] rounded-full cursor-pointer hover:border-[green]"
              >
                <Tooltip
                  content="New Enquiry Created"
                  className=""
                  style="dark"
                >
                  <div className="w-8 h-8"></div>
                </Tooltip>
              </div>

              <div className="w-[15px] h-[2px] bg-gray-300"></div>
              <div
                className="h-8 w-8 border-gray-300 border-2 bg-[#EEF8FF] rounded-full cursor-pointer hover:border-[green]"
              >
                <Tooltip
                  content="RFQ sent to Customer"
                  className=""
                  style="dark"
                >
                  <div className="w-8 h-8"></div>
                </Tooltip>
              </div>

              <div className="w-[15px] h-[2px] bg-gray-300"></div>
            </>)}

            <div
              className="h-8 w-8 border-gray-300 border-2 bg-[#FEE9B0] rounded-full cursor-pointer hover:border-[green]"
            >
              <Tooltip
                content="Order Confirmed"
                className=""
                style="dark"
              >
                <div className="w-8 h-8"></div>
              </Tooltip>
            </div>
            <div className="w-[15px] h-[2px] bg-gray-300"></div>
            <div
              className="h-8 w-8 border-gray-300 border-2 bg-[#FEFAB0] rounded-full cursor-pointer hover:border-[green]"
            >
              <Tooltip
                content="Order in Progress"
                className=""
                style="dark"
              >
                <div className="w-8 h-8"></div>
              </Tooltip>
            </div>
            <div className="w-[15px] h-[2px] bg-gray-300"></div>
            <div
              className="h-8 w-8 border-gray-300 border-2 bg-[#CEFFC7] rounded-full cursor-pointer hover:border-[green]"
            >
              <Tooltip
                content="Order Completed"
                className=""
                style="dark"
              >
                <div className="w-8 h-8"></div>
              </Tooltip>
            </div>
          </div>


          <div
            className={`grid ${gridMode ? "" : "grid-cols-1 gap-5 res125:gap-4 res150:gap-4"
              }  ${listMode ? "" : "grid-cols-3 gap-8 res125:gap-4 res150:gap-4"
              } pb-5 ${custProject ? "mt-6" : "mx-28"}`}
          >
            {/* Project Card */}
            {projectList.map((value, index) => (
              <div
                id="projectcoulms"
                className={`cursor-pointer w-full ${gridMode
                  ? "h-40 res150:h-32 res125:h-36 rounded-md"
                  : listMode
                    ? "h-[110px] res125:h-20 res150:h-16 rounded-md"
                    : "h-40 res125:h-36 res150:h-32 rounded-md"
                  }`}
                // style={{ background: randomColors(index) }}
                style={{ background: value.bgColor }}
                onClick={() => {
                  if (
                    type === "service-provider" &&
                    value.expiry_date &&
                    new Date(value.expiry_date) <
                    new Date().setHours(0, 0, 0, 0)
                  ) {
                  } else if (
                    value?.customerStatus === "Rejected"
                  ) {
                  } else {
                    handleClick(value);
                  }
                  onNextStep()
                }
                }
                key={index}
              >
                <div className="my-auto h-full ml-[30px]">
                  {/* Project Title */}
                  <div className="flex flex-col justify-center h-full ">
                    <div className="flex pt-3">
                      <div className="text-base overflow-hidden whitespace-nowrap text-ellipsis w-3/4 res125:text-sm res150:text-xs">
                        <div
                          title={value.projectUniqueId ? value.projectUniqueId : null}
                        >
                          <b>Project No :</b> {value.projectUniqueId ? value.projectUniqueId : null}
                        </div>
                        <div title={value.name}>
                          <b>{value.name}</b>
                        </div>
                      </div>
                      {value?.customerStatus === "Rejected" ? (<Badge color="dark" className={`${listMode ? "ml-auto" : null}`}>Rejected</Badge>) : (value.expiry_date ? (
                        new Date(value.expiry_date) <
                          new Date().setHours(0, 0, 0, 0) ? (
                          type == "admin" ? (
                            // <div
                            //   className="text-base text-end mr-6 overflow-hidden text-ellipsis w-3/4 res125:text-sm res150:text-xs"
                            //   title="Date is expired"
                            // >
                            <Badge color="failure" className={`${listMode ? "ml-auto" : null}`}>Expired</Badge>
                            // </div>
                          ) : (
                            <button
                              className={`${listMode ? "ml-auto" : "ps-7"}`}
                              onClick={() => handleSubmit(value._id)}
                              title="Click the button to send a notification to the admin to extend the expiry date."
                            >
                              <FontAwesomeIcon
                                className="text-xl text-[#0D2970] rounded"
                                icon={faBell}
                              />
                            </button>
                          )
                        ) : null
                      ) : null)}

                      <div
                        className={`text-[#929292] text-xs font-normal italic ml-auto mr-6  ${listMode ? "block" : "hidden"
                          }`}
                      >
                        {calculate(value.updated_at)}
                      </div>
                    </div>
                    {/* Project Description */}
                    <div className="flex">
                      <div
                        className={`font-normal text-xs text-[#5F5F5F]  mt-2  ${listMode
                          ? "h-[15px] w-full mb-3"
                          : "h-[50px] w-[80%] res150:text-xs"
                          } overflow-hidden whitespace-nowrap text-ellipsis`}
                        title={value.description}
                      >
                        {value.description}
                      </div>
                    </div>
                    <div
                      className={`flex mt-2.5  ${listMode ? "hidden" : "block"
                        }`}
                    >
                      <div className="text-[#929292] text-xs font-normal italic pb-2 res150:pt-px">
                        {calculate(value.updated_at)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {type === "service-provider" && (
              <button
                onClick={() => setOpenModal(true)}
                className="right-16 bottom-12 fixed p-2 rounded-full h-10 w-10 bg-[#0D2970]"
                title="Chat support"
              >
                <FontAwesomeIcon
                  className="text-xl text-white rounded"
                  icon={faComments}
                />
              </button>
            )}
            <Modal
              show={openModal}
              onClose={() => setOpenModal(false)}
              position="bottom-right"
              size="sm"
            >
              <Modal.Header className="bg-[#011A59]">
                <span className="text-white">Chat Support</span>
              </Modal.Header>
              <Modal.Body>
                <div className="space-y-6">
                  <div>
                    <label>Name</label>
                    <input
                      type="text"
                      placeholder="Enter your name"
                      className="w-full"
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div>
                  <div>
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Enter your mail"
                      className="w-full"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <label>Subject</label>
                    <input
                      type="text"
                      placeholder="Enter Subject"
                      className="w-full"
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                  <div>
                    <label>Message</label>
                    <textarea
                      placeholder="Type here"
                      className="w-full scrolling-add-comment"
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="mx-auto">
                  <button
                    className="px-3 py-2 me-3 rounded-md border"
                    onClick={() => setOpenModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#72C27A] hover:bg-[#72C27A] px-3 py-2 rounded-md"
                    onClick={() => {
                      sendChat({
                        userName,
                        email,
                        subject,
                        message,
                      });
                      setOpenModal(false);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default ProjectList;
