import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Tooltip } from "flowbite-react";
import React, { useState } from "react";
import Loader from "../../../Loader";
import { Logo } from "../../../constants/Constants";
import SignUpCompany from "./CompanySignup";
import SignUpFreelancer from "./FreelancerSignup";
// import { ReactComponent as Tourguide } from "../../../assets/svg/Tourguide";

// Modal.setAppElement("#root"); // Set the root element for accessibility

function ServiceSignUp() {
  const [activeTab, setActiveTab] = useState("tab1");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const openModalAndLoadVideo = () => {
    setOpenModal(true);
    setLoading(true);
  };

  return (
    // <div className="flex flex-col w-1/2 h-full mx-auto items-center justify-center bg-sky-200">
    //   <div className="flex flex-col mx-auto  items-center">
    <div className="  container flex min-h-screen mx-auto h-full max-w-full items-center justify-center bg-sky-200">
      <button
        className="help-icon border-1 fixed top-16 right-10"
        aria-label="Help"
        onClick={openModalAndLoadVideo}
      >
        <Tooltip content="Video Guide" placement="left">
          <FontAwesomeIcon icon={faCirclePlay} className="fa-3x text-[#03298C]" />
        </Tooltip>
      </button>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>Video Guide</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            {loading && (
              <Loader className="mx-auto" />
            )}
            <iframe
              className="w-[550px] mx-auto h-[400px] res125:w-[450px] res150:w-[350px] res125:h-[300px] res150:h-[250px]"
              src="https://www.loom.com/embed/9bc9f229d9be4a5987bc54392fad1b61?sid=682e2772-7869-4965-8d9b-e1a9ceea2aed"
              title="Video"
              onLoad={handleIframeLoad}
              style={{ display: loading ? 'none' : 'block' }}
            />
          </div>
        </Modal.Body>
      </Modal>

      <div className="card rounded-2xl mx-auto bg-white max-w-[440px] w-[100%] my-[50px]">
        <img
          src={Logo}
          className="mx-auto w-[224px]  mt-[15px]"
          alt="FactreeHub"
        />

        <p className=" font-bold text-center w-full text-lg mt-[10px] res150:h-9">
          Service Provider Sign-Up
        </p>
        <div className="flex flex-row mt-5 res150:text-[10px] res125:text-xs res150:h-8 res125:h-9  res150:mt-1 res125:mt-1 m-auto max-w-[360px]">
          <button
            className={`${activeTab === "tab1"
              ? "bg-[#03298C] text-white font-bold"
              : "bg-gray-200 text-gray-700 "
              }  py-2 rounded border-t border-l border-r focus:outline-none  flex-1`}
            onClick={() => handleTabClick("tab1")}
          >
            Freelancer
          </button>
          <button
            className={`${activeTab === "tab2"
              ? "bg-[#03298C] text-white font-bold"
              : "bg-gray-200 text-gray-700"
              }  py-2 rounded border-t border-r border-b focus:outline-none  flex-1`}
            onClick={() => handleTabClick("tab2")}
          >
            Company
          </button>
        </div>
        {/* input fields for freelancer */}
        {activeTab === "tab1" && <SignUpFreelancer />}
        {/* input field for company */}
        {activeTab === "tab2" && <SignUpCompany />}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Help Modal"
          style={{
            content: {
              width: "50%",
              height: "50%",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          }}
        >
          <div>
            <button
              className="close-icon"
              onClick={closeModal}
              aria-label="Close"
            >
              &times;
            </button>
            <iframe
              className="help-iframe"
              title="Help Video"
              // width="727px"
              // height="300px"
              width="100%"
              height="100%"
              src={Logo}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default ServiceSignUp;
