// import EmailDetails from "../../Mails/EmailDetails";
import AppContext from "../../../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import CreatePreviewProjects from "./CreatePreviewProject";
import CreateProjects from "./CreateProject";

const CreateProjectTagPreviews = () => {
  const { selectedMail } = useContext(AppContext);

  return (
    <div className="flex ml-auto w-4/5 overflow-hidden h-screen">
      <div className="h-screen bg-indigo-200 w-5"></div>
      {true && (
        <div className="bg-black/50  w-full h-full z-10 top-0 right-0"></div>
      )}
      {/* <EmailDetails showMailList={false} selectedMail={selectedMail} /> */}
      <CreateProjects className={"flex ml-0"}>
        <CreatePreviewProjects />
      </CreateProjects>
    </div>
  );
};

export default CreateProjectTagPreviews;
