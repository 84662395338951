import React, { useContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import Loader from "../../../Loader";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import AppContext from "../../../context/AppContext";
import useDebounce from "../../../useDebounce";
import swal from "sweetalert";
import { ReactComponent as Info } from "../../../assets/svg/info.svg";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Tooltip } from "flowbite-react";

const AssignVendorDrawer = (props) => {
  const { show, setShow, tagData, isEdit, scopeId, assignId, tagDetail } = props;
  const [techScope, setTechScope] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [serviceProviderList, setServiceProviderList] = useState();
  const selectedTag = JSON.parse(localStorage.getItem("SELECTED_TAG"));
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [error, setError] = useState(false);
  const { setLoadTeamMember } = useContext(AppContext);
  const [assignVendor, setAssignVendor] = useState([]);
  const { id } = useParams();

  const handleEdit = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/api/admin/getVendor/${assignId}`, { headers })
      setAssignVendor(res.data.getAssignVendor)
    }
    catch (err) {
      console.log(err);
    }
  }

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    const updatedTechScope = {
      scope_id: tagDetail?.length > 0 ? tagDetail[0]?.scope_id : selectedTag?.scopeId,
      vendor_ids: tagDetail?.length > 0 ? JSON.parse(tagDetail[0].vendor_ids) : [],
      files: tagDetail?.length > 0 ? JSON.parse(tagDetail[0].files) : [],
      expiry_date: tagDetail?.length > 0 ? new Date(tagDetail[0]?.expiry_date).toISOString().split('T')[0] : "",
    };
    setTechScope(updatedTechScope);
  }, [tagDetail]);

  const listVendors = async () => {
    setLoading(true);
    const params = {
      search,
    };
    axios
      .get(`${BASE_URL}/api/serviceproviders`, { params, headers })
      .then((res) => {
        setLoading(false);
        setServiceProviderList(res?.data.message);
        // console.log(res);
        let response = res?.data.data.vendorListResponse;
        setServiceProviderList(response);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };


  const handleFileCheck = (filename) => {
    const tabtechScope = { ...techScope }; // create a copy of techScope

    const updatedFiles = tabtechScope.files.includes(filename)
      ? tabtechScope.files.filter((file) => file !== filename)
      : [...tabtechScope.files, filename];

    tabtechScope.files = updatedFiles;
    setTechScope(tabtechScope); // update the state with the updated copy
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedtechScope = { ...techScope };
    const selectedScope = { ...updatedtechScope };
    selectedScope[name] = value;
    setTechScope(selectedScope);
  };

  const handleSelect = (id) => {
    let tempScopes = { ...techScope };
    let selectedScope = tempScopes;
    let vendorIds = [...selectedScope.vendor_ids];
    const index = vendorIds.indexOf(id);
    if (index > -1) {
      vendorIds.splice(index, 1);
    } else {
      vendorIds.push(id);
    }
    selectedScope.vendor_ids = vendorIds;
    setIsSelected(!isSelected);
    tempScopes = selectedScope;
    setTechScope(tempScopes);
  };

  const handleSubmit = () => {
    if (isEdit == false) {
      if (!techScope.vendor_ids.length) {
        setError("Please select at least one vendor !");
        toast.error("Please select at least one vendor !");
        swal("Oops !", "Please select at least one vendor !", "warning");
      } else if (!techScope.expiry_date) {
        setError("Please select an expiry date !");
        toast.error("Please select an expiry date !");
        swal("Oops !", "Please select an expiry date !", "warning");
      } else {
        const path = navigate.location.pathname;
        const pathArray = path.split("/");
        const id = pathArray[pathArray.length - 1];
        const data = {
          scope_id: scopeId,
          admin_id: JSON.parse(localStorage.getItem("User")).id,
          vendors: techScope?.vendor_ids,
          techScope,
          project_id: id,
          tag_id: tagData._id,
        };
        axios
          .post(`${BASE_URL}/api/vendors/assign`, data, { headers })
          .then((res) => {
            // handlePostNotification(data);
            localStorage.removeItem("SELECTED_TAG");
            setLoadTeamMember(true);
            toast.success("Success");
            setShow(!show);
          })
          .catch((err) => {
            console.log(err);
            toast.error("Failed to Request Quotation, Please Retry !");
            localStorage.removeItem("SELECTED_TAG");
          });
      }
    }
    else {
      const datas = {
        expiry_date: techScope.expiry_date,
        vendor_ids: techScope?.vendor_ids,
        files: techScope?.files,
        assign_id: tagDetail[0]._id,
      }
      axios
        .put(`${BASE_URL}/api/vendors/updateAssign`, datas, { headers })
        .then((res) => {
          // localStorage.removeItem("SELECTED_TAG");
          setLoadTeamMember(true);
          toast.success("Updated Successfully");
          setShow(!show);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Failed to Request, Please Retry !");
          // localStorage.removeItem("SELECTED_TAG");
        });

    }
  }

  // const handlePostNotification = async (data) => {
  //   const payload = {
  //     vendors: techScope?.vendor_ids,
  //     admin_id: JSON.parse(localStorage.getItem("User")).id,
  //     message: "New project has been assigned to you",
  //     project_id: data.project_id,
  //     scope_id: selectedTag?.scopeId,
  //     tag_id: tagData._id,
  //   };
  //   // console.log(payload);
  //   await axios
  //     .post(`${BASE_URL}/api/notification/post`, payload, { headers })
  //     .then((res) => {
  //       toast.success("Success! Notification sent.");
  //     })
  //     .catch((err) => {
  //       toast.error("Notification failed to sent.");
  //     });
  // };

  let navigate = useHistory();

  const debouceSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    listVendors();
  }, [debouceSearchTerm]);

  return (
    <div>
      {/* Overlay */}
      {show ? (
        <div className="bg-black/20 fixed w-full h-full z-50 top-0 right-0"></div>
      ) : (
        ""
      )}

      {/* Side drawer*/}
      <div
        className={
          show
            ? "fixed top-0 right-0 w-[630px] res150:w-[27rem] res125:w-[32rem] h-full z-50 mt-16 res125:mt-[60px] res150:mt-[44px] duration-300"
            : "fixed top-0 right-[-100%] w-[630px] res150:w-[26.2rem] h-full z-10 duration-300"
        }
        style={{
          background: "linear-gradient(90deg, #F8F8F8 0%, #FFFFFF 74.69%)",
        }}
      >
        <AiOutlineClose
          onClick={() => setShow(!show)}
          size={20}
          className="absolute right-5 top-5 cursor-pointer"
        ></AiOutlineClose>
        <div className="h-[490px] mt-5  w-[550px] res150:w-[23rem] res125:w-[27rem] mx-auto">
          <div className="font-bold text-[#011A59] text-xl tracking-wide text-center res125:text-base res150:text-sm">
            Assign Service Provider
          </div>
          {error && (
            <div
              class="bg-red-100 border w-full mx-auto border-red-400 text-red-700 px-4 py-3 my-2 rounded relative text-center res125:py-1.5 res125:text-sm res125:my-1 res150:my-1 res150:py-1  res150:text-xs"
              role="alert"
            >
              <span class="block sm:inline">{error}</span>
            </div>
          )}
          <input
            className="h-[40px] w-full pl-2 border border-[#C8C8C8] mt-4 res150:h-7 res150:text-xs res125:h-8 res125:text-sm res125:mt-2 res150:mt-2"
            placeholder="Search Vendors..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          ></input>

          <div className="grid grid-cols-3 gap-3 pb-5 my-4 overflow-y-auto h-3/4 res150:h-[50%] pr-2 res125:h-[60%] res125:my-2 res125:gap-2 res150:gap-2">
            {loading ? (
              <Loader className="ml-60 res125:w-7 res125:h-7 res125:ml-52 res150:w-7 res150:h-7 res150:ml-44" />
            ) : (
              serviceProviderList?.map((vendor, index) => (
                <div
                  className="cursor-pointer w-full h-48 rounded-md bg-[#FFFFFF] border border-[#C8C8C8] res125:h-40 res150:h-36"
                  key={index}
                >
                  <div className="flex flex-col justify-between h-full py-2 px-3 relative text-[#03298C] res125:p-2">
                    {/* vendor.serviceCapabilities */}
                    <div className="absolute right-2 top-2">
                      <Tooltip
                        content={vendor?.serviceCapabilities ? JSON.parse(vendor?.serviceCapabilities)?.map((service, index) => ({ index, value: service.value })).map((item) => (
                          <div key={item.index} className="w-[100px]" title={item.value}>
                            {item.index + 1}. {item.value?.length > 11 ? item.value.slice(0, 11) + '...' : item.value}
                          </div>
                        )) : ("null")}
                        placement="bottom"
                      >
                        <Info className="w-3 h-3" />
                      </Tooltip>


                    </div>

                    <div>
                      <p className="font-bold text-sm tracking-wide w-[100px] res150:text-xs res125:text-xs" title={vendor.name}>
                        {vendor.name?.length > 10 ? vendor.name.slice(0, 10) + '...' : vendor.name}
                      </p>
                      <div className="py-2 res125:py-0.5 res150:py-0.5">
                        <p className="font-normal text-sm break-words res125:text-[11px] res150:text-[10px] line-clamp-2" title={vendor.email}>
                          {vendor.email?.length > 12 ? vendor.email.slice(0, 12) + '...' : vendor.email}
                        </p>
                        <p className="font-normal text-sm break-words res150:text-[10px] res125:text-[11px]">
                          {vendor.contact_no}
                        </p>
                        <p className="font-normal text-sm res150:text-[10px] res125:text-[11px]">
                          {vendor.is_freelancer
                            ? "Freelancer"
                            : vendor.is_company
                              ? "Company"
                              : ""}
                        </p>
                      </div>
                    </div>
                    {
                      !isEdit ? (
                        <button
                          className={`bg-${techScope?.vendor_ids?.includes(vendor.id) ? "green-500" : "[#03298C]"} p-1 mr-2 w-full rounded-md text-[#EFF4FF] font-normal text-sm res150:text-[10px] res125:text-xs`}
                          onClick={() => handleSelect(vendor.id)}
                        >
                          {techScope?.vendor_ids?.includes(vendor.id) ? "Selected" : "Select"}
                        </button>
                      ) : (
                        tagDetail?.map((details, index) => {

                          return (
                            <button
                              key={index}
                              className={`bg-${techScope?.vendor_ids?.includes(vendor?.id) ? "green-500" : "[#03298C]"} p-1 mr-2 w-full rounded-md text-[#EFF4FF] font-normal text-sm res150:text-[10px] res125:text-xs`}
                              onClick={() => handleSelect(vendor?.id)}
                            >
                              {techScope.vendor_ids?.includes(vendor?.id) ? "Selected" : "Select"}
                            </button>
                          );
                        })
                      )
                    }

                    {/* {!isEdit ? <button
                      className={`bg-${techScope?.vendor_ids?.includes(vendor.id)
                        ? "green-500"
                        : "[#03298C]"
                        } p-1 mr-2 w-full rounded-md text-[#EFF4FF] font-normal text-sm res150:text-[10px] res125:text-xs`}
                      onClick={() => handleSelect(vendor.id)}
                    >
                      {techScope?.vendor_ids?.includes(vendor.id)
                        ? "Selected"
                        : "Select"}
                    </button>
                      :
                      (tagDetail?.map((details, index) => {
                        const vendorIdsArray = JSON.parse(details.vendor_ids);
                        console.log(details.vendor_ids, vendor.id)
                        return (
                          <button
                            key={index}
                            className={`bg-${details.vendor_ids.includes(vendor.id)
                              ? "green-500"
                              : "[#03298C]"
                              } p-1 mr-2 w-full rounded-md text-[#EFF4FF] font-normal text-sm res150:text-[10px] res125:text-xs`}
                            onClick={() => handleSelect(vendor.id)}
                          >
                            {details.vendor_ids?.includes(vendor.id)
                              ? "Selected"
                              : "Select"}
                          </button>
                        );
                      }))
                    } */}
                  </div>
                </div>
              ))
            )}
          </div>

          <div>
            <div className="text-[#03298C] underline res125:text-xs res150:text-[10px]">
              Attachments
            </div>
            {tagData?.attachments &&
              JSON.parse(tagData.attachments)?.length > 0 ? (
              <div className="grid grid-cols-3 gap-4 py-4 h-auto res125:gap-2 res125:py-2 max-h-28 overflow-y-auto res150:py-2 my-2 res150:my-0.5">
                {JSON.parse(tagData.attachments)?.map((attachment, index) => {
                  const isChecked =
                    techScope &&
                    techScope.files &&
                    techScope.files.includes(attachment);
                  return (
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-indigo-600 res125:h-4 res125:w-4 res150:h-3 res150:w-3"
                        checked={isChecked}
                        onChange={() => handleFileCheck(attachment, index)}
                      />
                      <span className="ml-2 text-gray-700 break-all res125:text-xs res150:text-[10px]">
                        {attachment}
                      </span>
                    </label>
                  );
                })}
              </div>
            ) : (
              <div className="pt-2 pb-6 res150:text-xs res125:text-xs res125:pb-2 res150:pb-2">
                There are no attachments for this tag.
              </div>
            )}
          </div>
          <div>
            <div className="text-[#03298C] underline res125:text-xs res150:text-[10px]">
              Expiry Date
            </div>
            <input
              type="date"
              className="w-full mt-2 pl-2 h-[40px] border border-[#C8C8C8] res150:text-[10px] res125:text-xs res125:h-8"
              value={techScope.expiry_date}
              name="expiry_date"
              onChange={handleInputChange}
              min={new Date().toISOString().split("T")[0]}
            />
          </div>
          <div className="flex flex-row fixed bottom-10 right-10">
            <div
              className=" border border-[#03298C] w-28 p-2 text-center hover:text-white hover:bg-[#03298C] rounded-sm cursor-pointer res150:text-xs res150:h-8 res125:text-xs"
              onClick={() => {
                localStorage.removeItem("SELECTED_TAG");
                setShow(!show);
              }}
            >
              Cancel
            </div>
            <div
              className=" ml-4 border border-[#03298C] w-52 p-2 text-center hover:text-white hover:bg-[#03298C] rounded-sm cursor-pointer res150:w-40 res150:text-xs res150:h-8 res125:text-xs res125:w-40"
              onClick={handleSubmit}
            >
              Request Quotation
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignVendorDrawer;