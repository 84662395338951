import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { BASE_URL } from "../../../constants/Constants";
import AppContext from "../../../context/AppContext";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import Loader from "../../../Loader";

const Notifications = () => {
  const [notifyList, setNotifyList] = useState([]);
  const { type } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const navigate = useHistory();
  // Bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };
  const [reload, setReload] = useState(false);

  const updateNotification = async (_id) => {
    try {
      await axios.put(
        `${BASE_URL}/api/notification/updateNotification`,
        { _id },
        { headers }
      );
    } catch (error) {
      console.error("Error updating notification:", error);
    }
  };

  const updateMarkUsRead = async () => {
    try {
      const user_id = type === "admin" ? "" : JSON.parse(localStorage.getItem("User")).id;

      const payload = {
        user_id: user_id
      };

      await axios.put(
        `${BASE_URL}/api/notification/markUsReadAll`,
        payload,
        { headers }
      );
      setReload(true);
    } catch (error) {
      console.error("Error updating notification:", error);
    }
  };

  useEffect(() => {
    if (type === "admin") {
      setLoading(true);
      axios
        .get(`${BASE_URL}/api/notification/admin/list`, { headers })
        .then((res) => {
          setLoading(false);
          setNotifyList(res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      const params = {
        vendor_id: JSON.parse(localStorage.getItem("User"))?.id,
      };
      setLoading(true);
      axios
        .get(`${BASE_URL}/api/notification/list`, { params, headers })
        .then((res) => {
          setLoading(false);
          setNotifyList(res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
    setReload(false);
  }, [type, reload]);

  const handleClick = (value) => {
    if (!value.is_seen) {
      updateNotification(value._id);
    }

    if (type === "service-provider") {
      navigate.push(`/service-provider/projects/${value.project_id}`);
    } else if (type === "customer") {
      if (value.project_id) {
        navigate.push(`/customer/projects/${value.project_id}`);
      } else {
        navigate.push(`/customer/projects`);
      }
    } else if (type === "admin") {
      if (value.project_id) {
        navigate.push(`/admin/projects/${value.project_id}`);
      } else if (value.message === "New project creation request received") {
        navigate.push(`/admin/mails`);
      } else {
        navigate.push(`/admin/projects`);
      }
    }
  };

  const GoToDashboard = () => {
    if (type === "service-provider") {
      navigate.push("/service-provider/projects");
    } else if (type === "admin") {
      navigate.push("/admin/mails");
    }
  };

  return (
    <div className="w-8/12 h-auto mr-28 res125:mr-24 res150:mr-20 ml-auto mt-28 res125:mt-24 res150:mt-16">
      <div className="flex justify-between">
        <div className="text-2xl font-bold pb-3 res150:pb-2 res125:text-lg res150:text-base">
          Notifications
        </div>
        <button className="pb-3 res150:pb-2 res150:text-base hover:text-[#03298C]" onClick={updateMarkUsRead}>Mark all read</button>
      </div>
      <hr />
      {loading ? (
        <Loader className="mx-auto" />
      ) : notifyList?.length === 0 ? (
        <div className="text-center mx-auto">
          <img
            className="w-[34rem] mx-auto res125:w-2/5 res150:w-2/5"
            src="https://cdn.dribbble.com/users/1319343/screenshots/6238304/_01-no-notifications.gif"
            alt="no notifiaction"
          />
          <div className="mb-6">
            <p className="text-xl mb-2 text-[#03298C] font-bold res125:text-base res150:text-sm">
              No Notification Yet !
            </p>
            <p className="text-lg res150:text-sm res125:text-sm">
              we'll notifiy you when something arrives
            </p>
          </div>
          <p
            onClick={GoToDashboard}
            className="underline text-[#03298C] font-bold cursor-pointer res150:text-sm"
          >
            Go to Dashboard
          </p>
        </div>
      ) : (
        notifyList.map((value, index) => (
          <div
            className="w-full h-[70px] bg-slate-300 cursor-pointer"
            key={index}
            onClick={() => handleClick(value)}
          >
            <div className="h-36 px-4 my-3 py-3">
              <div className="flex">
                <div className="pe-3">
                  {value.is_seen ? ('') : (<div className="text-3xl text-red-700"> &bull;</div>)}
                </div>
                <div className=" w-full">
                  <div className="flex">
                    <div className="font-bold text-base res125:text-sm res150:text-xs">
                      {value.message}
                    </div>
                    <div className="text-xs font-normal italic ml-auto res125:text-sm res150:text-xs">
                      {moment(value.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                    </div>
                  </div>
                  <div className="flex text-xs mt-0.5">
                    <div style={{ marginRight: "0.5rem" }}>
                      {value.project_name?.length > 60
                        ? value.project_name.substring(0, 60) + "..."
                        : value.project_name}
                    </div>
                    <div style={{ marginRight: "0.5rem", fontSize: "1.5em" }}>
                      &bull;
                    </div>
                    {/* <div style={{ marginRight: "0.5rem" }}>
                      {value.tag_name}
                    </div>
                    <div style={{ marginRight: "0.5rem", fontSize: "1.5em" }}>
                      &bull;
                    </div>
                    <div>{value.scope_name}</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Notifications;
