import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../../constants/Constants";
import ProjectCollaborations from "../../Admin/Projects/ProjectsCollaborations";
import UploadDiagram from "./UploadDiagram";
import Table from "../../Shared/Table/Table";
import AppContext from "../../../context/AppContext";
import Loader from "../../../Loader";
import toast from "react-hot-toast";

export default function ProjectCollaboration({ projectData }) {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useHistory();
  const [showAnnotate, canShowAnnotate] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [viewData, setViewData] = useState({});
  const [filter, setFilter] = useState("All");
  const { loadProject, setLoadProject } = useContext(AppContext);

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  useEffect(() => {
    getDiagram();
    setLoadProject(false);
  }, [filter, loadProject]);

  const getDiagram = async () => {
    const pathname = navigate.location.pathname.split("/");
    const project_id = pathname[pathname.length - 1];
    const user = JSON.parse(localStorage.getItem("User"));
    const params = {
      vendor_id: user.id,
      project_id,
      filter,
    };
    setLoading(true);
    await axios
      .get(`${BASE_URL}/api/diagram`, { params, headers })
      .then((res) => {
        const datas = res.data.message;
        // console.log(datas);
        let array = [];
        for (const diagram of datas.diagram) {
          array.push({
            id: diagram._id,
            diagram: diagram.file,
            tag_id: diagram.tag._id,
            tag: diagram.tag.name,
            scope_id: diagram.scope._id,
            scope: diagram.scope.microservice,
            createddate: moment(diagram.created_at).format("DD-MM-YYYY h:mm a"),
            created_at: diagram.created_at,
            created_by: diagram.created_by,
            status: diagram.adminStatus,
            comments: JSON.parse(diagram.comments) || [],
            adminStatus: diagram.adminStatus,
            vendor: { ...datas.vendor },
            sub_vertical: diagram.tag.sub_vertical,
          });
          setLoading(false);
        }
        setLoading(false);
        setData([...array]);
        // console.log([...array]);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getFileUrl = async (filename, comments, row) => {
    const params = {
      filter: "diagram",
      name: filename,
    };
    await axios
      .get(`${BASE_URL}/api/file-url`, { params, headers })
      .then((res) => {
        setPdfUrl(res?.data?.message);
        setFileName(filename);
        filename.endsWith(".pdf")
          ? canShowAnnotate(true)
          : window.open(res?.data?.message);
        setViewData(row);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = async (id, fileName, folder) => {
    const params = { id };
    await axios.delete(`${BASE_URL}/api/diagram/delete`, { params, headers });
    await axios
      .delete(`${BASE_URL}/api/deleteFileUrl/${folder}/${fileName}`, {
        headers,
      })
      .then((res) => {
        getDiagram();
        toast.success("Diagram Deleted Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAnnotate = () => {
    canShowAnnotate(false);
  };

  const handleShowPopup = (data) => {
    setSelectedData({ ...data });
    setIsOpen(true);
  };

  const Theaders = [
    "File Name",
    "Tag",
    "Microservice",
    "Created date / time",
    "Created By",
    "status",
  ];

  useEffect(() => {
    if (isOpen === false) {
      getDiagram();
    }
  }, [isOpen]);

  const handleSelectedData = (datas) => {
    setViewData(datas);
  };

  const flag = data?.find((ele) => ele.adminStatus === "Release to Customer" || ele.adminStatus === "Completed");
  return (
    <div>
      <button
        // disabled={flag?.adminStatus === "Release to Customer"}
        id="UploadFileButton"
        className="bg-[#03298C] w-44 h-12 res125:w-32  res125:h-9 res125:mt-3 res150:w-28 res150:h-8 res150:mt-2 text-white ml-auto flex mt-4 rounded"
        onClick={() => {
          if (flag?.adminStatus === "Release to Customer" || flag?.adminStatus === "Completed") {
            toast.error("Project has been completed")
          } else {
            setSelectedData({});
            setIsOpen(!isOpen);
          }
        }}
      >
        <span className="flex m-auto res125:text-sm res150:text-xs">
          Upload File
        </span>
      </button>

      <div className="relative ">
        {isOpen && (
          <UploadDiagram
            show={isOpen}
            setShow={setIsOpen}
            projectData={projectData}
            selectedData={selectedData}
          />
        )}
      </div>
      <div className="my-5 res125:my-3 res150:my-2 overflow-hidden">
        {loading ? (
          <Loader className="mx-auto" />
        ) : (
          <>
            {showAnnotate ? (
              <ProjectCollaborations
                fileName={fileName}
                pdfUrl={pdfUrl}
                handleAnnotate={handleAnnotate}
                selectedData={viewData}
                handleSelectedData={handleSelectedData}
              />
            ) : (
              <>
                <div className="flex items-center">
                  <label
                    className="block mr-4 ml-auto mb-3 res125:text-sm res150:text-xs"
                    htmlFor="category"
                  >
                    Filter For Created By:
                  </label>
                  <select
                    className={` mb-5 res125:mb-4 res150:mb-3 block appearance-none w-[16rem] h-12 res125:w-48 res125:h-9 res125:text-sm res150:w-44 res150:h-8 res150:text-xs bg-white border border-[#C8C8C8] px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline`}
                    id="category"
                    name="category"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    required
                  >
                    <option value="All">All</option>
                    <option value="admin">FactreeHub</option>
                    <option value="sp">Service Provider</option>
                  </select>
                </div>
                <Table
                  headers={Theaders}
                  data={data}
                  showActions={true}
                  status={true}
                  getFileUrl={getFileUrl}
                  handleShowPopup={handleShowPopup}
                  handleDelete={handleDelete}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
