import { useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import useDebounce from "../../../useDebounce";
import Loader from "../../../Loader";

const ServiceProvider = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [serviceProviderList, setServiceProviderList] = useState([]);

  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const searchFunc = () => {
    setLoading(true);
    const params = {
      search,
    };
    axios
      .get(`${BASE_URL}/api/serviceproviders/list`, { params, headers })
      .then((res) => {
        setLoading(false);
        setServiceProviderList(res.data.message);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const debouceSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    searchFunc();
  }, [debouceSearchTerm]);

  return (
    <div className="w-8/12 h-auto mr-28 ml-auto mt-24 res125:mt-20 res150:mt-16 res125:mr-24 res150:mr-20">
      <div className="w-full h-11 flex mt-1">
        <div className="text-base font-bold py-2.5 tracking-wide res125:text-sm res150:text-xs">
          Service Providers
        </div>

        <div className="ml-auto">
          {/* Search Box */}
          <div className="w-full">
            <input
              className="h-[46px] w-[320px] pl-2 border border-[#C8C8C8] res125:w-60 res125:h-9 res125:text-sm res150:w-52 res150:h-7 res150:text-xs"
              placeholder="Search organizations..."
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <hr className="w-full border border-[#C8C8C8] my-4 res125:mt-0 res150:mt-0" />
      {loading ? (
        <Loader className="mx-auto" />
      ) : (
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg my-6">
          <table class="w-full text-sm text-left text-black">
            <thead class="text-base text-[#03298C] bg-[#ECFFFF] res125:text-sm res150:text-xs">
              <tr>
                <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                  Service Provider Name
                </th>
                <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                  User Type
                </th>
                <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                  Email ID
                </th>
                <th scope="col" className="px-6 py-4 res150:px-4 res150:py-3">
                  Phone Number
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 text-center res150:px-4 res150:py-3"
                >
                  Completed Projects
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 text-center res150:px-4 res150:py-3"
                >
                  Ongoing Projects
                </th>
              </tr>
            </thead>
            <tbody>
              {serviceProviderList && serviceProviderList.length > 0 ? (
                serviceProviderList.map((value, index) => (
                  <tr className="bg-white border-b text-left res125:text-sm res150:text-xs">
                    <th
                      scope="row"
                      className="px-6 py-4 whitespace-nowrap font-bold res150:px-4 res150:py-3"
                    >
                      <div className="flex flex-col">
                        <p>{value.name}</p>
                      </div>
                    </th>
                    <td className="px-6 py-4 res150:px-4 res150:py-3">
                    {value.type === "freelancer" ? (
                      <span className="bg-green-500 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded res150:text-xs">
                        {value.type}
                      </span>
                    ) : (
                      <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded res150:text-xs">
                        {value.type}
                      </span>
                    )}
                    </td>
                    <td className="px-6 py-4 res150:px-4 res150:py-3">
                      {value.email}
                    </td>
                    <td className="px-6 py-4 res150:px-4 res150:py-3">
                      {value.contact_no}
                    </td>
                    <td className="px-6 py-4 text-center res150:px-4 res150:py-3">
                      {value.completedProjects.length}
                    </td>
                    <td className="px-6 py-4 text-center res150:px-4 res150:py-3">
                      {value.ongoingProjects.length}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="bg-white border-b  cursor-pointer">
                  <td
                    className="px-6 py-4 text-center text-2xl res125:text-base res150:text-sm"
                    colspan="6"
                  >
                    No Service provider Here !
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ServiceProvider;
