import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { ReactComponent as Plus } from "../../../../assets/svg/plus.svg";
import {
  BASE_URL,
  TAG_INFO_STATE,
  TAG_INFO_STEPPER,
  subCategoriesOptions,
  subVerticalOptions,
  verticalOptions,
} from "../../../../constants/Constants";
import AppContext from "../../../../context/AppContext";
import "../CreateOrModifyProject.css";

const CreateProjectForms2 = (props) => {
  const { fileNames, tagNo, error } = props;
  const { projectInfo, tagInfo, setTagInfo, setTagFiles, tagFiles } =
    useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [initialSet, setInitialSet] = useState(false);
  const [techScopeOptions, setTechScopeOptions] = useState([]);

  // Bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };
  useEffect(() => {
    if (!initialSet) {
      setInitialSet(true);
    } else {
      for (let i = 0; i < projectInfo.tags; i++) {
        setSelectedFile((selectedFile) => [...selectedFile, { file: [] }]);
      }
    }
  }, [initialSet]);

  useEffect(() => {
    setSelectedFile([...tagFiles]);
  }, [tagFiles]);

  // const handleFiles = async (event) => {
  //   const existingFiles = [...selectedFile];
  //   existingFiles[selectedTab].file.push({ name: event.target.files[0]?.name });
  //   setSelectedFile([...existingFiles]);
  //   await fileUpload(event.target.files[0]);
  //   setTagFiles([...existingFiles]);
  // };

  const handleFiles = async (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files).map((file) => ({
      name: file.name,
      file: file,
    }));
    const updatedFiles = [...selectedFile];
    updatedFiles[selectedTab].file.push(...newFiles);
    setSelectedFile(updatedFiles);
    await Promise.all(newFiles.map((file) => fileUpload(file.file)));
    setTagFiles(updatedFiles);
  };

  const fileUpload = async (file) => {
    let success = false;
    const s3Client = new S3Client({
      region: "ap-south-1",
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    });
    const params = {
      Bucket: "factreehub-docs",
      Key: `${file.name}`,
      ContentType: file.type,
      Body: file,
    };
    // const params = {
    //   Bucket: "factreehub-docs",
    //   Key: `customercreate/${selectedFile.name}`,
    //   ContentType: selectedFile.type,
    //   Body: selectedFile,
    //   // };
    // };
    try {
      const putObjectCommand = new PutObjectCommand(params);
      await s3Client.send(putObjectCommand);
      toast.success("File uploaded successfully!");
      success = true;
    } catch (error) {
      console.error(error);
      toast.error("Please Try Again");
      success = false;
    }
    if (!success) {
      setSelectedFile();
    }
  };

  useEffect(() => {
    if (tagNo > -1) {
      setSelectedTab(tagNo);
    }
  }, [tagNo]);

  useEffect(() => {
    const params = {
      sub_vertical_type: tagInfo[selectedTab]?.sub_vertical,
    };
    axios
      .get(`${BASE_URL}/api/tech-scopes`, { params, headers })
      .then((res) => {
        setTechScopeOptions([...res?.data?.message]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    tagInfo[selectedTab]?.verticals,
    tagInfo[selectedTab]?.sub_category,
    tagInfo[selectedTab]?.sub_vertical,
  ]);

  useEffect(() => {
    const existingTagInfo = localStorage.getItem(TAG_INFO_STEPPER);
    if (existingTagInfo !== null && existingTagInfo !== "undefined") {
      if (projectInfo.tags < JSON.parse(existingTagInfo).length) {
        let array = [];
        array = [...JSON.parse(existingTagInfo).slice(0, projectInfo.tags)];
        setTagInfo([...array]);
      } else {
        let diff = projectInfo.tags - JSON.parse(existingTagInfo).length;
        let array = [];
        for (let i = 0; i < diff; i++) {
          array.push(TAG_INFO_STATE);
        }
        const existing = JSON.parse(existingTagInfo).map((tag) => {
          return { ...tag, attachments: [] };
        });
        setTagInfo([...existing, ...array]);
        // setTagInfo([...JSON.parse(existingTagInfo), ...array]);
      }
    } else {
      let tagDatas = [];
      for (let i = 0; i < projectInfo.tags; i++) {
        tagDatas.push(TAG_INFO_STATE);
      }
      setTagInfo([...tagDatas]);
    }
  }, [projectInfo]);

  const handleTagInfoForm = (event, index) => {
    let existingTagInfo = [...tagInfo];
    const { name, value } = event.target;
    existingTagInfo[index] = {
      ...existingTagInfo[index],
      [name]: value,
    };
    setTagInfo([...existingTagInfo]);
  };

  const handleTabClick = (index) => {
    setSelectedTab(index);
  };

  const onSelect = (event, index) => {
    setSelectedValues(event);
    let existingTagInfo = [...tagInfo];
    existingTagInfo[index] = {
      ...existingTagInfo[index],
      attachments: event,
    };
    setTagInfo([...existingTagInfo]);
  };

  const onRemove = (event, index) => {
    setSelectedValues(event);
    let existingTagInfo = [...tagInfo];
    existingTagInfo[index] = {
      ...existingTagInfo[index],
      attachments: event,
    };
    setTagInfo([...existingTagInfo]);
  };

  const onTechSelect = (event, index) => {
    let existingTagInfo = [...tagInfo];
    existingTagInfo[index] = {
      ...existingTagInfo[index],
      serviceRequired: event,
    };
    setTagInfo([...existingTagInfo]);
  };

  const onTechRemove = (event, index) => {
    let existingTagInfo = [...tagInfo];
    existingTagInfo[index] = {
      ...existingTagInfo[index],
      serviceRequired: event,
    };
    setTagInfo([...existingTagInfo]);
  };

  useEffect(() => {
    if (tagInfo[selectedTab]?.attachments.length > 0) {
      setSelectedValues([...tagInfo[selectedTab].attachments]);
    } else {
      setSelectedValues([]);
    }
    let sub_category, verticals, sub_vertical;
    if (tagInfo[selectedTab]?.sub_category) {
      sub_category = tagInfo[selectedTab].sub_category;
    } else {
      sub_category = "Sub Category";
    }
    if (tagInfo[selectedTab]?.verticals) {
      verticals = tagInfo[selectedTab].verticals;
    } else {
      verticals = "Vertical";
    }
    if (tagInfo[selectedTab]?.sub_vertical) {
      sub_vertical = tagInfo[selectedTab].sub_vertical;
    } else {
      sub_vertical = "Sub Vertical";
    }
  }, [selectedTab]);

  const dropDownChange = ({ target: { name, value } }, index) => {
    let existingTagInfo = [...tagInfo];
    if (name === "subCategory") {
      existingTagInfo[index] = {
        ...existingTagInfo[index],
        sub_category: value,
        verticals: "",
        sub_vertical: "",
        serviceRequired: [],
      };
    } else if (name === "vertical") {
      existingTagInfo[index] = {
        ...existingTagInfo[index],
        verticals: value,
        sub_vertical: "",
        serviceRequired: [],
      };
    } else if (name === "subVertical") {
      existingTagInfo[index] = {
        ...existingTagInfo[index],
        sub_vertical: value,
        serviceRequired: [],
      };
    }
    setTagInfo([...existingTagInfo]);
  };
  return (
    <>
      <div className=" mx-14">
        {error && (
          <div
            className="bg-red-100 border text-center border-red-400 text-red-700 px-2 py-2 w-full rounded my-2 res150:py-1 res150:text-xs res125:py-1.5 res125:text-sm"
            role="alert"
          >
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <div className="mb-4 mt-2 border-b border-[#C8C8C8] max-h-[100px] overflow-x-auto overflow-y-hidden ">
          <ul
            className="flex text-sm font-medium"
            id="myTab"
            data-tabs-toggle="#myTabContent"
            role="tablist"
          >
            {Array.from({ length: projectInfo.tags }, (_, i) => (
              <li className="mr-2" role="presentation">
                <button
                  className={`font-bold text-base inline-block p-2 border-b-2 res150:text-xs hover:text-[#03298C] hover:border-[#03298C] focus:text-[#03298C] focus:border-[#03298C] ${
                    selectedTab === i
                      ? "text-[#03298C] border-[#03298C]"
                      : "border-transparent"
                  } whitespace-nowrap`}
                  id={`tab-${i}`}
                  data-tabs-target={`#tab-${i}`}
                  type="button"
                  role="tab"
                  aria-controls={`tab-${i}`}
                  aria-selected={selectedTab === i}
                  onClick={() => handleTabClick(i)}
                >
                  Tag {i + 1}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {tagInfo.length &&
        tagInfo.map(
          (tag, index) =>
            selectedTab === index && (
              <div
                className={`mt-2 flex-wrap max-w-md ml-[50px] mr-12 mb-5 overflow-y-auto max-h-[55%] h-[60%] overflow-hidden`}
              >
                <div className="w-full px-2 py-1">
                  <input
                    className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res125:py-1.5 res150:py-1 res150:text-xs res125:px-3 res150:px-2"
                    type="text"
                    placeholder="Tag Name"
                    name={"name"}
                    value={tag.name}
                    onChange={(e) => handleTagInfoForm(e, index)}
                  />
                </div>
                <div className="w-full px-2 pt-1.5">
                  <textarea
                    className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res125:py-1.5 res150:py-1 res150:text-xs res125:px-3 res150:px-2"
                    style={{ height: "65px", resize: "none" }}
                    placeholder="Tag Description"
                    name={"description"}
                    value={tag.description}
                    onChange={(e) => handleTagInfoForm(e, index)}
                  />
                </div>

                <div className="w-full px-2 pt-1.5">
                  <select
                    id="sub-category"
                    name="subCategory"
                    value={tagInfo[selectedTab].sub_category}
                    onChange={(e) => dropDownChange(e, index)}
                    className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res125:py-1.5 res150:py-1 res150:text-xs res125:px-3 res150:px-2"
                  >
                    <option
                      value=""
                      className="text-gray-500"
                      selected
                      disabled
                    >
                      Sub Category
                    </option>
                    {subCategoriesOptions &&
                      subCategoriesOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.value}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="w-full px-2 pt-1.5">
                  {tagInfo[selectedTab].sub_category && (
                    <select
                      id="vertical"
                      name="vertical"
                      value={tagInfo[selectedTab].verticals}
                      onChange={(e) => dropDownChange(e, index)}
                      className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res150:py-1 res125:py-1.5 res150:text-xs res125:px-3 res150:px-2"
                      disabled={!tagInfo[selectedTab].sub_category}
                    >
                      <option
                        value=""
                        selected
                        className="text-gray-500"
                        disabled
                      >
                        Vertical
                      </option>
                      {verticalOptions &&
                        verticalOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.value}
                          </option>
                        ))}
                    </select>
                  )}
                </div>

                <div className="w-full px-2 pt-1.5">
                  {tagInfo[selectedTab].verticals && (
                    <select
                      id="subvertical-"
                      name="subVertical"
                      value={tagInfo[selectedTab].sub_vertical}
                      onChange={(e) => dropDownChange(e, index)}
                      className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res150:py-1 res125:py-1.5 res150:text-xs res125:px-3 res150:px-2"
                      disabled={!tagInfo[selectedTab].verticals}
                    >
                      <option
                        value=""
                        selected
                        className="text-gray-500"
                        disabled
                      >
                        Sub Vertical
                      </option>
                      {subVerticalOptions &&
                        subVerticalOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.value}
                          </option>
                        ))}
                    </select>
                  )}
                </div>

                <div className="w-full px-2 pt-1.5">
                  {tagInfo[selectedTab].sub_vertical && (
                    <div className="">
                      <div className=" flex items-center justify-center w-full  bg-white res125:text-xs res125:py-1.5 res150:py-1 res150:text-xs">
                        <div className="w-full border border-[#C8C8C8]">
                          <Multiselect
                            options={techScopeOptions}
                            selectedValues={
                              tagInfo[selectedTab].serviceRequired
                            }
                            onSelect={(e) => onTechSelect(e, index)}
                            onRemove={(e) => onTechRemove(e, index)}
                            displayValue="name"
                            placeholder="Select Service-Required"
                            style={{
                              searchBox: {
                                border: "none",
                                fontSize: "10px",
                                minHeight: "40px",
                                width: "440px !important",
                              },
                              optionContainer: {
                                maxHeight: "250px",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* fileupload */}
                <div className="dropdown inline-block w-full px-2 pt-1.5">
                  <div className=" flex items-center justify-center w-full bg-white res150:text-xs">
                    <div className="w-full ms-auto border border-[#C8C8C8] res150:text-xs">
                      <Multiselect
                        className="ms-auto res150:text-[10px]"
                        options={fileNames}
                        selectedValues={selectedValues}
                        onSelect={(event) => onSelect(event, index)}
                        onRemove={(event) => onRemove(event, index)}
                        displayValue="name"
                        showCheckbox={true}
                        placeholder={"Select Files"}
                        style={{
                          searchBox: {
                            // To change search box element look
                            border: "none",
                            "font-size": "10px",
                            "min-height": "50px",
                            "@media (-webkit-min-device-pixel-ratio: 1.5)": {
                              "min-height": "10px",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center mt-1 bg-white mb-5  w-full px-2 pt-1.5">
                  <label
                    for="dropzone-file"
                    className="flex flex-col pl-4 w-full border border-[#c8c8c8] cursor-pointer  hover:bg-gray-100 "
                  >
                    <div className="flex flex-col justify-start py-4 res125:py-2 res150:py-1.5 ">
                      <div className="flex items-center">
                        <Plus className="mr-4 res125:mr-2 res150:mr-2" />
                        <span className="text-gray-500 text-sm res150:text-xs">
                          Upload Files
                        </span>
                      </div>
                    </div>
                    <input
                      id="dropzone-file"
                      name="file"
                      type="file"
                      className="hidden res150:text-xs"
                      onChange={(e) => handleFiles(e)}
                      multiple
                    />
                  </label>
                </div>
                {selectedFile?.length > 0 && (
                  <>
                    {selectedFile[selectedTab].file.map((file, index) => (
                      <div className="flex whitespace-nowrap ">
                        <span className="ml-3 w-[200px] text-ellipsis overflow-hidden res150:text-xs res125:text-xs">
                          {file.name}
                        </span>
                        <span
                          className="text-blue-300 underline cursor-pointer ml-auto mr-3 res150:text-xs res125:text-xs "
                          onClick={(e) => {
                            let existingfiles = [...selectedFile];
                            existingfiles[selectedTab].file.splice(index, 1);
                            setSelectedFile([...existingfiles] || []);
                            setTagFiles([...existingfiles] || []);
                          }}
                        >
                          Remove
                        </span>
                      </div>
                    ))}
                  </>
                )}
              </div>
            )
        )}
    </>
  );
};

export default CreateProjectForms2;

{
  /* ///for multiselect */
}
{
  /* <div className="w-full px-2 pt-1.5">
                                    {tagInfo[selectedTab].sub_category && (
                                        <select
                                            id="vertical"
                                            name="vertical"
                                            value={tagInfo[selectedTab].verticals}
                                            onChange={(e) => dropDownChange(e, index)}
                                            className="w-full px-5 py-3 text-sm placeholder-[#929292] bg-[#FFFFFF] outline-none focus:ring-1 focus:ring-[#C8C8C8] border border-[#C8C8C8] res150:py-1 res125:py-1.5 res150:text-xs res125:px-3 res150:px-2"
                                            disabled={!tagInfo[selectedTab].sub_category}
                                        >
                                            <option
                                                value=""
                                                selected
                                                className="text-gray-500"
                                                disabled
                                            >
                                                Vertical
                                            </option>
                                            {verticalOptions[
                                                tagInfo[selectedTab].sub_category
                                            ].map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.value}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div> */
}
