import { Route, useHistory } from "react-router-dom";
import { useEffect, useContext } from "react";
import AppContext from "../context/AppContext";

const ProtectedRoute = ({ component: Component, layout: Layout, redirect, role, ...rest }) => {
  const history = useHistory();
  const { isInitialSet, isAuthenticated, type } = useContext(AppContext);

  useEffect(() => {
    const pathname = history.location.pathname;
    if (isInitialSet) {
      if (pathname.includes("signin") || pathname.includes("signup") || pathname.includes("resetpassword")) {

      } else if (type) {
        if (role && isAuthenticated && type === role) {

        } else {
          history.push("/404");
        }
      } else {
        history.push('/404');
      }
    }
  }, [isInitialSet, type, isAuthenticated])

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props}></Component>
        </Layout>
      )}
    ></Route>
  );
};

export default ProtectedRoute;
