import React, { useState, useContext } from "react";
import QuotationsTab from "../../Admin/Quotations/QuotationsTab";
import DetailedProjectTab from "./DetailedProjectTab";
import Credentials from "../../Admin/Projects/Credentials/Credentials";
import DiagramTable from "../../Admin/Projects/DiagramTable";
import Loader from "../../../Loader";
import AppContext from "../../../context/AppContext";
import ServiceYourQuote from "../../ServiceProvider/Quotations/ServiceYourQuote";
import ProjectCollaboration from "../../ServiceProvider/Projects/ServiceProjectCollaboration";
import CustomerProjectCollabration from "../../Customer/Projects/ProjectCollabration";
import CustomerOrders from "../../Customer/Projects/CustomerOrders";
import YourQuotation from "../../Customer/Projects/YourQuotation";
import YourOrder from "../../Admin/Quotations/YourOrders";
import ServiceOrder from "../../ServiceProvider/Quotations/serviceOrders";
import QuotationsTable from "./QuotationsTable";
import CreateQuotation from "../../ServiceProvider/Quotations/CreateQuotation";
import Pricing from "../../ServiceProvider/Quotations/Pricing";
import toast from "react-hot-toast";
const TabSwitch = ({ projectData, loading, onNextStep }) => {
  const [activeTab, setActiveTab] = useState(0);
  const { type } = useContext(AppContext);
  let stats = projectData?.tags[0]?.serviceRequired.filter(
    (ele) => ele?.vendorStatus === "Pending"
  );

  return (
    <div className=" mx-16 mt-0">
      {loading ? (
        <Loader className="mx-auto" />
      ) : (
        <div>
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex res125:text-sm res150:text-xs">
              <button
                id="projectsDetailsTab"
                className={`${activeTab === 0
                  ? "border-[#03298C] text-[#03298C]"
                  : "border-transparent text-[#C8C8C8] hover:text-gray-700 hover:border-gray-300"
                  } w-44 res150:w-36 py-4 px-1 text-center border-b-2 font-bold  focus:outline-none`}
                onClick={() => setActiveTab(0)}
              >
                Project Details
              </button>
              {type === "customer" && (
                <button
                  id="projectsYourQuotation"
                  className={`${activeTab === 5
                    ? "border-[#03298C] text-[#03298C]"
                    : "border-transparent text-[#C8C8C8] hover:text-gray-700 hover:border-gray-300 "
                    } w-44 res150:w-36  py-4 px-1 text-center border-b-2 font-bold focus:outline-none`}
                  onClick={() => {
                    setActiveTab(5);
                    onNextStep();
                  }}
                >
                  Your Quotation
                </button>
              )}

              {type === "admin" && (
                <>
                  <button
                    className={`${activeTab === 1
                      ? "border-[#03298C] text-[#03298C]"
                      : "border-transparent text-[#C8C8C8] hover:text-gray-700 hover:border-gray-300 "
                      } w-44 res150:w-36  py-4 px-1 text-center border-b-2 font-bold focus:outline-none`}
                    onClick={() => setActiveTab(1)}
                  >
                    SPs & Quotations
                  </button>
                  <button
                    className={`${activeTab === 2
                      ? "border-[#03298C] text-[#03298C]"
                      : "border-transparent text-[#C8C8C8] hover:text-gray-700 hover:border-gray-300 "
                      } w-44 res150:w-36  py-4 px-1 text-center border-b-2 font-bold focus:outline-none`}
                    onClick={() => setActiveTab(2)}
                  >
                    Credentials
                  </button>
                </>
              )}
              <button
                // disabled={stats.length !== 0 && type === "service-provider"}
                id="ProjectCollabrations"
                className={`${activeTab === 3
                  ? "border-[#03298C] text-[#03298C]"
                  : "border-transparent text-[#C8C8C8] hover:text-gray-700 hover:border-gray-300 "
                  } w-44 res150:w-36  py-4 px-1 text-center border-b-2 font-bold focus:outline-none`}
                onClick={() => {
                  if (stats?.length !== 0 && type === "service-provider") {
                    toast.error("Please Accept or Reject Tags");
                  } else {
                    setActiveTab(3);
                    onNextStep();
                  }
                }}
              >
                Project Collaborations
              </button>

              {type === "service-provider" && (
                <button
                  // disabled={stats.length !== 0 && type === "service-provider"}
                  id="serviceProviderQuotations"
                  className={`${activeTab === 4
                    ? "border-[#03298C] text-[#03298C]"
                    : "border-transparent text-[#C8C8C8] hover:text-gray-700 hover:border-gray-300"
                    }  py-4  px-8 text-center border-b-2 font-bold  focus:outline-none disabled `}
                  onClick={() => {
                    if (stats?.length !== 0 && type === "service-provider") {
                      toast.error("Please Accept or Reject Tags");
                    } else {
                      setActiveTab(4);
                      onNextStep();
                    }
                  }}
                >
                  Your Quotations
                </button>
              )}
              {type === "admin" && (
                <button
                  className={`${activeTab === 6
                    ? "border-[#03298C] text-[#03298C]"
                    : "border-transparent text-[#C8C8C8] hover:text-gray-700 hover:border-gray-300"
                    }  py-4 px-8 text-center border-b-2 font-bold  focus:outline-none`}
                  onClick={() => setActiveTab(6)}
                >
                  Your Orders
                </button>
              )}

              {/* your orders */}
              {type === "customer" && (
                <button
                  id="cusYourOrder"
                  className={`${activeTab === 7
                    ? "border-[#03298C] text-[#03298C]"
                    : "border-transparent text-[#C8C8C8] hover:text-gray-700 hover:border-gray-300 "
                    } w-44 res150:w-36  py-4 px-1 text-center border-b-2 font-bold focus:outline-none`}
                  onClick={() => {
                    setActiveTab(7);
                    onNextStep();
                  }}
                >
                  {" "}
                  Your Orders{" "}
                </button>
              )}

              {type === "service-provider" && (
                <button
                  // disabled={stats.length !== 0 && type === "service-provider"}
                  id="serviceProviderYourORder"
                  className={`${activeTab === 8
                    ? "border-[#03298C] text-[#03298C]"
                    : "border-transparent text-[#C8C8C8] hover:text-gray-700 hover:border-gray-300 "
                    } w-44 res150:w-36  py-4 px-1 text-center border-b-2 font-bold focus:outline-none`}
                  onClick={() => {
                    if (stats?.length !== 0 && type === "service-provider") {
                      toast.error("Please Accept or Reject Tags");
                    } else {
                      setActiveTab(8);
                      onNextStep();
                    }
                  }}
                >
                  {" "}
                  Your Orders{" "}
                </button>
              )}

              {type != "customer" && (
                <button
                  // disabled={stats.length !== 0 && type === "service-provider"}
                  id="pringingforseriverprovider"
                  className={`${activeTab === 9
                    ? "border-[#03298C] text-[#03298C]"
                    : "border-transparent text-[#C8C8C8] hover:text-gray-700 hover:border-gray-300 "
                    } w-44 res150:w-36  py-4 px-1 text-center border-b-2 font-bold focus:outline-none`}
                  onClick={() => {
                    if (stats?.length !== 0 && type === "service-provider") {
                      toast.error("Please Accept or Reject Tags");
                    } else {
                      setActiveTab(9);
                      onNextStep();
                    }
                  }}
                >
                  {" "}
                  Pricing{" "}
                </button>
              )}
            </nav>
          </div>

          <div className="">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex">{/* ... */}</nav>
            </div>
            <div className="">
              {activeTab === 0 && (
                <DetailedProjectTab
                  projectData={projectData}
                  loading={loading}
                />
              )}

              {/* SPs & Quotations */}
              {activeTab === 1 && <QuotationsTab projectData={projectData} />}

              {/* SPs & Quotations */}
              {activeTab === 2 && (
                <Credentials customerId={projectData.customer_id} />
              )}

              {/* Project collaboration */}
              {activeTab === 3 && type === "admin" && (
                <DiagramTable projectData={projectData} />
              )}
              {activeTab === 3 && type === "service-provider" && (
                <ProjectCollaboration projectData={projectData} />
              )}
              {activeTab === 3 && type === "customer" && (
                <CustomerProjectCollabration projectId={projectData?._id} />
              )}
              {activeTab === 5 && type === "customer" && (
                <YourQuotation projectData={projectData} />
              )}

              {/* Your Quotation */}
              {activeTab === 4 && type === "service-provider" && (
                <ServiceYourQuote projectData={projectData} />
              )}
              {activeTab === 6 && type == "admin" && (
                <YourOrder projectData={projectData} />
              )}
              {/* your ordersss */}
              {activeTab === 7 && type === "customer" && (
                <CustomerOrders projectId={projectData?._id} />
              )}
              {activeTab === 8 && type === "service-provider" && (
                <ServiceOrder />
              )}

              {/* {activeTab === 9 && type === "admin" && (
                <QuotationsTable projectData={projectData} />
              )} */}

              {/* {activeTab === 10 && type === "service-provider" && (
                <CreateQuotation projectData={projectData} />
              )} */}

              {activeTab === 9 && type != "customer" && (
                <Pricing projectData={projectData} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TabSwitch;
