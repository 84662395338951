import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { BASE_URL } from "../../../constants/Constants";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";

export default function CustomerSetting({ fileName }) {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [contact, setContact] = useState("");
  const navigate = useHistory();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [enterKeyPressed, setEnterKeyPressed] = useState(false);
  const submitButtonRef = useRef();
  // bearer token
  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };
  // Get data from localStorage
  const storedData = JSON.parse(localStorage.getItem("User"));

  useEffect(() => {
    // Update state variables with stored data
    if (storedData) {
      setEmail(storedData.email);
      setUsername(storedData.name);
      setContact(storedData.contact_no);
    }
  }, []);

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "username") {
      setUsername(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "contact") {
      setContact(value);
    }
  };

  const handleCancel = () => {
    navigate.push("/customer/projects");
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      setEnterKeyPressed(true);
      event.preventDefault(); // Prevent form submission if necessary
    }
  };

  // define a handleSubmit function to pass the input values to the payload
  const handleSubmit = () => {
    if (!email || !contact || !username) {
      setError("Please fill the all fields");
    } else {
      const payload = {
        email,
        name: username,
        contact_no: contact,
        profile_picture: fileName,
        user_id: storedData.id,
      };
      setError("")
      //pass the payload to an API endpoint
      setLoading(true);
      setIsDisabled(true);
      axios
        .put(`${BASE_URL}/api/user/update`, payload, { headers })
        .then((res) => {
          setEmail(res.data.user.email);
          setUsername(res.data.user.name);
          setContact(res.data.user.contact_no);
          setLoading(false);
          setIsDisabled(false);

          // Update the localStorage with the updated user data
          const updatedUser = {
            ...storedData,
            email: res.data.user.email,
            name: res.data.user.name,
            contact_no: res.data.user.contact_no,
            profile_picture: res.data.user.profile_picture,
          };
          localStorage.setItem("User", JSON.stringify(updatedUser));

          toast.success("Profile Updated");
          // window.location.reload();
        })
        .catch((err) => {
          console.error(err);
          toast.error("Profile Not Updated");
          setLoading(false);
          setIsDisabled(false);
          setError(err.response.data.message);
        });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submitButtonRef.current.click(); // Programmatically click the submit button
    }
  };
  useEffect(() => {
    if (enterKeyPressed) {
      handleSubmit();
      setEnterKeyPressed(false);
    }
  }, [enterKeyPressed]);

  return (
    <>
      {error && (
        <div
          class="bg-red-100 border w-2/6 mx-auto mt-10 res150:mt-8 border-red-400 text-red-700 px-4 py-3  rounded relative mb-2 text-center res125:text-sm res125:py-1.5 res150:text-xs res150:py-1"
          role="alert"
        >
          <span class="block sm:inline">{error}</span>
        </div>
      )}
      <div className="grid grid-cols-2 gap-7 mx-auto w-4/5 mt-10 res150:mt-8">
        <div>
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700 res125:text-sm res150:text-xs"
          >
            Full name
          </label>
          <input
            name="username"
            value={username}
            className="mt-1 px-4 py-2 rounded  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14 res125:text-sm res125:h-9 res150:h-8 res150:text-xs"
            type="text"
            placeholder="Enter your first name"
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
          />
        </div>

        <div>
          <label
            htmlFor="contact"
            className="block text-sm font-medium text-gray-700 res125:text-sm res150:text-xs"
          >
            Contact Number
          </label>
          <input
            className="mt-1 px-4 py-2 rounded border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14 res125:text-sm res125:h-9 res150:h-8 res150:text-xs"
            type="text"
            placeholder="Contact number"
            name="contact"
            value={contact}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
          />
        </div>

        <div>
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700 res125:text-sm res150:text-xs"
          >
            Email
          </label>
          <input
            className="mt-1 px-4 py-2 rounded  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent block w-full h-14 res125:text-sm res125:h-9 res150:h-8 res150:text-xs"
            type="email"
            value={email}
            name="email"
            placeholder="Enter your email"
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
          />
        </div>
      </div>
      <div className="flex gap-10 res150:gap-8 mx-auto justify-center mt-20 res150:text-xs">
        <button
          className="w-[10rem] res125:w-36 res125:h-10 res125:text-sm res150:w-28 res150:h-7 res150:text-xs  border-[0.5px] border-[#03298C] h-12 rounded hover:bg-[#03298C] hover:text-white font-bold"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isDisabled}
          ref={submitButtonRef}
          onClick={handleSubmit}
          onKeyUp={handleKeyUp}
          className={`bg-[#03298C] w-[10rem] h-12 res125:w-36 res125:h-10 res125:text-sm res150:w-28 res150:h-7 res150:text-xs hover:bg-[#72C27A] text-white font-bold  rounded ${
            isDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {loading ? (
            <div className="flex justify-center ">
              <img
                src="https://media1.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif"
                className="w-[2rem] mx-auto res125:w-9 res150:w-7"
                alt=""
              />
            </div>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </>
  );
}
