import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-hot-toast";
import Popup from "../../Customer/Signup/popup";
import {
  BASE_URL,
  serviceCapabilitiesOptions,
  softwareCapabilitiesOptions,
  verticalOptions,
  subCategoriesOptions,
  subVerticalOptions,
} from "../../../constants/Constants";
import swal from "sweetalert";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import countryCodesData from "../../../assets/json/CountryCode.json";

export default function SignUpFreelancer() {
  const [error, setError] = useState(null);
  const formArray = [1, 2, 3, 4];
  const [formNo, setFormNo] = useState(formArray[0]);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setCofirmPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useHistory();
  const [countryCodes, setCountryCodes] = useState(countryCodesData.countries);
  const [selectedOption, setSelectedOption] = useState("+91");
  const [serviceRequiredOptions, setserviceRequiredOptions] = useState([]);
  const [softwareOptions, setSoftwareOptions] = useState([]);

  //terms and condition
  const openPopup = () => {
    setShowPopup(true);
  };
  const closePopup = () => {
    setShowPopup(false);
  };

  const [freeUser, setfreeUser] = useState({
    type: "freelancer",
    name: "",
    contact: "",
    email: "",
    password: "",
    country: "",
    totalhourstoworks: "",
    workingFullTime: "",
    fullTimeOrPartTime: "",
    education: "",
    educationDiscipline: "",
    linkedInID: "",
    category: "",
    subCategory: "",
    vertical: "",
    subVertical: "",
    serviceCapabilities: [],
    softwareCapabilities: [],
    bankName: "",
    accountHolderName: "",
    accountNumber: "",
    ifscCode: "",
    branchName: "",
    serviceAgreement: false,
    docs: "",
  });

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  freeUser.serviceAgreement = isChecked;

  const handleChange = ({ target: { name, value } }) => {
    setfreeUser((data) => {
      return { ...data, [name]: value };
    });
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = () => {
    setCofirmPassword(!showConfirmPassword);
  };
  //////service
  const handleSerSelect = (selectedList) => {
    let existingUser = freeUser;
    existingUser.serviceCapabilities = selectedList;
    setfreeUser(existingUser);
  };

  const handleSerRemove = (selectedList) => {
    resetSelectField();
    let existingUser = freeUser;
    existingUser.serviceCapabilities = selectedList;
    setfreeUser(existingUser);
  };

  //////softwareused
  const handleSoftSelect = (selectedList) => {
    let existingUser = freeUser;
    existingUser.softwareCapabilities = selectedList;
    setfreeUser(existingUser);
  };

  const handleSoftRemove = (selectedList) => {
    resetSelectField();
    let existingUser = freeUser;
    existingUser.softwareCapabilities = selectedList;
    setfreeUser(existingUser);
  };

  const dropDownChange = ({ target: { name, value } }) => {
    let existingUser = { ...freeUser };
    switch (name) {
      case "category":
        existingUser.category = value;
        existingUser.subCategory = "";
        existingUser.vertical = "";
        existingUser.subVertical = "";
        break;
      case "subCategory":
        existingUser.subCategory = value;
        existingUser.vertical = "";
        existingUser.subVertical = "";
        break;
      case "vertical":
        existingUser.vertical = value;
        existingUser.subVertical = "";
        break;
      case "subVertical":
        existingUser.subVertical = value;
        break;
      default:
        break;
    }
    setfreeUser({ ...existingUser });
    resetSelectField();
  };

  /////fileupload

  const handleFiles = async (event) => {
    setSelectedFile(event.target.files[0]);
    let obj = { ...freeUser };
    obj[event.target.name] = event.target.files[0].name;
    setfreeUser({ ...obj });
  };

  const fileUpload = async () => {
    let success = false;
    const s3Client = new S3Client({
      region: "ap-south-1",
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    });

    const params = {
      Bucket: "factreehub-docs",
      Key: `verification/${selectedFile.name}`,
      ContentType: selectedFile.type,
      Body: selectedFile,
    };

    try {
      const putObjectCommand = new PutObjectCommand(params);
      const reponse = await s3Client.send(putObjectCommand);
      toast.success("File uploaded successfully!");
      success = true;
    } catch (error) {
      console.error(error);
      toast.error("Please Try Again");
      success = false;
    }
    if (!success) {
      setSelectedFile();
    }
  };

  useEffect(() => {
    if (selectedFile) {
      fileUpload();
    }
  }, [selectedFile]);

  //form start

  const next = async () => {
    if (formNo === 1) {
      if (!freeUser.name) {
        setError("Full Name is required.");
        return;
      }
      if (!freeUser.contact) {
        setError("Contact No. is required.");
        return;
      } else if (!/^\d{4,15}$/.test(freeUser.contact)) {
        setError(
          "Contact should be number and it must contains 4 digits long."
        );
        return;
      }
      if (!freeUser.email) {
        setError("Email is required.");
        return;
      } else if (!/^\S+@\S+\.\S+$/.test(freeUser.email)) {
        setError("Please enter a valid email address.(EX:abcd@gmail.com)");
        return;
      } else if (freeUser.email) {
        try {
          await axios.get(`${BASE_URL}/api/email-check`, {
            params: {
              email: freeUser.email,
            },
          });
        } catch (error) {
          setError(error.response.data.message);
          return true;
        }
      }
      if (!freeUser.password) {
        setError("Password is required.");
        return;
      } else if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/.test(
          freeUser.password
        )
      ) {
        setError(
          "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character (!@#$%^&*)"
        );
        return;
      }
      if (!freeUser.confirmedpassword) {
        setError("Confirm password is required.");
        return;
      }
      if (freeUser.password !== freeUser.confirmedpassword) {
        setError("Password and Confirm password do not match.");
        return;
      }
      if (!freeUser.country) {
        setError("Country is required.");
        return;
      } else {
        setError(null);
      }
      setFormNo(formNo + 1);
    } else if (formNo === 2) {
      if (!freeUser.totalhourstoworks) {
        setError("Totalhours works is required.");
        return;
      } else if (!/^\d{1,5}$/.test(freeUser.totalhourstoworks)) {
        setError("Totalhours works time should be in number.");
        return;
      }
      if (!freeUser.workingFullTime) {
        setError("Working option is required.");
        return;
      }
      if (!freeUser.fullTimeOrPartTime) {
        setError("Working Type is required.");
        return;
      }
      if (!freeUser.education) {
        setError("Education is required.");
        return;
      }
      if (!freeUser.educationDiscipline) {
        setError("EducationDiscipline is required.");
        return;
      }
      // if (!freeUser.linkedInID) {
      //   setError("Organization Size is required");
      //   return;
      // }
      else {
        setError(null);
      }
      setFormNo(formNo + 1);
    } else if (formNo === 3) {
      if (!freeUser.category) {
        setError("Category is required.");
        return;
      }
      if (!freeUser.subCategory) {
        setError("Sub-Category is required.");
        return;
      }
      if (!freeUser.vertical) {
        setError("Vertical is required.");
        return;
      }
      if (!freeUser.subVertical) {
        setError("SubVertical is required.");
        return;
      }
      if (!freeUser.serviceCapabilities.length) {
        setError("ServiceCapabilities is required.");
        return;
      }
      if (!freeUser.softwareCapabilities.length) {
        setError("SoftwareUsed is required.");
        return;
      } else {
        setError(null);
      }
      setFormNo(formNo + 1);
    } else if (formNo === 4) {
      if (!freeUser.bankName) {
        setError("BankName is required.");
        return;
      }
      if (!freeUser.accountHolderName) {
        setError("AccountName is required.");
        return;
      }
      if (!freeUser.accountNumber) {
        setError("AccountNumber is required.");
        return;
      }
      if (!freeUser.ifscCode) {
        setError("IFSC Code is required");
        return;
      }
      if (!freeUser.branchName) {
        setError("AccountNumber is required");
        return;
      } else {
        setError(null);
      }
      setFormNo(formNo + 1);
    }
  };

  const multiselectRef = useRef();

  const resetSelectField = () => {
    multiselectRef.current?.resetSelectedValues();
  };
  const back = () => {
    setFormNo(formNo - 1);
  };
  const handleSubmit = async (e) => {
    if (!freeUser.name) {
      setError("Full Name is required");
    } else if (!freeUser.contact) {
      setError("Contact No. is required");
    } else if (!freeUser.email) {
      setError("Email is required");
    } else if (!freeUser.password) {
      setError("Password is required.");
    } else if (!freeUser.confirmedpassword) {
      setError("Confirm password is required.");
    } else if (freeUser.password !== freeUser.confirmedpassword) {
      setError("Password and confirm password do not match.");
    } else if (!freeUser.country) {
      setError("Country is required.");
    } else if (!freeUser.totalhourstoworks) {
      setError("TotalWorks Hour is required.");
    } else if (!freeUser.workingFullTime) {
      setError("Working Time is required.");
    } else if (!freeUser.fullTimeOrPartTime) {
      setError("Please Select one option");
    } else if (!freeUser.education) {
      setError("Education is required.");
    } else if (!freeUser.educationDiscipline) {
      setError("Education Discipline is required.");
    }
    // else if (!freeUser.linkedInID) {
    //   setError("LinkedIn ID is required.");
    // }
    else if (!freeUser.category) {
      setError("Category is required.");
    } else if (!freeUser.subCategory) {
      setError("SubCategory is required.");
    } else if (!freeUser.vertical) {
      setError("Vertical is required.");
    } else if (!freeUser.subVertical) {
      setError("Sub-Vertical is required.");
    } else if (!freeUser.serviceCapabilities) {
      setError("ServiceCapabilities is required.");
    } else if (!freeUser.softwareCapabilities) {
      setError("SoftwareUsed is required.");
    } else if (!freeUser.bankName) {
      setError("BankName is required.");
    } else if (!/^[a-zA-Z\s]{7,16}$/.test(freeUser.bankName)) {
      setError(
        `BankName should only contain letters, spaces, and be between 7 and 16 characters long.`
      );
    } else if (!freeUser.accountHolderName) {
      setError("AccountName is required.");
    } else if (!/^[a-zA-Z\s]{2,30}$/.test(freeUser.accountHolderName)) {
      setError(
        `AccountName should only contain letters, spaces, and be between 2 and 30 characters long.`
      );
    } else if (!freeUser.accountNumber) {
      setError("AccountNumber is required.");
    } else if (!/^\d{11,15}$/.test(freeUser.accountNumber)) {
      setError("AccountNumber contains only numbers between 11 to 15 digits.");
    } else if (!freeUser.ifscCode) {
      setError("IFSC Code is required.");
    } else if (!/^[A-Z]{4}[0][A-Z0-9]{6}$/.test(freeUser.ifscCode)) {
      setError(
        "Please enter a valid IFSC code,it should be 11 characters long (EX:ABCD0XX5943)"
      );
    } else if (!freeUser.branchName) {
      setError("Branch Name is required.");
    } else if (!/^[a-zA-Z\s]{2,30}$/.test(freeUser.branchName)) {
      setError(
        `BranchName should only contain letters, spaces, and be between 7 and 16 characters long.`
      );
    } else if (!freeUser.docs.length) {
      setError("Document is required.");
    } else if (!freeUser.serviceAgreement) {
      setError("Please Accept the Terms and Conditons.");
    } else {
      e.preventDefault();
      const updatedUser = {
        ...freeUser,
        contact: `${selectedOption} - ${freeUser.contact}`,
      };
      setLoading(true);
      setIsDisabled(true);
      setError("");
      await axios
        .post(`${BASE_URL}/api/serviceprovider/create`, updatedUser)

        .then((res) => {
          navigate.push("/auth/signin");
          toast.success(" Company Details Register Successfully");
          swal(
            "Congratulations",
            "Your Account has register successfully",
            "success"
          );
          setLoading(false);
          setIsDisabled(false);
        })
        .catch((err) => {
          swal(
            `${err.response.data.message}`,
            "Please try again with a different email !",
            "error"
          );
          toast.error(`${err.response.data.message}`);
          setLoading(false);
          setIsDisabled(false);
          console.log(err.response.data.message);
        });
    }
  };

  const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Access"))}`,
  };

  const serviceRequired = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getAllService`, {
        params: {
          service: JSON.stringify(freeUser.serviceCapabilities),
        },
      });

      if (response.data && response.data.Allmicroserivce) {
        const microservices = response.data.Allmicroserivce;

        const options = microservices.map((microservice) => ({
          label: microservice._id,
          value: microservice.microservice,
        }));

        setserviceRequiredOptions(options);
      } else {
        console.error("API response format is unexpected.");
      }
    } catch (error) {
      console.error("Error fetching service options:", error);
    }
  };

  const fetchSoftwareOptions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/get/softwares`, {
        headers,
      });
      if (response.data && response.data.data) {
        const softwareData = response.data.data.map((item) => ({
          label: item.softwares,
          value: item.softwares,
        }));
        setSoftwareOptions(softwareData);
      }
    } catch (error) {
      console.error("Error fetching software options:", error);
    }
  };

  useEffect(() => {
    serviceRequired();
    fetchSoftwareOptions();
  }, []);

  return (
    <div className="flex flex-col gap-3 pt-8 res125:pt-5 res150:pt-4 max-w-[390px] m-auto px-[20px]">
      {error && (
        <div
          className="bg-red-100 border text-center relative  border-red-400 text-red-700 px-3 py-2  rounded mb-2   res150:mb-1 res150:text-[10px] res125:mb-1 res125:text-xs"
          role="alert"
        >
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      {formNo === 1 && (
        <div>
          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="fullName"
              className="res150:text-[10px] res125:text-xs"
            >
              Full Name
            </label>
            <input
              value={freeUser.name}
              onChange={handleChange}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1  res150:h-8  res150:text-[10px]  res125:h-10  res125:text-xs"
              type="text"
              name="name"
              placeholder="Enter your full name"
            />
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="contact"
              className="res150:text-[10px] res125:text-xs"
            >
              Contact No
            </label>

            <div className="flex">
              <select
                className="w-[34%] h-12 mt-[10px] rounded border-[#C8C8C8] uppercase res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
                value={selectedOption}
                onChange={(event) => setSelectedOption(event.target.value)}
              >
                {countryCodes?.map((code) => (
                  <option key={code.code} value={code.code}>
                    <span>{code.name.slice(0, 3)}</span> ({code.code})
                  </option>
                ))}
              </select>
              <input
                value={freeUser.contact}
                onChange={handleChange}
                className="w-[64%] h-12 rounded border-[#C8C8C8] mt-[10px] ml-auto res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
                type="text"
                name="contact"
                placeholder="Enter your contact no"
              />
            </div>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="email"
              className="res150:text-[10px] res125:text-xs"
            >
              Email
            </label>
            <input
              value={freeUser.email}
              onChange={handleChange}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="email"
              name="email"
              placeholder="Enter your email"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="password"
              className="res150:text-[10px] res125:text-xs"
            >
              Password
            </label>
            <div className="relative mt-[10px] h-[50px]">
              <input
                type={showPassword ? "text" : "password"}
                className="w-[100%] border-[#C8C8C8] rounded h-12 placeholder-gray-400  res150:h-8 res150:text-[10px]  res125:h-10 res125:text-xs"
                placeholder="Enter password"
                value={freeUser.password}
                name="password"
                onChange={handleChange}
              />
              <span
                type="button"
                className="w-1/12 ml-auto flex transform -translate-y-8 text-gray-500 focus:outline-none res150:my-2 res125:my-1"
                onClick={handleTogglePassword}
              >
                {showPassword ? (
                  <i
                    className="fa fa-eye cursor-pointer"
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye-slash  cursor-pointer"
                    aria-hidden="true"
                  ></i>
                )}
              </span>
            </div>
          </div>
          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="confirmPassword"
              className="res150:text-[10px] res125:text-xs"
            >
              Confirm Password
            </label>
            <div className="relative mt-[10px] h-[50px]">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="w-[100%] border-[#C8C8C8] rounded h-12  placeholder-gray-400  res150:h-8 res150:text-[10px] res125:h-10 res125:text-xs"
                placeholder="Confirm password"
                value={freeUser.confirmedpassword}
                name="confirmedpassword"
                onChange={handleChange}
              />
              <span
                type="button"
                className="w-1/12 ml-auto flex transform -translate-y-8  text-gray-500 focus:outline-none res150:my-2 res125:my-1"
                onClick={handleToggleConfirmPassword}
              >
                {showConfirmPassword ? (
                  <i
                    className="fa fa-eye  cursor-pointer"
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye-slash  cursor-pointer"
                    aria-hidden="true"
                  ></i>
                )}
              </span>
            </div>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="country"
              className="res150:text-[10px] res125:text-xs"
            >
              Country
            </label>
            <input
              onChange={handleChange}
              value={freeUser.country}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="text"
              name="country"
              placeholder="Enter your Country"
            ></input>
          </div>
        </div>
      )}

      {formNo === 2 && (
        <div>
          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="totalhourstoworks"
              className="res150:text-[10px] res125:text-xs"
            >
              Total Work Hours
            </label>
            <input
              onChange={handleChange}
              value={freeUser.totalhourstoworks}
              className="h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="text"
              name="totalhourstoworks"
              placeholder="Enter Working Hours"
            ></input>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="workingFullTime"
              className="block res150:text-[10px] res125:text-xs"
            >
              Working full time
            </label>
            <select
              id="workingFullTime"
              name="workingFullTime"
              value={freeUser.workingFullTime}
              onChange={handleChange}
              className="h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
            >
              <option
                value=""
                className="text-gray-500 res150:text-[10px] res125:text-xs"
                selected
                disabled
              >
                Select
              </option>
              <option value="Role">Yes</option>
              <option value="admin">No</option>
            </select>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="fullTimeOrPartTime"
              className="block res150:text-[10px] res125:text-xs"
            >
              Full Time or Part Time
            </label>
            <select
              id="fullTimeOrPartTime"
              name="fullTimeOrPartTime"
              value={freeUser.fullTimeOrPartTime}
              onChange={handleChange}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
            >
              <option
                value=""
                className="text-gray-500 res150:text-[10px] res125:text-xs"
                selected
                disabled
              >
                Select
              </option>
              <option value="Role">Full Time</option>
              <option value="admin">Part Time</option>
            </select>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="education"
              className="block res150:text-[10px] res125:text-xs"
            >
              Education
            </label>
            <select
              id="education"
              name="education"
              value={freeUser.education}
              onChange={handleChange}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10"
            >
              <option
                value=""
                className="text-gray-500 res150:text-[10px] res125:text-xs"
                selected
                disabled
              >
                Select
              </option>
              <option value="EPC">Bsc</option>
              <option value="Manufacturer">Msc</option>
            </select>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="educationDiscipline"
              className="block res150:text-[10px] res125:text-xs"
            >
              Education discipline
            </label>
            <select
              id="educationDiscipline"
              name="educationDiscipline"
              value={freeUser.educationDiscipline}
              onChange={handleChange}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
            >
              <option
                value=""
                className="text-gray-500 res150:text-[10px] res125:text-xs"
                selected
                disabled
              >
                Select
              </option>
              <option value="Small">Computer science</option>
              <option value="Medium">Medium</option>
            </select>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="linkedInID"
              className="res150:text-[10px] res125:text-xs"
            >
              LinkedIn Id
            </label>
            <input
              onChange={handleChange}
              value={freeUser.linkedInID}
              className="h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="text"
              name="linkedInID"
              placeholder="Enter your LinkedIn ID"
            ></input>
          </div>
        </div>
      )}

      {/* form 3 */}
      {formNo === 3 && (
        <div>
          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="category"
              className="block res150:text-[10px] res125:text-xs"
            >
              Category
            </label>
            <select
              id="category"
              name="category"
              value={freeUser.category}
              onChange={dropDownChange}
              className=" h-12 rounded border-[#C8C8C8] mt-3 res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10"
            >
              <option
                value=""
                className="text-gray-500 res150:text-[10px] res125:text-xs"
                selected
                disabled
              >
                Select
              </option>
              <option value="Process Plants">Process Plants</option>
            </select>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="sub-category"
              className="block res150:text-[10px] res125:text-xs"
            >
              Sub-Category
            </label>
            <select
              id="sub-category"
              name="subCategory"
              value={freeUser.subCategory}
              onChange={dropDownChange}
              className=" h-12 rounded border-[#C8C8C8] mt-3 res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8   res125:h-10 "
              disabled={!freeUser.category}
            >
              <option
                value=""
                className="text-gray-500 res150:text-[10px] res125:text-xs"
                selected
                disabled
              >
                Select{" "}
              </option>
              {subCategoriesOptions &&
                subCategoriesOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.value}
                  </option>
                ))}
            </select>
          </div>

          <div className="flex flex-col mb-[10px]">
            {freeUser?.subCategory && (
              <>
                <label
                  htmlFor="vertical"
                  className="block res150:text-[10px] res125:text-xs "
                >
                  Vertical
                </label>
                <select
                  id="vertical"
                  name="vertical"
                  value={freeUser.vertical}
                  onChange={dropDownChange}
                  className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8   res125:h-10 "
                  disabled={!freeUser.subCategory}
                >
                  <option
                    value=""
                    className="text-gray-500 res150:text-[10px] res125:text-xs"
                    selected
                    disabled
                  >
                    Select{" "}
                  </option>
                  {verticalOptions &&
                    verticalOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.value}
                      </option>
                    ))}
                </select>
              </>
            )}
          </div>

          <div className="flex flex-col mb-[10px]">
            {freeUser?.vertical && (
              <>
                <label
                  htmlFor="subVertical"
                  className="block res150:text-[10px] res125:text-xs "
                >
                  Sub-Vertical
                </label>
                <select
                  id="subVertical"
                  name="subVertical"
                  value={freeUser.subVertical}
                  onChange={dropDownChange}
                  className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8   res125:h-10 "
                  disabled={!freeUser.vertical}
                >
                  <option
                    value=""
                    className="text-gray-500 res150:text-[10px] res125:text-xs"
                    selected
                    disabled
                  >
                    Select{" "}
                  </option>
                  {subVerticalOptions &&
                    subVerticalOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.value}
                      </option>
                    ))}
                </select>
              </>
            )}
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="serviceCapabilities"
              className="block res150:text-[10px] res125:text-xs"
            >
              Service Capabilities
            </label>
            <div className=" items-center justify-center  mb-1.5 bg-white ">
              <div className=" border border-[#C8C8C8] ml-0 signup">
                <Multiselect
                  className="res125:text-xs res150:text-[10px]"
                  options={serviceRequiredOptions}
                  selectedValues={freeUser.serviceCapabilities}
                  onSelect={handleSerSelect}
                  onRemove={handleSerRemove}
                  displayValue="value"
                  placeholder="Select Service"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="softwareUsed"
              className="block res150:text-[10px] res125:text-xs"
            >
              Software capabilities & ownership
            </label>
            <div className=" items-center justify-center  mb-1.5 bg-white ">
              <div className=" border border-[#C8C8C8] ml-0 signup">
                <Multiselect
                  className="res125:text-xs res150:text-[10px]"
                  options={softwareOptions}
                  selectedValues={freeUser.softwareCapabilities}
                  onSelect={handleSoftSelect}
                  onRemove={handleSoftRemove}
                  displayValue="value"
                  placeholder="Select Software"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {formNo === 4 && (
        <div>
          <div className="flex flex-col mb-1">
            <label
              htmlFor="bankName"
              className="res150:text-[10px] res125:text-xs"
            >
              Bank Name
            </label>
            <input
              onChange={handleChange}
              value={freeUser.bankName}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10  "
              type="text"
              name="bankName"
              placeholder="Enter Bank Name"
            ></input>
          </div>

          <div className="flex flex-col mb-1">
            <label
              htmlFor="accountHolderName"
              className="res150:text-[10px] res125:text-xs"
            >
              A/C Name
            </label>
            <input
              onChange={handleChange}
              value={freeUser.accountHolderName}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px]  res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="text"
              name="accountHolderName"
              placeholder="Enter Account Name"
            ></input>
          </div>

          <div className="flex flex-col mb-1">
            <label
              htmlFor="accountNumber"
              className="res150:text-[10px] res125:text-xs"
            >
              A/C Number
            </label>
            <input
              onChange={handleChange}
              value={freeUser.accountNumber}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px]  res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="text"
              name="accountNumber"
              placeholder="Enter Account Number"
            ></input>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="ifscCode"
              className="res150:text-[10px] res125:text-xs"
            >
              IFSC Code
            </label>
            <input
              onChange={handleChange}
              value={freeUser.ifscCode}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px] res150:mt-1 res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="text"
              name="ifscCode"
              placeholder="Enter IFSC No"
            ></input>
          </div>

          <div className="flex flex-col mb-1">
            <label
              htmlFor="branchName"
              className="res150:text-[10px] res125:text-xs"
            >
              Branch Name
            </label>
            <input
              onChange={handleChange}
              value={freeUser.branchName}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px]  res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="text"
              name="branchName"
              placeholder="Enter Company Name"
            ></input>
          </div>

          <div className="flex flex-col mb-[10px]">
            <label
              htmlFor="country"
              className="res150:text-[10px] res125:text-xs"
            >
              Document
            </label>
            <input
              onChange={(e) => handleFiles(e)}
              className=" h-12 rounded border-[#C8C8C8] mt-[10px]  res150:text-[10px] res125:text-xs res150:h-8 res125:h-10 "
              type="file"
              name="docs"
            />
          </div>

          <div className="flex items-center mb-1">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label className="ml-2">
              I agree with{" "}
              <span className="text-blue-800" onClick={openPopup}>
                terms & conditions
              </span>
            </label>
          </div>
          {showPopup && <Popup handleClose={closePopup} />}

          <button
            type="submit"
            className={`w-[100%] h-12 bg-[#03298C] text-white rounded tracking-wider font-bold text-lg mt-4 mb-4 hover:bg-[#03298C] res150:text-[10px] res125:text-xs res150:h-9 res125:h-10  res150:mt-1 ${
              isDisabled ? "opacity-50 cursor-not-allowed" : ""
            }`}
            id="companysubmit"
            disabled={isDisabled}
            onClick={handleSubmit}
          >
            {loading ? (
              <>
                <img
                  src="https://media1.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif"
                  className="w-[3rem] mx-auto res150:w-[1.5rem] res125:w-[2rem]"
                  alt=""
                />
              </>
            ) : (
              "SIGN UP"
            )}
          </button>
        </div>
      )}

      {/* </form> */}
      {/*steps number and line  */}
      <div className="flex justify-between">
        {formNo != 1 ? (
          <div className="flex justify-start items-center res125:mt-1 res150:mt-1">
            <p
              onClick={back}
              className="text-gray-400 cursor-pointer font-bold hover:text-[#03298C] res125:text-sm res150:text-[10px] whitespace-nowrap"
            >
              <i
                className="fa fa-angle-left text-lg pr-1 font-bold res150:text-xs res125:text-base"
                aria-hidden="true"
              ></i>{" "}
              Back
            </p>
          </div>
        ) : (
          <div></div>
        )}

        <div className="flex justify-center items-center px-4 res150:text-xs res125:text-xs res150:w-[20rem] res125:w-[24rem]">
          {/* this is step number */}
          {formArray.map((v, i) => (
            <>
              <div
                className={`w-[40px] my-3 text-white rounded-full ${
                  formNo - 1 === i ||
                  formNo - 1 === i + 1 ||
                  formNo === formArray.length ||
                  formNo - 1 >= i
                    ? "bg-[#03298C]"
                    : "bg-gray-300"
                } h-[35px] flex justify-center items-center res150:w-[30px] res150:h-[30px]`}
              >
                {v}
              </div>
              {/*  line between number */}
              {i !== formArray.length - 1 && (
                <div
                  className={`w-[30px] h-[2px] ${
                    formNo === i + 4 || formNo === formArray.length
                      ? "bg-[#03298C] "
                      : "bg-gray-300"
                  }`}
                ></div>
              )}
            </>
          ))}
        </div>

        {formNo != 4 ? (
          <div className="flex justify-end items-center">
            <p
              onClick={next}
              className="text-gray-400 cursor-pointer font-bold hover:text-[#03298C] justify-end res150:text-[10px] res125:text-sm whitespace-nowrap"
            >
              Next{" "}
              <i
                class="fa fa-angle-right text-lg font-bold res150:text-xs res125:text-base"
                aria-hidden="true"
              ></i>
            </p>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      {/* <div className="flex justify-end">
          <Link
            to="/auth/signin"
            className="underline text-blue-300 res150:text-[10px] res125:text-xs res150:mt-2"
          >
            Already have an account ?
          </Link>
        </div> */}
    </div>
  );
}
