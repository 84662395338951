import AppContext from "../../../context/AppContext";
import EmailDetails from "./EmailDetails";
import EmailList from "./EmailList";
import { useContext } from "react";

const Mails = () => {
  const { selectedMail } = useContext(AppContext);

  return (
    <div className="flex ml-auto w-4/5  h-screen overflow-hidden">
      <EmailList />
      <EmailDetails showMailList={true} selectedMail={selectedMail} />
    </div>
  );
};

export default Mails;
