import axios, { Axios } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
// import Logo from "../../assets/images/Factree Hub Logo.png";
import SendOtp from "./verify/sendOtp";
import { BASE_URL, Logo } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import swal from "sweetalert";
import "../../App.css";
import Login from "./Login";
import PasswordSet from "./PasswordSet";
// import { useParams } from 'react-router-dom';

export default function Signin() {
  // page route
  const navigate = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { setType, setIsAuthenticated } = useContext(AppContext);
  // user state
  const [signUser, setUser] = useState({
    email: "",
    password: "",
  });
  const [verified, setVerfied] = useState(false);
  const [forget, setForget] = useState(false);
  const [otp, setOtp] = useState(null);
  const [forgetdetails, setforgetdetails] = useState({
    email: "",
    password: "",
  });
  const [logindata, setlogindata] = useState({});

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  // validation error
  const [error, setError] = useState(null);

  const handleChange = ({ target: { name, value } }) => {
    setUser((data) => {
      return { ...data, [name]: value };
    });
  };

  const handleOTPChange = (event) => {
    setOtp(event.target.value);
  };

  const forgetClickhandle = () => {
    setForget(!forget);
  };

  // form submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (!signUser.email || !signUser.password) {
        setError("* Please enter a valid email and password.");
      } else {
        setLoading(true);
        setIsDisabled(true);
        setError(false);
        const {
          data: { token, message, user },
        } = await axios.post(`${BASE_URL}/api/auth/login`, signUser);
        setlogindata(user);

        window.localStorage.setItem("Access", JSON.stringify(token));
        token ? setVerfied(true) : setVerfied(false);
      }
      setIsDisabled(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setIsDisabled(false);
      setError(error.response.data.message);
    }
  };

  const HandleforgetpassChange = ({ target: { name, value } }) => {
    setforgetdetails((data) => {
      return { ...data, [name]: value };
    });
  };

  const HandleforgetpasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!forgetdetails.email) {
        setError("please enter a mail id");
      } else {
        setLoading(true);
        setIsDisabled(true);
        await axios.post(`${BASE_URL}/api/forgotpassword`, {
          email: forgetdetails.email,
          password: forgetdetails.password,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    try {
      const data = await axios.post(`${BASE_URL}/api/auth/login`, {
        email: signUser.email,
        password: signUser.password,
      });
      toast.success("OTP Resent Successfully");
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!otp) {
        setError("enter otp");
      } else {
        setLoading(true);
        setIsDisabled(true);
        const {
          data: { message, data },
        } = await axios.post(`${BASE_URL}/api/verifyOtp`, {
          email: signUser.email,
          otp: otp,
        });
        window.localStorage.setItem("User", JSON.stringify(logindata));


        const today = new Date(Date.now());
        setLoading(false);
        setIsAuthenticated(true);
        let success = false;
        if (logindata.is_customer === true) {
          if (!logindata.expired_at) {
            setType("customer");
            navigate.push("/customer/projects");
          } else {
            const expireDate = new Date(data.expired_at);
            if (expireDate.getTime() >= today.getTime()) {
              success = true;
              setType("customer");
              navigate.push("/customer/projects");
              swal(`Welcome ${data.name}`, "Customer", "info");
            } else {
              setError("Your Account is Expired");
              toast.error("Your Account is Expired");
              swal(
                "Expired",
                "Your account has expired. Please contact the admin",
                "error"
              );
            }
          }
        } else if (data.is_admin === true) {
          success = true;
          setType("admin");
          navigate.push("/admin/mails");
          swal(`Welcome ${data.name}`, "Admin", "info");
        } else if (data.is_company === true || data.is_freelancer === true) {
          success = true;
          setType("service-provider");
          swal(`Welcome ${data.name}`, "Service-Provider", "info");
          navigate.push("/service-provider/projects");
        }
        if (success) {
          toast.success(message);
        }
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message; // Extract error message if available, otherwise use default
      toast.error(errorMessage);
      setLoading(false);
      setIsDisabled(false);
    }
  };

  const result = verified ? (
    <SendOtp
      handleOTPSubmit={handleOTPSubmit}
      handleOTPChange={handleOTPChange}
      isDisabled={isDisabled}
      loading={loading}
      error={error}
      handleResendOtp={handleResendOtp}
    />
  ) : (
    <Login
      handleSubmit={handleSubmit}
      error={error}
      forget={forget}
      handleChange={handleChange}
      handleTogglePassword={handleTogglePassword}
      isDisabled={isDisabled}
      loading={loading}
      showPassword={showPassword}
      forgetClickhandle={forgetClickhandle}
    />
  );

  return (
    <div className="  max-h-[650px] mx-auto my-[100px] w-full max-w-[600px] bg-white rounded-[36px] pt-[40px]">
      {/* factreeHub logo */}
      <img
        src={Logo}
        className={` w-[202px]   res125:w-1/4 res150:w-1/4 m-0 mx-auto max-h-[86px] ${
          verified ? "" : ""
        }`}
      />
      <div className="mt-[15px] res150:mt-8">
        <p className="text-3xl font-bold mb-[10px] w-full mx-auto text-center res125:text-xl res150:text-xl ">
          {forget ? "forgot-password" : "Sign In"}
        </p>

        {!forget && result}

        {forget && (
          <PasswordSet
            handleForgetSubmit={handleOTPSubmit}
            HandleforgetpassChange={HandleforgetpassChange}
            isDisabled={isDisabled}
            loading={loading}
            HandleforgetpasswordSubmit={HandleforgetpasswordSubmit}
            forgetClickhandle={forgetClickhandle}
          />
        )}
      </div>
    </div>
  );
}
