import React, { useState, useEffect, useRef, useContext } from "react";
import "../../../App.css";
import SinglePage from "../../Admin/Projects/annotations/SinglePage";
import ModifyPage from "../../Admin/Projects/annotations/ModifyPage";
import AutoTextArea from "../../Admin/Projects/annotations/AutoTextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUndo,
  faRedo,
  faPencil,
  faDownload,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../../context/AppContext";

export default function ProjectCollab({
  samplePDF,
  fileName,
  selectedData,
  type,
  handleCommentsChange,
}) {
  // const samplePDF = "https://factreehub-files.s3.ap-south-1.amazonaws.com/diagram/demo.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEA%2F9HEMpqlzq0bmc355g%2F8VKFIFH2yh5R90dff5bydBzcCIEtsrJa0Vp2JZ0sT43usXt8liPvrVJJ4bkBjUz%2BxvuC8KoUDCEsQAhoMOTI3NTkxMDcxNTE1IgxGpvFrw1cwrgBZy6Aq4gIYEqqaZx0fDs5vBwSC%2BuVLtwIkYvermzpMAuJmrT0TJgsR%2FWszPONfN8cJL8Ki4l%2F7GRLNQLZFPnLGdZzHETyov%2B3eMI4ovWEGjpitAncZqK4EIeiafCXF8g3gukresjGrGe8v3rKa9CiAbs50RDu2Sn2Oe%2BePnVZ8SCKE3tGYdibf%2B4lwQFTugJgLi3Utwhfs5ieLdTZRG3iG1Qxx8Yst8k3LVDTv36Ck%2B%2BaJMOJ8B1Mhl%2FrznglD8bV%2B6XiW0%2BTmnOQ5x6MSrFBiXi7UD0TjJtojS6iqVNxnpLnQgJ3ROZyc6mhExPdGbJwMNIPrTES3p5gs6aT3CbJFHUpzYJEWdMxvRt6oRb9yfHsqB1jUuYUZwAgCrxcQVcE5NcNWlCC3yANOlmM8cRU%2BCB7tQa3sNtpeiJgCx4K95x3pQDBmmB%2BI4089sHFRneXmFlflL4enxt5waLSo60d66rMwvh%2FQgPEwiY%2BjoAY6swLQ%2FKnhQSFXIrvoFoahJDdUkxoVtSxEiB%2B0YbwZSYhdIzps9olPXKETW0smyPoUghB%2FQOTEmJXPbt1tqblLguURvkpLviXoFnA0SWZ0s8XzcYVhsV8mTedquhB9Dmr9rrXwUBG30ilsXnsSTWXYLcYUJIUD6E667cQ%2B5kInqXYAi%2Fu8MDd%2BMKM5I%2Bn4gsTqmfrcxG0HrOa9bUGQn08T7N52gkaeLO1FxFAWUfA7gr5rVIIdanpS3XmXZqTYwBJ4r15QCgdkzXuLxwSJxD5myKy6ypPi4muqlWj%2FIIgmm97IKF6EFHQgC0WKnSfsdGDu1YWs9cIurxW8xwkxKldhQ2jzrogGyFwcTMR1QZOEDlRRTP9xEAAe%2B1AWkXBbZJA6%2F4KRzYIIflvB5M8xM0NkoRDFtaCB&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230308T183427Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIA5P6F4P4NSQXK6FKU%2F20230308%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=66c8216318c9bd651b69bb9faba6455dbbf0d5606edc7cb560cbd51f3e218718"
  const [result, setResult] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [redoStack, setRedoStack] = useState([]);
  const [redoComments, setRedoComments] = useState([]);
  const [flag, setFlag] = useState("");
  const [bounds, setBounds] = useState({});
  const [isText, setIsText] = useState(false);
  const [buttonType, setButtonType] = useState("");
  const tempRef = useRef(null);
  const {
    comments,
    setComments,
    setTextChanged,
    setPageno,
    setCommentsUpdated,
    rect,
  } = useContext(AppContext);

  useEffect(() => {
    setPageno(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (isText) {
      setIsText(false);
    }
  }, [result]);

  //Keep track of current page number
  const pageChange = (num) => {
    setPageNumber(num);
  };


  //Function to add text in PDF
  const addText = () => {
    //Flag to change cursor if text
    setIsText(true);
    document.getElementById("drawArea").addEventListener(
      "click",
      (e) => {
        e.preventDefault();
        // console.log("234253963", e.pageX, e.pageY - 10);
        // console.log(
        //   "axis",
        //   e.clientX,
        //   e.pageX,
        //   rect.left,
        //   e.clientY,
        //   e.pageY,
        //   rect.bottom
        // );
        const id = generateKey(e.pageX);
        setResult((result) => [
          ...result,
          {
            id,
            x: e.pageX,
            y: e.pageY - 10,
            text: "",
            page: pageNumber,
            type: "text",
            ref: tempRef,
          },
        ]);
        setCommentsUpdated(true);
        setComments((comments) => [
          ...comments,
          {
            id,
            x: e.pageX - 10,
            y: e.pageY - 20,
            text: "",
            page: pageNumber,
            type: "text",
            pageNumber,
          },
        ]);
        handleCommentsChange();
      },
      { once: true }
    );
  };

  //Undo function for both line and text
  const undo = () => {
    let temp = result.pop();
    let tempcomments;
    if (temp?.type === "text") {
      tempcomments = comments.pop();
    }
    if (temp) {
      if (temp?.type === "freehand") {
        //Flag for DrawArea reference
        setFlag("undo");
      }
      setRedoStack((stack) => [...stack, temp]);
      setResult([...result]);
      if (temp?.type === "text") {
        setRedoComments((stack) => [...stack, tempcomments]);
        setComments([comments]);
        handleCommentsChange();
      }
    }
  };

  //Flag for DrawArea reference
  const changeFlag = () => {
    setFlag("");
  };

  //Redo functionality
  const redo = () => {
    let top = redoStack.pop();
    let topComments;
    if (top?.type === "text") {
      topComments = redoComments.pop();
    }
    if (top) {
      if (top?.type === "freehand") {
        //Flag for DrawArea reference
        setFlag("redo");
      }
      setResult((res) => [...res, top]);
      if (top?.type === "text") {
        setComments((res) => [...res, topComments]);
        handleCommentsChange();
      }
    }
  };

  const getPaths = (el) => {
    setResult((res) => [...res, el]);
  };

  const getBounds = (obj) => {
    setBounds(obj);
  };

  const generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`;
  };

  const onTextChange = (id, txt, ref) => {
    let indx = result.findIndex((x) => x.id === id);
    let item = { ...result[indx] };
    item.text = txt;
    item.ref = ref;
    result[indx] = item;
    let index = comments.findIndex((x) => x.id === id);
    let comment = { ...comments[index] };
    comment.text = txt;
    comments[index] = comment;
    setCommentsUpdated(true);
    setResult(result);
    setComments(comments);
    setTextChanged(true);
    handleCommentsChange();
  };

  const changeButtonType = (type) => {
    setButtonType(type);
  };

  const resetButtonType = () => {
    setButtonType("");
  };

  console.log("jki", selectedData);
  return (
    <div className="">
      {result.map((res) => {
        if (res?.type === "text") {
          let isShowing = "hidden";
          if (res?.page === pageNumber) {
            isShowing = "visible";
          }
          return (
            <AutoTextArea
              key={res.id}
              unique_key={res.id}
              val={res.text}
              y={res.y}
              onTextChange={onTextChange}
              style={{
                visibility: isShowing,
                color: "red",
                fontWeight: "normal",
                fontSize: 16,
                zIndex: 20,
                position: "absolute",
                left: res.x + "px",
                top: res.y + "px",
                width:
                  document.getElementById("drawArea")?.offsetLeft +
                  document.getElementById("drawArea")?.offsetWidth -
                  res.x,
                // overflowX: "hidden"
              }}
              width={
                document.getElementById("drawArea")?.offsetLeft +
                document.getElementById("drawArea")?.offsetWidth -
                res.x -
                5
              }
            ></AutoTextArea>
          );
        } else {
          return null;
        }
      })}

      {type !== "customer" && (
        <div className=" flex items-center mt-0 ml-3 ">
          <button
            title="Undo"
            className="px-2 py-1.5 mr-2 bg-white hover:bg-blue-100"
            onClick={undo}
            style={{ marginTop: "1%", marginBottom: "1%" }}
          >
            <FontAwesomeIcon icon={faUndo} color="blue" size="xl" />
          </button>
          <button
            title="Redo"
            className="px-2 py-1.5 mr-2 bg-white hover:bg-blue-100"
            onClick={redo}
            style={{ marginTop: "1%", marginBottom: "1%" }}
          >
            <FontAwesomeIcon icon={faRedo} color="blue" size="xl" />
          </button>
          <hr className="border-gray-400 bg-black w-0.5 h-10 mr-2" />
          <button
            title="Text"
            className="px-3 py-0 mr-2 bg-white hover:bg-blue-100"
            onClick={addText}
            style={{ marginTop: "1%", marginBottom: "1%" }}
          >
            <span className=" text-blue-700 font-serif text-3xl font-medium">
              T
            </span>
          </button>
          <button
            title="Pen"
            className="px-2 py-1.5 mr-2 bg-white hover:bg-blue-100"
            onClick={() => changeButtonType("draw")}
            style={{ marginTop: "1%", marginBottom: "1%" }}
          >
            <FontAwesomeIcon icon={faPencil} color="blue" size="xl" />
          </button>
          <hr className="border-gray-400 bg-black w-0.5 h-10 mr-2" />
          <button
            title="Download"
            className="px-2 py-1.5 ml-auto bg-white hover:bg-blue-100 mr-2"
            onClick={() => changeButtonType("download")}
            style={{ marginTop: "1%", marginBottom: "1%" }}
          >
            <FontAwesomeIcon icon={faDownload} color="blue" size="xl" />
          </button>
          {selectedData.adminStatus === "Rework" && (
            <button
              // disabled={selectedData.status !== "Rework"}
              title="Upload"
              className="px-2 py-1.5 mr-2 bg-white hover:bg-blue-100 "
              onClick={() => changeButtonType("upload")}
              style={{ marginTop: "1%", marginBottom: "1%" }}
            >
              <FontAwesomeIcon icon={faUpload} color="blue" size="xl" />
            </button>
          )}
        </div>
      )}

      {type === "customer" && (
        <div className=" flex items-center mt-0 ml-3">
          <button
            title="Download"
            className="px-2 py-1.5 ml-auto bg-white hover:bg-blue-100 mr-2"
            onClick={() => changeButtonType("download")}
            style={{ marginTop: "1%", marginBottom: "1%" }}
          >
            <FontAwesomeIcon icon={faDownload} color="blue" size="xl" />
          </button>
        </div>
      )}
      <hr />
      <SinglePage
        resetButtonType={resetButtonType}
        buttonType={buttonType}
        cursor={isText ? "text" : "default"}
        pdf={samplePDF}
        pageChange={pageChange}
        getPaths={getPaths}
        flag={flag}
        getBounds={getBounds}
        changeFlag={changeFlag}
        result={result}
        pageNumber={pageNumber}
        onTextChange={onTextChange}
      />
      <ModifyPage
        resetButtonType={resetButtonType}
        buttonType={buttonType}
        pdf={samplePDF}
        result={result}
        bounds={bounds}
        fileName={fileName}
        selectedData={selectedData}
      />
    </div>
  );
}
