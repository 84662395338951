import React from "react";
import CredentialsForm from "../../Admin/Projects/Credentials/CredentialsForm";
import CustomerCredentialsForm from "../../Customer/MailIds/CustomerCredentialsForm";
import TeamsCredentialsForm from "../../Shared/Teams/TeamsCredentialsForm";

const NewCredentialsDrawer = (props) => {
  const { show, setShow, type, cred_type } = props;

  return (
    <div>
      {/* Overlay */}
      {show && (
        <div className="bg-black/50 fixed w-full h-full z-10 top-0 right-0"></div>
      )}

      {/* Side drawer*/}
      <div
        className={
          show
            ? "fixed top-0 right-0 w-[420px] res125:w-[21rem] res150:w-[17.5rem] h-full z-10 duration-300"
            : "fixed top-0 right-[-100%] w-[420px] h-full z-10 duration-300"
        }
        style={{
          background: "linear-gradient(90deg, #F8F8F8 0%, #FFFFFF 74.69%)",
        }}
      >
        <div className="h-[85%] mt-24 res125:mt-20 res150:mt-16 ml-10 res125:ml-9 res150:ml-8 w-[340px] res125:w-60 res150:w-52">
          <p className="text-[#011A59] font-bold text-xl tracking-wide res125:text-base res150:text-sm">
            {type}
          </p>
          {cred_type === "Credentials" && (
            <CredentialsForm show={show} setShow={setShow} />
          )}
          {cred_type === "Teams" && (
            <TeamsCredentialsForm show={show} setShow={setShow} type={type} />
          )}
          {cred_type === "Customer" && (
            <CustomerCredentialsForm
              show={show}
              setShow={setShow}
              type={type}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewCredentialsDrawer;
